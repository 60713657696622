import React, { Fragment, useState, useEffect } from 'react';
import {setAppCustomTitle} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import requestApi from '../../services/requestApi.service';
import {Row, Col, Button, Label, Input, InputGroup} from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { modalContent } from './constants';
import Loading from '../../Common/Loading';
import TooltipItem from '../../Common/ToolTip';

const Calendar = (props) => {
    const {agent_id,saveDetails,actionId} = props;
    const [modalDetails] = useState(modalContent['book_calendar']);
    const [inputData, setInputData] = useState({});
    const [customOptions, setCustomOptions] = useState({});
    const [customField ,setCustomField] = useState(false);
    const {control, handleSubmit, formState: { errors },trigger,clearErrors,setError } = useForm();
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; 
    const [showLoading, setshowLoading] = useState(false);

    useEffect(async () => {
         if(actionId !== undefined && actionId !== ""){
            await getActionDetailsbyActionId();
          }else{
            getActionDetailsbyAppId();
          }
        await fetchCalendarEvents("gcalendar");
    }, []);

    const fetchCalendarEvents = async (calendarName) => {
        setshowLoading(true);
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "GET_CALENDARS",
                'calendarName': calendarName,
            };
            
            let response = await requestApi.processServerRequest(params);
            if(response.issuccess){
                let calendarData = response.data;
                setCustomOptions(prevState => ({
                    ...prevState,
                    "calendar_type": calendarData, // Replace `newKey` with the key you want to add and `newValue` with the value.
                }));
            }
        setshowLoading(false);    
    }

    const onSave = async () => {
        await saveDetails(inputData,inputData.action_name,"book_calendar","during_call");
    };

    const handleInputChange = (e, value, type) => {
        let tmpInputData = { ...inputData };
        if (type === "dropdown") {
            tmpInputData[value] = e; 
            if(value === "location"){
                if(e.value !== "custom"){
                    setCustomField(false);
                    tmpInputData['location_custom'] = "";
                }else{
                    setCustomField(true);
                }
            }
            if(value === "calendar"){
                fetchCalendarEvents(e.value);
            }
        }else if(type === "date"){
            tmpInputData[value] = new Date(e); 
        } else {
            tmpInputData[e.target.name] = e.target.value; 
        }
        setInputData(tmpInputData);
    };

    const getActionDetailsbyActionId = async(value="book_calendar",onaction="during_call") =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            if(actionId){
                if(data.action_type == "book_calendar"){
                    data.start_date = new Date(data.start_date);
                }
                setInputData(data);

            }else{
                let calendarValues = modalContent['book_calendar'];
                const updatedInputData = { ...inputData, onaction, action_type: value };
                calendarValues.map((option) => {
                    const { name: fieldname, type, options: dropdownMenu } = option;
                    if (type === "dropdown") {
                        updatedInputData[fieldname] = dropdownMenu[0];
                    }else if(type === "date"){
                        updatedInputData[fieldname] = new Date();
                    }
                });
                setInputData(updatedInputData);
            }
            await fetchCalendarEvents();
        }
    }

    const getActionDetailsbyAppId = async(value="book_calendar",onaction="during_call") =>{
        let calendarValues = modalContent['book_calendar'];
        const updatedInputData = { ...inputData, onaction, action_type: value };
        calendarValues.map((option) => {
            const { name: fieldname, type, options: dropdownMenu } = option;
            if (type === "dropdown") {
                updatedInputData[fieldname] = dropdownMenu[0];
            }else if(type === "date"){
                updatedInputData[fieldname] = new Date();
            }
        });
        setInputData(updatedInputData);
    }


    return(
        <>
        <Loading showLoader={showLoading} mainContent={
        <Fragment>
             <form id="agency-form" name={'agency'} onSubmit={handleSubmit(onSave)}>
                <div>
                    {modalDetails !== undefined && modalDetails !== null && modalDetails !== "" && modalDetails ?
                        modalDetails.map((option, index) => {
                            let label = option.label;
                            let fieldname = option.name;
                            let type = option.type;
                            let dropdownMenu = [];
                            let infoNeeded = option.infoNeeded === true ? true : false;
                            if(type === "dropdown"){
                                dropdownMenu = option.options.length > 0 ? option.options : customOptions[fieldname];
                            }
                            let tempData = inputData || {};
                            let calendarAppName = inputData?.["calendar"]?.["value"] || "";
                            let maxVal = type === "number" ? (fieldname === "slots_per_day" ? 10 : fieldname === "slots_break" ? 60 : undefined) : undefined;
                            let minVal = type === "number" ? (fieldname === "slots_per_day" ? 0 : fieldname === "slots_break" ? 0 : undefined) : undefined;
                            if(calendarAppName == "ghl_calendar" || calendarAppName == "cal.com"){
                                if (["slots_per_day", "slots_break", "booking_window","duration","working_hours","start_date"].includes(fieldname)){
                                    return;
                                }
                            }
                            return (
                                <div key={index}>
                                    <div className={type !== "date" && type !== "week" ? "ps-1" : "hide"}>
                                        <InputGroup>
                                        <Label className=''>{label} </Label>
                                            <div className={infoNeeded ? 'ps-2' : 'hide'}><TooltipItem id={index} text={option.info} content={<i class="fi fi-ss-info"></i>}/>
                                            </div>
                                        </InputGroup>
                                        <Controller
                                            name={fieldname}
                                            control={control}
                                            rules={{
                                                required: fieldname !== "working_hours" && fieldname !== "delay" && !tempData[fieldname] || tempData[fieldname] === "" ? `Enter "${label}"` : "",
                                            }}
                                            render={({ field }) =>
                                                type !== "dropdown" ? (
                                                <Input
                                                    {...field}
                                                    id={fieldname}
                                                    autoComplete="off"
                                                    placeholder={`Enter ${label}`}
                                                    onChange={(e) => {
                                                        const toolNamePattern = /^$|^[a-zA-Z0-9_-]{1,64}$/;
                                                        if (!toolNamePattern.test(e.target.value) && fieldname == "action_name") {
                                                            setError("action_name", {
                                                                type: "manual",
                                                                message: "Action Name must be 1-64 characters and only contain letters, numbers, hyphens, or underscores.",
                                                            });
                                                        } else {
                                                            clearErrors('action_name');
                                                            handleInputChange(e, fieldname, "text");
                                                            field.onChange(e);
                                                        }
                                                    }}
                                                    value={tempData[fieldname] ?? (fieldname === "delay" ? 2 : "")}
                                                    type={type === "text" ? "text" : "number"}
                                                    min={type === "number" ? minVal : undefined}  
                                                    max={type === "number" ?  maxVal : undefined}
                                                    className={`form-control mb-2 ${errors[fieldname] ? "is-invalid" : ""}`}
                                                    disabled = {fieldname === "delay" ? true : false}
                                                />
                                                ) : (
                                                <Select
                                                    {...field}
                                                    placeholder="---Choose One---"
                                                    name={fieldname}
                                                    id={fieldname}
                                                    className="basic-select mb-2"
                                                    classNamePrefix="sub-select wa-select"
                                                    value={tempData[fieldname]}
                                                    defaultValue={dropdownMenu !== undefined && dropdownMenu.length > 0 ? dropdownMenu[0] : {}}
                                                    onChange={(e) => {
                                                    handleInputChange(e, fieldname, "dropdown");
                                                    field.onChange(e);
                                                    }}
                                                    menuPlacement="top"
                                                    options={dropdownMenu}
                                                />
                                                )
                                            }
                                            />
                                            {errors[fieldname] && (<em className="mb-2 error invalid-feedback">{errors[fieldname].message}</em>)}
                                            <div className={customField && fieldname === "location" ? "" : "hide"}>
                                                <Controller
                                                    name="location_custom"
                                                    control={control}
                                                    rules={{ required: customField && (inputData.location_custom === undefined || inputData.location_custom === "") ? "Please provide url" : "" }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            name="location_custom"
                                                            id="location_custom"
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                                handleInputChange(e,fieldname,"text");
                                                                field.onChange(e);
                                                            }}
                                                            type='text'
                                                            value={tempData.location_custom}
                                                            placeholder='e.g. https://apidocs.voicemetrics.ai'
                                                            className={`form-control mb-2 ${errors.location_custom ? 'is-invalid' : ''}`}
                                                        />
                                                    )}
                                                />
                                                {errors.location_custom && <em className="mb-2 error invalid-feedback">{errors.location_custom.message}</em>}
                                            </div>
                                    </div>
                                    <div className={type === "date" ? "mb-3 mt-4 ps-1" : "hide"}>
                                        <Row>
                                            <Col md={5} className={type === "date" ? "mt-3":"mt-2"}>
                                                <Label>{label}</Label>
                                            </Col>
                                            {type !== "date" ? (
                                                <Col md={7} className="week-btn-container">
                                                    {weekDays.map((day, index) => (
                                                        <Button key={index} className="week-btn pt-1">
                                                            {day[0]}
                                                        </Button>
                                                    ))}
                                                </Col>
                                            ) : (
                                                <Col md={7} className="mt-2">
                                                    <InputGroup>
                                                        <div className="input-group-text">
                                                            <i className="fi fi-rr-calendar-days"></i>
                                                        </div>
                                                        <DatePicker
                                                            selected={inputData[fieldname]}
                                                            selectsStart
                                                            className="form-control"
                                                            startDate={inputData[fieldname]}
                                                            onChange={(e) => handleInputChange(e,fieldname,"date")}
                                                            dateFormat="dd-MMM-yyyy"
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            );
                        })
                    :
                    <>
                    </>}
                </div>
                <div className='agent-action-dropdown mt-4'>
                    <Button type='submit' className="btn-icon date-filter-toggle theme-bg-g btn-hover-shine" color="link"><span className='pe-1'>
                    </span>{"Add Action"}
                    </Button>
                </div>
            </form>
        </Fragment>}>
        </Loading>
        </>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);