import React, { Fragment } from "react";
import { useTheme } from "../Context/ThemeContext";
import CallLog from "../Components/CallAnalysis/Calllog";
import PricingList from "../Components/Agency/PricingList";
import Payment from "../Components/Agency/Payment";

const ContextControl = (props) => {
    const url = window.location.href;
    const parts = url.split("/"); 
    const demosIndex = parts.indexOf("#") + 1; 
    const extractedValue = parts[demosIndex]; // "demos"
    const { subCurrency, processCurrency } = useTheme();  // Get currency from context
    if(extractedValue === "call-details"){
        return (
            <Fragment>
                <CallLog currency={subCurrency} data={props}/>
            </Fragment>
        );
    }else if(extractedValue === "subscription-tiers"){
        return (
            <Fragment>
                <PricingList currency={subCurrency} />
            </Fragment>
        );
    }else if(extractedValue === "stripe-integration"){
        return (
            <Fragment>
                <Payment processCurrency={processCurrency} />
            </Fragment>
        );
    }else{
        return (
            <Fragment>
            </Fragment>
        );
    }
};

export default ContextControl;


