import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../../reducers/ThemeOptions";
import { Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Button, Container } from "reactstrap";
import Select from "react-select";
import { useForm , Controller} from "react-hook-form";
import { convertUTCToLocalTime, formatDate, getLicenseinfoByAppid, getObjectByValue, getSessionValue, parseJSON, formatToTwoDecimals } from "../../../Common/Appcommon";
import { licenseTypes } from "./constants";
import requestApiService from "../../../services/requestApi.service";
import Loading from "../../../Common/Loading";
import ListTable from "../../../Common/ListTable";

const LicenseManager = (props) => {
    const [formData, setFormData] = useState([]);
    const [rowData, setRowData] = useState({});
    const [licenseinfo, setLicenseinfo] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [paymentdetails, setPaymentdetails] = useState({});
    const appid = props.accountInfo && props.accountInfo.appid ? props.accountInfo.appid : "";
    const { handleSubmit, control, formState: { errors } ,clearErrors} = useForm();


    useEffect(() => {
        getLicenseHistory();
        processLicenseInfo();
        setShowLoading(false);
    }, []);
    
    // useEffect(()=>{
    //     formateFormData();
    // },[formData])

    const toggle = () => {
        setRowData({});
        clearErrors();
        setIsOpen(prevState => !prevState);
    };

    const getLicenseHistory = async () => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_LICENSE_HISTORY",
            "subaccount_appid": appid,
        }
        let result = await requestApiService.processServerRequest(params, "WebV2");
        if(result.issuccess){
            setFormData(result.data);
        }
    }

    const saveLicenseHistory = async () => {
        setShowLoading(true);
        let userName = getSessionValue("loginuser");
        userName = userName == 'sadmin' ? 'System' : userName;
        rowData["remarks"] = rowData["remarks"] + `   (Created By User : ${userName})` 
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_LICENSE_HISTORY",
            "subaccount_appid": appid,
            "FORMINPUT": rowData
        }
        let result = await requestApiService.processServerRequest(params, "WebV2");
        if(result.issuccess){
            setFormData(result.data);
            await processLicenseInfo();
            toggle();
        }
        setShowLoading(false);
    }

    const processLicenseInfo = async () => {
        let licenseinfo = await getLicenseinfoByAppid(appid);
        if(licenseinfo.paymentdetails && licenseinfo.paymentdetails !== ""){
            setPaymentdetails(parseJSON(licenseinfo.paymentdetails) ?? {});
        }
        setLicenseinfo(licenseinfo);
    }

    const processConfigData = (data) => {
        let license_type = data['license_type'];
        if(license_type){
            let license_value = license_type["value"];
            let closing = 0;
            let new_add = 0;
            if(license_value === "license_days"){
                const end = new Date(licenseinfo["license_end_date"]);
                const today = new Date();
                const differenceInTime = end - today;
                closing = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            } else if (license_value === "free_min"){
                if(licenseinfo["total_free_min"] !== "UL"){
                    const actual_free_min = parseInt(licenseinfo["actual_free_min"]);
                    const total_free_min = parseInt(licenseinfo["total_free_min"]);
                    closing = total_free_min - actual_free_min;
                    closing = closing / 60;
                }else{
                    closing = "UL";
                }
            }
            setRowData(prevState => ({
                ...prevState,
                ["license_type"]: data['license_type'],
                ["closing"]: closing,
                ["new_add"]: new_add,
                ["cur_avl"]: closing,
            }));
        }
    }

    const handleInputChange = (e) => {
        let {name, value} = e.target

        if(name === "new_add" && isNaN(value)){
            return;
        }else if(name === "new_add"){
            if(value === ""){
                value = 0;
            }else{
                value = parseInt(value);
            }
            if(value > 1111111111111111){
                return;
            }
            processCalculate(value);
        }
        if(name === "remarks" && value.length > 30){
            return;
        }
        setRowData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const processCalculate = (new_add) => {
        let closing = rowData["closing"];
        let total_new = closing + new_add;
        setRowData(prevState => ({
            ...prevState,
            ["cur_avl"]: rowData["closing"] !== "UL" ? parseInt(total_new) : new_add
        }));
    }

    const handleSelectChange = (selected, name) => {
        if(name === "license_type"){
            rowData[name] = selected;
            processConfigData(rowData)
        }
    };

    const getModalContainer = () => {
        return(
            <Fragment>
                <Modal isOpen={isOpen}>
                    <Form onSubmit={handleSubmit(saveLicenseHistory)}>
                        <ModalHeader toggle={toggle}>
                            Add License
                        </ModalHeader>
                        <ModalBody>
                                <Row md={12} className="m-3">
                                    <Col md={12}>
                                        <Label for="license_type">License Type	</Label>
                                        <Controller
                                            name="license_type"
                                            control={control}
                                            rules={{ required: rowData["license_type"] === undefined  || rowData["license_type"] === "" || rowData["license_type"] === null  ? "Please provide License Type" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        name={"license_type"}
                                                        id="license_type"
                                                        options={licenseTypes}
                                                        className={` basic-select ${errors.license_type ? 'is-invalid' : ''}`}
                                                        classNamePrefix="sub-select wa-select"
                                                        value={rowData["license_type"]}
                                                        onChange={e => handleSelectChange(e, "license_type")}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.license_type && <em className={`error invalid-feedback`}>{errors.license_type.message}</em>}
                                    </Col>
                                </Row>
                                <Row md={12} className="m-3">
                                    <Col md={12}>
                                        <Label for="closing">Closing Balance</Label>
                                        <Controller
                                            name="closing"
                                            control={control}
                                            rules={{ required: false && rowData["closing"] === undefined  || rowData["closing"] === "" || rowData["closing"] === null  ? "Please provide Closing balance" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Input 
                                                        type="text"
                                                        name="closing"
                                                        id="closing"
                                                        className={`form-control ${errors.closing ? 'is-invalid' : ''}`}
                                                        value={formatToTwoDecimals(rowData["closing"])}
                                                        disabled
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.closing && <em className={`error invalid-feedback`}>{errors.closing.message}</em>}
                                    </Col>
                                </Row>
                                <Row md={12} className="m-3">
                                    <Col md={12}>
                                        <Label for="new_add">New Addition</Label>
                                        <Controller
                                            name="new_add"
                                            control={control}
                                            rules={{ required: ((rowData && rowData["license_type"] && rowData["license_type"]["value"])) && rowData["new_add"] === undefined  || rowData["new_add"] === "" || rowData["new_add"] === null  ? " Please provide New Addition" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Input 
                                                        type="text"
                                                        name="new_add"
                                                        id="new_add"
                                                        value={rowData["new_add"]}
                                                        className={`form-control ${errors.new_add ? 'is-invalid' : ''}`}
                                                        onChange={e => handleInputChange(e)}
                                                        disabled={!(rowData && rowData["license_type"] && rowData["license_type"]["value"])}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.new_add && <em className={`error invalid-feedback`}>{errors.new_add.message}</em>}
                                    </Col>
                                </Row>
                                <Row md={12} className="m-3">
                                    <Col md={12}>
                                        <Label for="cur_avl">Current Available Balance</Label>
                                        <Controller
                                            name="cur_avl"
                                            control={control}
                                            rules={{ required: false && rowData["cur_avl"] === undefined  || rowData["cur_avl"] === "" || rowData["cur_avl"] === null  ? " Please provide Current Avaliable Balance" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Input 
                                                        type="text"
                                                        name="cur_avl"
                                                        id="cur_avl"
                                                        className={`form-control ${errors.cur_avl ? 'is-invalid' : ''}`}
                                                        value={formatToTwoDecimals(rowData["cur_avl"])}
                                                        disabled
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.cur_avl && <em className={`error invalid-feedback`}>{errors.cur_avl.message}</em>}
                                    </Col>
                                </Row>
                                <Row md={12} className="m-3">
                                    <Col md={12}>
                                        <Label for="remarks">Remarks</Label>
                                        <Controller
                                            name="remarks"
                                            control={control}
                                            rules={{ required: rowData["remarks"] === undefined  || rowData["remarks"] === "" || rowData["remarks"] === null  ? " Please provide Current Avaliable Balance" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Input 
                                                        type="text"
                                                        name="remarks"
                                                        id="remarks"
                                                        className={`form-control ${errors.remarks ? 'is-invalid' : ''}`}
                                                        onChange={e => handleInputChange(e)}
                                                        value={rowData["remarks"]}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.remarks && <em className={`error invalid-feedback`}>{errors.remarks.message}</em>}
                                    </Col>
                                </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Loading showLoader = {showLoading} mainContent = {
                                <Button type={'submit'} className="theme-bg-g">
                                    Save
                                </Button>
                                }
                            />
                        </ModalFooter>
                    </Form>
                </Modal>
            </Fragment>
        );
    }

    const columns = [
        // {
        //     name: (
        //         <div>
        //             S.No
        //         </div>
        //     ),
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        //     style: {
        //         padding: 0,
        //         margin: 0,
        //         justifyContent: 'center'
        //     },
        //     width: "60px !important",
        // },
        {
            name: (
                <div>
                    Updated on
                </div>
            ),
            selector: row => formatDate(convertUTCToLocalTime(row["date_time"]), "mmm dd, yyyy, hh:MM:ss TT"),
            sortable: true,
            maxWidth: "230px !important"
        },
        {
            name: (
                <div>
                    Category
                </div>
            ),
            selector: row => (row && row["type"] ? getObjectByValue(licenseTypes, row["type"]).label : ""),
            sortable: true,
            maxWidth: "100px !important"
        },
        {
            name: (
                <div>
                    New Entries
                </div>
            ),
            selector: row => row["newaddition"],
            sortable: true,
            maxWidth: "100px !important",
        },
        {
            name: (
                <div>
                    Remarks
                </div>
            ),
            selector: row => row["remarks"],
            sortable: true,
        },
        /* {
            name: (
                <div>
                    Actions
                </div>
            ),
            cell: (row) => (
                <div>
                    <Button
                        onClick={() => deleteData(row)}
                        className="theme-bg-danger mt-1">
                        <i class="fi fi-rs-trash"></i>
                    </Button>
                </div>
            ),
            minWidth: "90px",
            maxWidth: "10px",
        } */
    ];

    // const  formateFormData = () =>{
    //     formData && formData.map((d, i) => {
    //         let value = formData[i]['date_time'];
    //         formData[i]['date_time'] = formatDate(convertUTCToLocalTime(value), "mmm dd, yyyy, hh:MM:ss TT");
    //     });
    // }

    let end_date = licenseinfo["license_end_date"];
    let total_free_min = licenseinfo["total_free_min"];
    let actual_free_min = licenseinfo["actual_free_min"];
    let total_addon_min = licenseinfo["total_addon_min"];
    let actual_addon_min = licenseinfo["actual_addon_min"];
    let free_min = total_free_min !== "UL" ? (((parseInt(total_free_min) - parseInt(actual_free_min)) / 60).toFixed(2)) : "UL";
    let addon_min = total_free_min !== "UL" ? (((parseInt(total_addon_min) - parseInt(actual_addon_min)) / 60).toFixed(2)) : "UL";
    const today = new Date();
    const targetDate = new Date(end_date);
    const differenceInTime = targetDate - today;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    end_date = formatDate(end_date, "d mmmm, yyyy");

    return (
        <Fragment>
            <Container>
                {
                    getModalContainer()
                }
                <div>
                    <div className="d-flex">
                        <div className="ms-3 mt-3">
                            <p>Current License Valid Until: <strong>{end_date} ({differenceInDays} days remaining) </strong></p>
                        </div>
                        <span className=" ms-3 mt-3 separator">|</span>
                        <div className="ms-3 mt-3">
                            <p>Available Free Minutes: <strong>{free_min} minutes</strong></p>
                        </div>
                        <span className="ms-3 mt-3 separator">|</span>
                        <div className="ms-3 mt-3">
                            <p>Available Add-on Minutes: <strong>{addon_min} minutes</strong></p>
                        </div>
                        <div className="ms-auto me-3 mt-2">
                            <Button onClick={e => toggle()} className="theme-bg-g">
                                Add
                            </Button>
                        </div>
                    </div>
                    <Loading 
                        showLoader={showLoading} 
                        mainContent={
                            <ListTable 
                                columns={columns} 
                                data={formData} 
                            />
                        }
                    />
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseManager);
