import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../../reducers/ThemeOptions";
import { connect } from "react-redux";
import cx from "classnames";
import { Button, ButtonGroup, Card, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';

import AddPlanModal from './AddPlanModal';
import requestApi from '../../../services/requestApi.service';
import { parseJSON, confirmBox, showToast, getSessionValue, checkIsAdmin, translateContent } from '../../../Common/Appcommon';
import {plansCycle} from '../assets/css/constants';

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        let data_row = props.accountinfo.data_row;
        this.state={
            account_id: props.accountinfo.account_id,
            account_appid: data_row.appid ?? "",
            newPlanModalOpen: false,
            plansList : [],
            planSelected: 30,
            rowData: {},
            selectedindex: "",
            isSubAccountView: props.isSubAccountView,
            purchasedplans: [],
            currency: props.currency,
            isAdmin:false
        }
    }

    componentDidMount = async () => {  
        await this.getPlanPricing();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        // Check if currency changed
        if (prevState.currency !== this.state.currency) {
            await this.getPlanPricing();
        }
    }
    
    getPlanPricing = async () => {
        let {isSubAccountView, account_appid} = this.state;
        if(this.props.showloader){
            this.props.showloader(true);
        }
        let adminProfile = await checkIsAdmin();
        this.setState({isAdmin:adminProfile});
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_PLAN_PRICING",
            account_id: this.state.account_id,
            isSubAccountView: isSubAccountView,
            account_appid: account_appid,
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let plan = response.data;
                if(plan.length > 0){
                    this.setState({
                        plansList : plan[0],
                        purchasedplans: plan[1],
                    })
                }
                if(this.props.showloader){
                    this.props.showloader(false);
                }
            }
        } catch (error) {
            if(this.props.showloader){
                this.props.showloader(false);
            }
            console.error("Error fetching plan pricing:", error);
        }
    }

    getStripeDetails = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_STRIPE_DETAILS",
            METHOD: "ACCOUNT_ID",
        };
        const response = await requestApi.processServerRequest(params);
        if (response.issuccess && response.data.length > 0) {
            const formResponse = response.data[0];
            const account_id =  formResponse["account_id"];
            if(account_id !== ''){
                return true;
            }
        }
        return false;
    };
    
    handleModalState =  async (rowData, selectedindex='', oncreate=false) => {
        if(oncreate){
        }
        this.setState((prevState) => ({
            rowData : rowData ?? "",
            newPlanModalOpen: !prevState.newPlanModalOpen,
            selectedindex : selectedindex,
        }));
    }

    onRadioBtnClick = (selectedplan) => {
        this.setState({
            planSelected : selectedplan
        })
    }

    newPlanAddPlanList = async (plandata) => {
        await this.getPlanPricing();
        /* let {plansList, selectedindex} = this.state;
        plansList[selectedindex] = plandata;
        this.setState({
            plansList: plansList
        }); */
    }

    handleStripePopup = async () =>{
        let isStripeConnected = true;
        let IsAccountConnected = await this.getStripeDetails();
        let isAgency = true;
        if (this.state.isSubAccountView) {
            isAgency = false;
        }
        if (!IsAccountConnected && !isAgency) {
            isStripeConnected = false;
            const userConfirmed = await confirmBox(
                "Connect Stripe",
                translateContent("vm.subaccount.pricing.warning.msg"),
                'warning',
                'Connect Stripe',
                'Cancel'
            )
            if(userConfirmed){
                window.location.hash = `#/stripe-integration`;
            }else{
                return;
            }
        }
        return isStripeConnected;
    }

    getPlanButtons = () => {
        
        return (
            <>
                <ButtonGroup className="mb-2">
                    {
                        plansCycle.map(({label, value, isHide}, index) => {

                            return (
                                <Button 
                                    className={`btn-outline-2x btn-square ${isHide ? "hide" : ""}`} 
                                    outline 
                                    color="light" 
                                    name={value}
                                    onClick={() => this.onRadioBtnClick(value)} 
                                    active={this.state.planSelected === value}
                                >
                                    {label}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </>
        );
    }
    
    openPopover = (name, plan, selectedindex) => {
        if(name === "edit"){
            this.handleModalState(plan, selectedindex);
        }else if(name === "delete"){
            this.deletePlan(plan);
        }else if(name === "select"){
            this.planSelect(plan);
        }else if(name === "deselect"){
            this.planSelect(plan, false);
        }
    }

    planSelect = async (plan, isSelected = true) => {
        let stripeConnected = await this.handleStripePopup();
        if(!stripeConnected){
            return;
        }
        let {account_id, account_appid} = this.state;
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SELECT_PLAN_SUB",
            account_id: account_id,
            account_appid: account_appid,
            FORMINPUT: plan,
            is_select: isSelected,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                await this.getPlanPricing();
            }else{
                showToast(response.errormsg, "top-right", "error");
            }
        } catch (error) {
            console.error("Error save to Plan:", error);
        }
    };

    deletePlan = async (plan) => {
        let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"),'warning','Delete','Cancel');
        if (!confirm) {
            return;
        }
        let {isSubAccountView, account_appid} = this.state;
        let planId = plan.plan_id ?? "";
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "DELETE_PLAN",
            plan_id: planId,
            isSubAccountView: isSubAccountView,
            account_appid: account_appid,
            isOtherApiReq: true
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                /* this.setState({
                    plansList : response.data
                }); */
                await this.getPlanPricing();
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }

    getMainContent = () => {
        let { plansList, planSelected , isSubAccountView, purchasedplans, currency, isAdmin} = this.state;
        let popoverOptions = [];
        return (
        <Fragment>
            <div className='account-pricing-container'>  
                <div className='pricing-header d-flex'>
                    {/* <div className='pricing-header-left'>
                        <h4>
                            Plan List
                        </h4>
                    </div> */}
                    <div className={`${isAdmin ? 'pricing-header-right' : 'hide'}`}>
                        <Button className='add-plan-btn theme-bg-g' onClick={e => {this.handleModalState("","",true)}}><FontAwesomeIcon icon={faPlus} className='me-2'/>Create Subscription Tier</Button> {/* Pricing Tier */}
                    </div>
                </div>
                <div className='pricing-body m-3'>
                    <div className='plans-btn'>
                        {this.getPlanButtons()}
                    </div>
                    <div className='plans-list m-3'>
                        <Row lg="12">
                            {
                                plansList.map((plan, index) => {
                                    let {plan_id, plan_name, status, plan_cost, plan_description, annual_discount, other_features, is_popular, is_agent_created, Type, features_included} = plan;
                                    if (planSelected === 360) {
                                        plan_cost =  plan_cost - (plan_cost * (annual_discount / 100));
                                    }

                                    if (plan_cost) {
                                        plan_cost = Number(plan_cost).toFixed(0); // Converts to a number and rounds to integer
                                    }

                                    let isBuyedPlan = purchasedplans.includes(plan_id);
                                    if(!isAdmin || (isSubAccountView && (is_agent_created === "true" || isBuyedPlan))){
                                        popoverOptions = [];
                                    }else{
                                        popoverOptions = [
                                            {
                                                label: "Edit",
                                                name: "edit",
                                            },
                                            {
                                                label: "Delete",
                                                name: "delete",
                                            }
                                        ];
                                    }

                                    if(Type !== ""){
                                        plan.Type = parseJSON(Type);               
                                    }
                                    if(other_features !== ""){
                                        other_features = parseJSON(other_features);               
                                    }
                                    if(features_included !== ""){
                                        features_included = parseJSON(features_included);               
                                    }
                                    let plan_type = plan && plan.type && plan.type.value ? plan.type.value : "";
                                    let plan_price = currency + plan_cost + "/m";
                                    if(plan_type === "LTD"){
                                        plan_price = currency + plan_cost + "/One-Time";
                                    }
                                    return (
                                        <Col lg="4">
                                            <Card className={cx('main-card mb-3 plan-card', {"selected-plan most-popular-plan" : is_popular === "1"}, {"cursor-not-allowed" : isBuyedPlan})} >
                                                <div className='plan-details' title={isBuyedPlan ? "The customer has already purchased this plan." : ""}>
                                                    <div>
                                                        {
                                                            isSubAccountView && 
                                                            <Input 
                                                                id={index} 
                                                                type="checkbox" 
                                                                checked={status === "Active"} 
                                                                className="custom-checkbox" 
                                                                onClick={e => {this.openPopover(status === "Active" ? "deselect" : "select", plan, index)}}
                                                                disabled={isBuyedPlan}
                                                            />
                                                        }
                                                        <div className='plan-title col-md-12'>
                                                            <div className='col-md-6'>
                                                                <span className='plan-name'>
                                                                    {plan_name}
                                                                </span>
                                                            </div>
                                                            <div className='col-md-6 plan-popular'>
                                                                {
                                                                    is_popular === "1" && 
                                                                    <span className='recommended-tag'>
                                                                        Most Popular
                                                                    </span>
                                                                }
                                                                {
                                                                popoverOptions.length > 0 && 
                                                                    <div className='plan-desc'>
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle className="p-0" color="link" onClick={e => { e.stopPropagation()}}>
                                                                                <Button className="btn-icon plan-config" color="link">
                                                                                    <FontAwesomeIcon className='theme-c-g' icon={faEllipsisV}/>
                                                                                </Button>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu end className="dropdown-menu-right dropdown-menu-rounded">
                                                                                {
                                                                                    popoverOptions.map((option, i) => {
                                                                                        let label = option.label;
                                                                                        let name = option.name;
                                                                                        return (
                                                                                            <>
                                                                                                <DropdownItem onClick={e => { e.stopPropagation(); this.openPopover(name, plan, index)}}>{label}</DropdownItem>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='plan-title-desc'>
                                                            {plan_description}
                                                        </div>
                                                    </div>
                                                    {
                                                        features_included && 
                                                        features_included.offered_text &&
                                                        <div className='offered-plan-price text-decoration-line-through fw-light'>
                                                            {features_included.offered_text}
                                                        </div>
                                                    }
                                                    <div className='plan-price'>
                                                        {plan_price}
                                                    </div>
                                                    <div className='plan-features'>
                                                        {
                                                            other_features && other_features.map((val, index) => {
                                                            let iconKey = Object.keys(val).find(key => key.startsWith('cus_icon_'));
                                                            let descKey = Object.keys(val).find(key => key.startsWith('cus_desc_'));
                                                            let boldKey = Object.keys(val).find(key => key.startsWith('cus_bold_'));
                                                            let underlineKey = Object.keys(val).find(key => key.startsWith('cus_underline_'));
                                                            let icon = val[iconKey];
                                                            let desc = val[descKey];
                                                            let bold = val[boldKey];
                                                            let underline = val[underlineKey];
                                                                return(
                                                                    <>
                                                                        {
                                                                            desc !== "" && <div className='plan-feature-description' key={index}>
                                                                                <span className='plan-feature-text'>
                                                                                    {icon}
                                                                                </span>
                                                                                <span className={cx('plan-feature-text', { "fw-bold" : bold }, { "text-decoration-underline" : underline })}>
                                                                                    {desc}
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>
      )
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currency !== prevState.currency) {
            return { currency: nextProps.currency };
        }
        return null;
    }
    
    render() {
        let { newPlanModalOpen, rowData, account_id, account_appid, isSubAccountView } = this.state;
        return (
            <Fragment>
                {this.getMainContent()}
                {
                    newPlanModalOpen && 
                    <AddPlanModal 
                        isOpen={newPlanModalOpen} 
                        toggle={e => {this.handleModalState()}} 
                        newPlanData={this.newPlanAddPlanList} 
                        row={rowData} 
                        account_id={account_id} 
                        account_appid={account_appid} 
                        isSubAccountView={isSubAccountView}
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);