import React, { Fragment, useState, useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { getSessionValue, clearSessionValue, getLogoutURL, clearLegacyCookie, handleErrorResponse, translateContent } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';
import cx from "classnames";
import { useTheme } from '../../Context/ThemeContext';
import { Label, Col, Input, InputGroup, Button, Row, FormText, Modal, ModalBody, ModalHeader, ModalFooter,} from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showToast, confirmBox } from '../../Common/Appcommon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import { LanguageOptions } from './Constants/Constant';
import { useLanguage } from '../../Context/LanguageContext';
import { useForm, Controller } from "react-hook-form";
import rotation_lock from "../../assets/utils/images/rotation-lock.png";
import dark_rotation_lock from "../../assets/utils/images/dark-rotation-lock.png";
import diamond_exclamation_img from "../../assets/utils/images/diamond-exclamation.png";
import dark_diamond_exclamation_img from "../../assets/utils/images/diamond-exclamation-dark.png";

const Profile = () => {
    const [isToggleTheme, setIsToggleTheme] = useState(false);
    const { theme, toggleTheme } = useTheme();
    const [isCopied, setTextCopied] = useState(false);
    const [apiKey, setapiKey] = useState("");
    const [lastUpdated, setlastUpdated] = useState("");
    const [showAPI, setshowAPI] = useState(false);
    const { language, toggleLanguage } = useLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [languageOptions, setLanguageOptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, trigger,clearErrors } = useForm();
    const [pwdDetails, setPwdDetails] = useState({});
    const [Errormsg,setErrormsg] = useState('');
        useEffect(() => {
        if (theme === "dark-theme") {
            setIsToggleTheme(true);
        }
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        (async () => {
            await loadProfileData();
        })();
    }, []);

    const loadProfileData = async(isNewApi = false) => {
        let appid = getSessionValue("appid");
        let username = getSessionValue("loginuser");
        let usertableid = getSessionValue("loginuserid");
        let params = {
            "ENTITY" : "AIPROCESSOR",
            "OPERATION" : "LOAD_PROFILE",
            "isexist" : isNewApi ? "true" : "false",
            "isfirsttime": isNewApi ? "true" : "false",
            "APPID": appid,
            "username": username,
            "usertableid": usertableid,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            let apiData = data['api'];
            let languages = data['languages'];
            if (isNewApi) {
                showToast("New Key Generated");
            }
            if(language === "" || language === null){
                toggleLanguage(language);
            }
            if(language !== ""){
                languages.map((option, index) => {
                    let value = option.value;
                    if(value === language){
                        setSelectedLanguage(option);
                    }
                })
            }
            setLanguageOptions(languages);
            setapiKey(apiData[1] !== undefined && apiData[1] !== null && apiData[1] !== "" ? apiData[1] : "");
            setlastUpdated(apiData[2] !== undefined && apiData[2] !== null && apiData[2] !== "" ? apiData[2] : "");
        }
    }

    const getAPIKey = async (isNewApi = false) => {
        let appid = getSessionValue("appid");
        let username = getSessionValue("loginuser");
        let usertableid = getSessionValue("loginuserid");
        let params = {
            "ENTITY": "API_PROCESS",
            "OPERATION": "GENERATE_API_KEY",
            "isexist": isNewApi ? "true" : "false",
            "isfirsttime": isNewApi ? "true" : "false",
            "APPID": appid,
            "username": username,
            "usertableid": usertableid,
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            if (isNewApi) {
                showToast("New Key Generated");
            }
            setapiKey(data[1] !== undefined && data[1] !== null && data[1] !== "" ? data[1] : "");
            setlastUpdated(data[2] !== undefined && data[2] !== null && data[2] !== "" ? data[2] : "");
        }
    }

    const generateNewKey = async () => {
        let generateNewKey = await confirmBox("API Key", "API key exists already.click yes to reset or click no to view exist key.", 'warning', 'Yes', 'No');
        if (generateNewKey) {
            setshowAPI(true);
            await getAPIKey(true);
        }
    }

    const copyUrl = () => {
        setTextCopied(true);
    }

    const logoutApp = async (e) => {
        //let activeSessionCount = 1;
        let params = {
            "ENTITY": "session_check",
        }
        let sessionResponse = await requestApi.processServerRequest(params);
        if (sessionResponse.issuccess) {
            //activeSessionCount = sessionResponse.data['activeSessions'];
            let params = {
                "ENTITY": "logout",
                'logoutfromall': false
            };
            clearLegacyCookie();
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                clearSessionValue();
                if (response.data !== "") {
                    window.location = response.data;
                } else {
                    window.location = getLogoutURL();
                }
            } else {
                handleErrorResponse(response.errormsg);
            }
        }
    }

    const updateThemeSelection = async (theme) => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_THEME",
            "THEME": theme,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {

        }
    }

    const onLanguageChange = async (lang) => {
        let {value} = lang;
        if(selectedLanguage !== lang){
            let confirm = await confirmBox("Attention!", "This feature uses Google Realtime Translation from English To Selected Language. You may briefly see English text before it appears in your selected language. Do you want to continue?", document.body.className === 'dark-theme' ? dark_diamond_exclamation_img : diamond_exclamation_img , 'Continue', 'Cancel');
            if (!confirm) {
                return;
            }
        }else if(selectedLanguage === lang){
                return;
        }
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_LANGUAGE",
            "LANGUAGE": value,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            toggleLanguage(value);
            setSelectedLanguage(lang);
            setTimeout(() => {
                window.location.hash = "#/login_check";
            }, 150);
        }
    }

    const onThemeChange = () => {
        setIsToggleTheme(!isToggleTheme);
        let themeOption = "light-theme";
        if (!isToggleTheme) {
            themeOption = "dark-theme";
        }
        toggleTheme(themeOption);
        updateThemeSelection(themeOption);
    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
        setPwdDetails({});
        clearErrors();
        setErrormsg('');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'oldPwd'){
            setErrormsg('');
        }
        setPwdDetails((prev) => ({ ...prev, [name]: value }));
    };  

    const onsubmit = async () =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "REST_PASSWORD",
            "FORMDATA": pwdDetails   
        }
        let close = true;
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            close = true;
            showToast(translateContent("vm.users.password.change"),'top-right','success',function () {
                window.location = getLogoutURL();
            });
        }else{
            close = false;
            setErrormsg(response.errormsg);
            // showToast(response.errormsg,'top-right','error');
        }
        if(close){
            toggleModal();
        }
    }
    const getModalContent = () => {
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={toggleModal}>
                        Reset Password
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                        <form id="agency-form" name={'agency'}>
                                <Label>Current Password</Label>
                                <Controller
                                    name="oldPwd"
                                    control={control}
                                    rules={{ 
                                        required: (pwdDetails.oldPwd === undefined || pwdDetails.oldPwd === "" || pwdDetails.oldPwd === null) ? "Please provide Current Password" : false ,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="oldPwd"
                                            autoComplete="off"
                                            placeholder="Enter your current password..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            type='password'
                                            value={pwdDetails.oldPwd}
                                            className={`form-control mb-2 ${(errors.oldPwd || Errormsg !== '') ? 'is-invalid' : ''}`}
                                        />
                                    )}
                                />
                                {(errors.oldPwd || Errormsg !== '') && (<em className="mb-2 error invalid-feedback">{errors.oldPwd?.message || Errormsg}</em>)}                                <Label>New Password </Label>
                                <Controller
                                    name="newPwd"
                                    control={control}
                                    rules={{
                                        required: (pwdDetails.newPwd === undefined || pwdDetails.newPwd === "" ||  pwdDetails.newPwd === null) ? "Please provide New Password": false,
                                        validate: (value) => {
                                            if (value === "") {
                                              return "New password can't be empty!";
                                            } else if (value.length < 8) {
                                              return "Password must be at least 8 characters long!";
                                            } else if (!/[0-9]/.test(value)) {
                                              return "Password must include at least one numeric digit!";
                                            } else if (!/[!@#$%^&*(),.?\":{}|<>]/.test(value)) {
                                              return "Password must include at least one special character!";
                                            } else if (/\s/.test(value)) {
                                              return "Password cannot contain spaces!";
                                            } else if (!/[a-zA-Z]/.test(value)) {
                                              return "Password must include at least one alphabetic character!";
                                            }
                                            return true;
                                        }
                                    }}

                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="userEmail"
                                            type="password"
                                            autoComplete="off"
                                            placeholder="Set a new password..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            value={pwdDetails.newPwd}
                                            className={`form-control mb-2 ${errors.newPwd ? 'is-invalid' : ''}`}
                                        />
                                    )}
                                />
                                {errors.newPwd && <em className="mb-2 error invalid-feedback">{errors.newPwd.message}</em>}
                                <Label>Confirm Password</Label>
                                <Controller
                                name="confirmPwd"
                                control={control}
                                rules={{
                                    required: (pwdDetails.confirmPwd === undefined ||  pwdDetails.confirmPwd === "" ||  pwdDetails.confirmPwd === null) ? "Please confirm your New Password" : false,
                                    validate: (value) =>
                                        value !== pwdDetails.newPwd ? "Passwords do not match" : true,                                    
                                }}
                                render={({ field }) => (
                                    <Input
                                    {...field}
                                    id="confirmPwd"
                                    type="password"
                                    autoComplete="off"
                                    placeholder="Confirm your new password…"
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        field.onChange(e);
                                    }}
                                    value={pwdDetails.confirmPwd}
                                    className={`form-control mb-2 ${errors.confirmPwd ? 'is-invalid' : ''}`}
                                    />
                                )}
                                />
                                {errors.confirmPwd && <em className="mb-2 error invalid-feedback">{errors.confirmPwd.message}</em>}
                            </form>
                        </Fragment>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={toggleModal} className={"theme-bg-g"}>                            
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit(onsubmit)} className={"theme-bg-g"}>                            
                            Reset Password
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }  
    const getComfirmBox = async () =>{
        let confirm = await confirmBox("Reset Password", translateContent("vm.users.reset.password.confirm"), document.body.className === 'dark-theme' ? dark_rotation_lock : rotation_lock , 'Reset', 'No');
        if(confirm){
            toggleModal();
        }
    }
    let userName = getSessionValue("loginuser");
    let userEmail = getSessionValue("loginemail");
    let isSubAccount = getSessionValue("isSubAccountView") == "true" ? true : false;

    return (
        <Fragment>
            <Common titleContent={
                <span>Profile</span>
            } title = {
                "Profile"
            }
            mainContent={
                <div className='container overflow-auto h-100'>
                    <div className='agency-details'>
                        <div className='card-fields d-grid'>
                            <span class="heading">Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Email</span>
                            <span className='profile-desc'>{userEmail}</span>
                        </div>
                        <div className='card-fields hide'>
                            <span class="heading">Business Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Theme</span>
                            <div className="switch has-switch mb-2 me-2" data-on-label="ON"
                                data-off-label="OFF" onClick={onThemeChange}>
                                <div className={cx("switch-animate", {
                                    "switch-on": isToggleTheme,
                                    "switch-off": !isToggleTheme,
                                })}>
                                    <input type="checkbox" />
                                    <span className={`switch-left bg-dark ${isToggleTheme ? 'pe-3 ps-3 pt-1' : ''}`}>Dark</span>
                                    <label>&nbsp;</label>
                                    <span className="switch-right bg-dark ps-2 pt-1">Light</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='hide'>
                            <Row>
                                <span class="heading pb-2">API Key</span>
                                <Col md={6} className="pr-0">
                                    <div className="d-flex align-items-center">
                                        <InputGroup>
                                            <Input value={showAPI ? apiKey : `************${apiKey.slice(-4)}`} className={`google-ads-webhook-url`} disabled={true} />
                                            <div className="input-group-text theme-bg-g">
                                                <CopyToClipboard onCopy={copyUrl} text={apiKey}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </CopyToClipboard>
                                            </div>
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={4} className='ps-2'>
                                    <div className="d-flex align-items-center">
                                        <Button className={"theme-bg-g api-btn-height"} onClick={generateNewKey}>
                                            {'Re-generate API Key'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {isCopied && (
                                <FormText color="success" className="mb-2 ">Text has been copied.</FormText>
                            )}
                            <div className='hide'>
                                <Label className='pt-3'>Last Updated At</Label>
                                <div className='font-bold'>
                                    {lastUpdated}
                                </div>
                            </div>
                        </div>
                        <div className='hide'>
                            <div className='col-md-6'>
                            <span className='heading'>Language</span>
                            <Select
                                isClearable={false}
                                onChange={(eve) => {
                                    onLanguageChange(eve)
                                }}
                                placeholder={"Choose a Language"}
                                value={selectedLanguage}
                                options={languageOptions}
                                className='basic-multi-select-lan me-4'
                                classNamePrefix="wa-select"
                                menuPlacement={"bottom"}
                            />
                            </div>
                        </div>
                        <div className='d-flex'>
                            <button onClick={(e) => logoutApp(e)} className='btn btn-light theme-bg-g custom-display'><i class="fi fi-bs-sign-out-alt"></i><span className='pl-1'>Log out</span></button>
                            <span className='profile-desc ms-3'>
                                <Button onClick={getComfirmBox}  className={"theme-bg-g"}>
                                        <i class="fi fi-rr-password-lock"></i>
                                        <span className='ms-2'>Reset Password</span>
                                </Button>
                            </span>
                        </div>
                    </div>
                </div>
            } subAccountView = {isSubAccount}/>
            {isModalOpen && getModalContent()}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
