import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Card, Col, Container, Row } from "reactstrap";
import cx from "classnames";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { useStepsContext } from "./context";
import NoRecordFound from "../Common/NoRecordFound";
import { confirmBoxWithImage, parseJSON, setSessionValue } from "../../Common/Appcommon";
import { useTheme } from "../../Context/ThemeContext";

const SubscriptionPlans = (props) => {
    const { 
        plansList,
        noofMonth, 
        setNoofMonth,
        stripePromise,
        currentplan,
        setCurrentStep,
        setSelectedPlan,
        currentStep,
        currencyInfo,
        paymentDetails,
        subaccountCount,
    } = useStepsContext();
    const {subCurrency} = useTheme();
    const checkIfAllLTD = (plans) => {
        return plans.every(plan => {
            let type = plan.type && plan.type !== "" ? plan.type : "";
            if(type !== ""){
                type = parseJSON(type);
            }else{
                return false;
            }
            return (type && type.value === "LTD");
        });
    };

    const getPlanHeader = () => {
        const plansCycle = [
            {
                label : "Monthly",
                value : 1,
                isHide : false,
            },
            {
                label : "Quarterly",
                value : 3,
                isHide : true,
            },
            {
                label : "Half Yearly",
                value : 6,
                isHide : true,
            },
            {
                label : "Annually",
                value : 12,
                isHide : false,
            }
        ];
        return (
            <>
                {
                    checkIfAllLTD(plansList) ? (
                        <div className="text-center">
                            <h3 className="text-decoration-underline"><b>Limited-Time Deal!</b></h3>
                            <h3><b>Voicemetrics Lifetime Offer – No Recurring Fees!</b></h3>
                            <h5>🔥 Grab This Lifetime Deal Before It’s Gone! 🔥</h5>
                        </div>
                    ) : (
                        <div className='plans-btn'>
                            <ButtonGroup className="mb-2">
                                {
                                    plansCycle.map(({label, value, isHide}, index) => {
                                        let key = "btn_no_" + index;
                                        return (
                                            <Button 
                                                key={key}
                                                className={`btn-outline-2x btn-square ${isHide ? "hide" : ""}`} 
                                                outline 
                                                color="light" 
                                                name={value}
                                                onClick={() => onRadioBtnClick(value)} 
                                                active={noofMonth === value}
                                            >
                                                {label}
                                            </Button>
                                        )
                                    })
                                }
                            </ButtonGroup>
                        </div>
                        
                    )
                }
            </>
        );
    }

    const onRadioBtnClick = (selectedplan) => {
        setNoofMonth(selectedplan)
    }

    const numberToText = (num) => {
        const numbers = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];
        return numbers[num] || num;
    }

    const handleBuyPlan = async (plan) => {
        if(plan.features_included !== ""){
            let features = parseJSON(plan.features_included);
            if(Object.keys(features).length > 0 && features.max_subaccount){
                let max = features.max_subaccount;
                setSessionValue('max_accounts_allowed',max);
                if(max !== "UL" && subaccountCount > max){
                    setSessionValue('max_accounts',true);
                    let header = "Kind Attention!";
                    let body = "You have more than " + numberToText(max) + " Sub-Accounts in your account. Please upgrade to a higher plan or delete an existing sub-accounts before purchasing this plan.";
                    let confirmbtn = "Go to Sub Accounts";
                    let cancelbtn = "Close";
                    let confirm = await confirmBoxWithImage(header, body, 'warning', confirmbtn, cancelbtn);
                    if(confirm){
                        window.location.hash = "#/subaccounts";
                    }
                    return;
                }
            }
        }
        setCurrentStep(2);
        setSelectedPlan(plan)
    }

    const handleGoBack = () => {
        if(currentStep === 1 && Object.keys(paymentDetails).length > 1){
            setCurrentStep(6)
        }
    }
    return(
        <Fragment>
            <Container fluid>
                <div className='account-pricing-container'>
                    {
                        currentStep === 1 &&
                        Object.keys(paymentDetails).length > 1 &&
                        <div className={"m-3 "} style={{gap: "8px", display: "flex"}}>
                            <a className='cursor-pointer mr-3' onClick={e => handleGoBack()}>
                                <span className='d-flex align-center'>
                                    <i class="fi fi-br-arrow-left pr-1"></i> <span>Go Back</span>
                                </span>
                            </a>
                        </div>
                    }
                    {
                        plansList && plansList.length > 0 ? (
                            <div className='pricing-body m-3'>
                                {getPlanHeader()}
                                <div className='plans-list m-3'>
                                    { 
                                        <Row lg="12">
                                            {
                                                plansList.map((plan, index) => {
                                                    let {plan_id, plan_name, plan_cost, plan_description, annual_discount, is_popular, other_features, actual_customers, type, features_included} = plan;
                                                    if (noofMonth === 12) {
                                                        plan_cost =  plan_cost - (plan_cost * (annual_discount / 100));
                                                    }
                                                    if (plan_cost) {
                                                        plan_cost = Number(plan_cost).toFixed(0); // Converts to a number and rounds to integer
                                                    }
                                                    if(other_features !== ""){
                                                        other_features = parseJSON(other_features);               
                                                    }
                                                    if(type !== ""){
                                                        type = parseJSON(type);
                                                    }
                                                    if(!actual_customers){
                                                        // if actual customers was null or undefined it will be allow default
                                                        actual_customers = 1;
                                                    }
                                                    let isCurrentPlan = (currentplan.subscription_status === "Active" && plan_id === currentplan.plan_id) ?? false;
                                                    let buy_plan_name = isCurrentPlan ? "Current Plan" : (actual_customers > 0 ? "Subscribe " + plan_name : "Expired");
                                                    let plan_cost_label = currencyInfo && currencyInfo.name ? currencyInfo.name : subCurrency ?? "$";
                                                    if(type && type.value && type.value === "LTD"){
                                                        buy_plan_name = isCurrentPlan ? "Current Plan" : (actual_customers > 0 ? "Buy " + plan_name : "Expired");
                                                        plan_cost_label = plan_cost_label + plan_cost + "/One-Time";
                                                    }else{
                                                        plan_cost_label = plan_cost_label + plan_cost + "/m";
                                                    }

                                                    if(features_included !== ""){
                                                        features_included = parseJSON(features_included);               
                                                    }
                                                    return (
                                                        <Col lg="4">
                                                            <Card className={`main-card mb-3 plan-card ${is_popular === "1" ? "selected-plan" : (isCurrentPlan ? "current-plan" : "")}`}>
                                                                <div className='plan-details'>
                                                                    <div>
                                                                        <div className='plan-title col-md-12'>
                                                                            <div className='col-md-6'>
                                                                                <span className='plan-name font-lbyl'>
                                                                                    {plan_name}
                                                                                </span>
                                                                            </div>
                                                                            <div className='col-md-6 plan-popular'>
                                                                                {
                                                                                    is_popular === "1" && 
                                                                                    <span className='recommended-tag'>
                                                                                        Most Popular
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='plan-title-desc font-lbyl'>
                                                                            {plan_description}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        features_included && 
                                                                        features_included.offered_text &&
                                                                        <div className='offered-plan-price text-decoration-line-through '>
                                                                            {features_included.offered_text}
                                                                        </div>
                                                                    }
                                                                    <div className='plan-price font-lbyl'>
                                                                        {plan_cost_label}
                                                                    </div>
                                                                    <div className='plan-features'>
                                                                        {
                                                                            other_features && other_features.map((val, index) => {
                                                                            let iconKey = Object.keys(val).find(key => key.startsWith('cus_icon_'));
                                                                            let descKey = Object.keys(val).find(key => key.startsWith('cus_desc_'));
                                                                            let boldKey = Object.keys(val).find(key => key.startsWith('cus_bold_'));
                                                                            let underlineKey = Object.keys(val).find(key => key.startsWith('cus_underline_'));
                                                                            let icon = val[iconKey];
                                                                            let desc = val[descKey];
                                                                            let bold = val[boldKey];
                                                                            let underline = val[underlineKey];
                                                                                return(
                                                                                    <>
                                                                                        {
                                                                                            desc !== "" && <div className='plan-feature-description' key={index}>
                                                                                                <span className='plan-feature-text'>
                                                                                                    {icon}
                                                                                                </span>
                                                                                                <span className={cx('plan-feature-text', { "fw-bold" : bold }, { "text-decoration-underline" : underline })}>
                                                                                                    {desc}
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        <div className='plan-footer'>
                                                                            <Button className='w-100 theme-bg-g' onClick={e => {e.stopPropagation(); handleBuyPlan(plan)}} disabled={!stripePromise || isCurrentPlan || actual_customers <= 0}>
                                                                                {buy_plan_name && buy_plan_name.toLocaleUpperCase()}
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    }
                                </div>
                            </div>
                        ) : (!props.showLoader &&
                            <div>
                                <NoRecordFound isOnlyContent = {true}/>
                            </div>
                        )
                    }
                </div>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);