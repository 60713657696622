import React, { Fragment, useEffect, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import Tabs from "react-responsive-tabs";
import ProviderCredentials from './ProviderCredentials';
import AccountGeneral from './AccountGeneral';
import Pricing from './Pricing/index.js';
import Users from '../Settings/Users.js';
import TooltipItem from '../../Common/ToolTip.js';
import Permissions from './Permissions';
import LicenseManager from "./LIcenseManager"
import { useTheme } from '../../Context/ThemeContext';
import AgencySettings from './AgencySettings';
import { getSessionValue } from '../../Common/Appcommon.js';

const SubAccountDetails = (props) => {
    let { accountInfo, backToViewAction, openSubAccountView,limitReached } = props;
    let { subCurrency } = useTheme();
    let [customer_type,setCurrentType] = useState();
    let [balance_days,setBalanceDays] = useState();
    useEffect(() => {
        const fetchData = async () => {
            let custom_type = getSessionValue("customer_type");
            let balance_day = getSessionValue("balance_days");
            setCurrentType(custom_type);
            setBalanceDays(balance_day);
        }
        fetchData();
    }, []);

    const subtables = [
        {
            name: "General",
            component:
                <AccountGeneral accountInfo={accountInfo} />,
        },
        {
            name: "Provider Credentials",
            component:
                <ProviderCredentials accountInfo={accountInfo} />,
        },
        {
            name:"Permissions",
            component:
               <Permissions accountInfo={accountInfo}/>
        },
        {
            name: "Pricing",
            component:
                <Pricing 
                    accountinfo={accountInfo} 
                    isSubAccountView={true} 
                    currency={subCurrency}
                />
        },
        {
            name: "Users",
            component:
                <Users accountInfo={accountInfo} />,
        },
        {
            name:"License",
            component:
               <LicenseManager accountInfo={accountInfo}/>
        },
        {
            name:"Settings",
            component:
               <AgencySettings accountInfo={accountInfo}/>
        }
    ];

    const getSimpleTabs = () => {
        if(customer_type !== undefined && customer_type){
            let type = customer_type;
            if (type === 'Trial' && balance_days < 0) {
                return subtables
                    .filter(({ name }) => name === "General")
                    .map(({ name, component }, index) => ({
                        key: index,
                        title: name,
                        getContent: () => component,
                    }));
            }
        }
        return subtables.map(({ name, component }, index) => ({
            key: index,
            title: name,
            getContent: () => component,
        }));
    }

    return (
        <Fragment>
            <div className='subaccount-detailed-view'>
                <div className='account-details'>

                    <Row>
                        <Col md={6} className='rdw-image-left'>
                            <div className=''>
                                <span class="card-heading" >
                                    {accountInfo.account_name}
                                </span>
                            </div>
                        </Col>
                        <Col md={6} className=' rdw-image-right'>
                            <div className='ml-2'>
                                <div className='back-action d-flex align-center pe-5'>
                                    <a className='cursor-pointer theme-c-link' onClick={(e) => { backToViewAction() }}>
                                        <span className='d-flex align-center'>
                                            <i class="fi fi-br-arrow-left pr-1"></i> <span className=' text-d-underline'>Back to List View</span>
                                        </span>
                                    </a>
                                    <span className={limitReached ? 'hide' : ''}>
                                    <span className='pl-1'>|</span>
                                        <TooltipItem id={'2'} text={"Access `" + accountInfo.account_name + "` account"} content={<a onClick={(e) => { openSubAccountView(accountInfo) }} className='pl-1 cursor-pointer theme-c-link '><span className='text-d-underline'>Access Account</span> <i class="fi fi-bs-arrow-up-right-from-square"></i></a>} />
                                    </span>

                                </div>

                            </div>
                        </Col>
                    </Row>

                </div>
                <div className='agent-details agent-other-details'>
                    <Row>
                        <Col md="12">
                            <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                                showMore={true}
                                transform={true}
                                showInkBar={true}
                                selectedTabKey={0}
                                transformWidth={600}
                                items={getSimpleTabs()} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountDetails);
