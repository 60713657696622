import React, { Fragment, useEffect, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { providers,providersSelectList } from './assets/css/constants';
import Form from '../../Common/Form';
import Select from "react-select";
import requestApi from '../../services/requestApi.service';
import { confirmBox, handleErrorResponse, parseJSON, showToast,getTimeDiff, translateContent,getWarningIcon } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';

const ProviderCredentials = (props) => {
    const {accountInfo} = props;
    let {data_row} = accountInfo;
    //const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const [formData, setFormData] = useState({concurrency_limit:8});
    const [selectProviderList, setSelectproviderList] = useState(providersSelectList[0]);
    const [provider , setProvider] = useState(providersSelectList[0].value);
    const [showButtonLoading,setShowButtonLoading] = useState(false);
    const [lastSyncTime ,setLastSyncTime] = useState("");
    const [lastSyncStatus , setStatus] = useState(true);

    useEffect(async () => {
        await getproviderData();
        await getLastSyncTime();
    }, []);

    /*
        const numbers = [1, 2, 3, 4];
        reduce() is a method in JavaScript used to "reduce" an array into a single value, 
        typically an object, array, or even a number. 
        It applies a function to each item in the array and accumulates a result, which is passed on to the next iteration.
        // Using reduce to sum the numbers
        const sum = numbers.reduce((acc, num) => {
            return acc + num;
        }, 0);  // Initial accumulator value is 0
    */

    /* const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    } */

    const submitProviderCredentials = async (name,formData) => {
        setShowButtonLoading(true);
        let providerData = formData;
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'SAVE_API_DETAILS',
            'PROVIDER_DATA' : providerData,
            'PROVIDER' : provider,
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNTID" : appid
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast(translateContent("vm.subaccount.provider.save.msg"));
            await getproviderData();
            setShowButtonLoading(false);
        }else{
            if(response.errormsg !== ""){
                handleErrorResponse(response.errormsg);
            }else{
                handleErrorResponse(translateContent("vm.subaccount.provider.error.msg"));
            }
            setShowButtonLoading(false);
        }
    }

    const getproviderData = async () => {
        
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'GET_API_DETAILS',
            "SUBACCOUNTID" : appid
        };
        setShowButtonLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            console.log(response.data['providerDetails'])
            let data = response.data['providerDetails'];
            let customProvider = response.data['provider'];
            let syncStatus = response.data['syncStatus'] !== undefined && response.data['syncStatus'] !== null && response.data['syncStatus'] !== "" ? response.data['syncStatus'] : lastSyncStatus;
            if(syncStatus !== ""){
                setStatus(syncStatus);
            }
            if(data !== undefined && data.length > 0){
                data = parseJSON(data);
                setFormData({...data,
                    concurrency_limit: (data.concurrency_limit) || 8, 
                });
                setProvider(customProvider); 
                if(customProvider === "vapi"){
                    setSelectproviderList(providersSelectList[0]);
                }else if(customProvider === "bland"){
                    setSelectproviderList(providersSelectList[1]);
                }else if(customProvider === "retell"){
                    setSelectproviderList(providersSelectList[2]);
                }
            }
        }
        setShowButtonLoading(false);
    }

    const getLastSyncTime = async() =>{
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'GET_LAST_SYNC_TIME',
            'subAccountId':appid
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let previousSyncTime = new Date(response.data[0]+ " UTC");
            let timeDiff = getTimeDiff(previousSyncTime);
            setLastSyncTime(timeDiff);
        }else{
            setLastSyncTime('N/A');
        }
        
    }

    const syncProviderDetails = async() =>{
        setShowButtonLoading(true);
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'SYNC_API_DETAILS',
            "SUBACCOUNTID" : appid
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast(translateContent("vm.subaccount.cred.sync.msg"));
            setShowButtonLoading(false);
        }else{
            setShowButtonLoading(false);
            handleErrorResponse(translateContent("vm.subaccount.cred.sync.error"));
        }
    }

    const handleChange = async(option) =>{
        if(formData[provider+"_api_key"] !== undefined && formData[provider+"_api_key"] !== null && formData[provider+"_api_key"] !== ""){
            if(provider !== option.value){
                let confirm = await confirmBox("Warning!", translateContent("vm.subaccount.cred.change.msg",{provider: provider, newprovider: option.value}),getWarningIcon(), translateContent("vm.general.continue") , 'No'); // All "+provider+" related details will be removed, and "+option.value+"  will be reconfigured from scratch. Do you want to proceed?"
                if(!confirm){
                    return;
                }
            }else{
                return;
            }
        }
        setSelectproviderList(option);
        setProvider(option.value);
        setFormData({});
    }

    const manualSync = () =>{
        let providerName = selectProviderList.value;
        return(
            <div className='mx-auto'>
                {
                    
                    Object.keys(formData).length > 0 && 
                    (
                    <Button size="lg" className='theme-bg-g d-flex flex-end' onClick={syncProviderDetails}>
                        Sync {providerName.toUpperCase()+' Account'}
                    </Button>
                    )
                }
            </div>
        )
    }
    return (
    <Loading showLoader={showButtonLoading} mainContent={
        <Fragment>

          
            <div className='provider-container'>
                <Select
                    isClearable={false}
                    onChange={(eve) => {
                        handleChange(eve)
                    }}
                    placeholder={"Choose a ai name"}
                    options={providersSelectList}
                    value={selectProviderList}
                    className='provider-creaditials-select basic-multi-select me-4 p-0'
                    classNamePrefix="wa-select"
                    isSearchable={false}
                />
                <div>
                    <Row>
                        {providers.map((provider) => {
                            const { name, label, url, description, fields } = provider;
                            const {data_row} = accountInfo;
                            let providerName = selectProviderList.value;
                            if(name === selectProviderList.value){
                                return (
                                    <Fragment>
                                        {
                                        (providerName !== "") ? (
                                        <Col md={12}>
                                            <Card>
                                                <CardBody className='agency-details'>
                                                    <div className='col-md-12 card-fields pb-3 bb-1'>
                                                        <Row>
                                                            <>
                                                                <Col md={7}>
                                                                    <div class="d-grid">
                                                                        <span class="provider-header">{label} 
                                                                            <a className="ps-2" tooltip="The providers API page" target='_blank' rel="noreferrer" href={url}><i className="pe-7s-link"></i></a>
                                                                        </span>
                                                                        <span class="header-desc">{description}</span>
                                                                    </div>
                                                                </Col>
                                                                {/* <Col md={5}>
                                                                <div className='d-flex justify-end'>
                                                                    {
                                                                        
                                                                        Object.keys(formData).length > 0 && 
                                                                        (
                                                                        <Button size="lg" className='theme-bg-g d-flex flex-end' onClick={syncProviderDetails}>
                                                                            Sync {providerName.toUpperCase()+' Account'}
                                                                        </Button>
                                                                        )
                                                                    }
                                                                </div>
                                                                </Col> */}
                                                            </>
                                                        </Row>
                                                        <div className='pt-2 d-flex flex-row-reverse hide'>
                                                            <span class="">Last sync time: {lastSyncTime}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Form submitAction={submitProviderCredentials} pname={name} fields={fields} formData={formData} account_id={data_row.account_id !== undefined && data_row.account_id !== "" ? data_row.account_id : ""} lastSyncTime={lastSyncTime} syncStatus={lastSyncStatus} submitBeforebtn = {manualSync()}/>
                                                    </div> 
                                                </CardBody>
                                            </Card>
                                        </Col>
                                             )
                                        : 
                                        <div className='not-valid-provider'>
                                        <span>Coming Soon</span>
                                        </div>}
                                    </Fragment>
                                )
                            }
                            return null;
                        })}
                    </Row>
                </div>
            </div>
        </Fragment>
    }/>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCredentials);
