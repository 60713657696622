import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Button, Modal, ModalBody } from "reactstrap";
import { Row } from "reactstrap";
import { arrangeCustomSystemFields, getFieldWidget, getUpdatedFieldValue, handleErrorResponse, isMobile, prepareDefaultValueList, showToast, translateContent } from '../../Common/Appcommon';
import requestApi from "../../services/requestApi.service";
import Loading from '../../Common/Loading';


const FormModal = (props) => {
    let { formDetail, isNew, onSubmit, isModalOpen, toggleModal, initialFormData, formId, dataRow } = props;
    const [formData, setFormData] = useState({});
    const [fieldInfo, setFieldsInfo] = useState({});
    const [tagInfo, setTagInfo] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [fieldChanges, setFieldChangeDetails] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    let buttonClickRef = React.createRef();
    let formInfo = formDetail[0];
    let formlabel = formInfo[0] === "Contacts" ? "Contact" : formInfo[0];
    let formname = formInfo[1];
    let fieldDetails = formDetail[1];
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();

    /*useEffect(() => {
        setFormData(initialFormData || {});
    }, [initialFormData]);*/

    useEffect(() => {
        (async () => {
            await processSeperatingFields(fieldDetails);
            setShowLoader(true);
            if (formId !== "" && formId !== undefined) {
                await getSelectedDataRow(formId);
            } else {
                let defaultData = await prepareDefaultValueList(formDetail);
                setFormData(defaultData);
            }
            setShowLoader(false);
        })();

    }, [formDetail]);

    const getSelectedDataRow = async (id) => {
        let params = {
            "ENTITY": "FORMPROCESS",
            "OPERATION": "GETSELECTEDRECORD",
            "FORMNAME": formname,
            "FORMID": id,
            "ISAPIREQ": true
        };

        let response = await requestApi.processServerRequest(params, 'WebV2', 'get');
        if (response.issuccess) {
            let dataRow = response.data;
            setFormData(dataRow);
            setShowLoader(false);
            setIsDataLoaded(true);
        }
    }

    const processSeperatingFields = async (fieldDetails) => {
        let fieldMapper = await arrangeCustomSystemFields(fieldDetails);
        setFieldsInfo(fieldMapper);
    }
    const onInputChange = async (e, valueInfo, attributes) => {
        let fieldName = attributes['fieldName'];
        let tagFormname = attributes['tag_formname'];
        let dataRow = getUpdatedFieldValue(valueInfo, attributes, formData);
        let label = attributes['fieldLabel']
        setFormData((prevData) => ({
            ...prevData,
            [label]: dataRow
        }));
        if (attributes['fieldType'] === "subtable" && (fieldName === "table_82_0_table_82" || fieldName === "table_81_0_table_81")) {
            if (attributes !== undefined && attributes['custom_property'] !== undefined) {
                tagInfo[tagFormname] = valueInfo;
                setTagInfo(tagInfo);
            }
        }
        // setFormData(dataRow);
        let fieldchangedetails = fieldChanges;
        fieldchangedetails[fieldName] = fieldName;
        setFieldChangeDetails(fieldchangedetails);
    }

    const getDesiredField = (field, fieldDetail) => {
        return getFieldWidget(fieldDetail, onInputChange, errors, formData, field, formId, isNew);
    }

    const onFormSave = async (e) => {
        await buttonClickRef.current.click();
    }

    const onFormSubmit = async () => {
        setShowLoader(true);
        let formValue = {};
        let fieldChangeDetails = fieldChanges;
        let formFieldDetails = {};
        //let formInfo = formDetail[0];
        let moduleName = formInfo[0];
        //let fieldDetails = formDetail[1];
        fieldDetails.map((field) => {
            let fieldName = field[2];
            let fieldLabel = field[3];
            let fieldType = field[4];
            if (fieldType !== "reference_entityid" && fieldType !== "subtable" && fieldType !== "") {
                let fieldValue = fieldType === "form_entityid" ? formData[fieldName] : formData[fieldLabel];
                formValue[fieldName] = fieldValue === undefined ? "" : fieldValue;

                if (fieldName === formname + "_id" && !isNew) {
                    formValue[fieldName] = formId;
                }

                let defaultValue = field[19];
                let isFieldChanged = false;
                if (fieldChangeDetails[fieldName] !== undefined) {
                    isFieldChanged = true;
                }
                let fType = fieldType;
                if (fieldType === "entity_group" || fieldType === "reference_group") {
                    fType = "reference_entityid";
                }
                let fieldInfo = [fType, defaultValue, isFieldChanged];
                let refFieldInfo = [];
                let refFieldKey = "";
                if (fieldType === "reference") {
                    let refDetail = field[35];
                    fieldInfo = ["reference_entityid", defaultValue, isFieldChanged];
                    //if(refDetail[1] !== "table_6"){
                    refFieldKey = fieldName.replace("_id", "_name");
                    formValue[refFieldKey] = formData[refFieldKey];
                    refFieldInfo = [fieldType, defaultValue, isFieldChanged, refDetail[1], refDetail[2], refDetail[3]];
                    //}
                }
                formFieldDetails[fieldName] = fieldInfo;
                if (refFieldKey !== "") {
                    formFieldDetails[refFieldKey] = refFieldInfo;
                }
            }

        });

        //update default values in form.
        /*formValue = {
            ...formValue,
            ...defaultFields
        };*/

        let auditInfo = { "displaystring": "" };
        formValue["SUBTABLE_INPUTFIELDS"] = [];
        formValue["SUBTABLELINK_INPUTFIELDS"] = [];
        formValue["LINEITEM_INPUTFIELDS"] = [];
        formValue["BASETABLE_INPUTFIELDS"] = [];
        let params = {
            "ENTITY": "FORMPROCESS",
            "OPERATION": isNew ? "SAVE" : "EDIT",
            "MODULENAME": moduleName,
            "FORMNAME": formname,
            "concurrent_process_confirmed": true,
            "FORMTYPE": "",
            "auditinfo": auditInfo,
            "FORM_INPUTFIELDS": formValue,
            "FORM_FIELDSDETAIL": formFieldDetails,
        };
        if (Object.keys(tagInfo).length > 0) {
            params["tagInfo"] = tagInfo;
        }
        let response = await requestApi.processServerRequest(params, 'WebV2', 'post');
        if (response.issuccess) {
            let successMsg = "vm.contact.create.msg";
            if (!isNew) {
                successMsg = "vm.contact.update.msg";
            }
            showToast(translateContent(successMsg, { formlabel: formlabel }), "top-right", "success");
            toggleModal(true);
            setShowLoader(false);
        } else {
            setShowLoader(false);
            handleErrorResponse(response.errormsg);
        }
    }

    const getModalBodyContent = () => {
        if(!isNew && !isDataLoaded){
            return (
                <Fragment></Fragment>
            )
        }
        return (
            <Fragment><div className='vm-modal-header'>
                <div className={`d-flex ${isMobile() && "w-50"}`}>
                    <div className='col d-flex align-center'>
                        <span className='modal-title'>{isNew ? "Create " + formlabel : "Update " + formlabel}</span>
                    </div>
                    <div className='col d-flex justify-end'>
                        <a className='theme-bg-g btn btn-dark mr-1 custom-btn-1 m-1' onClick={(e) => { toggleModal() }}>Cancel</a>
                        <Button onClick={onFormSave} className="theme-bg-g btn btn-dark ml-1 m-1">{isNew ? "Add " : "Update "}</Button>
                    </div>
                </div>
            </div>
                <div>
                    <form id={`form-${formname}`} name={'dataform-' + formname} onSubmit={handleSubmit(onFormSubmit)}>
                        <div className='field-details data-form'>
                            <Row className={`p-3 me-5 ${isMobile() && "m-contacts-w"}`}>
                                {   
                                    Object.keys(fieldInfo).map((fieldKey) => {
                                        return (
                                            <Fragment>
                                                {
                                                    fieldInfo[fieldKey].length > 0 && <div className='field-container-title mt-2'>
                                                        <span>
                                                            {fieldKey === "system" ? "Contact Informations:" : fieldInfo[fieldKey].every((fd) => parseInt(fd[10]) === 1) ? "" : "Custom Fields:"}                                                       
                                                        </span>
                                                    </div>
                                                }
                                                {
                                                    fieldInfo[fieldKey].map((fd) => {
                                                        let fieldName = fd[2];
                                                        let fieldLabel = fd[3];
                                                        let fieldType = fd[4];
                                                        let isConfigHide = parseInt(fd[10]);
                                                        let isMandatory = parseInt(fd[16]);
                                                        let fieldValue = formData[fieldLabel];
                                                        if (!isConfigHide && fieldType !== "") {
                                                            return (
                                                                <Fragment>
                                                                    <div className={"field-section mt-1 w-100 d-flex"}>
                                                                        <div className='col-md-4 d-flex justify-end mt-2'>
                                                                            <label className={'form-label'} htmlFor={fieldName}>{fieldLabel} <span className={'text-danger'}>{isMandatory ? "*" : ""}</span></label>
                                                                        </div>
                                                                        <div className='col-md-8 justify-end align-center ml-3 m-2'>
                                                                            <Controller
                                                                                name={fieldName + "_fieldinput"}
                                                                                control={control}
                                                                                rules={{ required: isMandatory && (fieldValue === undefined || fieldValue === "" || fieldValue === null) ? fieldLabel + " is required" : "" }}
                                                                                render={({ field }) => {
                                                                                    return getDesiredField(field, fd);
                                                                                }}
                                                                            />
                                                                            {errors[fieldName + "_fieldinput"] &&
                                                                                <em className={`error invalid-feedback d-flex`}>{errors[fieldName + "_fieldinput"].message}</em>}
                                                                                {fieldName === 'Mobile' && fieldLabel === 'Mobile Number' && <span className="field-desc">Phone number with country code and + sign without spaces or dashes.</span>}
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        <div className={'mt-3 text-center hide'}>
                            <button ref={buttonClickRef}
                                className="btn-wide mb-2 me-2 lyte-button lytePrimaryBtn"
                                size="lg"
                                color="primary">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Modal className={'vm-modal-lg'} isOpen={isModalOpen} >
                <ModalBody>
                    <Loading showLoader={showLoader} mainContent={getModalBodyContent()} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default FormModal;