import Vapi from "@vapi-ai/web";
import { RetellWebClient, } from "retell-client-js-sdk";
import { BlandWebClient } from "bland-client-js-sdk";
import { populateFormDetails, getTemplatesFormDetails, loadFormData,prepareLabelNameMapper,updatewebAgentId, isValidData, parseJSON } from '../../Common/Appcommon';

import {getSessionValue,showToast} from '../../Common/Appcommon';
class ProviderDetails {

}

export function getProviderKey(proName,keyName = ""){
    let proVal = "";
    if(proName !== ""){
        proName = proName.toLowerCase();
        let apiKeys = parseJSON(getSessionValue(proName+"_api_keys"));
        if(keyName !== ""){
            if(keyName.toLocaleLowerCase() === "public"){
                keyName = proName +"_pub_key";
            }else if(keyName.toLocaleLowerCase() === "private"){
                keyName = proName +"_prv_key";
            }
        }else{
            keyName = proName +"_api_key";
        }
        if(apiKeys !== undefined && apiKeys[keyName] !== undefined){
            proVal = apiKeys[keyName];
        }
    }
    
    return proVal;
};

export async function providerConn(proName, agentId= ""){
    let proCon = "";
    if(proName === "vapi"){
        let pubKey = getProviderKey(proName,"public");
        proCon = new Vapi(pubKey);
    }else if(proName === "bland"){
        if(agentId !== null){
            let pubKey = getProviderKey(proName);
            let sesTkn = await generateBlandSessionToken(pubKey,agentId);
            if(sesTkn !== null){
                proCon = new BlandWebClient(
                    agentId, 
                    sesTkn
                )
            }
        }          
    }else if(proName === "retell"){
        proCon = new RetellWebClient();
    }
    return proCon;
};

export async function generateBlandAgent (aId,apiKey,phNum,rowData,nameLabelMapper) {
    let aDetails = await getAgDetails(apiKey,phNum);
   /* let response = await fetch(`https://api.bland.ai/v1/agents`, {
        method: "POST",
        body: JSON.stringify({
            prompt : rowData[nameLabelMapper["System_Prompt"]],
            first_sentence: rowData[nameLabelMapper["Call_Start_Message"]], 
            model:aDetails["model"], 
            voice : aDetails['voice'], 
        }),
        headers: {
            "Content-type": "application/json",
            "Authorization": apiKey 
        }
    });*/
    if(aDetails !== null){
        let url = `https://api.bland.ai/v1/agents`;
        let param = {
            prompt : rowData[nameLabelMapper["System_Prompt"]],
            first_sentence: rowData[nameLabelMapper["Call_Start_Message"]], 
            model:aDetails["model"], 
            //voice : aDetails['voice'], 
        };
        let response = await processProviderApi(apiKey,url,"POST",param);
    
        if (response.issuccess) {
            let data = response.data;
            await updatewebAgentId(aId,data.agent.agent_id);
            return data.agent.agent_id;
        }else {
            console.error("Failed to update agent:", response.errormsg);
            return null;
        }
    }else {
        return null;
    }
};

export async function generateBlandSessionToken (apiKey,agentId) {
    /*let response = await fetch(`https://api.bland.ai/v1/agents/${agentId}/authorize`, {
        method: "POST",
        headers: {
            "Authorization": apiKey
        }
    });*/
    let url = `https://api.bland.ai/v1/agents/${agentId}/authorize`;
    let response = await processProviderApi(apiKey,url,"POST");
    if (response.issuccess) {
        let data = response.data;
        return data.token;
    }else {
        console.error("Failed to update agent:", response.errormsg);
        return null; 
    }
};

export async function updateBlandAgent(apiKey,agentId,phNum,rowData,nameLabelMapper) {
    let aDetails = await getAgDetails(apiKey,phNum);
    if(aDetails !== "" && aDetails !== null && typeof aDetails !== "undefined" ){
        /*let response = await fetch(`https://api.bland.ai/v1/agents/${agentId}`, {
            method: "POST",
            body: JSON.stringify({
                "prompt": rowData[nameLabelMapper["System_Prompt"]],
                "first_sentence": rowData[nameLabelMapper["Call_Start_Message"]],            
                "model":aDetails["model"], 
                "voice" : aDetails['voice'], 
            }),
            headers: {
                "Content-type": "application/json",
                "Authorization": apiKey
            }
        });*/
        let url = `https://api.bland.ai/v1/agents/${agentId}`;
        let param = {
            "prompt": rowData[nameLabelMapper["System_Prompt"]],
            "first_sentence": rowData[nameLabelMapper["Call_Start_Message"]],            
            "model":aDetails["model"], 
            //"voice" : aDetails['voice'],
        };
        let response = await processProviderApi(`Bearer ${apiKey}`,url,"POST",param);

        if (response.issuccess) {
            let data = response;
            return agentId; 
        } else {
            console.error("Failed to update agent:", response.status, response.statusText);
            return null; 
        }
    }
    return null; 
}
export async function getAgDetails (apiKey,phoneNumber) {
    if(phoneNumber !== "" && phoneNumber !== null && typeof phoneNumber !== 'undefined'){
        let response = await fetch(`https://api.bland.ai/v1/inbound/${phoneNumber}`, {
            method: "GET",
            headers: {
                "Authorization": apiKey
            }
        });
        if (response.ok) {
            let data = await response.json();
            return data;
        } else {
            let error = await response.json();
            if(error.message ?.toLowerCase().includes("not found")){
                error.message = "Agent Not Found";
            }
            showToast("ERROR! "+error.message,'top-right','error');
            console.error("Failed to get agent:", response.status, response.statusText);
            return null; // Returns null if the update fails
        }
    }else{
        showToast("ERROR! Phone number not found",'top-right','error');
        return null;
    }
    
   
};

export async function fetchPhNum (searchStr,formname) {
    let phNum = "";
    let skey = formname+"_id";
    let fDetail = await populateFormDetails("table_247");
    let nameLabelMapperList = await prepareLabelNameMapper(fDetail[1]);
    let { selectedTemplate } = await getTemplatesFormDetails(fDetail,"Recently Created");
    let adFilterSearch = "nondate@table_250_0@"+skey+"@is@"+searchStr+"@AND#";
    let phData = await loadFormData("table_247", fDetail, selectedTemplate,"",adFilterSearch);
    if (phData.issuccess) {
        let { formData} = phData;
        let phInfo = formData[0];
        phNum = phInfo?.[nameLabelMapperList?.["Phone_Number"]];
    }
    return phNum;
}

export async function getAssistantDetails(apiKey, agentId,provider) {
    try {
        let url = "";
        if(provider == 'vapi'){
            url = "https://api.vapi.ai/assistant";
        }else if(provider == "retell"){
            url = "https://api.retellai.com/get-agent";
        }
        if(url !== ""){
            const response = await fetch(`${url}/${agentId}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${apiKey}`,
                    "Content-Type": "application/json"
                }
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                let errorText = await response.json();
                errorText = errorText.message;
                showToast("ERROR! Agent " + errorText,'top-right','error');
                return null;
            }
        }else {
            return null;
        }
    } catch (error) {
        showToast("ERROR!" +error,'top-right','error');
        return null;
    }
}

export async function updateAgentInfo(apiKey,data,nameLabelMapper,provider,fname,voiceList) {
    let res = null;
    if(data !== "" && data !== null){
        let agentId = data[nameLabelMapper['Agent_ID']];
        let firstMsg = data[nameLabelMapper['Call_Start_Message']];
        let prompt = data[nameLabelMapper['System_Prompt']];
        let name = data[nameLabelMapper['Agent_Name']];
        let voiceId = data[nameLabelMapper['voice_id']];
        const voice = voiceList.flat().find(voice => voice.Voice_Id === voiceId);
        const voiceProvider = voice ? voice.Voice_Provider : '';
        if(provider == "vapi"){
            res = updateVapiAgent(apiKey,agentId,firstMsg,prompt,name,provider,voiceId,voiceProvider);
        }else if(provider == "retell"){
            res = updateRetellAgent(apiKey,agentId,prompt,name,provider,voiceId,voiceProvider,firstMsg);
        }else if(provider == "bland"){
            let agId = data[fname + "_id"];
            let num = await fetchPhNum(agId,fname);
            if(typeof agentId !== "undefined" && agentId !== null && agentId !== ""){
                res = await updateBlandAgent(apiKey,agentId,num,data,nameLabelMapper);
                if(res !== null){
                    res = await updateBlandInboundAgent(apiKey,agentId,num,data,nameLabelMapper);
                }
            }else{
                res = await generateBlandAgent(agId,apiKey,num,data,nameLabelMapper);
            }
        }
    }
    return res;
}

export async function updateVapiAgent(apiKey,agentId,firstMsg,prompt,name,provider,voiceId,voiceProvider) {
    let ast = await getAssistantDetails(apiKey,agentId,provider);
    if(ast !== null){
        let model = ast['model']['model'];
        let provider = ast['model']['provider'];
        
        let url = `https://api.vapi.ai/assistant/${agentId}`;
        let param = {
            "name": name,
            "firstMessage": firstMsg,
            "model": {
                "provider": provider,
                "model": model,
                "messages": [
                    {
                        "role": "system",
                        "content": prompt,
                    }
                ]
            },
            // "voice": {
            //     "provider": voiceProvider,
            //     "voiceId": voiceId,
            // }
        };
        let response = await processProviderApi(`Bearer ${apiKey}`,url,"PATCH",param);
        
        /*const response = await fetch(`https://api.vapi.ai/assistant/${agentId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
                },
                body: JSON.stringify({
                "name": name,
                "firstMessage": firstMsg,
                "model": {
                    "provider": provider,
                    "model": model,
                    "messages": [
                        {
                            "role": "system",
                            "content": prompt,
                        }
                    ]
                },
                "voice": {
                    "provider": voiceProvider,
                    "voiceId": voiceId,
                }
                }),
        });*/
        if (response.issuccess) {
            const data = response.data;
            return data;
        } else {
            let errorText = response.errormsg;
            return null;
        }
    }
}

export async function updateRetellAgent(apiKey,agentId,prompt,agentName,provider,voiceId,voiceProvider,firstMsg) {
    try {
        let llmres = await updateLLmInfo(apiKey,agentId,prompt,provider,firstMsg);
        if(llmres != null){
            let url = `https://api.retellai.com/update-agent/${agentId}`;
            let param = {
                agent_name: agentName,
                general_prompt: prompt,
            };
            
            if (await isValidData(voiceId)) {
                param.voice_id = voiceId;
            }
           
            let response = await processProviderApi(`Bearer ${apiKey}`,url,"PATCH",param);
            if (response.issuccess) {
                const data = response.data;
                return data;
            } else {
                let errorText = response.errormsg;
                return null;
            }
        }else {
            return null;
        }
        
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};
  
  export async function getRetellLLm(apiKey,agentId,provider) {
    try {
        let ast = await getAssistantDetails(apiKey,agentId,provider);
        if(ast !== null){
            let llmId = ast['response_engine']['llm_id'];
            if(llmId !== null && llmId !== ""){
                let url = `https://api.retellai.com/get-retell-llm/${llmId}`;
                let response = await processProviderApi(`Bearer ${apiKey}`,url,"GET");
                if (response.issuccess) {
                    const data = response.data;
                    return data;
                } else {
                    let errorText = response.errormsg;
                    return null;
                }
            }else{
                return null;
            }
        }else {
            return null;
        }    
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
  };

  export async function updateLLmInfo(apiKey,agentId,prompt,provider,firstMsg) {
    try {
        let llmId = await getRetellLLm(apiKey,agentId,provider);
        if(llmId !== null){
            llmId = llmId['llm_id'];
            let model = llmId['model'];
            
            let url = `https://api.retellai.com/update-retell-llm/${llmId}`;
            let param = {
                "model" : model,
                "general_prompt": prompt,
                "begin_message" : firstMsg,
            };
            let response = await processProviderApi(`Bearer ${apiKey}`,url,"PATCH",param);
            if (response.issuccess) {
                return response.data;
            }else {
                let errorText = response.errormsg;
                showToast("ERROR! " + errorText,'top-right','error');
                return null;
            }
        }else{
            return null;
        }
        
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
  };


export async function updateBlandInboundAgent(apiKey,agentId,phNum,rowData,nameLabelMapper) {
    let aDetails = await getAgDetails(apiKey,phNum);
    if(await isValidData(aDetails)){
        let url = `https://api.bland.ai/v1/inbound/${phNum}`;
        let param = {
            "prompt": rowData[nameLabelMapper["System_Prompt"]],
            "first_sentence": rowData[nameLabelMapper["Call_Start_Message"]],            
            "model":aDetails["model"], 
            "voice" : aDetails['voice'], 
        };
        let response = await processProviderApi(apiKey,url,"POST",param);
        if (response.issuccess) {
            return agentId; 
        } else {
            console.error("Failed to update agent:", response.errormsg);
            return null; 
        }
    }
    return null;
}

export async function fetchvoiceLib (apiKey) {
    try {
        let url = `https://api.retellai.com/list-voices`;
        let response = await processProviderApi(`Bearer ${apiKey}`,url,"GET");
        if (!response.issuccess) {
            throw new Error(`HTTP error! Status: ${response.errormsg}`);
        }
        let data = response.data;
        if(data != "" && data !== null){
            const britishVoices = getBalancedVoices(data, "British", 5);
            const americanVoices = getBalancedVoices(data, "American", 5);
            const selectedVoices = [...britishVoices, ...americanVoices];
            return selectedVoices;
        }
    } catch (error) {
        console.error("Error fetching VAPI voices:", error.message);
    }
   
}

const getBalancedVoices = (voices, accent, limit) => {
    const filteredVoices = voices.filter((voice) => voice.accent === accent);
    const genders = { male: [], female: [] };
    filteredVoices.forEach((voice) => genders[voice.gender].push(voice));
    const ageGroups = ["Young", "Middle Aged", "Old"];
    const result = [];
  
    ageGroups.forEach((age) => {
      const male = genders.male.find((voice) => voice.age === age && !result.includes(voice));
      const female = genders.female.find((voice) => voice.age === age && !result.includes(voice));
  
      if (male) result.push(male);
      if (female) result.push(female);
    });
  
    const remaining = filteredVoices.filter((voice) => !result.includes(voice));
    while (result.length < limit && remaining.length > 0) {
      result.push(remaining.shift());
    }
  
    return result.slice(0, limit); 
};

export async function importMobileNumber(provider,paramobj,numProvider) {
    let apikey = getProviderKey(provider);
    let url = "";
    let param  = {}
    let { number, accountSid, authToken } = paramobj;
    if(provider === 'vapi'){
        url = "https://api.vapi.ai/phone-number"
        if(numProvider === "twilio"){
            param  = {
                provider :"twilio",
                number : number,
                twilioAccountSid : accountSid,
                twilioAuthToken : authToken,
            };
        }
    }
    let response = await processProviderApi(`Bearer ${apikey}`,url,"POST",param);
    return response;
}

export async function  updateMobileDetail(provider,paramobj) {
    let apikey = getProviderKey(provider);
    let url = "";
    let param  = {}
    let { id, name, assistantid } = paramobj;
    if(provider === 'vapi'){
        url = `https://api.vapi.ai/phone-number/${id}`;
        param = {
            name:name,
            assistantId:assistantid,
        }
    }
    let response = await processProviderApi(`Bearer ${apikey}`,url,"PATCH",param);
    return response;
}
export async function  deleteMobileDetail(provider,paramobj) {
    let apikey = getProviderKey(provider);
    let url = "";
    let { id } = paramobj;
    if(provider === 'vapi'){
        url = `https://api.vapi.ai/phone-number/${id}`;
    }
    let response = await processProviderApi(`Bearer ${apikey}`,url,"DELETE");
    return response;
}

export async function createTool(provider,params) {
    let apiKey = getProviderKey(provider);
    let url = "";
    let { param } = params;
    if(provider === 'vapi'){
        url = `https://api.vapi.ai/tool`;
    }
    let response = await processProviderApi(`Bearer ${apiKey}`,url,"POST",params);
    return response;
}

export async function updateTool(provider,params) {
    let apiKey = getProviderKey(provider);
    let url = "";
    let { id, param } = params;
    if(provider === 'vapi'){
        url = `https://api.vapi.ai/tool/${id}`;
    }
    let response = await processProviderApi(`Bearer ${apiKey}`,url,"PATCH",param);
    return response;
}

export async function deleteTool(provider,params) {
    let apiKey = getProviderKey(provider);
    let url = "";
    let { id } = params;
    if(provider === 'vapi'){
        url = `https://api.vapi.ai/tool/${id}`;
    }
    let response = await processProviderApi(`Bearer ${apiKey}`,url,"DELETE");
    return response;
}

export async function createorUpdateActionForAgent(provider,agentId,params,agentDetails = []) {
    let apiKey = getProviderKey(provider);
    let url = "";
    let response = null;
    if(agentId){
        if(provider === 'vapi'){
            url = `https://api.vapi.ai/assistant/${agentId}`;
        }else if(provider === 'retell'){
            if(agentDetails){
                let llm_id = agentDetails["llm id"];
                url = `https://api.retellai.com/update-agent/${agentId}`;
                params = constructRetellToolParam(params,llm_id);
            }
        }
        if(url !== ""){
            response = await processProviderApi(`Bearer ${apiKey}`,url,"PATCH",params);
        }
    }
    return response;
}

export async function processProviderApi(apikey,url,reqmethod,param = "") {
    let isSuccess = false;
    let errorMsg = "";
    let responseData = null;
    let responseStatus = null;
    try{
        const options = {
            method: reqmethod,
            headers: {
                Authorization: apikey,
                'Content-Type': 'application/json',
            },
            ...(param ? { body: JSON.stringify(param) } : {}), // Ensure body is a JSON string
        };

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            responseData = data;
            isSuccess = true;
            responseStatus = data?.statusCode;
        }else {
            let errorText = await response.json();
            errorMsg = errorText.message;
            responseStatus = errorText?.statusCode;
            if(responseStatus !== 404){
                showToast("ERROR! "+errorMsg,'top-right','error');
            }
        }
    }catch(error){
        console.error("Error fetching VAPI voices:", error.message);
        isSuccess = false;
        errorMsg =  error.message;
    }
    let serverRes = {
        issuccess: isSuccess,
        errormsg: errorMsg,
        data: responseData,
        statusCode : responseStatus
    };
    return serverRes;
   

}
export async function createAgentInfo(apiKey,data,nameLabelMapper,provider,fname,voiceList){
    let res = null;
    if(data !== "" && data !== null){
        let agentId = data[nameLabelMapper['Agent_ID']];
        let firstMsg = data[nameLabelMapper['Call_Start_Message']];
        let prompt = data[nameLabelMapper['System_Prompt']];
        let name = data[nameLabelMapper['Agent_Name']];
        let voiceId = data[nameLabelMapper['voice_id']];
        const voice = voiceList.flat().find(voice => voice.Voice_Id === voiceId);
        const voiceProvider = voice ? voice.Voice_Provider : '';
        if(provider == "vapi"){
            res = updateVapiAgent(apiKey,agentId,firstMsg,prompt,name,provider,voiceId,voiceProvider);
        }else if(provider == "retell"){
            res = updateRetellAgent(apiKey,agentId,prompt,name,provider,voiceId,voiceProvider,firstMsg);
        }else if(provider == "bland"){
            let agId = data[fname + "_id"];
            let num = await fetchPhNum(agId,fname);
            if(typeof agentId !== "undefined" && agentId !== null && agentId !== ""){
                res = await updateBlandAgent(apiKey,agentId,num,data,nameLabelMapper);
                if(res !== null){
                    res = await updateBlandInboundAgent(apiKey,agentId,num,data,nameLabelMapper);
                }
            }else{
                res = await generateBlandAgent(agId,apiKey,num,data,nameLabelMapper);
            }
        }
    }
    return res;
}

const constructRetellToolParam = (params,llm_id) => {
    let data = params?.["analysisPlan"]?.["structuredDataSchema"]?.["properties"] || null;
    if (data !== null) {
        const postCallAnalysisData = Object.entries(data).map(([key, value]) => {
            let description = value.description || "";
            if (key === "Call Rating") {
                description = " **Options**: Excellent, Good, Average, Poor, Very Poor - **Purpose**: Measures customer satisfaction with service quality; “Excellent” shows strong customer happiness and loyalty.";
            } else if (key === "Call Sentiment") {
                description = "Very Positive, Positive, Neutral, Negative, Very Negative - **Purpose**: Gauges customer sentiment; “Very Positive” indicates high satisfaction and loyalty.Measure Call Rating: Measures customer satisfaction with service quality; “Excellent” shows strong customer happiness and loyalty";
            }
            return {
                type: value.type || "unknown",
                name: key,
                ...(description ? { description } : {})
            };
        });
        let param = {
            "post_call_analysis_data": postCallAnalysisData,
            "response_engine": {
                "type": "retell-llm",
                "llm_id": llm_id,
            }
        };
        return param;
    }
}
export default ProviderDetails;