import React, { useState, Fragment, useEffect } from 'react';
import Loading from '../../Common/Loading';
import { Col, Button, Row, CardBody, Card } from 'reactstrap';
import Common from '../Common';
import { arrangeCustomSystemFields, handleErrorResponse, isMobile, populateFormDetails, checkIsAdmin,confirmBox,translateContent,getDeleteIcon } from '../../Common/Appcommon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FieldModal from './FieldModal';
import requestApi from "../../services/requestApi.service";

const CustomFields = (props) => {
    let contactform = "table_74";
    let isDeleteNeeded = true;
    const [showLoading, setShowLoading] = useState(false);
    const [formD, setFormDetail] = useState([]);
    const [fieldInfo, setFieldsInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [currentFD, setCurrentFD] = useState([]);
    const [isAdmin,setAdmin] = useState(false);
    const [customFieldCount,setCount] = useState(false);
    useEffect(() => {
        setShowLoading(true);
        loadFields();
    }, []);
    useEffect(() => {
        let newCount = 0;
        Object.keys(fieldInfo).forEach(fieldKey => {
          fieldInfo[fieldKey].forEach(fd => {
            if (parseInt(fd[32])) { 
              newCount++;
            }
          });
        });
        setCount(newCount);
    }, [fieldInfo]);


    const loadFields = async () => {
        let formDetail = await populateFormDetails(contactform);
        setFormDetail(formDetail);
        let fieldMapper = await arrangeCustomSystemFields(formDetail[1]);
        setFieldsInfo(fieldMapper);
        setShowLoading(false);
        let adminProfile = await checkIsAdmin();
        setAdmin(adminProfile);
    }

    const onAddCustomField = () => {
        if(customFieldCount+1 > 20){
            handleErrorResponse("You have reached maximum Fields");
            return;
        }
        setCurrentFD([]);
        setIsNew(true);
        setIsModalOpen(true);
    }

    const getHeaderTitleBody = () => {
        return (
            <div>
                <Button size="lg" className='theme-bg-g btn-hover-shine' color="dark" onClick={onAddCustomField}><FontAwesomeIcon icon={faPlus} /> &nbsp;Add Field</Button>
            </div>
        )
    }

    const toggleModal = async (isRefresh = false) => {
        setIsModalOpen(!isModalOpen);
        if (isRefresh) {
            loadFields();
        }
    };

    const onEditFieldInfo = (e, fd) => {
        setCurrentFD(fd);
        setIsNew(false);
        toggleModal();
    }

    const onDeleteFieldInfo = async(e, fd) => {
        let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"), getDeleteIcon(), 'Delete', 'Cancel');
        if (!confirm) {
            return;
        }
        setCurrentFD(fd);
        let formInfo = fd;
        let moduleName = formInfo[0];
        let formName = formInfo[1];
        let fieldName = formInfo[2];
        let fieldType = formInfo[4];
        let params = {
            "OPERATION":"FIELDDELETE",
            "ENTITY":"NGBuilder",
            "modulename": moduleName,
            "screenname": formName,
            "formtype":"",
            "fieldname": fieldName,
            "fieldtype": fieldType,
        };
        setShowLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            await loadFields();
        }
        setShowLoading(false);
    }

    const onHideUnHideField = async (e, fd) => {
        setShowLoading(true);
        let formName = fd[1];
        let fieldName = fd[2];
        let isConfigHide = parseInt(fd[10]);
        let hidevalue = 0;
        if(isConfigHide === 0){
            hidevalue = 1;
        }
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "FIELD_HIDE",
            "isConfigHide": hidevalue,
            "FIELDNAME": fieldName,
            "FORMNAME": formName
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            await loadFields();
            setShowLoading(false);
        } else {
            handleErrorResponse(response.errormsg)
        }
    }

    return (
        <Fragment>
            <Common
                title={"Custom Fields"}
                titleContent={
                    <span>Custom Fields</span>
                }
                titleBody={isAdmin ? getHeaderTitleBody() : ''}
                mainContent={
                    <Fragment>
                        {isModalOpen && formD !== null && <FieldModal setFormDetail={setFormDetail} isModalOpen={isModalOpen} formDetail={formD} fd={currentFD} isNew={isNew} toggleModal={toggleModal} />}
                        <div className='w-100'>
                            <Loading showLoader={showLoading} mainContent={

                                <div className='fields-container'>
                                        <CardBody className={`d-flex ${isMobile() ? '' : 'custom-field-container p-3'}`}>
                                            <Col md={12} className={`${isMobile() ? 'w-100' : ''}`}>
                                                {
                                                    Object.keys(fieldInfo).map((fieldKey) => {
                                                        return (
                                                            <Fragment>
                                                                {
                                                                    fieldInfo[fieldKey].length > 0 && <div className='heading mt-3'>
                                                                        <span>
                                                                            {fieldKey === "system" ? "System Fields " : "Custom Fields"}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {
                                                                    fieldInfo[fieldKey].map((fd) => {
                                                                        let fieldName = fd[2];
                                                                        let fieldLabel = fd[3];
                                                                        let fieldType = (fd[4] == "subtable" ? "combobox":(fd[4] == "ComboBox" ? "dropdown": fd[4])).toLowerCase();
                                                                        let isConfigHide = parseInt(fd[10]);
                                                                        let isMandatory = parseInt(fd[16]);
                                                                        let isCustomField = parseInt(fd[32]);
                                                                        if ((!isConfigHide || isCustomField) && fieldType !== "") {
                                                                            return (
                                                                                <Fragment>
                                                                                    <div className='d-flex'>
                                                                                        {isMobile() ? (
                                                                                                <>
                                                                                                    <Row className='custom-field-border'>
                                                                                                    <Col>
                                                                                                        <div className={`d-flex align-center`}>
                                                                                                            <label className={'domain-container'} htmlFor={fieldName}>{fieldLabel} <span className={'text-danger'}>{isMandatory ? "*" : ""}</span></label>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <div className='col-md-4 align-center'>
                                                                                                            <label className={'form-label'} htmlFor={fieldName}>{fieldType}</label>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    </Row>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                <div className={"field-section mt-1 d-flex"}>
                                                                                                    <div className={`col-md-6 d-flex align-center pr-2`}>
                                                                                                        <label className={'form-label'} htmlFor={fieldName}>{fieldLabel} <span className={'text-danger'}>{isMandatory ? "*" : ""}</span></label>
                                                                                                    </div>
                                                                                                    <div className='col-md-4 align-center'>
                                                                                                        <label className={'form-label'} htmlFor={fieldName}>{fieldType}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        {isAdmin && isCustomField === 1 &&
                                                                                            <div className={`custom-field-outside d-flex align-center  ${isMobile() && 'ms-3'}`}>
                                                                                                <div onClick={(e) => { onEditFieldInfo(e, fd) }} className='p-1 cursor-pointer'>
                                                                                                    <i class="fi fi-rc-pencil"></i>
                                                                                                </div>
                                                                                                {isDeleteNeeded &&
                                                                                                    <div onClick={(e) => { onDeleteFieldInfo(e, fd) }} className='p-1 cursor-pointer'>
                                                                                                        <i class="fi fi-ss-trash"></i>
                                                                                                    </div>
                                                                                                }
                                                                                                <div onClick={(e) => { onHideUnHideField(e, fd) }} className='p-1 cursor-pointer'>
                                                                                                    {isConfigHide === 0 && <i class="fi fi-rr-eye"></i>}
                                                                                                    {isConfigHide === 1 && <i class="fi fi-rr-eye-crossed"></i>}
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </CardBody> 
                                </div>

                            } />
                        </div>
                    </Fragment>
                }
                titleKey={"custom_fields_allowed"}
            />
        </Fragment>
    )
}

export default CustomFields;