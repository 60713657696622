import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Common from "../Common";
import { Button, Card, CardBody, Input, Col, Row } from "reactstrap";
import Tabs from "react-responsive-tabs";
import { useForm, Controller } from "react-hook-form";
import { DefaultMessage } from "./assets/Constants";
import "./assets/index.css";
import Switch from "react-switch";  
import ToolProperty from "./ToolsProperty";
import requestApi from "../../services/requestApi.service";
import { convertKeyValue, formatToolProperty, getSessionValue, handleErrorResponse, showToast, translateContent } from "../../Common/Appcommon";
import { createTool, updateTool} from "../Agents/ProviderDetails";

const CreateTool = ({ onClose, rowData }) => {
    const { handleSubmit, control, formState: { errors }, trigger, setError, clearErrors } = useForm();
    const [formData, setFormData] = useState({});
    const [headerDetails, setHeaderDetails] = useState([]);
    const [properties, setProperties] = useState([{ name: "", type: "string", description: "",children: []}])
    const [isChecked,setIsChecked] = useState(false);
    const [propertiesRequered, setPropertiesRequered] = useState([]);
    const [isUpdate,setIsUpdate] = useState(false);
    const [fid,setFid] = useState("");
    const [showLoader,setShowLoader] = useState(false);
    const processData = async (rowData) =>{
        setShowLoader(true);
        let Data = rowData['data_row'];  
        let server = JSON.parse(Data['server']);
        let message = JSON.parse(Data['messages']);
        let param = JSON.parse(Data['parameters']);
        let name = Data['name'];
        let desc = Data['description'];
        let async = Data['isAsync'];
        let id = Data['functionId'];
        let formatHeader = server.headers === "" ? await convertKeyValue(server.headers,true) : [];
        let properties = param?.properties ;
        let required = param?.required ;
        let formattedProperties = properties !== undefined ||  properties !== "" ? await formatToolProperty(properties,required,true) : ""; 
        setIsChecked(async === "1" ? true : false);
        setHeaderDetails(formatHeader);
        if(formattedProperties.required && formattedProperties.nestedProperties){
            setPropertiesRequered(formattedProperties.required);
            setProperties(formattedProperties.nestedProperties);
        }
        setFid(id)
        let formData = {
            server_url: server.url || "", 
            timeout_sec: server.timeoutSeconds || 0,
            start_msg: message[0]?.content || "",
            success_msg: message[1]?.content || "",
            error_msg: message[2]?.content || "",
            delay_msg: message[3]?.content || "",
            tool_name: name || "",  
            tool_desc: desc || "",  
        };
        setIsUpdate(true);        
        setFormData(formData);
        setShowLoader(false);
    }

    useEffect(() => {
        if(rowData){
            processData(rowData);
        }
    }, []);
    
    const handleInputChange = (e, index = null) => {
        const { name, value } = e.target;
        if (index !== null) {
            const updatedHeaders = [...headerDetails];
            updatedHeaders[index] = { ...updatedHeaders[index], [name]: value };
            setHeaderDetails(updatedHeaders);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSwitchChange = (checked) =>{
        setIsChecked(checked);
    }

    const addHeader = () => {
        setHeaderDetails([...headerDetails, { key: '', value: '' }]);
    };

    const removeHeader = (index) => {
        const updatedHeaders = headerDetails.filter((_, i) => i !== index);
        setHeaderDetails(updatedHeaders);
    };

    const onAddProperty = (newProperty, parentPath = null) => {
        const updateProperties = (props, path) => {
            if (!path) {
                return [...props, newProperty];
            }
            const [current, ...rest] = path.split(".");
            const index = parseInt(current, 10);

            return props.map((item, idx) =>
                idx === index
                ? {
                    ...item,
                    children: updateProperties(item.children || [], rest.join(".")),
                    }
                : item
            );
        };
        setProperties((prev) => updateProperties(prev, parentPath));
    };

    const onUpdateProperty = (path, field, value) => {
        const updateProperties = (props, path) => {
          const [current, ...rest] = path.split(".");
          const index = parseInt(current, 10);
          return props.map((item, idx) =>
            idx === index
              ? rest.length === 0
                ? { ...item, [field]: value }
                : {
                    ...item,
                    children: updateProperties(item.children || [], rest.join(".")),
                  }
              : item
          );
        };
        setProperties((prev) => updateProperties(prev, path));
    };

    const onRemoveProperty = (path) => {
        const updateProperties = (props, path) => {
          const [current, ...rest] = path.split(".");
          const index = parseInt(current, 10);
          if (rest.length === 0) {
            return props.filter((_, idx) => idx !== index);
          }
          return props.map((item, idx) =>
            idx === index
              ? {
                  ...item,
                  children: updateProperties(item.children || [], rest.join(".")),
                }
              : item
          );
        };
        clearErrors(`property_${path}_name`);
        clearErrors(`property_${path}_description`);
        setProperties((prev) => updateProperties(prev, path));
    };

    const onRequiredProperty = (selectedOption) => {
        setPropertiesRequered(selectedOption)
    };

    const subTableByHeader = {
        H1: [
            {
                name: "Server URL",
                icon: "fi fi-rr-link-alt",
                component: (
                    <>
                        <Controller
                            name="server_url"
                            control={control}
                            rules={{ required: formData.server_url === undefined || formData.server_url === null || formData.server_url === "" ? "Server URL is required" : "" }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    id="serverUrl" 
                                    name="server_url"
                                    value = {formData.server_url || ""}
                                    placeholder="Server URL"
                                    className={`form-control ${errors.server_url ? "is-invalid" : ""}`}
                                    onChange={(e) => {
                                        let url = e.target.value;
                                        field.onChange(e);
                                        handleInputChange(e);
                                    }}
                                />
                            )}
                        />
                        {errors.server_url && (
                            <em className="error invalid-feedback">{errors.server_url.message}</em>
                        )}
                    </>
                ),
            },
            {
                name: "Timeout Seconds",
                icon: "fi fi-rr-clock-nine",
                component: (
                    <>
                        <div className="header-desc mb-2">
                            Timeout in seconds for the request to your server. Must be between 1 and 120 seconds.
                        </div>
                        <Controller
                            name="timeout_sec"
                            control={control}
                            rules={{
                                required: formData.timeout_sec  === undefined || formData.timeout_sec === null || formData.timeout_sec === "" ? "Timeout is required" : "",
                                min: { value: 1, message: "Minimum value is 1 second" },
                                max: { value: 120, message: "Maximum value is 120 seconds" },
                            }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    id="timeout_sec"
                                    name="timeout_sec"
                                    pattern="[0-9]*"
                                    value = {formData.timeout_sec || ""}
                                    placeholder="Timeout (1-120)"
                                    className={`form-control ${errors.timeout_sec ? "is-invalid" : ""}`}
                                    onChange={(e) => {
                                        const numberonly = /^[0-9]*$/; 
                                        if (!numberonly.test(e.target.value)) {
                                            setError("timeout_sec", {
                                                type: "manual",
                                                message: "Timeout Seconds must be a numerical value.",
                                            });
                                        } else {
                                            field.onChange(e); 
                                            handleInputChange(e);
                                            clearErrors("timeout_sec"); 
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.timeout_sec && (
                            <em className="error invalid-feedback">{errors.timeout_sec.message}</em>
                        )}
                    </>
                ),
            },
            {
                name: "Header",
                icon: "fi fi-rr-edit",
                component: (
                    <>
                      <div className="header-desc">Define headers for your request.</div>
                      {headerDetails.length > 0 && (
                        <div className="d-flex align-items-center mt-2">
                          <Col md={4} className="me-2"><label className="form-label">Key</label></Col>
                          <Col md={4} className="me-2"><label className="form-label">Value</label></Col>
                        </div>
                      )}
                      {headerDetails.map((header, index) => (
                        <div className="d-flex align-items-center mt-1" key={index}>
                          <Col md={3} className="me-2">
                            <Input
                              name="key"
                              value={header.key}
                              onChange={(e) => handleInputChange(e, index)}
                              className="form-control"
                              placeholder="Enter key"
                            />
                          </Col>
                          <Col md={3} className="me-2">
                            <Input
                              name="value"
                              value={header.value}
                              onChange={(e) => handleInputChange(e, index)}
                              className="form-control"
                              placeholder="Enter value"
                            />
                          </Col>
                          <Col md={2} className="me-2 d-flex align-items-center">
                            <i
                              className="fi fi-rr-minus-circle icon-color cursor-pointer"
                              onClick={() => removeHeader(index)}
                            />
                          </Col>
                        </div>
                      ))}
                      <div className="mt-3">
                        <Button onClick={addHeader} className="btn-hover-shine theme-bg-g custom-display">
                          Add Header
                        </Button>
                      </div>
                    </>
                )
            },
        ],
        H4: [
            {
                name: "Start Message",
                component: (
                    <>
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="start_msg"
                                    control={control}
                                    rules={{ required: formData.start_msg === undefined || formData.start_msg === null || formData.start_msg === "" ? "Start Message is required" : ""}}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="start_msg"
                                            name="start_msg"
                                            value={formData.start_msg || ""}
                                            placeholder="Message to show when the tool starts running (e.g. 'Fetching user information...')"
                                            className={`form-control ${errors.start_msg ? "is-invalid" : ""}`}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e, null);
                                            }}
                                        />
                                    )}
                                />
                                {errors.start_msg && (
                                    <em className="error invalid-feedback">{errors.start_msg.message}</em>
                                )}
                            </Col>
                        </Row>
                    </>
                ),
            },
            {
                name: "Delayed Response Message",
                component: (
                    <>
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="delay_msg"
                                    control={control}
                                    rules={{ required: formData.delay_msg === undefined || formData.delay_msg === null || formData.delay_msg === "" ? "Delayed Response Message is required" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="delay_msg"
                                            name="delay_msg"
                                            value={formData.delay_msg || ""}
                                            placeholder="Message to show when the response is taking longer than expected"
                                            className={`form-control ${errors.delay_msg ? "is-invalid" : ""}`}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e, null);
                                            }}
                                        />
                                    )}
                                />
                                {errors.delay_msg && (
                                    <em className="error invalid-feedback">{errors.delay_msg.message}</em>
                                )}
                            </Col>
                        </Row>
                    </>
                ),
            },
            {
                name: "Success Message",
                component: (
                    <>
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="success_msg"
                                    control={control}
                                    rules={{ required: formData.success_msg === undefined || formData.success_msg === null || formData.success_msg === "" ? "Success Message is required" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="success_msg"
                                            name="success_msg"
                                            value={formData.success_msg || ""}
                                            placeholder="Message to show when the tool completes successfully"
                                            className={`form-control ${errors.success_msg ? "is-invalid" : ""}`}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e, null, "success_msg");
                                            }}
                                        />
                                    )}
                                />
                                {errors.success_msg && (
                                    <em className="error invalid-feedback">{errors.success_msg.message}</em>
                                )}
                            </Col>
                        </Row>
                    </>
                ),
            },
            {
                name: "Error Message",
                component: (
                    <>
                        <Row>
                            <Col md={12}>
                                <Controller
                                    name="error_msg"
                                    control={control}
                                    rules={{ required: formData.error_msg === undefined || formData.error_msg === null || formData.error_msg === "" ? "Error Message is required" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="error_msg"
                                            name="error_msg"
                                            value={formData.error_msg || ""}
                                            placeholder="Message to show if the tool execution fails"
                                            className={`form-control ${errors.error_msg ? "is-invalid" : ""}`}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e, null);
                                            }}
                                        />
                                    )}
                                />
                                {errors.error_msg && (
                                    <em className="error invalid-feedback">{errors.error_msg.message}</em>
                                )}
                            </Col>
                        </Row>
                    </>
                ),
            },
        ],
    };

    const getTabsByHeader = (headerKey) => subTableByHeader[headerKey].map(({ name, component, icon }, index) => ({
        key: index,
        title: <><i className={`${icon}`}></i><span className="ml-2">{name}</span></>,
        getContent: () => component,
    }));

    const createNewTool = async () => {
        const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([\/\w\-?.=&%]*)?$/;
        let url = formData.server_url;
         if (!urlPattern.test(url)) {
            setError("server_url", {
                type: "manual",
                message: "Invalid URL format",
            });
            return;
        }else if(formData.timeout_sec <= 0 || !formData.timeout_sec){
            handleErrorResponse(translateContent("vm.agent.tools.timeout.error"));
            return;
        }
        setShowLoader(true);
        let formatedproperty = await formatToolProperty(properties,propertiesRequered);
        let formatedHeader = await convertKeyValue(headerDetails);
        let proName = getSessionValue("agent_provider_name");
        let OPERATION = "";
        let msg = "";
        let res = [];
        let params = {
            ...(isUpdate ? {} : { type: "function" }),            
            async:isChecked,
            function:{
                name:formData.tool_name,
                description:formData.tool_desc,
                parameters:formatedproperty
            },
            messages:[
                {
                    type :'request-start',
                    content:formData.start_msg,//DefaultMessage.startMessage,//
                },{
                    type: "request-complete",
                    content:formData.success_msg,//DefaultMessage.successMessage,//
                },{
                    type: "request-failed",
                    content:formData.error_msg,//DefaultMessage.failMessage,//
                },{
                    type: "request-response-delayed",
                    content:formData.delay_msg,//DefaultMessage.delayMessage,//
                }
            ],
            server:{
                url:formData.server_url,
                timeoutSeconds:parseInt(formData.timeout_sec, 10),
                headers:formatedHeader
            }
        }
        if(isUpdate){
            let uparam = {
                id:fid,
                param:params
            }
            res = await updateTool(proName,uparam);
            OPERATION="UPDATE_TOOL_DETAIL";
            msg="Updated";
        }else{
            res = await createTool(proName,params);
            OPERATION="CREATE_TOOL_DETAIL";
            msg="Created";
        }
        if (res.issuccess) {
            let reqobj = {
                ENTITY: "AIPROCESSOR",
                OPERATION: OPERATION,
                provider: proName,
                FORMDATA: res.data,
                isSystem:'1',
            };
            const response = await requestApi.processServerRequest(reqobj);
            const message = response.issuccess 
                ? `Tool ${msg} Successfully` 
                : `Unable to ${msg}`;
            const toastType = response.issuccess ? "success" : "error";
            showToast(message, 'top-right', toastType);
            if(response.issuccess){ 
                onClose();
            }
        }
        setShowLoader(false);

    };

    const onSubmit = async (data) => {   
        const isValid = await trigger();
        if(isValid){
            createNewTool();
        }
    };

    const getFieldsContent = () => (
        <div className="w-100 custome-tools">
            <div className="agency-details">
                <div className="card-fields pb-3 d-grid">
                    <span className="heading layout-title">Create a Tool</span>
                </div>
                <div className="field-details">
                    <Card className="card-br">
                        <CardBody className="pb-6 card-fields">
                            <div className="card-fields profile-title-info d-grid">
                                <div className="tool-heading">Connect to API</div>
                                <div className="header-desc">
                                    Create a custom Function and connect it to any backend implementation.
                                </div>
                            </div>
                            <Tabs 
                                tabsWrapperClass="body-tabs" 
                                activeTab="1" 
                                showMore={true} 
                                transform={true} 
                                showInkBar={true} 
                                selectedTabKey={0} 
                                transformWidth={600} 
                                items={getTabsByHeader("H1")} 
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="agency-details">
                <div className="field-details">
                    <Card className="card-br">
                        <CardBody className="pb-6 card-fields">
                            <div className="card-fields profile-title-info d-grid">
                                <div className="tool-heading">
                                    <i class="fi fi-rr-build-alt"></i>
                                    <span className="ml-2">Build your Schema</span>
                                </div>
                                <div className="header-desc">
                                    Define your schema which the LLM should generate.
                                </div>
                            </div>
                            <ToolProperty
                                properties={properties}
                                requiredProperties={propertiesRequered}
                                onAddProperty={onAddProperty}
                                onUpdateProperty={onUpdateProperty}
                                onRemoveProperty={onRemoveProperty}
                                onRequiredProperty={onRequiredProperty}
                                control={control}
                                clearErrors={clearErrors}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="agency-details">
                <div className="field-details">
                    <Card className="card-br">
                        <CardBody className="pb-6 card-fields">
                            <div className="card-fields profile-title-info d-grid">
                                <div className="tool-heading">
                                    <i class="fi fi-rr-tools"></i>
                                    <span className="ml-2">What is this tool for?</span>
                                </div>
                                <div className="header-desc">
                                    Add Details to your tool which will be used by the LLM to understand and call the tool.
                                </div>
                            </div>
                            <div className="tool-heading-desc">
                                Name
                            </div>
                            <div className="mt-2">
                                <Controller
                                    name="tool_name"
                                    control={control}
                                    rules={{ required: formData.tool_name === undefined || formData.tool_name === null || formData.tool_name === "" ? "Tool Name is required" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="tool_name"
                                            name="tool_name"
                                            value={formData.tool_name || ""}
                                            placeholder="What is your tool name? (e.g. getUserInfo, getUserEmail, lookupAddress...)"
                                            className={`form-control ${errors.tool_name ? "is-invalid" : ""}`}
                                            onChange={(e) => {
                                                const toolNamePattern = /^[a-zA-Z0-9_-]{1,64}$/;
                                                if (!toolNamePattern.test(e.target.value)) {
                                                    setError("tool_name", {
                                                        type: "manual",
                                                        message: "Tool name must be 1-64 characters and only contain letters, numbers, hyphens, or underscores.",
                                                    });
                                                } else {
                                                    field.onChange(e);
                                                    handleInputChange(e);
                                                    clearErrors("tool_name");
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.tool_name && (
                                    <em className="error invalid-feedback">{errors.tool_name.message}</em>
                                )}
                            </div>
                            <div className="tool-heading-desc">
                                Description
                            </div>
                            <div className="mt-2">
                                <Controller
                                    name="tool_desc"
                                    control={control}
                                    rules={{ required: formData.tool_desc === "" || formData.tool_desc === undefined || formData.tool_desc === null ? "Tool Description is required" : ""}}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="tool_desc"
                                            name="tool_desc"
                                            value={formData.tool_desc || ""}
                                            placeholder="Describe the Tool, its purpose, its use, etc."
                                            className={`form-control ${errors.tool_desc ? "is-invalid" : ""}`}
                                            rows="3"
                                            maxLength="1000"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e);
                                            }}
                                        />
                                    )}
                                />
                                {errors.tool_desc && (
                                    <em className="error invalid-feedback">{errors.tool_desc.message}</em>
                                )}
                            </div>
                            <Row className="mt-3">
                                <Col md={10} className="d-flex align-items-center">
                                    <div>
                                        <div className="tool-heading">Async Tool boolean async</div>
                                        <div className="tool-heading-desc">
                                            This setting defines whether the assistant will move forward or wait for your server to respond. Enable this if you just want to trigger something on your server.
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end">
                                        <Switch
                                            height={20}
                                            width={40}
                                            onColor={"#034737"}
                                            name="switch"
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="agency-details">
                <div className="field-details">
                    <Card className="card-br">
                        <CardBody className="pb-6 card-fields">
                            <div className="card-fields profile-title-info d-grid">
                                <div className="tool-heading">
                                    <i class="fi fi-rr-messages"></i>
                                    <span className="ml-2">
                                        What messages should be shown to the user as the tool is running?
                                    </span>
                                </div>
                                <div className="header-desc">
                                    Configure the messages that should be shown to the user as the tool is running.
                                </div>
                            </div>
                            <Tabs 
                                tabsWrapperClass="body-tabs" 
                                activeTab="1" 
                                showMore={true} 
                                transform={true} 
                                showInkBar={true} 
                                selectedTabKey={0} 
                                transformWidth={600} 
                                items={getTabsByHeader("H4")} 
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );

    const getMainContent = () =>{
        return(
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                {getFieldsContent()}
            </form>
        );
    }

    const getTitleBody = () => {
        return(
            <>
                <div className='settings-phonenumber-titlebody'>
                    <Button className="container-footer-save theme-bg-g" type="submit" onClick={handleSubmit(onSubmit)}>
                        <i className="fi fi-rs-plus"></i> {`${isUpdate ? "Update Tool" : "Create Tool"}`}
                    </Button>
                    <Button className="container-footer-save theme-bg-g" onClick={onClose}>
                        <i className="fi fi-rr-arrow-left"></i> Back
                    </Button>
                </div>
            </>
        );
    };

    return (
        <div className="full-page-container">
            <Common 
                mainLoader={showLoader}
                title="Tools" 
                titleContent="Tools" 
                titleBody={getTitleBody()} 
                mainContent={getMainContent()} 
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTool);