import React, { Fragment } from 'react';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import PhoneNumberContent from "./PhoneNumberContent";
import { showToast,populateFormDetails,getTemplatesFormDetails,loadFormData,prepareLabelNameMapper, syncOperation,getSessionValue,getPermissions,parseJSON, handleErrorResponse } from '../../Common/Appcommon';
import OpenImportModal from "./ImportTwillo";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import SearchBox from "../Conversations/SearchBar";
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';
import { importMobileNumber } from '../Agents/ProviderDetails';
import requestApi from "../../services/requestApi.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class PhoneNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            numbersList: [],
            pnlist: [],
            isContactOpen: false,
            rowData: "",
            isImportOpen: false,
            isLoading: true, 
            nameLabelMapper: {},
            formDetails:[],
            proName : getSessionValue("agent_provider_name"),
            numberLimitReached:false,
            issubaccountView:getSessionValue("isSubAccountView"),
        };
    }
    componentDidMount = async () => {
        await syncOperation("START_PN_SYNC");
        this.loadPhoneNumberDetails();
           
    };


    loadPhoneNumberDetails =  async () =>{
        this.showLoader();
        let proName = getSessionValue("agent_provider_name");
        let adFilterSearch = "";
        if(proName !== ""){
            proName = proName.toLowerCase();
            adFilterSearch = "nondate@table_247@Provider@is@"+proName+"@AND#";
        }
        let formDetail = await populateFormDetails("table_247", "");
        let { selectedTemplate } = await getTemplatesFormDetails(formDetail);
        let usersData = await loadFormData("table_247", formDetail, selectedTemplate, "",adFilterSearch);
        if (usersData.issuccess) {
            let { formData } = usersData;
            let nameLabelMapperList = await prepareLabelNameMapper(formDetail[1]);
            let permissionRes = await getPermissions();
            if(permissionRes.issuccess){
                let data = parseJSON(permissionRes.data);
                if(data.number_limit !== undefined && data.number_limit !== null && data.number_limit !== ""){
                    if(parseInt(formData.length) +1 > data.webhook_limit){
                        this.setState({numberLimitReached:true});
                    }
                }
            }

            this.setState({
                pnlist: formData,
                nameLabelMapper:nameLabelMapperList,
                filteredPn: formData,
                isLoading: false,
                formDetails :formDetail,
            });
        }
        this.showLoader(false);
    }
    showLoader = (show = true) => {
        this.setState({isLoading:show})
    }
    addTwilioMobileNumber = (number) => {
        let contactNumber = [...this.state.numbersList, number];
        this.setState({ numbersList: contactNumber });
        let message = "Error! Limit reached.";
        showToast(message, 'top-right', "error");

    }

    handleImportNumber = async (formData,mobileProvider)=>{
        this.showLoader()
        let proName = getSessionValue("agent_provider_name");
        let param = {
           number : formData.phone_number,
           accountSid : formData.account_sid,
           authToken : formData.auth_token,
        };
        let res =  await importMobileNumber(proName,param,mobileProvider);
        if (res.issuccess) {
            const reqobj = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "SAVE_PN_DETAILS",
                provider: proName,
                FORMDATA: res.data,
                twiliodetails:formData,
            };
            const response = await requestApi.processServerRequest(reqobj);
            if (response.issuccess) {
                showToast(`Import Phone numbers from ${mobileProvider} successfully`, 'top-right');
                this.loadPhoneNumberDetails();
            } else {
                showToast("Unable to import phone numbers", 'top-right', "error");
            }
        }       
        this.showLoader(false);
    }
    
    handleNumbersModal = (name) => {
        let {numberLimitReached} = this.state;
        if(numberLimitReached){
            handleErrorResponse('Limit Reached');
            return;
        }
        if (name === "addnumber") {
            this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
        } else {
           this.setState(prevState => ({ isImportOpen: !prevState.isImportOpen }));
        }
    }

    handleSearch = (query) => {
        this.setState({ searchQuery: query });

        let filteredPn = this.state.pnlist.filter(phoneNumber => {
            const phone = phoneNumber[this.state.nameLabelMapper['Phone_Number']].toLowerCase();
            return phone.includes(query.toLowerCase());
        });

        this.setState({ filteredPn });
    };

    openPhoneConfig = (data) => {
        this.setState({
            isContactOpen: true,
            rowData: data
        });
    }

    backToPnNumber = () => {
        this.setState({
            isContactOpen: false,
            rowData: {}
        });
        this.loadPhoneNumberDetails();
    }

    closeModal = (name) => {
        if (name === "addnumber") {
            this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
        } else {
            this.setState(prevState => ({ isImportOpen: !prevState.isImportOpen }));
        }
    }

    handleImportNum =  async (importNumber,operation) =>{
        if(operation === "ADD"){
            this.closeModal("addnumber"); 
            this.loadPhoneNumberDetails();
        } else if(operation === "DELETE"){
            this.backToPnNumber();
        }
    }


    deletePN = async (importNumber) =>{
        this.handleImportNum(importNumber,"DELETE");
    }

    addImportedPN = async (importNumber) =>{
        this.handleImportNum(importNumber,"ADD");
    }
   
    getTitleBody = () => {
        let proName = getSessionValue("agent_provider_name");
        return (
            <div className='settings-phonenumber-titlebody'>
                <Button className='theme-bg-g btn-hover-shine hide' onClick={() => this.handleNumbersModal("addnumber")}>
                   <FontAwesomeIcon icon={faPlus} /> Add number
                </Button>
                {
                    this.state.issubaccountView && proName.toLocaleLowerCase() === 'vapi' && (
                        <Button className='btn-hover-shine custom-btn-1' onClick={() => this.handleNumbersModal("importnumber")}>
                            <FontAwesomeIcon icon={faPlus} />  Import number
                        </Button>
                    )
                }
            </div>
        );
    };
    

    getModalContent = () => {
        const { isModalOpen, isLoading, error,nameLabelMapper,filteredPn } = this.state;
        return (
            <Modal isOpen={isModalOpen} toggle={this.handleNumbersModal}>
                <ModalHeader toggle={() => this.closeModal("addnumber")}>
                    Add USA number
                </ModalHeader>
                <SearchBox className='search-box' moduleName="PhoneNumber" onSearch={this.handleSearch} />

                <ModalBody>
                    <Fragment>
                    <div className='number-list-wrap'>
                            {isLoading ? (
                                <div>Loading numbers...</div>
                            ) : error ? (
                                <div>{error}</div>
                            ) : filteredPn.length > 0 ? (
                                filteredPn.map((field, index) => (
                                    <div className='search-numbers' key={index} onClick={() => this.addImportedPN(field)}>
                                        <span>{field[nameLabelMapper['Phone_Number']]}</span>
                                    </div>
                                ))
                            ) : (
                                <div className='numbers-not-found'>No results found for your search.</div>
                            )}
                        </div>
                    </Fragment>
                </ModalBody>
                <ModalFooter>
                    <button className='container-footer-save' onClick={() => this.addTwilioMobileNumber()}>
                        Add Number
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const { isContactOpen, isModalOpen, pnlist, isLoading, nameLabelMapper,formDetails,isImportOpen, proName} = this.state;
        return (
            <Fragment>
                {!isContactOpen && (
                    <Common
                        title = {
                          "Phone Numbers"
                        }
                        titleContent={
                            <span>
                                Phone Numbers
                                <span className='count'>
                                    {isLoading ? '...' : pnlist.length > 0 ? pnlist.length : 0}
                                </span>
                            </span>
                        }
                        mainLoader = {
                            isLoading
                        }
                        mainContent={
                            <>
                                {!isLoading &&  (
                                    <>
                                        {pnlist.length > 0 ? (
                                            <div className='agent-details agent_details-wid phone-numbers-container'>
                                                <div className="card-fields">
                                                    <span className="heading">Your Phone Numbers</span>
                                                    <span className="subheading">Below is a list of all phone numbers connected to your account</span>
                                                </div>
                                                {pnlist.map((data, index) => (
                                                    <div className='number-hover' key={index} onClick={() => this.openPhoneConfig(data)}>
                                                        <span className='cloud-number-logo'>
                                                            <i class="fi fi-rr-mobile-button"></i>
                                                        </span>
                                                        <span className='cloud-number-name-number'>
                                                            <p className="name">{data[nameLabelMapper["Name"]]}</p>
                                                            <p className="number">{data[nameLabelMapper["Phone_Number"]]}</p>
                                                        </span>
                                                        <span className='settings-i-cont'>
                                                        <i class="fi fi-ss-settings"></i>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <NoRecordFound
                                            img={aiData}
                                            isOnlyContent={true}
                                            content={"No Numbers Found."}
                                            />
                                        )}
                                    </>
                                )}
                            </>


                        }
                        titleBody={
                            this.getTitleBody()
                        }
                    />
                )}
                {isModalOpen && this.getModalContent()}
                {isContactOpen && <PhoneNumberContent rowData={this.state.rowData} backToPN={this.backToPnNumber} deletePN = {this.deletePN} pnLabelMapper = {nameLabelMapper} pnFormDetails ={formDetails}/>}
                {isImportOpen && <OpenImportModal isImportModalOpen={isImportOpen} handleNumbersModal={this.handleNumbersModal} closeModal={this.closeModal} onSubmit={this.handleImportNumber}/>}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
