import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Common from '../Common';
import { Button, Col, Row } from 'reactstrap';
import CreateTool from './CreateTool';
import { syncOperation, getSessionValue, prepareCustomTableData, confirmBox, showToast } from '../../Common/Appcommon';
import requestApi from "../../services/requestApi.service";
import {  DropdownMenuOption } from './assets/Constants';
import ListTable from '../../Common/ListTable';
import { createTool, deleteTool } from '../Agents/ProviderDetails';
import TooltipItem from '../../Common/ToolTip';

const Tools = () => {
    const [isToolOpen, setIsToolOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [rowData,setRowData] = useState("")
    const [showLoader,setShowLoader] = useState(false);
    const proName = getSessionValue("agent_provider_name");
    // Function to toggle the tool open/close state
    const toggleTool = () => {
        setIsToolOpen((prevState) => !prevState);
    };

    useEffect( async ()=>{
        await syncOperation("START_TOOL_SYNC");
        await getToolDetails();
    },[]);

    const onEdit = (row) =>{
        setRowData(row);
        toggleTool();
    }

    const onBack = async () =>{
        setRowData("");
        toggleTool();
        await getToolDetails();
    }

    const getToolDetails = async () =>{
        setShowLoader(true);
        let providerName = getSessionValue("agent_provider_name");
        let param = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CUSTOM_TOOLS",
            provider: providerName,
        };
        let response = await requestApi.processServerRequest(param);
        if (response.issuccess) {
            let rdata = response.data;
            let formData = rdata[0]
            let listFields = rdata[1];
            let ActionOption = DropdownMenuOption;
            let tableData = await prepareCustomTableData(formData,listFields,true,false,onEdit)
            let columns = tableData.columns;
            let data = tableData.data;
            columns.push(
                {
                    name: "Action",
                    selector: 'Action',
                    minWidth: "10%",
                    maxWidth:"10%",
                    cell: (row) => (
                        <div className='mx-auto'>
                            <Button 
                                onClick={()=>{handleAction('duplicate',row)}} 
                                className={`user-account-btn theme-bg-info mt-1`}>
                                <TooltipItem
                                    id={'2'}
                                    text={"Duplicate Tool"}
                                    content={<i class="fi fi-rs-duplicate"></i>}
                                />
                            </Button>
                            <Button                                    
                                onClick={()=>{handleAction('delete',row)}} 
                                className="user-account-btn theme-bg-danger mt-1" >
                                <TooltipItem
                                    id={'3'}
                                    text={"Delete Tool"}
                                    content={<i class="fi fi-rs-trash"></i>}
                                />
                            </Button>
                        </div>
                    ),
                }
            );
            const updatedData = {
                columns: columns, 
                data: data,
            };
            setTableData(
                updatedData, 
            );
        }
        setShowLoader(false)
    }

    const handleAction = (action, row) => {
        switch(action) {
            case 'duplicate':
                handleDuplicate(row);
                break;
            case 'delete':
                handleDelete(row);
                break;
            default:
                console.log('Action not recognized:', action);
        }
    }

    const handleDuplicate = async (row) => {
        try {
            setShowLoader(true);
            let Data = row['data_row'];
            let isAsync = Data?.isAsync === "1" ? true : false;
            let messages = (Data?.messages && Data.messages !== "" && Data.messages !== null) 
                ? JSON.parse(Data.messages) 
                : {};
            let server = (Data?.server && Data.server !== "" && Data.server !== null) 
                ? JSON.parse(Data.server) 
                : {};
            let name = Data?.name + "_Copy";
            let desc = Data?.description;
            let parameters = (Data?.parameters && Data.parameters !== "" && Data.parameters !== null) 
                ? JSON.parse(Data.parameters) 
                : { type: "object", properties: {}, required: [] };
            let params = {
                type: "function",
                async: isAsync,
                function: {
                    name: name,
                    description: desc,
                    parameters: parameters,
                },
                messages: messages,
                server: server,
                };
                let res = await createTool(proName, params);
                if (res.issuccess) {
                    let reqobj = {
                        ENTITY: "AIPROCESSOR",
                        OPERATION: "CREATE_TOOL_DETAIL",
                        provider: proName,
                        FORMDATA: res.data,
                    };
                    const response = await requestApi.processServerRequest(reqobj);
                    const message = response.issuccess 
                        ? `Tool Duplicated Successfully` 
                        : `Unable to Duplicate`;
                    const toastType = response.issuccess ? "success" : "error";
                    showToast(message, 'top-right', toastType);
                } else {
                    showToast("Failed to create the tool.", 'top-right', "error");
                }
        } catch (error) {
            showToast("Error creating tool: " + error.message, 'top-right', "error");
        }finally {
            setShowLoader(false);
            await getToolDetails();
        }
    };
    

    const handleDelete = async (row) => {
        try {
            let confirm = await confirmBox(
                "Are you sure you want to delete?",
                "Press Delete to confirm",
                'warning',
                'Delete',
                'Cancel'
            );
    
            if (!confirm) {
                return;
            }
    
            setShowLoader(true);
    
            let data = row['data_row'];
            let id = data['functionId'];
            let param = { id: id };
            let res = await deleteTool(proName, param);
            if (res.issuccess || res.statusCode == "404") {
                if(res.statusCode == "404"){
                    res.data = param;
                }
                let reqobj = {
                    ENTITY: "AIPROCESSOR",
                    OPERATION: "DELETE_TOOL_DETAILS",
                    provider: proName,
                    FORMDATA: res.data,
                };
                const response = await requestApi.processServerRequest(reqobj);
                const message = response.issuccess
                    ? "Tool Deleted Successfully"
                    : "Unable to Delete";
                const toastType = response.issuccess ? "success" : "error";
                showToast(message, 'top-right', toastType);
                if (response.issuccess) {
                    await getToolDetails();
                }
            } else {
                showToast("Failed to delete the tool.", 'top-right', "error");
            }
        } catch (error) {
            showToast("Error occurred: " + error.message, 'top-right', "error");
        } finally {
            setShowLoader(false);
        }
    };
    

    const getTitleBody = () => {
        return (
            proName.toString() === 'vapi' && (
                <Button
                    className='container-footer-save theme-bg-g'
                    onClick={toggleTool}
                >
                    <i className="fi fi-rs-plus"></i>Create
                </Button>
            )
        );
    };
    

    const getMainContent = () =>{
        return (
            <>
                <div className='w-100 mt-4'>
                    <ListTable 
                        columns={tableData.columns} 
                        data={tableData.data} 
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {!isToolOpen && (
                <Common
                    mainLoader={showLoader}
                    title="Tools"
                    titleContent="Tools"
                    titleBody={getTitleBody()}
                    mainContent={getMainContent()}
                />
            )}
            {isToolOpen && <CreateTool onClose={onBack} rowData={rowData}/>}
        </>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
