import React, { createContext, useContext, useState } from 'react';
import { getSessionValue } from '../Common/Appcommon';

// Create the LanguageContext
const LanguageContext = createContext();

// Create a provider component
export const LanguageProvider = ({ children }) => {
  const isSubAccountView = getSessionValue("isSubAccountView");

  const [language, setLanguage] = useState(() => {
    // Get the saved theme from localStorage
    return localStorage.getItem('language') || 'en';
  }); // Default language

  const toggleLanguage = (lan) => {
    setLanguage(lan);
    localStorage.setItem('language', lan); // Save to localStorage
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the LanguageContext
export const useLanguage = () => {
  return useContext(LanguageContext);
};
