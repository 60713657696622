import React, { Fragment, Component } from "react";
import siteRequestApiService from "../../services/siteRequestApi.service.js";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import requestApi from "../../services/requestApi.service.js";
import gicon from "./assets/gicon.png";
import { clearLegacyCookie, showToast, clearSessionValue, getAppidFromCookie, getHostPrefix, setCookie, fetchAgencyDetails, getCurrentDomain } from "../../Common/Appcommon";
import AppLogo from "../../assets/utils/images/logo.png";
import Link from "../../Link.js";
import { decode as base64_decode, encode as base64_encode } from 'base-64';


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.loginFormRef = React.createRef();
    this.state = {
      errorEmail: { message: "", isValid: true },
      errorPwd: { message: "", isValid: true },
      configData: [],
      brandLogo: "",
      agencyLogo: [],
    };
  }

  componentDidMount = async () => {
    clearLegacyCookie();
    clearSessionValue();
    document.body.className = "light-theme";
    document.title = 'Sign in to your account';
    let agencyData = await fetchAgencyDetails();
    if (agencyData.length > 0) {
      this.setState({
        agencyLogo: agencyData
      })
    } else {
      this.setState({
        brandLogo: process.env.REACT_APP_LIGHT_LOGO,
      })
    }
    // await this.redirectToLegacy();
  };

  redirectToLegacy = async () => {

    let appid = getAppidFromCookie();
    let redirectUrl = "";
    if (appid !== undefined && appid !== null && appid !== "") {
      let params = {
        'ENTITY': 'getRedirectPage',
        'APPID': appid
      };
      let response = await requestApi.processServerRequest(params);
      if (response.issuccess) {
        redirectUrl = response.data['url'];
        if (window.self !== window.top) {
          window.parent.location.href = redirectUrl;
        } else {
          window.location.href = redirectUrl;
        }
      }
    } else {
      clearSessionValue();
    }
  }


  loginAction = async (e) => {
    e.preventDefault();
    let emailVal = this.loginFormRef.current.querySelector("input[type='email']");
    let pwdVal = this.loginFormRef.current.querySelector(
      "input[type='password']"
    );
    let emailError = { message: "", isValid: true };
    let pwdError = { message: "", isValid: true };
    if (emailVal.value === "") {
      emailError.message = "Email can't be empty!";
      emailError.isValid = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal.value)
    ) {
      emailError.message = "Provide valid email address!";
      emailError.isValid = false;
    }

    if (pwdVal.value === "") {
      pwdError.message = "Password can't be empty!";
      pwdError.isValid = false;
    }
    if (pwdError.isValid && emailError.isValid) {
      /* let queryString =
        "productid=" +
        process.env.REACT_APP_SITE_PRODUCT_ID +
        "&username=" +
        emailVal.value +
        "&pwd=" +
        pwdVal.value; */
      let subDomain = getCurrentDomain();
      let queryParams = {
        "productid": process.env.REACT_APP_SITE_PRODUCT_ID,
        "username": emailVal.value,
        "pwd": base64_encode(pwdVal.value),
        "domain": subDomain,
      }
      let response = await siteRequestApiService.processRequest("logIn",
        "",
        queryParams
      );
      if (response.status !== undefined && !response.status) {
        showToast(response.message, "top-right", "error", undefined, 5000);
      } else if (response.status !== undefined && response.status) {
        clearLegacyCookie();
        let redirectUrl = "";
        let appid = response.appid;
        let prefix = getHostPrefix();
        setCookie(prefix + 'loginappid', appid, 365, "voicemetrics.ai");
        if (response.url === "crm.html") {
          let tempUrl = process.env.REACT_APP_SERVER_URL;
          let appHost = window.location.hostname;
          if (appHost.indexOf("cratio.com") > 0) {
            tempUrl = process.env.REACT_APP_SITE_HOSTING_URL;
          }
          redirectUrl = tempUrl + "/" + response.url;
        } else {
          redirectUrl = response.url;

        }
        if (window.self !== window.top) { // checking if it is an iframe
          window.parent.location.href = redirectUrl;
        } else {
          window.location.href = redirectUrl;
        }
      }
    }
    emailVal.value = "";
    pwdVal.value = "";
    this.setState({
      errorEmail: emailError,
      errorPwd: pwdError,
    });
    return false;
  };

  handleLogin = async (e) => {
    const clientId = '283720779505-ffcv0jokpodrelcq8eifda4u84qqhb03.apps.googleusercontent.com';
    let redirectUri = process.env.REACT_APP_SERVER_URL;
    const scope = 'openid profile email';
    const responseType = 'code';
    const accessType = 'offline';
    redirectUri = redirectUri + "/gsigninprocess.php";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}`;
    console.log(authUrl)

    // Redirect to Google login
    window.parent.location.href = authUrl;
  }


  render() {
    /* let type = 0;
    let alertMessage = "";
    let alertType = ""; */
    let { /* configData, */ brandLogo, agencyLogo } = this.state;
    /* let alertClassName = "";
    if (configData.length > 0) {
      type = configData[0];
      alertType = configData[1];
      alertMessage = configData[2];
      if (alertType === "warning") {
        alertClassName = "alert-warning";
      } else if (alertType === "danger") {
        alertClassName = "alert-danger";
      } else if (alertType === "success") {
        alertClassName = "alert-success";
      }
    } */
    let loginType = process.env.REACT_APP_LOGIN_TYPE;
    let favIcon = "";
    if (agencyLogo.length > 0) {
      brandLogo = agencyLogo[2] !== "" ? agencyLogo[2] : agencyLogo[0];
      favIcon = agencyLogo[1];
    }

    // if(brandLogo === ""){
    //   return (
    //     <Fragment>...</Fragment>
    //   )
    // }
    return (
      <Fragment>
        <Link icon={favIcon} />
        <div className="h-100 bg-dark-shadow bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div className="app-logo-inverse mx-auto mb-3 text-center">
              {brandLogo !== "" && <img src={brandLogo} alt="Brand Logo"/>}
              </div>
              <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="h5 modal-title text-center">
                      <h4 className="mt-2">
                        <div>Welcome back,</div>
                        <span>Please sign in to your account below.</span>
                      </h4>
                    </div>
                    {(loginType === 'LEGACY' || loginType === 'BOTH') && (
                      <div ref={this.loginFormRef}>
                        <Form onSubmit={this.loginAction}>
                          <Row>
                            <Row md={1}>
                              <FormGroup>
                                <Label className="" for="exampleEmail">Email</Label>
                                <Input
                                  className={
                                    !this.state.errorEmail.isValid
                                      ? 'is-invalid form-control-blue'
                                      : 'form-control-blue'
                                  }
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="Email here..."
                                />
                                {!this.state.errorEmail.isValid && (
                                  <label className="errors" for="errorEmail">
                                    {this.state.errorEmail.message}
                                  </label>
                                )}
                              </FormGroup>
                            </Row>
                            <Row md={1}>
                              <FormGroup>
                                <Label for="examplePassword">Password</Label>
                                <Input
                                  className={
                                    !this.state.errorPwd.isValid
                                      ? 'is-invalid form-control-blue'
                                      : 'form-control-blue'
                                  }
                                  type="password"
                                  name="password"
                                  id="password"
                                  placeholder="Password here..."
                                  autoComplete="off"
                                />
                                {!this.state.errorPwd.isValid && (
                                  <label className="errors" for="errorPassword">
                                    {this.state.errorPwd.message}
                                  </label>
                                )}
                              </FormGroup>
                            </Row>
                          </Row>
                          <Row className="divider" />
                          <div className="d-flex align-items-center">
                            <div className="ms-auto">
                              <a
                                target={'_blank'}
                                rel="noopener noreferrer"
                                href={'#/forgotpassword'}
                                className="btn-lg btn theme-c-g vm-btn-link hover-underline"
                              >
                                Recover Password
                              </a>{' '}
                              <Button size="lg" className="theme-bg-g" type="submit" color="dark">
                                Login to Dashboard
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {(loginType === 'GOOGLE' || loginType === 'BOTH') && (
                      <div className="d-grid align-items">
                        {loginType === 'BOTH' && (
                          <div className="divider-wrapper">
                            <span className="g-divider">Or</span>
                          </div>
                        )}
                        <div className="mt-4 d-flex justify-center">
                          <button className="social-btn" onClick={this.handleLogin}>
                            <span className="social-logo-wrapper">
                              <img
                                className="social-logo"
                                src={gicon}
                                alt="Google logo"
                              />
                            </span>
                            <span className="social-text">Continue with Google</span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </Fragment>
    );
  }
}
