import React, { useState, Fragment, useEffect } from 'react';
import Select from "react-select";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { downloadFile, getContactLinkedData, getTemplatesFormDetails, loadFormData, populateFormDetails, showToast , confirmBox, parseJSON, getSessionValue, translateContent,handleErrorResponse} from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import parseHtml from 'html-react-parser';
import exportIcon from "../../assets/utils/images/file-export.png";
import darkexportIcon from "../../assets/utils/images/file-export-dark.png";


const ExportPanel = (props) => {

    let exportRecords = getSessionValue('exportLimit');
    let exportRecordsCounts = (exportRecords !== '' && exportRecords !== undefined && exportRecords !== 'undefined' && exportRecords !== null) ? parseJSON(exportRecords) : [];
    const contactform = "table_74";
    const [selectedList, setSelectedList] = useState({});
    const [options, setOptions] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [exportLimit, setExportLimit] = useState(exportRecordsCounts[0].value);// exportRecordsCounts[0].value
    const [showButtonLoading, setShowButtonLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [fileName, setFileName] = useState(0);
    const [formData, setFormData] = useState(0);
    const [isDisabled, setisDisabled] = useState(false);
    const [btnDisabled, setbtnDisabled] = useState(false);

   
    const onListChange = async (selected) => {
        //console.log(selected)
        setSelectedList(selected);
        setShowLoading(true);
        let formDetail = await populateFormDetails(contactform);
        let {selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let tagList = [selected];
        tagList = JSON.stringify(tagList);
        let data = await loadFormData(contactform, formDetail, selectedTemplate, "", "linkspl@List@table_81@includes@"+tagList+"@AND#", true, [], "", 0, "csv");
        const lines = data.formData.split('\n').filter(line => line.trim() !== '');
        setbtnDisabled(true);
        if(lines.length -1 > 0){
            setbtnDisabled(false);
        }
        setShowLoading(false);
    }
    useEffect(() => {
        (async () => {
            await loadListOptions();
        })();
    }, []);

    const loadListOptions = async () => {
        let listOptions = await getContactLinkedData("table_81");
        setOptions(listOptions);
    }

    const onExportContact = async () => {
        setShowLoading(true);
        let formDetail = await populateFormDetails(contactform);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let tagList = [selectedList];
        tagList = JSON.stringify(tagList);
        let data = await loadFormData(contactform, formDetail, selectedTemplate, "", "linkspl@List@table_81@includes@"+tagList+"@AND#", true, [], "", 0, "csv");
        
        let totalValues = 0;
        const lines = data.formData.split('\n').filter(line => line.trim() !== '');
        const rowsArray = data.formData.trim().split("\n");
        const result = rowsArray.map(row => [row]);
        lines.forEach(line => {
        const values = line.split(',').filter(value => value.trim() !== '');
        totalValues += values.length;
        });
        if(lines.length-1 > 25000){
            handleErrorResponse(`This list contains ${lines.length-1} contacts.You can export only upto 25000 at a time`);
            setShowLoading(false);
            return;
        }
        let confirm = await confirmBox(`This list contains ${lines.length-1} contacts`,translateContent("vm.export.confirm.msg"), document.body.className === 'dark-theme' ? darkexportIcon : exportIcon, 'Export', 'Cancel');
        if (!confirm) {
            setShowLoading(false);
            return;
        }
        setTotalCount(lines.length-1);
            if (parseInt(lines.length-1, 10) === 0) {
                setShowLoading(false);
                showToast("No data found ", "top-right", "error");
            } else if (lines.length-1 <= 5000) {   
                if (data.issuccess && confirm) {
                    let formInfo = formDetail[0];
                    let filename = formInfo[2];
                    let {formData} = data;
                    await downloadFile(filename, [formData]);
                    showToast(translateContent("vm.filedownload.msg",{filename: selectedList.label !== undefined && selectedList.label !== null && selectedList.label !== "" ? selectedList.label : filename}));
                    setSelectedList({});
                    setShowLoading(false);
                }
                setIsModalOpen(false);
            } else {
                setIsModalOpen((prev) => !prev);
                let formInfo = formDetail[0];
                let filename = formInfo[2];
                setFileName(filename);
                setFormData(result);
                // showToast("A maximum of 5000 records can be download", "top-right", "error");
        }
    }
    const convertToCSVString = (data) => {
        return data.map(row => row.join(',')).join('\n');
     };
      


    const handleExportClick = async (index, filename, start, exportLimit, data) => {
        if(index < 0){
            return;
        }else{
            const header = data[0];
            const slicedData = data.slice(start, start + exportLimit);
            const finalData = [header, ...slicedData];
            const csvContent = convertToCSVString(finalData);
            if (fileName && csvContent) {
                setisDisabled(true);
                setShowLoading(true);
                await downloadFile(fileName, [csvContent]);
                showToast(translateContent("vm.filedownload.msg",{fileName: filename}));
                setSelectedList({});
                setShowLoading(false);
                setisDisabled(false);
                setShowButtonLoading(false);
                setIsModalOpen(false);
            }
        }
    }

      const closeModal = (name) => {
        setIsModalOpen((prev) => !prev); // Toggle the modal state // (prev) => !prev
        setShowButtonLoading(false); // Reset button loading state
        setShowLoading(false);
      }
      
      const  getModalContent = () => {
        let noofloops = Math.ceil(totalCount / exportLimit);
        let downloadList = [];
        let startcount = 1;
        for (let i = 0; i < noofloops; i++) {
            let endcount = Math.min(startcount + exportLimit - 1, totalCount); // Ensure endcount does not exceed totalCount
            downloadList.push([startcount, endcount]);
            startcount = endcount + 1;
        }
        return (
            <>
            { totalCount && exportLimit &&
                <Modal isOpen={true} toggle={{}}>
                    <ModalHeader toggle={closeModal}>
                            <div>
                                <div className={`quickreply-inbox-header`}>
                                    <span>Export as CSV</span>
                                </div>
                            </div>
                    </ModalHeader>
                    <ModalBody>
                    <div>
                            {
                                downloadList.length && 
                                downloadList.map((data, index) => {
                                    let label = data[0] + " to " + data[1];
                                    let filename = "Call Logs Details " + data[0] + " to " + data[1] + ".csv";
                                    let name ="Download <strong>" + label +  "</strong> records as <strong>" + filename + "</strong>";
                                    return(
                                        <Fragment key={index}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>{parseHtml(name)}</span>
                                                <span title="Click to Download CSV" className={isDisabled ? 'cursor-wait' : 'cursor-p'} onClick={() => handleExportClick(isDisabled ? -1 : index, filename, data[0], exportLimit, formData)}>
                                                <Loading showLoader={showButtonLoading} mainContent={
                                                <Fragment>
                                                <Button className='theme-bg-g'>
                                                <i class= "fi fi-bs-cloud-download-alt" ></i>
                                                </Button>
                                                </Fragment>
                                                } />
                                                </span>
                                            </div>
                                            <hr />
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </ModalBody>
                </Modal>
            }
            </>
        );
    }

    return (
        <Fragment>
            <div className='export-panel'>
                <div className='header-desc pt-2 mb-3'>Export your contacts to a CSV file.</div>
                <label className={'form-label'} >Select contact list to export</label>
                <Select value={selectedList}
                    onChange={(e) => {
                        onListChange(e);
                    }} options={options}
                    className={`basic-multi-select`}
                    classNamePrefix="wa-select"
                    isDisabled={showLoading}
                />
                <div class="d-flex justify-end pt-3">
                    <Loading showLoader={showLoading} mainContent={
                        <Fragment>
                            <Button disabled={Object.keys(selectedList).length === 0 || btnDisabled} onClick={(e) => onExportContact(e)} size="lg" className='theme-bg-g'>Export CSV</Button>
                        </Fragment>
                    } />
                </div>
            </div>
            {isModalOpen && getModalContent()}
        </Fragment>
    )
}

export default ExportPanel;