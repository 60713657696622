import React, { Fragment, useState, useEffect } from 'react';
import {setAppCustomTitle} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import {Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { modalHeaders } from './constants';
import Calendar from './Calendar';
import { getSessionValue,showToast, translateContent } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';
import SendEmailSMSAction from './SendEmailSMS';
import CallTransfer from './CallTransfer';
import CallEnd from './CallEnd';
import ExtractInfo from './ExtractInfo';
import Voicemail from './Voicemail';
import CustomeAction from './CustomAction';
import Loading from '../../Common/Loading';


const ActionModal = (props) => {
    const { isOpen, toggle, currentAction, agent_id,getAgentAction,actionId, onAction, rowData ,selectedAgentDetails} = props;
    const [modalHeaderDetails, setHeaderDetails] = useState({ header: "", description: "" });
    const [showLoading,setShowLoading] = useState(false);
    useEffect(() => {
        if (currentAction) {
            const headerDetails = modalHeaders[currentAction];
            setHeaderDetails({
                header: headerDetails !== undefined && headerDetails !== null && headerDetails[0]?.header || "",
                description: headerDetails !== undefined && headerDetails !== null && headerDetails[0]?.description || ""
            });
        }
    }, [currentAction]);
    
    const saveActionDetails = async (inputData, action_name, action_type = "", onaction = "") => {
        setShowLoading(true);
        let curUser = getSessionValue('loginusername');
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_ACTION_DETAILS",
            "action_details": inputData,
            "action_name":action_name,
            "action_type": action_type,
            "onaction":onaction,
            'status': 'active',
            'updatedusername': curUser,
            'agent_id': agent_id,
            'action_id':actionId
        };
        let response = await requestApi.processServerRequest(params);
        setShowLoading(false)
        if(response.issuccess){
            showToast(translateContent("vm.agents.actions.success"));
            toggle(true);
            await getAgentAction(onaction);
        }
    }

    const getDesiredComponent = (currentAction = "") => {
        if (currentAction === "book_calendar") {
            return (
                <Fragment>
                    <Calendar
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                    />
                </Fragment>
            );
        }else if(currentAction === "send_sms" || currentAction === "send_email") {
            return (
                <SendEmailSMSAction
                    type={currentAction}
                    onAction={onAction}
                    agent_id={agent_id}
                    actionId={actionId}                    
                    saveDetails={saveActionDetails}
                    rowData={rowData}
                />
            );
        }else if(currentAction === "transfer_call"){
            return (
                <Fragment>
                    <CallTransfer 
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                        onAction={onAction}
                    />
                </Fragment>
            )
        }else if(currentAction === "end_call"){
            return (
                <Fragment>
                    <CallEnd
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                        onAction={onAction}
                    />
                </Fragment>
            )
        }else if(currentAction === "extract_info"){
            return (
                <Fragment>
                    <ExtractInfo
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                        onAction={onAction}
                        agentDetails = {selectedAgentDetails}
                    />
                </Fragment>
            )
        }else if(currentAction === "vmail_message"){
            return (
                <Fragment>
                    <Voicemail
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                        onAction={onAction}
                    />
                </Fragment>
            )
        }else if (currentAction === "custom_action"){
            return (
                <Fragment>
                    <CustomeAction
                        agent_id={agent_id}
                        saveDetails={saveActionDetails} 
                        actionId={actionId}
                        onAction={onAction}
                    />
                </Fragment>
            )
        }
    };
    

    const getModalContent = () => {
        return (
            <>
                <Modal className={'cal-action'} isOpen={isOpen}>
                    <ModalHeader toggle={toggle}>
                        {modalHeaderDetails.header}
                        <div className='md-2 del-subaccount-header'>
                            {modalHeaderDetails.description}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            <Loading showLoader={showLoading} mainContent={
                                getDesiredComponent(currentAction)
                            }/>
                        </Fragment>
                    </ModalBody>
                </Modal>
            </>
        );
    }

    return(
        <Fragment>
            {getModalContent()}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionModal);