import React, { Fragment, Component } from "react";
import { Col } from "reactstrap";

//internal
import requestApi from "../../services/requestApi.service";
import { clearLegacyCookie, fetchAgencyDetails, clearSessionValue } from "../../Common/Appcommon";
import '../site.css';
import Link from "../../Link";
import AppLogo from "../../assets/utils/images/logo.png";
import TextLoading from "../../Common/TextLoading";

export default class ActivateSignup extends Component {
  constructor(props) {
    super(props);
    this.recoverFormRef = React.createRef();
    let activate_code = props.match.params.activate_code !== undefined && props.match.params.activate_code !== "" ? props.match.params.activate_code : "";
    this.state = {
      userData: activate_code,
      brandLogo: "",
      agencyLogo: [],
    };
  }

  componentDidMount = async () => {
    clearLegacyCookie();
    clearSessionValue();
    document.title = 'Activate Signup | Voicemetrics';
    let agencyData = await fetchAgencyDetails();
    if (agencyData.length > 0) {
      this.setState({
        agencyLogo: agencyData
      })
    } else {
      this.setState({
        brandLogo: process.env.REACT_APP_LIGHT_LOGO
      })
    }
    await this.activateAgencyAccount();
  };

  activateAgencyAccount = async () => {
    const { userData } = this.state;
    let params = {
      "ENTITY": "AIPROCESSOR",
      "OPERATION": "ACTIVATE_AGENCY",
      'userData': userData
    };
    let response = await requestApi.processServerRequest(params, "WebV2", "POST", true);
    if (response.issuccess) {
      let resdata = response.data;
      if(resdata['issuccess']){
        window.location = "#/login";
      } else {
        let msg = resdata['errormsg'];
        if(msg === "Already_Activated"){
          window.location = "#/login";
        }
      }
      
    } 
  }


  render() {
    let { brandLogo, agencyLogo } = this.state;
    let favIcon = "";
    if (agencyLogo.length > 0) {
      brandLogo = agencyLogo[2] !== "" ? agencyLogo[2] : agencyLogo[0];
      favIcon = agencyLogo[1];
    }
    // if(brandLogo === ""){
    //   return (
    //     <Fragment>...</Fragment>
    //   )
    // }
    return (
      <Fragment>
        <div className="h-100">
          <Fragment>
            <Link icon={favIcon} />
            <div className="h-100 bg-dark-shadow bg-animation">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                  <div className="app-logo-inverse mx-auto mb-3 text-center" >
                  {/* {brandLogo !== "" && <img src={brandLogo} alt="Brand Logo"/>} */}
                  </div>
                  <div className="modal-dialog w-100">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="d-grid align-center w-100">
                        <div className="h5 modal-title text-center">
                          Account Activation
                          <TextLoading message="Loading..." />
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Fragment>
        </div>
      </Fragment>
    )
  }
}
