import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import {
    Row,
    Col,
    Button
} from "reactstrap";
import Loading from '../../Common/Loading';
import { createorUpdateActionForAgent, getAssistantDetails, getProviderKey } from './ProviderDetails';
import { getSessionValue } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';
import Select from "react-select";

const CustomeAction = ({agent_id, saveDetails, actionId, onAction}) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        trigger
    } = useForm();

    const [customeAction,setcustomeAction] = useState({});
    const [showLoader,setShowLoader] = useState(false);
    const [customSelectOptions,setCustomSelectOptions] = useState({});
    const proName = getSessionValue("agent_provider_name");

    const handleSelectChange = (name, value) => {
        setcustomeAction({ ...customeAction, [name]: value });
    };

    const handleInputChange = (e) =>{
        const { name, value } = e.target;
        setcustomeAction({ ...customeAction, [name]: value });
    }

    const constructorCustomeActionParams = async () =>{
        let apiKey = getProviderKey(proName);
        let ast = await getAssistantDetails(apiKey,agent_id,proName);
        if(proName === 'vapi'){
            if(ast !== null){
                let model = ast['model']['model'];
                let provider = ast['model']['provider'];
                let option = [];
                customeAction['action_select'].map((item) => {
                    option.push(item.value);
                });
                return {
                    model: {
                        provider:provider,
                        model:model,
                        toolIds: option,
                    }
                };
            }
        }
    }

    const onAddMoreOption = () =>{
        window.location.hash = "/tools";
    }

    const onSubmit = async () => {
        const isValid = await trigger();
        if(!isValid){
            return;
        }
        setShowLoader(true);
        await saveDetails(customeAction,customeAction['action_name'],'custom_action',onAction);
        setShowLoader(false);
    };

    const getMainContent = () =>{
        return(
            <div className='w-100'>
                <Loading 
                    mainContent = {
                        <>
                            <div className='w-100 p-0 agency-details'>
                                <Row className='mt-1'>
                                    <Col md={12} className='field-section'>
                                        <label className={'form-label'} htmlFor="action_name">Action name</label>
                                        <Controller
                                            name="action_name"
                                            control={control}
                                            rules={{ required: customeAction.action_name === undefined || customeAction.action_name === null || customeAction.action_name === "" ? "Please provide Action Name": false }}
                                            render={({ field }) => {
                                                return (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        name="action_name"
                                                        id="action_name"
                                                        placeholder='e.g. good bye'
                                                        value={customeAction.action_name}
                                                        autoComplete={"off"}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleInputChange(e);
                                                        }}
                                                        className={`form-control ${errors.action_name ? 'is-invalid' : ''}`}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.action_name && <em className={`error invalid-feedback`}>{errors.action_name.message}</em>}
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col md={12} className='field-section'>
                                        <label className={'form-label'} htmlFor="action_select">Custom Action Select</label>
                                        <Controller
                                            name="action_select"
                                            control={control}
                                            rules={{ required: customeAction.action_select === undefined || customeAction.action_select === null || customeAction.action_select === "" ? "Please provide Action Name": false }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        id="action_select"
                                                        name='action_select'
                                                        isMulti={true}
                                                        options={customSelectOptions}
                                                        className="basic-select"
                                                        classNamePrefix="sub-select wa-select"
                                                        value={customeAction.action_select}
                                                        onChange={(selectedOption) => handleSelectChange('action_select', selectedOption)}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.action_select && <em className={`error invalid-feedback`}>{errors.action_select.message}</em>}
                                    </Col>                
                                    <span className='field-desc '>Select Custom Action to assign to Agent. <a className='cursor-pointer' onClick={onAddMoreOption}><strong>Click here</strong></a>  to add new.</span>
                                </Row>
                                {
                                    proName === 'vapi' && (
                                        <Row className='mt-3'>
                                            <Col md={12} className='d-flex justify-content-end'>
                                                <Button className="container-footer-save theme-bg-g" type="submit" onClick={handleSubmit(onSubmit)}>
                                                    <i className="fi fi-rs-plus"></i> {'Add Action'}
                                                </Button>
                                            </Col>
                                        </Row> 
                                    )
                                }
                            </div>
                        </>
                    }
                    showLoader = {
                        showLoader
                    }
            />
            </div>
            
        )
    }

    const getActionDetails = async() =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            setcustomeAction(data)
        }

    }

    const getCustomeActionList = async () =>{
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CUSTOM_TOOLS",
            provider: proName,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data[0];
            let option = [];
            data.map((item) => {
                option.push({ value: item.functionId, label: item.name });
            });
            setCustomSelectOptions(option)
        }
    }

    useEffect(() => {
        const fetchActionDetails = async () => {
            if (agent_id !== undefined || actionId !== undefined) {
                await getActionDetails();
            }
        };
        fetchActionDetails();
    }, [agent_id, actionId]);
    
    useEffect(() => {
        const fetchCustomActionList = async () => {
            await getCustomeActionList();
        };
        fetchCustomActionList();
    }, []);
    



    return ( getMainContent()) 
};

export default CustomeAction;
