import React, {Fragment, useEffect, useState} from "react";
import { Button, Card, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm,Controller } from "react-hook-form";
import Loading from "../../Common/Loading";
import requestApi from '../../services/requestApi.service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getSessionValue, handleAudioOperation, showToast } from "../../Common/Appcommon";
import { createAgentInfo, getProviderKey, updateAgentInfo } from "./ProviderDetails";
import Select from "react-select"; 
import {agentLanguage} from "../Dashboard/Constant";
const CreateAgentModal = (props) => {
    let {formData, curRowId, formname, fieldNames, onAgentChange,inputData,data,isOpen,providerName} = props;
    const [formdata, setFormdata] = useState([]);
    const [isModalOpen, setModalOpen] = useState(isOpen);
    const [listData,setListData] = useState({});
    const [isPlaying,setIsPlaying] = useState(false);
    const [audioStates,setAudioStates] = useState({});
    const [isUpdating,setIsUpdating] = useState(false);
    const { handleSubmit, control, reset, formState: { errors },setError } = useForm();
    const [isCreateModalOpen,setIsCreateModalOpen] = useState(false);
    const [defaultDropDown,setDefaultDropDown] = useState(agentLanguage[0]);
    const [agName,setName] = useState("");
    const [blankLoading,setBlankLoading] = useState(false);
    const [saveLoading,setSaveLoading] = useState(false);
    const [curBtnIndex,setCurBtnIndex] = useState(null);
    const [isnewAgent,setIsnewAgent] = useState(false);
    let isnew = true;
    useEffect(() => {
        setFormdata(props.formData)
    }, [props]);

    useEffect (() =>{
        getAgentList();
    },[])
    const configViewFieldSave = [
        {
            label: "Agent name", 
            name: "Agent_Name",
            type: "text",
            description: "fd",
            placeholder: "Name Here",
            required: true,
            isDisabled: false,
        },
    ];

    const saveAgentInfo = async (data) => {
        const agentData = listData.find(item => item.Agent_Name === "start scrach");
        let isNameEmpty = (data) => !data?.["Agent_Name"]?.trim();
        if (isNameEmpty(data)) {
            setError("Agent_Name", {
                type: "manual",
                message: "Invalid Agent name.",
            });
            return;
        }
        setBlankLoading(true);
        if(isnewAgent){
            let forminput = {
                Agent_Name : data["Agent_Name"],
                Voice_Id : agentData["Voice_Id"],
                Voice_Provider : agentData["Voice_Provider"],
                model_name : agentData["model_name"],
                model_provider : agentData["model_provider"],
                transcription_provider : agentData["transcription_provider"],
                transcription_language : agentData["transcription_language"],
                transcription_model : agentData["transcription_model"],
                client_Messages : agentData["client_Messages"],
                server_messages : agentData["server_messages"],
                idle_messages : agentData["idle_messages"],
                Task_Prompt : agentData["Task_Prompt"],
                First_Message : agentData["First_Message"],
            }
            data = forminput;
        }
        props.handleSave(data);
    }

    const handleInputChange = (e) => {

    }

    const toggle = () => {
        toggleModal();
    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
        props.handleToggle();
    };

    const newtoggle = () => {
        newtoggleModal();
    }

    const newtoggleModal = () => {
        setIsCreateModalOpen(!isCreateModalOpen);
    };
    
    const showButtonLoading = () =>{

    }

    const getAgentList = async () => {
        let param = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_AGENT_LIST",
            PROVIDER: providerName,
            isOtherApiReq: true
        }

        const response = await requestApi.processServerRequest(param);
        if (response.issuccess) {
            const data = response.data;
            if(data.length > 0){
                setListData(data[0]);
            }
        }
    }

    const handlePlay = async (audioUrl, rowIndex, event) => {
        event.stopPropagation();
        Object.keys(audioStates).forEach((index) => {
            if (audioStates[index]?.isPlaying) {
                audioStates[index].audio.pause();
                audioStates[index].audio.currentTime = 0; // Reset playback to the start
                setAudioStates((prevAudioStates) => ({
                    ...prevAudioStates,
                    [index]: { isPlaying: false, audio: null },
                }));
            }
        });
        if(audioUrl === null){
            showToast("Audio url not found");
            return;
        }
        if (isUpdating) return;
        const newAudioState = await handleAudioOperation(audioUrl, audioStates[rowIndex]);
        setAudioStates((audioStates) => ({
            ...audioStates,
            [rowIndex]: newAudioState,
        }));
        setIsUpdating(false);
        if (newAudioState.audio) {
            newAudioState.audio.onended = () => {
                setAudioStates((audioStates) => ({
                    ...audioStates,
                    [rowIndex]: { isPlaying: false, audio: null },
                }));
            };
        }
        setIsPlaying(true);
    };

    const handleCreateAgent = (isCreateBlank = false,data = "",index = "") =>{
        
        const proName = getSessionValue("agent_provider_name");
        setIsnewAgent(isCreateBlank);
        if(data == "" && isCreateBlank){
            setModalOpen(!isModalOpen);
            setIsCreateModalOpen(!isCreateModalOpen);
        }else {
            setCurBtnIndex(index);
            setSaveLoading(true)
            saveAgentInfo(data);
        }
    }
    
    const onTemplateChange = async (data) => {
    }
    const handleChange = (e) => {
        setName(e.target.value);
    }
    const handleBlankModal = () => {   
        const agentData = listData.find(item => item.Agent_Name === "start scrach");
        return (
            <>
                <Modal isOpen={isCreateModalOpen} contentClassName="">
                    <ModalHeader className="ps-4 ms-2" toggle={newtoggle}>
                        Create new agent
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={handleSubmit(saveAgentInfo)}>
                            {configViewFieldSave.map((field, index) => {
                                const { label, name, type, placeholder, required, isDisabled, autoComplete } = field;
                                return (
                                    <Row key={index} className="mb-3">
                                        <Col md="12" lg="12">
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        className={`${errors[name] ? "is-invalid" : ""}`}
                                                        disabled={isDisabled}
                                                        autoComplete={autoComplete}
                                                    />
                                                )}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    </Row>
                                );
                            })}
                            {/* Dropdown for Language Selection */}
                            {/* <Controller
                                name="languageDrop"
                                control={control}
                                rules={{ required: "Language selection is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder="---Choose one---"
                                        className="basic-select"
                                        classNamePrefix="sub-select wa-select"
                                        options={agentLanguage}
                                    />
                                )}
                            />
                            {errors.languageDrop && (
                                <em className="text-danger">
                                    {errors.languageDrop.message}
                                </em>
                            )} */}
                        </Form>
                    </ModalBody>
                    <ModalFooter className="custom-modal-footer">
                        <Loading 
                            showLoader={blankLoading} 
                            mainContent = {
                                <Button
                                    className="btn btn-secondary theme-bg-g"
                                    type="submit"
                                    onClick={handleSubmit(saveAgentInfo)} // Ensures form submission
                                >
                                    Add Agent
                                </Button>
                            }
                        />
                        
                    </ModalFooter>
                </Modal>
            </>
        );
    };
    

    return(
        <Fragment>
            <>
                {isCreateModalOpen && handleBlankModal()}
                <Modal scrollable = {true} isOpen={isModalOpen} contentClassName="agent-model-wid">
                    <ModalHeader className="ps-4 ms-2" toggle={toggle}>
                        Add Agent
                    </ModalHeader>
                    <div className="d-flex ms-2 ps-4">Start creating AI Agents from scratch</div>
                    <ModalBody>
                        <Loading 
                            showLoader={saveLoading} 
                            mainContent = {
                                <Fragment>
                                    {
                                        true && 
                                        <Row className='profile-desc cs-domain mx-auto'>
                                            <Col md={6}>
                                                <Card className='card-br'>
                                                    <CardBody className='pb-6 h-75'>
                                                        <div className="d-flex flex-column gap-4 pt-5 pb-5">
                                                            <div className="mx-auto">
                                                                <Button onClick = {(e) => {handleCreateAgent(true)}} className="agent-btn"> <span className="agent-btn-icon me-2"><FontAwesomeIcon icon={faPlus} /></span>Start from blank</Button>
                                                            </div>
                                                            <div className="mx-auto">
                                                                Create AI agent with voice, language, and custom prompt
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='profile-desc cs-domain mx-auto'>
                                        {listData && listData.length > 0 && listData.map((data, index) => {
                                            if( data["Agent_Name"]== "start scrach"){
                                                return;
                                            }
                                            let services = data["Services"] ? data["Services"].split(",") : [];
                                            const isPlaying = audioStates[index]?.isPlaying;
                                            return (
                                                <Col md={6} key={index} className="mt-4">
                                                    <Card className='card-br'>
                                                        <CardBody className='pb-6 mt-2'>
                                                            <div>
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <div className="agent-name-div">
                                                                            {data["Agent_Name"]}
                                                                        </div>
                                                                        <div>
                                                                            {data["Category"]}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex ms-auto h-75">
                                                                        <span onClick={(event) => handlePlay(data["Voice_Url"],index,event)} className="audio-play-button d-flex pe-4 cursor-pointer">
                                                                            {isPlaying ? (
                                                                                <i className="icon-color fi fi-rr-pause"></i> // Pause icon
                                                                            ) : (
                                                                                <i className="icon-color le-form-row fi fi-rr-play"></i> // Play icon
                                                                            )}
                                                                        </span>
                                                                        <Button onClick = {(e) => {handleCreateAgent(false,data,index)}} className="agent-create-btn agent-btn">  <span className="agent-btn-icon me-2"><FontAwesomeIcon icon={faPlus} /></span>Create Agent </Button>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="pt-4 d-flex flex-column">
                                                                    <span className="pb-2">Perfect for</span>
                                                                    <div className="d-flex flex-wrap gap-3">
                                                                        {services && services.length > 0 && services.map((val, i) => (
                                                                            <div key={i} className={`agent-badge`}>
                                                                                {val.trim()}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Fragment>
                            }
                        />
                    </ModalBody>
                </Modal>
            </>
        </Fragment>
    )
}



export default CreateAgentModal;