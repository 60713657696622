import React, { Fragment, useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";
import Tabs from "react-responsive-tabs";

const ShowIntegrationInfo = ({ keyname, headData, integrationData, isInstalled, isConnected, accountInfo, onInstall, onRemove }) => {
    const [tabInfo, setTabInfo] = useState([]);
    const [integrationID, setintegrationID] = useState([]);
    // Set tabs when component mounts
    useEffect(() => {
        setTabView();
        if (Array.isArray(accountInfo) && accountInfo.length > 0 && accountInfo[0].id) {
            setintegrationID(accountInfo[0].id);
        } else {
            setintegrationID([]); // Fallback if accountInfo is not an array or is empty
        }
    }, [accountInfo]);


    const getAccountView = () => {
        return (
        !accountInfo == '' && accountInfo.length !== 0 && 
            <div className="ghl-account-details">
                <table>
                    <tbody>
                    {accountInfo.filter(field => field.type !== "id").map((field, index) => (
                            <tr key={index}>
                                <td className="field-title">{field.label}&nbsp;</td>
                                <td className="field-value">{field.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="hor-8px">
                    <button  onClick={() => onRemove(keyname)} className="btn btn-danger">Remove</button>
                </div>
            </div>
        );
    };

    // Function to initialize tab data
    const setTabView = () => {
        let tabs = [
            {
                name: "Accounts",
                biography: <Fragment>{getAccountView()}</Fragment>,
            },
            {
                name: "Info",
                biography: <Fragment>{getGeneralInfoView()}</Fragment>,
            },
        ];
        setintegrationID();
        setTabInfo(tabs);
    };

    // Function to format tab items
    const getSimpleTabs = () => {
        return tabInfo.map(({ name, biography }, index) => ({
            key: index,
            title: name,
            getContent: () => biography, // Render function for content
        }));
    };

    // Function to display the integration header
    const getIntegrationHeader = () => (
        <div className="ghl-heading">
            <div className="ghl-details">
                <Row>
                    <Col md={1}>
                        <span className="card-image-status">
                            <img src={headData.image} className="intagration-card-detail-img" alt="Integration Logo" />
                        </span>
                    </Col>
                    <Col md={4} className="p-1">
                        <span className="heading">{headData.name}</span>
                        <span className="tag">{headData.tag}</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        {!isInstalled ? (
                            <Button name="install" className="badge-theme badge-btn m-4" onClick={() => onInstall(keyname,true)}>
                                <i className="fi fi-br-plus-small"></i>
                                <span>{headData.installButtonText}</span>
                            </Button>
                        ) : (
                            <>
                            {keyname === 'email' &&
                                <Button name="install" className="btn theme-bg-g m-4" onClick={() => onInstall('test_connect',true)}>
                                    <i className="fi fi-br-plus-small"></i>
                                    <span>{headData.testConnectionText}</span>
                                </Button>
                            }
                            <Button name="uninstall" className="badge-theme badge-btn m-4"  onClick={() => onRemove(keyname,integrationID)}>
                                <i className="fi fi-br-plus-small"></i>
                                <span>{headData.uninstallButtonText}</span>
                            </Button>
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );

    // Function to display account details


    // Function to display general information
    const getGeneralInfoView = () => (
        <div>
            {integrationData.map((item, index) => (
                <div key={index} className="card-fields">
                    <span className="field-name">{item.heading}</span>
                    {item.type === "url" ? (
                        <span>
                            <a href={item.subheading} target="_blank" rel="noopener noreferrer">
                                {item.subheading}
                            </a>
                        </span>
                    ) : item.type === "text" ? (
                        <span className="subheading">{item.subheading}</span>
                    ) : item.type === "list" ? (
                        <div className="subheading ver-2px">
{/*                             <ul className="benefits-list">
 */}                                {item.subheading.map((benefit, idx) => (
                                    <li key={idx}>
                                        <span className="subheading" >{benefit.title}</span>: <br></br> <span>{benefit.description}</span>
                                    </li>
                                ))}
{/*                             </ul>
 */}                        </div>
                    )  : null}
                </div>
            ))}
        </div>
    );

    // Function to display the agent details tab (if connected)
    const getAgentDetailsTab = () => {
        if (!isConnected) return null;
        return (
            <div className="agent-details-tab calls-details">
                <Row md={12}>
                    <Tabs
                        tabsWrapperClass="body-tabs"
                        activeTab={"1"}
                        showMore={true}
                        transform={true}
                        showInkBar={true}
                        selectedTabKey={0}
                        transformWidth={400}
                        items={getSimpleTabs()}
                    />
                </Row>
            </div>
        );
    };

    return (
        <Fragment>
            {getIntegrationHeader()}
            {isConnected && getAgentDetailsTab()}
            {!isConnected && getGeneralInfoView()}
            
        </Fragment>
    );
};

export default ShowIntegrationInfo;
