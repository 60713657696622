import React, { Component } from "react";
import { handleAudioOperation, showToast } from "./Appcommon";

class CustomTable extends Component {
    constructor(props) {
        super(props);
        if(props.isModalClose){
            this.state = {
                selectedRow: null,
                audioStates: {},
                isUpdating: false, 
            }; 
        }
        this.state = {
            selectedRow: null,
            audioStates: {},
            isUpdating: false, 
        };
    }
    
    componentDidMount() {   
        setTimeout(() => {
            let { data, columns ,agentVoiceId } = this.props; 
            if(agentVoiceId != undefined && data != undefined){
                data.map((row, rowIndex) => {
                    if(row.Voice_Id === agentVoiceId.Voice_Id){
                        this.setState({ selectedRow: rowIndex });
                        this.props.selectRow(row);
                    }
                });            
            }
        }, 100);
    }

    handleRowSelect = (row,rowIndex) => {
        this.setState({ selectedRow: rowIndex });
        this.props.selectRow(row);
    };

    handleAudioPlay = async (audioUrl, rowIndex, event) => {
        event.stopPropagation();
        const { audioStates, isUpdating } = this.state;
        Object.keys(audioStates).forEach((index) => {
            if (audioStates[index]?.isPlaying) {
                audioStates[index].audio.pause();
                audioStates[index].audio.currentTime = 0; // Reset playback to the start
                this.setState((prevState) => ({
                    audioStates: {
                        ...prevState.audioStates,
                        [index]: { isPlaying: false, audio: null },
                    },
                }));
            }
        });
        if(audioUrl === null){
            showToast("Audio url not found",'error');
            return;
        }
        // Prevent multiple clicks while updating
        if (isUpdating) return;
        // Set the lock
        this.setState({ isUpdating: true });
        // Pause all currently playing audios and update their states
        // Call the audio operation function and get the new state for the row
        const newAudioState = await handleAudioOperation(audioUrl, audioStates[rowIndex]);
        // Update the component state
        this.setState((prevState) => ({
            audioStates: {
                ...prevState.audioStates,
                [rowIndex]: newAudioState,
            },
            isUpdating: false, // Release the lock after state update
        }));
    
        // Handle audio end event
        if (newAudioState.audio) {
            newAudioState.audio.onended = () => {
                this.setState((prevState) => ({
                    audioStates: {
                        ...prevState.audioStates,
                        [rowIndex]: { isPlaying: false, audio: null },
                    },
                }));
            };
        }
        this.props.handleAudio(this.state.audioStates);
    };
    
    renderCell = (row, column, rowIndex) => {
        const { selectedRow } = this.state;
        const { isSelect,isRefresh } = this.props;
        const isSelected = selectedRow === rowIndex;
        const cellClass = [
            "custom-cell",
            column.alias === "Icon" && isSelect && "icon-cell-wid",
            isSelected && "selected"
        ]
            .filter(Boolean)
            .join(" ");
        if (column.alias === "Icon") {
            return (
                <div onClick={() => this.handleRowSelect(row, rowIndex)} className={cellClass}>
                    {isSelected && !isRefresh && (
                        <div className="voice-model-icon">
                            <i className="fi fi-ss-check-circle"></i>
                        </div>
                    )}
                </div>
            );
        }
        if (column.alias === "Url") {
            const audioUrl = row["Audio_Url"] ? row["Audio_Url"] : row["Custom_Url"];
            const { audioStates } = this.state;
            const isPlaying = audioStates[rowIndex]?.isPlaying;
            return (
                <div
                    onClick={(event) => this.handleAudioPlay(audioUrl, rowIndex, event)}
                    className="custom-cell audio-play-button"
                >
                    {audioStates[rowIndex]?.isPlaying ? (
                        <i className="icon-color fi fi-rr-pause"></i>
                    ) : (
                        <i className="icon-color le-form-row fi fi-rr-play"></i>
                    )}
                </div>
            );
        }
    
        return (
            <div className={cellClass}>
                <span>{row[column.alias]}</span>
            </div>
        );
    };
    
    render() {
        const { data, columns } = this.props;
        const { selectedRow } = this.state;
        if (data.length === 0) {
            return (
                <div className="custom-table-wrapper">
                    <div className="custom-table">
                    <div className="custom-row custom-header">
                        {columns.map((column, idx) => (
                            <div 
                                key={idx} 
                                className={`custom-cell custom-header-cell ${column.alias === "Icon" ? "icon-cell-wid" : ""}`}
                            >
                                {column.name}
                            </div>
                        ))}
                    </div>
                        <div className="cus-no-records">
                            <div>No records found</div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="custom-table-wrapper">
                <div className="custom-table">
                <div className="custom-row custom-header">
                    {columns.map((column, idx) => (
                        <div 
                            key={idx} 
                            className={`custom-cell custom-header-cell ${column.alias === "Icon" ? "icon-cell-wid" : ""}`}
                        >
                            {column.name}
                        </div>
                    ))}
                </div>
                <div className="cus-tbl-bdy custom-table-body">
                    {data.map((row, rowIndex) => (
                        <div
                            key={rowIndex}
                            className={`custom-row custom-data-row ${
                                selectedRow === rowIndex ? "selected" : ""
                            }`}
                            onClick={() => this.handleRowSelect(row,rowIndex)}
                        >
                            {columns.map((column, colIndex) => (
                                <React.Fragment key={colIndex}>
                                    {this.renderCell(row, column, rowIndex)}
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </div>
                </div>
            </div>
        );
    }
}

export default CustomTable;
