import cal from "../../assets/utils/images/Intagration image/cal.png";
import calendly from "../../assets/utils/images/Intagration image/calendly.png";
import gohighlevel from "../../assets/utils/images/Intagration image/gohighlevel.png";
import google_calendar from "../../assets/utils/images/Intagration image/google_calendar.svg";
import mail from "../../assets/utils/images/Intagration image/mail.png";
import make from "../../assets/utils/images/Intagration image/make.png";
import meet from "../../assets/utils/images/Intagration image/meet.svg";
import sms from "../../assets/utils/images/Intagration image/sms.png";
import stripe_icon from "../../assets/utils/images/Intagration image/stripe-icon.svg";
import twilio from "../../assets/utils/images/Intagration image/twilio.svg";
import zapier_icon from "../../assets/utils/images/Intagration image/zapier-icon.svg";
import zoom from "../../assets/utils/images/Intagration image/zoom.svg";

export const cardData = [
    { img: gohighlevel, name: 'ghl_calendar' ,heading: 'HighLevel', subheading: 'Calendar', discription : 'Import your subaccounts from GHL',component:{ button : {details : 'Details', install : 'Install app', key: 'highlevel'}}},
    { img: cal, name: 'cal.com' , heading: 'Cal.com', subheading: 'Calendar', discription : 'Organize events and meetings', component:{ button : {details : 'Details', install : 'Install app', key: 'cal.com'}} },
    { img: google_calendar, name: 'gcalendar' , heading: 'Google Calendar', subheading: 'Calendar',discription : 'Time management and scheduling service', component:{ button : {details : 'Details', install : 'Install app', key: 'google-calendar'}} },
    { img: twilio, name: 'twilio' , heading: 'Twilio', subheading: 'Voice & video', discription : 'Make and receive calls around the world', component:{ button : {details : 'Details', install : 'Install app', key: 'twilio'}} },
    { img: mail, name: 'email' , heading: 'Email', subheading: 'Communication',discription : 'Send emails directly from', component:{ button : {details : 'Details', install : 'Install app', key: 'email'}} },
    { img: sms, name: 'sms' , heading: 'SMS', subheading: 'Communication',discription : 'Send SMS directly from', /* component:{ button : {details : 'details', install : 'install', key: 'sms'}}  */},
    { img: zoom, name: 'zoom' , heading: 'Zoom', subheading: 'Communication',discription : 'Secure and reliable online communication platform', /* component:{ button : {details : 'details', install : 'install', key: 'zoom'}} */ },
    { img: zapier_icon, name: 'zapier' , heading: 'Zapier', subheading: 'Developer Tools',discription : 'Automate tasks and workflows', /* component:{ button : {details : 'details', install : 'install', key: 'zapier_icon'}} */ },
    { img: make, name: 'make' , heading: 'Make', subheading: 'Productivity',discription : 'Build and automate workflows', /* component:{ button : {details : 'details', install : 'install', key: 'make'}} */ },
    { img: calendly, name: 'calendly' , heading: 'Calendly', subheading: 'Calendar',discription : 'Make scheduling faster and more efficient', /* component:{ button : {details : 'details', install : 'install', key: 'calendly'}} */ },
    { img: stripe_icon, name: 'stripe' , heading: 'Stripe', subheading: 'Payment',discription : 'Online payment processing and financial solutions', /* component:{ button : {details : 'details', install : 'install', key: 'stripe'}} */ },
    { img: meet, name: 'google_meet' , heading: 'Google Meet', subheading: 'Communication',discription : 'Connect & collaborate with video conferencing', /* component:{ button : {details : 'details', install : 'install', key: 'meet'}} */ },
];
export const modalOptions = {
   "cal.com" : { 
      label: "Cal.com", 
      fields: [{ name: "apiKey", label: "API Key", type: "text" }], 
      desc: ['Ensure that the API key is set to "Never Expires".'] 
    },
  "twilio" : { 
      label: "Twilio", 
      fields: [
        { name: "apiKey", label: "SID", type: "text" },
        { name: "authToken", label: "Auth Token", type: "text" }
      ]
    },
  "email" : { 
      label: "Email", 
      fields: [
        { name: "name", label: "User Name", type: "text" },
        { name: "password", label: "Password", type: "password" },
        { name: "url", label: "Url", type: "text" },
        { name: "port", label: "Port Number", type: "text" },
        { 
          name: "protocol", 
          label: "Protocol", 
          type: "dropdown", 
          options: [
            { label: "SSL", value: "ssl" },
            { label: "TLS", value: "tls" }
          ]
        },
        { name: "sender", label: "Sender Name", type: "text" },
        { name: "fromaddress", label: "From Address", type: "text" }
      ]
    },
  "highlevel" : { 
      label: "Go Highlevel", 
      fields: [{ name: "apiKey", label: "API Key", type: "text" }], 
      desc: ['Ensure that the API key is set to "Never Expires".'] 
    }
}

/* export const modalOption = {
   "cal.com" : { 
       
      label: "API Key", 
      fields: [{ name: "apiKey", label: "API Key", type: "text" }], 
      desc: ['Ensure that the API key is set to "Never Expires".'] 
    },
 "twilio" : { 
      label: "Twilio Credentials", 
      fields: [
        { name: "apiKey", label: "SID", type: "text" },
        { name: "authToken", label: "Auth Token", type: "text" }
      ]
    },
  "email" : { 
      label: "Email Settings", 
      fields: [
        { name: "name", label: "User Name", type: "text" },
        { name: "password", label: "Password", type: "password" },
        { name: "url", label: "Url", type: "text" },
        { name: "port", label: "Port Number", type: "text" },
        { 
          name: "protocol", 
          label: "Protocol", 
          type: "dropdown", 
          options: [
            { label: "SSL", value: "ssl" },
            { label: "TLS", value: "tls" }
          ]
        },
        { name: "sender", label: "Sender Name", type: "text" },
        { name: "fromaddress", label: "From Address", type: "text" }
      ]
    },
}; */


/* export const details = [ {
    'cal.com' : {
        headerComponent : { 
            name:"Cal.com",
            image:{
                    "classname" : "cloud-teley-image",
                    "icon" : cal
                },
            subheading: 'Calendar',
            btn : '+ Install',
        },
        website : {
            name : 'Website',
            link : 'https://cal.com'
        },
        overview : {
            name : 'Overview',
            details : 'The Voicemetrics integration with Cal.com transforms scheduling into an effortless experience for your team and customers. By combining OneInbox’s advanced AI calling capabilities with Cal.com’s flexible calendar management, businesses can fully automate appointment booking, rescheduling, and reminders. This integration lets you simplify workflows and increase efficiency by enabling AI agents to handle scheduling tasks seamlessly, even in high-volume scenarios.'
        },
        keybenefits : {
            name : 'Key Benefits',
            benefitsSubheadind : ['Automated Scheduling and Rescheduling','Enhanced Customer Experience','Real-Time Syncing'],
            benefitsDetails : ['Free up valuable time by allowing our AI-powered agents to take over appointment bookings and rescheduling tasks. OneInbox integrates with Cal.com to check real-time availability and instantly confirm bookings on your behalf.','Provide a smoother, faster experience by offering 24/7 booking capabilities. OneInbox AI agents can assist customers anytime, checking availability and scheduling appointments within seconds.','All appointments are automatically synced with your Cal.com calendar, ensuring you and your team have the latest information without needing manual updates.'],
        },
        //key : 'cal.com'
    },
    'Googlecalender' : {
        headerComponent : { 
            name:"Google Calender",
            image:{
                    "classname" : "google_calendar",
                    "icon" : google_calendar
                },
            subheading: 'Calendar',
            btn : '+ Install',
        },
        website : {
            name : 'Website',
            link : 'https://calendar.google.com'
        },
        overview : {
            name : 'Overview',
            details : 'The Voicemetrics integration with Google Calendar simplifies scheduling for individuals and businesses by connecting advanced AI calling capabilities to Google’s reliable calendar platform. With this integration, users can automate appointment bookings, check real-time slot availability, and manage their schedules effortlessly.'
        },
        keybenefits : {
            name : 'Key Benefits',
            benefitsSubheadind : ['AI-Powered Scheduling','Improved Accessibility','Seamless Synchronization'],
            benefitsDetails : ['Enable AI agents to book and reschedule appointments by checking your Google Calendar availability, reducing manual effort.','Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time.','Automatically sync appointments with Google Calendar to keep your schedule up-to-date and eliminate double bookings.'],
        },
       // key : 'Google'
    },
    'gohighlevel' : {
        headerComponent : { 
            name:"HighLevel",
            image:{
                    "classname" : "gohighlevel",
                    "icon" : gohighlevel
                },
            subheading: 'Calendar',
            btn : '+ Install',
        },
        website : {
            name : 'Website',
            link : 'www.gohighlevel.com'
        },
        overview : {
            name : 'Overview',
            details : 'The Voicemetrics integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting OneInbox’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.'
        },
        keybenefits : {
            name : 'Key Benefits',
            benefitsSubheadind : ['Automated Appointment Management','Streamlined Client Interactions','Integrated Workflows'],
            benefitsDetails : ['Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.','Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.','Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.'],
        },
        key : 'gohighlevel'
    },
    'twilio' : {
        headerComponent : { 
            name:"Twilio",
            image:{
                    "classname" : "gohighlevel",
                    "icon" : twilio
                },
            subheading: 'Voice & video',
            btn : '+ Install',
        },
        website : {
            name : 'Website',
            link : 'www.gohighlevel.com'
        },
        overview : {
            name : 'Overview',
            details : 'The Voicemetrics integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting OneInbox’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.'
        },
        keybenefits : {
            name : 'Key Benefits',
            benefitsSubheadind : ['Automated Appointment Management','Streamlined Client Interactions','Integrated Workflows'],
            benefitsDetails : ['Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.','Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.','Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.'],
        },
        //key : 'twilio'
    },
    'Email' : {
        headerComponent : { 
            name:"Email",
            image:{
                    "classname" : "mail",
                    "icon" : mail
                },
            subheading: 'Communication',
            btn : '+ Install',
        },
        website : {
            name : 'Website',
            link : 'https://www.gmail.com'
        },
        overview : {
            name : 'Overview',
            details : 'The Voicemetrics integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting OneInbox’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.'
        },
        keybenefits : {
            name : 'Key Benefits',
            benefitsSubheadind : ['Automated Appointment Management','Streamlined Client Interactions','Integrated Workflows'],
            benefitsDetails : ['Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.','Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.','Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.'],
        },
        //key : 'twilio'
    }
}]; */ 


