import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { Button, Row, Col, Modal, Card, ModalHeader, ModalFooter, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import unCheckedCircle from '../../../src/assets/utils/images/unchecked.png';
import requestApiService from '../../services/requestApi.service';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getOrganizationInfo, handleErrorResponse, showToast, checkIsAdmin, confirmBox,translateContent, getDeleteIcon } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';

class CustomDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domainName: '',
            isValid: false,
            isOpen: false,
            custom_domain_ip: '',
            setTextCopied: false,
            validDNS: false,
            isVerified: false,
            showPopup: false,
            showButtonLoading: false,
            disableCheckDns : false,
            showLoader:false,
            isAdmin:false,
            domain_Columns: [
                { name: "Record Type", selector: "record_type", placeholder : "CNAME" ,value :""},
                { name: "Hostname", selector: "host_name", placeholder : "@",value :"" },
                { name: "Points to", selector: "points_to" , placeholder : "cname.voicemetrics.ai", value :""},
            ],
            dnsError : "DNS propagation may take up to 24 hours. Please ensure your DNS records are properly configured in the DNS settings section of your domain manager",
            dnsContent : "To configure your domain, access your domain hosting service. Copy the Host and Value for the record and add them to your domain manager.",
            dnsConnect : "The linking process is in progress and may take up to 24 hours to complete.",
            showSaveLoading : false,
            userDomainName : "",
        };
    }

    componentDidMount = async () => {
        this.getDNSRecords();
    };
    onInputChange = (e) => {
        const value = e.target.value;
        const isValid = value !== "" && value !== undefined;
        const isModalOpen = (value === undefined || value === "") ? false : this.state.isOpen;

        this.setState({
            domainName: value,
            isValid: isValid,
            isOpen: isModalOpen,
        });
    };
    handleSubmit = async (e) => {
        if(this.state.domainName == "" || this.state.domainName.includes("voicemetrics") || this.state.domainName.includes("cratiocrm")){
            showToast("This domain is not allowed.");
            return;
        }
        this.setState({
            showSaveLoading : true,
        });
        let accountId = getOrganizationInfo("Account_ID");
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_DNS_RECORDS",
            "domain_name": this.state.domainName ,//this.state.domainName,
            "account_id": accountId,
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            this.setState({
                custom_domain_ip: response.data[1],
                isValid: false,
                isOpen: true
            })
            await this.afterResponceProcess(response);
        } else {
            handleErrorResponse(response.errormsg);
        }
        this.setState({
            showSaveLoading : false,
        });
    };

    getDNSRecords = async () => {
        let isAdmin = await checkIsAdmin();
        this.setState({showLoader:true,isAdmin:isAdmin});
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_DNS_RECORDS",
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        await this.afterResponceProcess(response);
        this.setState({showLoader:false});
    }

    checkDNSRecords = async () => {
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "CHECK_DNS_RECORDS",
            "domain_name": this.state.domainName,
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let errormsg = response.data['errormsg'];
            let isValid = response.data['validDNS'];
            this.setState({disableCheckDns:isValid});
            errormsg = "The verification process is underway and may take up to 24 hours to complete.";
            if (errormsg !== "") {
                handleErrorResponse(errormsg);
            } else {
                let domain_name = this.state.domainName;
                showToast("The verification process is underway and may take up to 24 hours to complete.");
                setTimeout(() => {
                    this.setState({
                        validDNS: response.data['validDNS'],
                        domainName: domain_name,
                        isVerified: response.data['validDNS'],
                        isOpen: true,
                        userDomainName : domain_name,
                    });
                }, 1500)
            }
        } else {
            handleErrorResponse("Technical error Occured.Please try after some time.");
        }
    }
    copyUrl = () => {
        let show = false;
        if(!this.state.setTextCopied){
            show = true;
        }
        this.setState({
            setTextCopied: show
        })
    }

    customDomainConfigView = () => {
        return (
            <div className='agent-details agent_details-wid phone-numbers-container'>
                <div className="card-fields">
                    <span className="heading">Your Domains</span>
                    <span className="subheading">Manage Your Domains</span>
                </div>
                <div className='number-hover'>
                    <span className='cloud-number-name-number'>
                        <p className="name">{"Domain Name"}</p>
                        <p className="number">{this.state.domainName}</p>
                    </span>
                    {this.state.isAdmin &&
                        <Col md={2} className='custom-display p-0'>
                            <Button autoComplete="off" color="danger" onClick={this.removeDomain}>
                                Remove Domain
                            </Button>
                        </Col>
                    }
                </div>
            </div>
        );

    }

    removeDomain = async () => {
        let confirm = await confirmBox(translateContent("vm.customdomain.confirm"), translateContent("vm.customdomain.confirm.msg"),getDeleteIcon(),'Remove Domain','Cancel');
        if(!confirm){
            return;
        }

        this.setState({
            showButtonLoading: true
        })
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "DELETE_DNS_RECORDS",
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            this.setState({
                showButtonLoading: false
            })
            showToast("Domain Removed Successfully.");
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }

    };

    toggle = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    };

    afterResponceProcess = async (response) =>{
        if (response.issuccess && response.data.length > 0) {
            let verified = response.data[0][3] === "verified" ? true : false;
            let domainName =response.data[0][2];
            let disabled = response.data[0][3] === "ip_verified" ? true : false;
            this.setState({
                validDNS: verified,
                domainName: domainName,
                isVerified: verified,
                isOpen: domainName !== "" ? true : false,
                custom_domain_ip: response.data[1],
                disableCheckDns: disabled,
                userDomainName : domainName,
            });
        }

    }

    openDnsError = (content,type) => {
        let icon = type == "error" ? <i class="fi fi-rr-exclamation dns-error-icon"></i> : <i class="fi fi-rs-bulb domain-ip-verifed"></i>;
        let title = type === "error" ? "DNS not yet verified" : type === "ipVerified" ? "Your domain has been successfully verified!" : null;
        return (
            <div className ='ps-3'>
                <Card className="main-card scrollable-table dns-error">
                    <CardBody>
                        <span className='d-flex p-2 gap-2'>
                            <span>
                                {icon}
                            </span>
                            <span>
                                {type == "error" ? <span className= 'dns-error-icon'>DNS not yet verified</span> : type === "ipVerified" ? <span className= 'domain-ip-verifed'>Your domain has been successfully verified!</span> : null}
                                <span className='d-block'>{content} {type === "text" && (
                                    <a className="dns-note-link text-decoration-underline" href="https://help.voicemetrics.ai/en/category/setting-up-a-custom-domain" target="_blank" rel="noopener noreferrer">
                                    {"Refer to this article for detailed instructions."}
                                    </a>
                                )}</span>
                            </span>
                        </span>
                    </CardBody>
                </Card>
            </div>
        );
    }
    getModalContent = () => {
        return (
            <div>
                <Modal isOpen={true} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Remove Domain</ModalHeader>
                    <div className='ms-3 mb-1 del-subaccount-header'>
                        Are you sure you want to permanently remove your domain? This action cannot be undone.
                    </div>
                    <div className='delete-sub-account-popup mb-1'>
                        <ModalFooter>
                            <Loading showLoader={this.state.showButtonLoading} mainContent={
                                <Fragment>
                                    <Button className='btn btn-danger' onClick={this.removeDomain}>
                                        Remove Domain
                                    </Button>
                                </Fragment>
                            } />
                        </ModalFooter>
                    </div>
                </Modal>
            </div>
        );
    };

    render() {
        let customDomain = this.state.custom_domain_ip;
        customDomain = customDomain?.split(".");
        return (
            <Fragment>
                <Common title="Custom Domain" titleContent={
                    <span>Custom Domain</span>
                } mainContent={
                    <>

                        {! this.state.showLoader && 
                            <div className='d-container container m-auto max-width-65'>
                                {!this.state.validDNS ?
                                    <>
                                        <div className='agency-details'>
                                            <div>
                                                <div className="p-1">
                                                    <Card className="main-card scrollable-table">
                                                    
                                                        <CardBody>
                                                            <div className='p-2 ms-2 card-fields d-grid'>
                                                                <span className="heading">Connect Your Custom Domain</span>
                                                            </div>
                                                            {
                                                                this.state.userDomainName &&
                                                                    (this.state.disableCheckDns 
                                                                        ? this.openDnsError(this.state.dnsConnect, "ipVerified")
                                                                        : this.openDnsError(this.state.dnsError, "error")
                                                                    )
                                                            }
                                                            <div className = "p-2">
                                                                <span className='heading ms-2'>Domain URL</span>
                                                                <div className='p-2'>
                                                                    <input
                                                                        type="text"
                                                                        value={this.state.domainName}
                                                                        onChange={this.onInputChange}
                                                                        className="form-control"
                                                                        autoComplete={"off"}
                                                                        placeholder="e.g., app.yourdomain.com (without 'https://' or 'http://')"
                                                                        disabled={!this.state.isAdmin}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className = "p-2 ms-2">
                                                                <span className='heading'>DNS Configuration</span>
                                                            </div>
                                                            <div className='ps-2 ms-2'>
                                                                <table className="domain-table table table-bordered rounded-table table-full-width border-collapse border border-gray-300 list-data-table">
                                                                    <thead className='doman-thead'>
                                                                        <tr className="">
                                                                        {this.state.domain_Columns.map((col, index) => (
                                                                            <th key={index} className="border-0">{col.name}</th>
                                                                        ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="border-0">
                                                                                {customDomain[0]?.toUpperCase()}
                                                                            </td>
                                                                            <td className="border-0">
                                                                                Your Sub Domain
                                                                            </td>
                                                                            <td className="border-0">
                                                                            <span className='d-flex'>
                                                                                {this.state.custom_domain_ip}
                                                                                <CopyToClipboard onCopy={this.copyUrl} text={this.state.custom_domain_ip}>
                                                                                    <i class="fi fi-rr-copy-alt ms-2 cursor-pointer"></i>
                                                                                </CopyToClipboard>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <span className='d-flex'>
                                                                {this.state.setTextCopied ? <span className='ms-auto me-2 dns-copy-msg'> Copied! </span> : ""}
                                                            </span>
                                                            {this.openDnsError(this.state.dnsContent,"text")}
                                                            <div className='pt-4 pb-5'>
                                                                <Loading showLoader={this.state.showSaveLoading} mainContent=
                                                                    {
                                                                        <Button className='d-flex mx-auto theme-bg-g' autoComplete="off" color="dark" onClick={this.state.disableCheckDns ? this.getDNSRecords :this.handleSubmit }>
                                                                            {this.state.disableCheckDns ? "Check Status" : "Save & Check Status"}
                                                                        </Button>
                                                                    }
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        { <div className='agency-details hide'>
                                            <div className='card-fields d-grid'>
                                                <span className="heading">Custom Domain</span>
                                                <span className='header-desc'>Easily integrate your custom domain for white labeling.</span>
                                            </div>
                                            <Card className='card-br'>
                                                <CardBody className='pb-6'>
                                                    <div className='card-fields d-grid'>
                                                        <div className="dns-records-container">
                                                            <div className={`dns-checkbox ${this.state.isOpen ? 'dns-verified' : ''}`}>
                                                                <i className="fi fi-ss-check-circle"></i>
                                                            </div>
                                                            {/* <img className='domain-img' src={!this.state.isOpen ? unCheckedCircle : checkedCircle}></img> */}
                                                            <span className='heading'>Add Your Domain</span>
                                                        </div>
                                                        <span className='profile-desc cs-domain'>Name</span>
                                                        <div>
                                                            <Row className='profile-desc cs-domain'>
                                                                <Col md={9}>
                                                                    <input
                                                                        type="text"
                                                                        name="custom_domain_name"
                                                                        id="custom_domain_name"
                                                                        value={this.state.domainName}
                                                                        autoComplete={"off"}
                                                                        onChange={this.onInputChange}
                                                                        className='form-control'
                                                                        placeholder='app.yourdomain.com'
                                                                        disabled={!this.state.isAdmin}
                                                                    />
                                                                </Col>
                                                                <Col md={3} className='custom-row p-0'>
                                                                    <Button
                                                                        className='domain-btn theme-bg-g'
                                                                        color={this.state.isValid ? "dark" : "light"}
                                                                        backgroundColor={this.state.isValid ? "light" : "dark"}
                                                                        disabled={!this.state.isValid}
                                                                        onClick={this.handleSubmit}
                                                                    >
                                                                        <FontAwesomeIcon icon={faLink} /> Connect Domain
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <span className='field-desc'>Example: app.yourdomain.com (Without specifying 'https://' or 'http://')</span>
                                                        </div>

                                                        <div className='card-fields d-grid mt-4'>
                                                        <div className="dns-records-container">
                                                            <div className={`dns-checkbox ${this.state.isVerified ? 'dns-verified' : ''}`}>
                                                                <i className="fi fi-ss-check-circle"></i>
                                                            </div>
                                                            {/* <img className="domain-img" src={ ? checkedCircle : unCheckedCircle} alt="DNS Record Status" /> */}
                                                            <span className="heading">Add Your DNS Records</span>
                                                        </div>

                                                        {this.state.isOpen && (
                                                            <>
                                                                <div className=''>
                                                                    <span className='profile-desc'>
                                                                        Point your domain to our servers to make it work. You can use the following records.
                                                                    </span>
                                                                        <Row>
                                                                        <Col md={9} className="mt-2">
                                                                                <CopyToClipboard onCopy={this.copyUrl} text={this.state.custom_domain_ip}>
                                                                                    <Button className="w-100 btn-hover-shine custom-domain-record" color="light" disabled={!this.state.isAdmin}>
                                                                                        {/* cname.voicemetrics.ai  */}
                                                                                        {this.state.custom_domain_ip}
                                                                                    </Button>
                                                                                </CopyToClipboard>
                                                                            </Col>
                                                                            <Col md={3} className={`${!this.state.isAdmin ? 'hide' : 'custom-row mt-2 p-0'}`}>
                                                                                {!this.state.disableCheckDns ? 
                                                                                    (
                                                                                        <Button className='theme-bg-g' autoComplete="off" color="dark" onClick={this.checkDNSRecords} disabled={this.state.disableCheckDns}>
                                                                                            Check DNS Records
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button className='theme-bg-g' autoComplete="off" color="dark" onClick={() => this.getDNSRecords()}>
                                                                                            <i class="fi fi-br-refresh"></i> Refresh
                                                                                        </Button>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <div className='dns-copy-text theme-c-g mt-2 ps-1'>
                                                                                {this.state.setTextCopied ? <span className=''>Copied!</span> : null}
                                                                        </div>
                                                                </div>
                                                            </>

                                                        )}
                                                    </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div> }
                                        

                                        <div className='agency-details hide'>
                                            <div className='card-fields d-grid'>
                                                <div className='dns-records-container'>
                                                    <img className='domain-img' src={unCheckedCircle} alt="domain-image"></img>
                                                    <span class="heading">Add Meta Tags</span>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    this.customDomainConfigView()
                                }

                            </div>
                        }
                    </>
                } 
                mainLoader={this.state.showLoader}/>
                {this.state.showPopup && this.getModalContent()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDomain);
