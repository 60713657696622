import React, { useState, useRef, useEffect, Fragment } from "react";


const SearchableDropdown = (props) => {
  let { dropDownList,placeHolder,selectedOption,value } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(value ? value : null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = dropDownList.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );


  const hadleSelected = (option) =>{
    setSelected(option);
    selectedOption(option);
  }
  return (
    <Fragment>
        <div className="dropdown-container" ref={dropdownRef}>
            <button type ="button" onClick={toggleDropdown} className="dropdown-button">
              {selected ? selected.label : (value ? value.label : "Select a timezone")}
              <span className="select-down-icon"> <i class="fi fi-rr-tally-1"></i> <i class="fi fi-br-angle-down"></i></span>
            </button>

        {isOpen && (
            <div className="dropdown-search">
            <div className="dropdown-search-container">
                <i className="ps-1 icon-color fi fi-rs-search dropdown-search-icon"></i>
                <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={placeHolder}
                className="dropdown-search-input"
                onClick={(e) => e.stopPropagation()}
                />
            </div>

            <ul className="dropdown-options">
                {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                    <li
                    key={option.value}
                    className="dropdown-option"
                    onClick={() => {
                        hadleSelected(option);
                        setIsOpen(false);
                    }}
                    >
                    {option.label}
                    </li>
                ))
                ) : (
                <li className="no-options">No results found</li>
                )}
            </ul>
            </div>
        )}
        </div>
    </Fragment>
    
  );
};

export default SearchableDropdown;
