import React, { useState, Fragment, useEffect } from 'react';
import CratioDropdownField from '../Common/CratioFields/CratioDropdownField';
import Loader from 'react-loaders';
import { Button } from "reactstrap";
import Loading from '../../Common/Loading';

const ImportUploader = (props) => {
    let { isUploading, fileDetails, showLoading, setFileDetails, handleOnFileChange, hiddenFileInput, onContactListChange, onUploadImportFile, onContinueClick ,onContactTagChange} = props;
    let contactform = "table_74";
    const inString = process.env.REACT_APP_MAX_IMPORT_SIZE_FORMAT;
    let attributesList = { fieldLabel: "Contact List", fieldName: "table_81_0_table_81", fieldType: "subtable", formname: "table_74", fLength: 40, custom_property: "table_81_0_table_81", tag_formname: "table_81" };
    let attributesTag  = { fieldLabel: "Contact Tag", fieldName: "table_82_0_table_82", fieldType: "subtable", formname: "table_74", fLength: 40, custom_property: "table_82_0_table_82", tag_formname: "table_82" };
    let filesAccept = "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const onRemoveFile = (e) =>{
        setFileDetails({});
    }

    return (
        <Fragment>
            <div>

                <div className='meta-fields mb-3'>
                    <div className='p-3 pb-0'>
                        <div className='row'>
                            <div className='mb-1 contacts-list-select col-md-6'>
                                <label className={'form-label'} >Contact List </label>
                                <CratioDropdownField
                                    onChange={onContactListChange}
                                    attributes={attributesList}
                                    isMulti={true}
                                    customClass={`w-100`}
                                    needBottom={true}
                                    customInputClass={'w-60'}
                                />
                            </div>
                            <div className='mb-1 contacts-list-select col-md-6'>
                                <label className={'form-label'} >Contact Tag </label>
                                <CratioDropdownField
                                    onChange={onContactTagChange}
                                    attributes={attributesTag}
                                    isMulti={true}
                                    customClass={`w-100`}
                                    needBottom={true}
                                    customInputClass={'w-60'}
                                />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label className={'form-label'} >Contacts File </label>
                            <div>
                                <div className='dropzone' onClick={(e) => (onUploadImportFile(e))}>
                                    <div className='dropzone-content'>
                                        <i class="fi fi-br-upload"></i>
                                        <p>Choose a CSV file.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isUploading &&
                            <div className='upload-loading-panel'>
                                <div className='d-flex align-center theme-c-g'>
                                    <Loader type="ball-clip-rotate" />
                                    <span className='ml-1'>Uploading...</span>
                                </div>
                            </div>
                        }
                        {Object.keys(fileDetails).length > 0 &&
                            <div className='d-flex align-items-center justify-content-start'>
                                <div className='my-1'>
                                    <div className='form-preview-document'>
                                        <div>
                                            <i class="bx bx-md fi fi-ss-file me-1"></i>
                                        </div>
                                        <div className='pe-2'>
                                            <div class="fw-bold truncate-string">{fileDetails.name}</div>
                                            <div class="text-start">{fileDetails.size} • {fileDetails.type}</div>
                                        </div>
                                    </div>

                                </div>
                                <div onClick={(e) => {onRemoveFile()}}><i class="fi fi-rs-trash btn theme-bg-danger m-1"></i></div>
                            </div>
                        }
                        <div>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                accept={filesAccept}
                                style={{ display: 'none' }}
                                onChange={handleOnFileChange}
                                value={""}
                            />
                            <div className='header-desc'>
                                <div className='max-size-info'>
                                    <span>Max allowed file size: <b>{inString}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="d-flex justify-end p-3">
                    <Loading showLoader={showLoading} mainContent={
                        <Fragment>
                            <Button disabled={Object.keys(fileDetails).length === 0} onClick={(e) => onContinueClick(e)} size="lg" className='theme-bg-g'>Continue</Button>
                        </Fragment>
                    } />
                </div>
            </div>
        </Fragment>
    )
}

export default ImportUploader;