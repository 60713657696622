import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Input, Button, Card, CardBody, Collapse, Col, Row } from "reactstrap";
import Select from "react-select";
import { PropertyDataType } from "./assets/Constants";

const ToolProperty = ({
  properties,
  onAddProperty,
  onUpdateProperty,
  onRemoveProperty,
  onRequiredProperty,
  requiredProperties,
  control,
  clearErrors,
}) => {
  const [collapseState, setCollapseState] = useState({});
  const [requiredSelections, setRequiredSelections] = useState();

  const toggleCollapse = (path) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [path]: !prevState[path],
    }));
  };

  useEffect(() => {
    if (requiredProperties) {
      setRequiredSelections(requiredProperties);
    }
  }, [requiredProperties, properties]);

  const handleRequiredPropertyChange = (path, selectedOptions) => {
    const requiredKeys = selectedOptions.map((option) => option.value);
    setRequiredSelections((prevState) => {
      const updatedState = { ...prevState, [path]: requiredKeys };
      onRequiredProperty(updatedState);
      return updatedState;
    });
  };

  const getErrorMsgAndToggleCollapse = (path, error) => {
    if (error) {
      if(collapseState[path] !== true){
        const pathParts = path.split('.');
        let currentPath = '';
        pathParts.forEach((part, index) => {
          currentPath = index === 0 ? part : `${currentPath}.${part}`;
          setCollapseState((prevState) => ({
            ...prevState,
            [path]: true,
          }));
        });
      }
    }    
    return error.message;
  };

  const generateRequiredOptions = (properties) => {
    let options = properties
      .filter((property) => property.name !== "")
      .map((property) => ({
        value: property.name,
        label: property.name,
      }));
    return { options };
  };

  const renderProperties = (properties, parentPath = "") => {
    let { options } = generateRequiredOptions(properties);
    return (
      <>
        {!parentPath && (
          <Button
            onClick={() =>
              onAddProperty({ name: "", type: "string", description: "", children: [] })
            }
            className="btn-hover-shine theme-bg-g custom-display mt-2"
          >
            <i className="fi fi-br-plus"></i> <span className="ml-2">Add Property</span>
          </Button>
        )}
        {properties.length > 0 &&
          properties.map((property, index) => {
            const currentPath = parentPath ? `${parentPath}.${index}` : `${index}`;
            return (
              <Card className="card-br mt-3" key={currentPath}>
                <CardBody>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => toggleCollapse(currentPath)}
                    aria-expanded={!!collapseState[currentPath]}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="mb-0 tool-heading">{property.name || "Property"}</p>
                  </div>
                  <Collapse isOpen={!!collapseState[currentPath]}>
                    <div className="mt-3">
                      {/* Property Name */}
                      <div className="mb-2">
                        <Controller
                          name={`property_${currentPath}_name`}
                          control={control}
                          rules={{ required: property.name === undefined || property.name === null ||  property.name === "" ? "Property Name is required" : false}}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <Input
                                {...field}
                                placeholder="Property Name"
                                name={`property_${currentPath}_name`}
                                value={property.name}
                                onChange={(e) =>{
                                  const value = e.target.value.replace(/\s/g, ""); 
                                  onUpdateProperty(currentPath, "name", value)
                                  clearErrors(e.target.name)
                                }}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                              />
                              {error && (
                                <em className="error invalid-feedback">{getErrorMsgAndToggleCollapse(currentPath,error)}</em>
                              )}
                            </>
                          )}
                        />
                      </div>

                      {/* Property Description */}
                      <div className="mb-2">
                        <Controller
                          name={`property_${currentPath}_description`}
                          control={control}
                          rules={{ required:property.description === undefined || property.description === null ||  property.description === "" ? "Description is required" : false}}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <Input
                                {...field}
                                placeholder="Description"
                                value={property.description}
                                name={`property_${currentPath}_description`}
                                onChange={(e) =>{
                                  onUpdateProperty(currentPath, "description", e.target.value)
                                  clearErrors(e.target.name)
                                }}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                              />
                              {error && (
                                <em className="error invalid-feedback">{getErrorMsgAndToggleCollapse(currentPath,error)}</em>
                              )}
                            </>
                          )}
                        />
                      </div>

                      {/* Property Type */}
                      <Row>
                        <Col className="d-flex justify-content-start w-25">
                          <Select
                            name={`property_${currentPath}_type`}
                            options={PropertyDataType}
                            value={
                              PropertyDataType.find(
                                (option) => option.value === property.type
                              ) || { value: property.type, label: property.type }
                            }
                            onChange={(selectedOption) =>
                              onUpdateProperty(
                                currentPath,
                                "type",
                                selectedOption.value
                              )
                            }
                            className="basic-select"
                            classNamePrefix="sub-select wa-select"
                          />
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button
                            onClick={() => onRemoveProperty(currentPath)}
                            className="mt-2 btn-hover-shine theme-bg-g"
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>

                      {/* Add Sub-Property */}
                      {["object", "array"].includes(property.type) && (
                        <Button
                          onClick={() =>
                            onAddProperty(
                              { name: "", type: "string", description: "", children: [] },
                              currentPath
                            )
                          }
                          className="mt-3 btn-hover-shine theme-bg-g"
                        >
                          Add Sub-Property
                        </Button>
                      )}

                      {/* Render Nested Properties */}
                      {property.children && property.children.length > 0 && (
                        <div className="mt-3">
                          {renderProperties(property.children, currentPath)}
                        </div>
                      )}
                    </div>
                  </Collapse>
                </CardBody>
              </Card>
            );
          })}

        {/* Required Properties Select */}
        {options.length > 0 && (
          <div className="mt-3">
            <div className="pb-2 pt-2"> Required </div>
            <Select
              options={options}
              value={
                requiredSelections?.[parentPath]
                  ? requiredSelections[parentPath].map((value) => ({
                      value,
                      label: value,
                    }))
                  : []
              }
              onChange={(selected) =>
                handleRequiredPropertyChange(parentPath, selected)
              }
              isMulti
              className="basic-select"
              classNamePrefix="sub-select wa-select"
            />
          </div>
        )}
      </>
    );
  };
    return <div className="mt-3">{renderProperties(properties)}</div>;
  };

export default ToolProperty;
