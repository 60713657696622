import React, { Component, Fragment } from "react";
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label,Row} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import Common from "../Common";
import ListTable from "../../Common/ListTable";
import aiData from "../../assets/utils/images/agent_no_records.png";
import {showToast,prepareCustomTableData,confirmBox,isMobile,getLoginUserInfo, checkIsAdmin, translateContent,getDeleteIcon} from "../../Common/Appcommon";
import requestApiService from "../../services/requestApi.service";
import Loading from "../../Common/Loading";
import NoRecordFound from "../Common/NoRecordFound";
import TooltipItem from "../../Common/ToolTip";
import { Icon } from "@iconify/react";

class Tags extends Component {
  constructor(props) {
    const mobile = isMobile();
    super(props);
    this.state = {
      formname: "table_82",
      searchStr: "",
      data: [], // Assuming data is fetched and managed here
      columns: [], // Define columns for your table
      showDetailed: false,
      showLoader: false,
      TagName: "",
      Tagid: "",
      Edit: false,
      isModalOpen: false,
      showButtonLoading: false,
      isMobile: mobile,
      isAdmin:false
    };
  }

  setShowLoaderStatus = async (status = false) => {
    this.setState({
      showLoader: status,
    });
  };
  componentDidMount = async () => {
    await this.setShowLoaderStatus(true);
    await this.getTags();
    await this.setShowLoaderStatus();
  };

  getTags = async (searchStr) => {
    const { formname } = this.state;
    let params = {
      ENTITY: "AIPROCESSOR",
      OPERATION: "GET_LISTS_TAGS",
      FORMNAME: formname,
      SearchStr: searchStr,
    };
    let formresponse = await requestApiService.processServerRequest(params,"WebV2");
    if (formresponse.issuccess) {
      let data = formresponse.data;
      let lists = data[0] ?? [];
      let columndetails = data[1] ?? [];
      await this.prepareListData(lists, columndetails);
    }
  };

  handleContactTag = (row) => () => {
    let name = "";
    let edit = false;
    let tagid = "";
    if (row["Name"]) {
      name = row["Name"];
      edit = true;
      tagid = row["data_row"]["list_tag_id"];
    }
    this.setState({
      isModalOpen: true, // Open the modal
      errorMessage: false,
      showButtonLoading: false,
      Edit: edit,
      TagName: name,
      Tagid: tagid,
    });
  };

  closeModal = (name) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      errorMessage: "",
      showButtonLoading: false,
      TagName: "",
    }));
  };

  prepareListData = async (formData, listFields) => {
    let data = [];
    let columns = [];
    let tableData = await prepareCustomTableData(
      formData,
      listFields,
      false,
      false,
      this.onViewAccountSettings
    );
    data = tableData.data;
    let isAdmin = await checkIsAdmin();
    this.setState({isAdmin:isAdmin});

    columns = [
      {
        name: <div style={{ margin: "left" }}>Tag Name</div>, // Set the name property here
        sortable: false, // Example
        selector: (data) => data["Name"],
        style: {
          padding: 0,
          margin: 0,
          justifyContent: "left",
          paddingInline: "16px"
        },
        width: "50% !important",
      },
      {
        name: <div style={{ margin: "auto" }}>Contacts</div>,
        selector: (data) => data["contact_count"],
        sortable: false,
        style: {
          padding: 0,
          margin: 0,
          justifyContent: "center",
          position: "relative",
        },
        width: isAdmin ? "25% !important" : "50% !important",
      },
    ];

    isAdmin && columns.push({
      name: <div style={{ margin: "auto" }}>Actions</div>,
      selector: "access",
      width: "25% !important",
      margin: "0",
      cell: (row) => (
        <div>
          <Button
            onClick={this.handleContactTag(row)}
            className="user-account-btn theme-bg-info"
          >
            <TooltipItem
              id={"1"}
              text={"Edit list"}
              content={<Icon icon="bx:edit-alt" />}
            />
          </Button>
          <Button
            onClick={() => this.deleteTag(row)}
            className="user-account-btn theme-bg-danger mt-1"
          >
            <TooltipItem
              id={"2"}
              text={"Delete list"}
              content={<i class="fi fi-rs-trash"></i>}
            />
          </Button>
        </div>
      ),
      style: {
        padding: 0,
        margin: "auto",
        justifyContent: "center",
        position: "relative",
      },
    });

    this.setState({
      data,
      columns,
    });
  };

  backToViewAction = () => {
    this.setState({ showDetailed: false });
  };

  getModalContent = () => {
    let { isModalOpen, errorMessage, showButtonLoading, Edit } = this.state;
    return (
      <>
        <Modal isOpen={isModalOpen}>
        {Edit === false ? 
              <ModalHeader toggle={this.closeModal}>
                    Add New Tag
              </ModalHeader>:
              <ModalHeader toggle={this.closeModal}>
                    Update Tag
              </ModalHeader>}
          <ModalBody>
            <Fragment>
              <Label>Tag Name</Label>
              <Input
                className="mb-2"
                onChange={(e) => {
                  this.handleInputChange(e);
                }}
                maxLength={100}
                required
                value={this.state.TagName}
              ></Input>
            </Fragment>
            <div>
              {errorMessage && <em className="error">{errorMessage}</em>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Loading
              showLoader={showButtonLoading}
              mainContent={
                <Fragment>
                  {Edit === true && (
                    <Button
                      className="container-footer-save theme-bg-g"
                      onClick={(e) => {
                        this.editTag(e);
                      }}
                    >
                      Update
                    </Button>
                  )}
                  {Edit === false && (
                    <Button
                      className="container-footer-save theme-bg-g"
                      onClick={(e) => {
                        this.addTag(e);
                      }}
                    >
                      Create New Tag
                    </Button>
                  )}
                </Fragment>
              }
            />
          </ModalFooter>
        </Modal>
      </>
    );
  };

  handleInputChange = (e) => {
    let value = e.target.value;
    const regex = /^[a-zA-Z0-9 _-]*$/;
    if (regex.test(value)) {
      this.setState({
        TagName: (value !== undefined && value !== null && value.trim() === "") ? value.trim() : value,
        errorMessage:
          value !== undefined && value !== "" ? "" : this.state.errorMessage,
      });
    } else {
      this.setState({
        errorMessage: "Special characters are not allowed.",
      });
    }
  };

  addTag = async (e) => {
    e.preventDefault();
    let { TagName } = this.state;
    const { formname } = this.state;
    if (TagName === "" || TagName === undefined) {
      this.setState({
        errorMessage: "Tag Name is required.",
      });
    } else {
      await this.setShowLoaderStatus(true);
      this.setState({
        errorMessage: "",
        showButtonLoading: true,
      });
      let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "ADD_AND_UPDATE_LISTS_TAGS",
        FORMNAME: formname,
        list_tag_name: TagName,
        check: "CREATE_LISTS_TAGS",
      };
      let formresponse = await requestApiService.processServerRequest(
        params,
        "WebV2"
      );
      if (formresponse.issuccess) {
        this.closeModal();
        showToast(translateContent("vm.taglist.create.msg",{label: "Tag"}));
        await this.getTags();
      }else if(formresponse.errormsg){
        this.setState({
          errorMessage: formresponse.errormsg,
          showButtonLoading: false,
      })
    }
      await this.setShowLoaderStatus();
    }
  };

  editTag = async (e) => {
    e.preventDefault();
    let { TagName, Tagid } = this.state;
    const { formname } = this.state;
    if (
      TagName === "" ||
      TagName === undefined ||
      Tagid === undefined ||
      Tagid === ""
    ) {
      this.setState({
        errorMessage: "Tag Name is required.",
      });
    } else {
      this.setState({
        errorMessage: "",
        showButtonLoading: true,
      });
      let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "ADD_AND_UPDATE_LISTS_TAGS",
        FORMNAME: formname,
        list_tag_name: TagName,
        list_tag_id: Tagid,
        check: "UPDATE_LISTS_TAGS",
      };
      let formresponse = await requestApiService.processServerRequest(params,"WebV2");
      if (formresponse.issuccess) {
        this.closeModal();
        showToast(translateContent("vm.taglist.update.msg",{label: "Tag"}));
        await this.getTags();
      }else if(formresponse.errormsg){
        this.setState({
          errorMessage: formresponse.errormsg,
          showButtonLoading: false,
      })
    }
    }
  };

  deleteTag = async (row) => {
    let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"), getDeleteIcon(), "Delete", "Cancel");
    if (!confirm) {
      return;
    }
    const { formname } = this.state;
    let params = {
      ENTITY: "AIPROCESSOR",
      OPERATION: "DELETE_LISTS_TAGS",
      FORMNAME: formname,
      list_tag_id: row["data_row"]["list_tag_id"],
    };
    let response = await requestApiService.processServerRequest(params,"WebV2");
    if (response.issuccess) {
      showToast(translateContent("vm.taglist.delete.msg",{label: "Tag"}));
      await this.getTags();
    }
  };

  handleSearch = (e) => {
    //this.setState({ searchStr: e.target.value });
    let searchStr = e.target.value;
    this.setState({
      searchStr: searchStr,
    });
    this.getTags(searchStr);
  };

  getTitleBody = (showDetailed) => {
    if (showDetailed) {
      return "";
    }
    return (
      <div className="d-flex align-center justify-end">
        <Col md={8} className={cx("", {})}>
          <div className="le-form-row">
            <div className="input-with-icon">
              <span className="search-icon">
                <i className="icon-color fi fi-rs-search"></i>
              </span>
              <Input
                id="userName"
                autoComplete="off"
                placeholder="Search by Name"
                onChange={(e) => {
                  this.handleSearch(e);
                }}
                value={this.state.searchStr}
                className="search-input"
              />
            </div>
          </div>
        </Col>
        <Col md={5} className={`${this.state.isAdmin ? "pl-2 d-flex justify-end" : 'hide'}`}>
          <Button
            onClick={this.handleContactTag({})}
            size="lg"
            className="theme-bg-g compact-padding btn-hover-shine"
            color="dark"
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add Tag
          </Button>
        </Col>
      </div>
    );
  };

  render() {
    const { data, columns, showDetailed, isModalOpen, isMobile} = this.state;

    return (
      <Fragment>
        <Common
          title={"Tags"}
          titleContent={
            <span>
              {"Tags"}
              <span class="count">{data.length}</span>
            </span>
          }
          titleBody={!isMobile && this.getTitleBody(showDetailed)}
          mainContent={
            <Fragment>
            <div className="d-grid w-100">
               {!showDetailed && (
                    <div className="tag">
                        <Fragment>
                            {!this.state.showLoader ? (
                                data.length === 0 ? (
                                    !isMobile ? (
                                        <NoRecordFound
                                            img={aiData}
                                            isOnlyContent={true}
                                            content={"No Users Found."}
                                        />
                                    ) : (
                                        <div>
                                            <Row className="ms-0 me-0 gx-0 gy-0">
                                                {this.getTitleBody(showDetailed)}
                                            </Row>
                                            <div className="mobile-no-record">
                                                {/* Add mobile-specific "No Records Found" content here */}
                                                <NoRecordFound
                                                    img={aiData}
                                                    isOnlyContent={true}
                                                    content={"No Users Found."}
                                                />
                                            </div>
                                        </div>
                                    )
                                ) : !isMobile ? (
                                    <div className="col-md-12">
                                        <ListTable data={data} columns={columns} />
                                    </div>
                                ) : (
                                    <div>
                                        <Row className="ms-0 me-0 gx-0 gy-0">
                                            {this.getTitleBody(showDetailed)}
                                        </Row>
                                        <div className="col-md-12">
                                            <ListTable data={data} columns={columns} />
                                        </div>
                                    </div>
                                )
                            ) : (
                                <Loading showLoader={this.state.showLoader} />
                            )}
                        </Fragment>
                    </div>
                )}
                {showDetailed && <div className="col-md-12"></div>}
            </div>
            {isModalOpen && this.getModalContent()}
          </Fragment>
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
