import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Controller } from "react-hook-form";
import Select from"react-select";
import Switch from "react-switch";
import cx from "classnames";
import { multiDial, durationList } from './assets/Constant';
import { useTheme } from '../../Context/ThemeContext';
import { getDarkThemeColor } from '../../Common/Appcommon';

const VariationContainer = forwardRef((props, ref) => {
    const { theme } = useTheme();
    const { control, isEnabled, variationsList, maxCount } = props;
    // State to hold the variations
    const [variations, setVariations] = useState([]);
    const [showVariation, setShowVariation] = useState(false);
    const validations = {};

    useEffect(() => {
        setShowVariation(isEnabled ?? false);
        setVariations(variationsList ?? []);
    }, [isEnabled])

    useImperativeHandle(ref, () => ({
        returnVariationValues,
    }));

    const returnVariationValues = () => {
        return ({variations: variations, followup_enabled: showVariation});
    }

    // Function to handle change in variation input
    const handleInputChange = (e, name, cur_variation) => {
        let { value } = e.target;
        if(/^\d*\.?\d*$/.test(value)){
            value = value ? parseInt(value) : 0;
            setVariations(prevState =>
                prevState.map(variation =>
                    variation.id === cur_variation.id ? { ...variation, [name]: value } : variation
                )
            );
        }
    };

    const handleSwitchChange = () => {
        if(!showVariation && variations.length === 0){
            setVariations([]);
            addVariation();
        }
        setShowVariation(!showVariation);
    };

    const handleSelectChange = (name, selectedOption, index) => {
        setVariations((prevFormData) => {
            const updatedFormData = [...prevFormData]; // Make a shallow copy of the array
            updatedFormData[index] = {
                ...updatedFormData[index], // Copy the existing object properties
                [name]: selectedOption, // Update the specific field
            };
            return updatedFormData; // Return the updated array
        });
    };    

    // Function to add a new variation
    const addVariation = () => {
        let newObj = {};
        newObj["id"] = Date.now();
        newObj["duration"] = variations.length + 1;
        newObj["duration_type"] = {
            label: "Hours",
            value: "hours",
            min: 60
        };
        newObj["multi_dial"] = {
            value: "2_times",
            label: "Dial 2 times in a row"
        };
        setVariations([...variations, newObj]);
    };

    // Function to remove a variation
    const removeVariation = (id = "") => {
        if(id !== ""){
            setVariations(variations.filter(variation => variation.id !== id));
        }else{
            setVariations(prevVariations => prevVariations.slice(0, -1));
        }
    };

    const requiredController = (type, index) => {
        let response = true;
    
        // Get the current variation
        const cur_variation = variations[index] ?? {};
        const cur_duration = cur_variation["duration"] ?? 0;
        const cur_duration_type = cur_variation["duration_type"] ?? {};
        const cur_dur_min = cur_duration_type["min"] ?? 0;
        if (type === "duration") {
            // Validation for minimum duration
            if ((cur_duration < 10 && cur_duration_type["value"] === "minutes") || cur_duration === 0) {
                response = "Follow-ups should be scheduled at least 10 minutes after the previous call.";
            }
        }
    
        return response;
    };
    

    return (
        <div className="">
            <Row>
                <Col md={10} className="align-content-end me-4">
                    <label>
                        Schedule a follow-up call if the previous call was not connected.
                    </label>
                </Col>
                <Col md={1}>
                    <Switch
                        onColor={getDarkThemeColor()}
                        onChange={() => handleSwitchChange()} 
                        checked={showVariation}
                        checkedIcon={
                            <div className='on-switch-btn'>
                                On
                            </div>
                        }
                        uncheckedIcon={
                            <div className='off-switch-btn'>
                                Off
                            </div>
                        }
                        width={60}
                        height={25}
                    />
                </Col>
            </Row>
            {
                showVariation && variations && variations.map((variation, index) => (
                    <Row key={variation.id} className='mt-0 me-0 ms-0'>
                        <Col md={3} className={"mt-3"}>
                            <label htmlFor={"duration_" + variation.id}>Call After</label>
                            <Controller 
                                name={"duration_" + variation.id}
                                control={control}
                                rules={{
                                    validate: () => requiredController("duration", index)
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={"text"}
                                            className={cx("form-control", {"is-invalid" : error})}
                                            placeholder='Duration'
                                            value={variation["duration"]}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                                handleInputChange(e, "duration", variation)
                                            }}
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                            
                        </Col>
                        <Col md={4} className={"mt-3 mb-3"}>
                            <label htmlFor={"duration_type_" + variation.id}>Duration Type</label>
                            <Controller 
                                name={"duration_type_" + variation.id}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={durationList}
                                            onChange={e => handleSelectChange("duration_type", e, index)}
                                            value={variation["duration_type"]}
                                            classNamePrefix="wa-select"
                                            className="basic-select"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col md={4} className={"mt-3"}>
                            <label htmlFor={"multi_dial_" + variation.id}>Multi-dial</label>
                            <Controller 
                                name={"multi_dial_" + variation.id}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={multiDial}
                                            onChange={e => handleSelectChange("multi_dial", e, index)}
                                            value={variation["multi_dial"]}
                                            classNamePrefix="wa-select"
                                            className="basic-select"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col md={1} className={"mt-3"}>
                            <label></label>
                            <div className="d-flex justify-content-center cursor-pointer" onClick={e => removeVariation(variation.id)}>
                                <i class={"fi fi-rr-circle-xmark fs-4"}></i>
                            </div>
                        </Col>
                    </Row>
                ))
            }
            {
                showVariation && variations.length < maxCount && 
                <Row>
                    <Col className='mt-3'>
                        <div className="d-flex justify-content-center">
                            <i class="fi fi-rr-add fs-4 cursor-pointer" onClick={addVariation}></i>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
});

export default VariationContainer;
