import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import {
    Row,
    Col,
    Button
} from "reactstrap";
import Loading from '../../Common/Loading';
import { createorUpdateActionForAgent } from './ProviderDetails';
import { getSessionValue } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';

const CallEnd = ({agent_id, saveDetails, actionId,onAction}) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        trigger,
        setError
    } = useForm();

    const [callEndData,setCallEndData] = useState({});
    const [showLoader,setShowLoader] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCallEndData({ ...callEndData, [name]: value });
    };
    const proName = getSessionValue("agent_provider_name");

    const constructorCallEndParams = () =>{
        if(proName === 'vapi'){
            return {
                endCallFunctionEnabled : true,
                endCallMessage : callEndData['end_call_msg']
            }
        }
    }

    const onSubmit = async () => {   
        const isValid = await trigger();
        if(!isValid){
            return;
        }
        let isNameEmpty = (callEndData) => !callEndData?.["action_name"]?.trim();
        if (isNameEmpty(callEndData)) {
            setError("action_name", {
                type: "manual",
                message: "Invalid Action name.",
            });
            return;
        }
        setShowLoader(true);
        let params = constructorCallEndParams();
        let response = await createorUpdateActionForAgent(proName,agent_id,params)
        if(response.issuccess){
            await saveDetails(callEndData,callEndData['action_name'],'end_call',onAction);
        }
        setShowLoader(false);
    };

    const getMainContent = () =>{
        return(
            <div className='w-100'>
                <Loading 
                    mainContent = {
                        <>
                            <div className='w-100 p-0 agency-details'>
                                <Row className='mt-1'>
                                    <Col md={12} className='field-section'>
                                        <label className={'form-label'} htmlFor="action_name">Action name</label>
                                        <Controller
                                            name="action_name"
                                            control={control}
                                            rules={{ required: callEndData.action_name === undefined || callEndData.action_name === null || callEndData.action_name === "" ? "Please provide Action Name": false }}
                                            render={({ field }) => {
                                                return (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        name="action_name"
                                                        id="action_name"
                                                        placeholder='e.g. good bye'
                                                        value={callEndData.action_name}
                                                        autoComplete={"off"}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleInputChange(e);
                                                        }}
                                                        className={`form-control ${errors.action_name ? 'is-invalid' : ''}`}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.action_name && <em className={`error invalid-feedback`}>{errors.action_name.message}</em>}
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col md={12} className='field-section'>
                                        <label className={'form-label'} htmlFor="end_call_msg">When should the call end?</label>
                                        <Controller
                                            name="end_call_msg"
                                            control={control}
                                            rules={{ required: callEndData.end_call_msg === undefined || callEndData.end_call_msg === null || callEndData.end_call_msg === "" ? "Please provide End Condition": false }}
                                            render={({ field }) => {
                                                return (
                                                    <textarea
                                                        {...field}
                                                        id="end_call_msg"
                                                        name="end_call_msg"
                                                        value={callEndData.end_call_msg}
                                                        placeholder="e.g. End the call if the user uses goodbye phrases such as 'bye,' 'goodbye,' or 'have a nice day."
                                                        className={`form-control ${errors.end_call_msg ? "is-invalid" : ""}`}
                                                        rows="3"
                                                        maxLength="1000"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleInputChange(e);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.end_call_msg && <em className={`error invalid-feedback`}>{errors.end_call_msg.message}</em>}
                                    </Col>
                                </Row>    
                                {
                                    proName === 'vapi' && (
                                        <Row className='mt-3'>
                                            <Col md={12} className='d-flex justify-content-end'>
                                                <Button className="container-footer-save theme-bg-g" type="submit" onClick={handleSubmit(onSubmit)}>
                                                    <i className="fi fi-rs-plus"></i> {'Add Action'}
                                                </Button>
                                            </Col>
                                        </Row> 
                                    )
                                }
                            </div>
                        </>
                    }
                    showLoader = {
                        showLoader
                    }
            />
            </div>
            
        )
    }

    const getActionDetails = async() =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            setCallEndData(data);
        }

    }

    useEffect(() => {
        const fetchActionDetails = async () => {
            if (agent_id !== undefined || actionId !== undefined) {
                await getActionDetails();
            }
        };
        fetchActionDetails();
    }, [agent_id, actionId]);


    return ( getMainContent()) 
};

export default CallEnd;
