import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Input } from "reactstrap";

const CratioTextInput = (props) => {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    (async () => { })();
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = async (e) => {
    if (e.target.validity.valid || (props.skipValidityCheck !== undefined && props.skipValidityCheck)) {
      let name = e.target.name;
      let value = e.target.value;
      if(value !== undefined && value !== null && value.trim() === ""){
          value = "";
      }
      if (props.onChange) {
        props.onChange(e, { name: name, value: value }, props.attributes);
      }
      setValue(value);
    }
  };
  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={value}
    >
      <Input
        className={`${props.inputClass}`}
        type={`${props.type !== undefined ? props.type : 'text'}`}
        value={value}
        onChange={(e) => onChange(e)}
        {...props.controls}
      />
    </div>
  );
};

export default CratioTextInput;
