import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { isAgentAccount } from "./Common/Appcommon";

const Link = (props) => {
  const [favicon, setFavicon] = useState();
  const [chatbot,setChartBot] = useState(false);

  useEffect(() => {
    if (props.icon !== "" && props.icon !== undefined && props.icon !== null) {
      setFavicon(props.icon);
    } else {
      //setFavicon(process.env.REACT_APP_FAVICON);
    }
    
  }, [props]);

  useEffect(()=>{
    let agent = isAgentAccount();
    setChartBot(agent);
  });
  
  return (
    <div>
      <Helmet>
        {
          false && <script src="https://chatbox.simplebase.co/projects/65edde8014daeafe6b9708f3/widget.js"></script>
        }
        <link rel="icon" href={favicon} />
      </Helmet>
    </div>
  );
};

export default Link;
