import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { callTypes, sentTypes } from "./assets/context";
import requestApiService from "../../services/requestApi.service";
import { parseJSON } from "../../Common/Appcommon";
import requestApi from '../../services/requestApi.service';
import CratioMention from "../Common/CratioFields/CratioMention";

const SendEmailSMSAction = (props) => {
    const { type, onAction, actionId, agent_id, saveDetails, rowData } = props;

    const { handleSubmit, control, formState: { errors }, setError,clearErrors } = useForm();

    const [formData, setFormData] = useState({});

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            ["type"] : type,
            ["callType"] : callTypes[0],
            ["call_to"] : sentTypes[0],
        }));
    }, []);

    // useEffect(() => {
        // if(rowData){
        //     let data_row = rowData.data_row;
        //     if(data_row && data_row.action_details){
        //         let action_details = parseJSON(data_row.action_details);
        //         console.log(action_details)
        //         setFormData(action_details)
        //     }
        // }
    // }, [rowData]);

    const handleChange = (e, type) => {
        if(type === "callType" || type === "call_to"){
            setFormData(prev => ({
                ...prev,
                [type] : e
            }))
        }else{
            let { name, value } = e.target;
            if(type === "mention"){
                value = e.target.value.replace("#$", "$").replace("#[", "[").replace("#@", "@").replaceAll("&nbsp;", " ");
            }
            setFormData(prev => ({
                ...prev,
                [name] : value
            }))
        }
    }

    const validateFormData = (key) => {
        let response = false;
        if(formData[key] === undefined || formData[key] === null || formData[key] === ""){
            response = "fill the field";
        }
        return response;
    }

    const getActionDetails = async() =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            setFormData(data)
        }

    }

    useEffect(() => {
        const fetchActionDetails = async () => {
            if (agent_id !== undefined || actionId !== undefined) {
                await getActionDetails();
            }
        };
        fetchActionDetails();
    }, [agent_id, actionId]);

    const submitForm = async () => {
        await saveDetails(formData, formData.action_name, type, onAction);
    }

    return (
        <div>
            <Form className="m-3" onSubmit={handleSubmit(submitForm)}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="action_name" >Action Name</Label>
                            <Controller
                               name="action_name"
                               control={control}
                               rules={{ required: validateFormData("action_name") }} 
                               render={(field) => {
                                    return (
                                        <Input 
                                            {...field}
                                            type="text" 
                                            onChange={(e) => {
                                                const toolNamePattern = /^$|^[a-zA-Z0-9_-]{1,64}$/;
                                                if (!toolNamePattern.test(e.target.value)) {
                                                    setError("action_name", {
                                                        type: "manual",
                                                        message: "Action Name must be 1-64 characters and only contain letters, numbers, hyphens, or underscores.",
                                                    });
                                                } else {
                                                    clearErrors('action_name');
                                                    handleChange(e);
                                                }
                                            }}
                                            value={formData.action_name} 
                                            id="action_name" 
                                            name="action_name" 
                                            placeholder="Enter action name"
                                        />
                                    )
                               }}
                            />
                            {errors["action_name"] && <em className={`error invalid-feedback`}>{errors["action_name"].message}</em>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="callType" >Call Type</Label>
                            <Controller
                               name="callType"
                               control={control}
                               rules={{ required: validateFormData("callType") }} 
                               render={(field) => {
                                    return (
                                        <Select
                                            {...field}
                                            type="text" 
                                            onChange={e => handleChange(e, "callType")} 
                                            value={formData.callType}
                                            options={callTypes} 
                                            id="callType" 
                                            name="callType" 
                                            placeholder="Select call type" 
                                            classNamePrefix="sub-select wa-select"
                                        />
                                    )
                               }}
                            />
                            {errors["callType"] && <em className={`error invalid-feedback`}>{errors["callType"].message}</em>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {
                        type === "send_sms" && 
                        <Col md={12}>
                            <FormGroup>
                                <Label htmlFor="call_to" >Phone number to send SMS</Label>
                                <Controller
                                    name="call_to"
                                    control={control}
                                    rules={{ required: validateFormData("call_to") }} 
                                    render={(field) => {
                                        return (
                                            <Select
                                                {...field}
                                                onChange={e => handleChange(e, "call_to")} 
                                                value={formData.call_to}
                                                options={sentTypes} 
                                                id="call_to" 
                                                name="call_to" 
                                                classNamePrefix="sub-select wa-select"
                                            />
                                        )
                                    }}
                                />
                                {errors["call_to"] && <em className={`error invalid-feedback`}>{errors["call_to"].message}</em>}
                            </FormGroup>
                        </Col>
                    }
                    {
                        formData.call_to && 
                        formData.call_to.value !== sentTypes[1].value &&
                        <Col md={12}>
                            <FormGroup>
                                <Label >{type === "send_sms" ? "Phone Number to Transfer the Call (Include Country Code)" : "Email ID to Send Email"}</Label>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{ required: validateFormData("to") }} 
                                    render={(field) => {
                                        return (
                                            <Input 
                                                {...field}
                                                type="to" 
                                                onChange={(e) => {
                                                    if (type === "send_sms") {
                                                        const phonePattern = /^\+?[0-9]{0,16}$/;
                                                        const phoneNumber = e.target.value.trim();                                                
                                                        if (!phonePattern.test(phoneNumber)) {
                                                            setError("to", {
                                                                type: "manual",
                                                                message: "Mobile number Is Not Valids",
                                                            });
                                                            return;
                                                        }
                                                        clearErrors("to");
                                                    }
                                                    handleChange(e);
                                                }}
                                                value={formData.to} 
                                                id="to" 
                                                name="to" 
                                                placeholder={type === "send_sms" ? "Enter Mobile Number" : "Enter Email Address"}
                                                className="mt-2"
                                            />
                                        )
                                    }}
                                />
                                {errors["to"] && <em className={`error invalid-feedback`}>{errors["to"].message}</em>}
                            </FormGroup>
                        </Col>
                    }
                </Row>
                {   type !== "send_sms" && (
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="subject" >Email Subject</Label>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        rules={{ required: validateFormData("subject") }} 
                                        render={(field) => {
                                            return (
                                                <Input 
                                                    {...field}
                                                    onChange={e => handleChange(e)}
                                                    value={formData.subject} 
                                                    id="subject"
                                                    name="subject"
                                                    placeholder={"Enter Email Subject"}
                                                    rows="4"
                                                />
                                            )
                                        }}
                                    />
                                    {errors["subject"] && <em className={`error invalid-feedback`}>{errors["subject"].message}</em>}
                                </FormGroup>
                            </Col>
                        </Row>
                    )
                }
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="content" >{type === "send_sms" ? "SMS Content" : "Email Content"}</Label>
                            <Controller
                                name="content"
                                control={control}
                                rules={{ required: validateFormData("content") }} 
                                render={(field) => {
                                    return (
                                        <div>
                                            <CratioMention
                                                {...field}
                                                name={"content"}
                                                inputID={"subject"}
                                                value={formData.content} 
                                                fieldkey={"value"}
                                                type="textarea"
                                                mentionTrigger={"#"}
                                                handleOnChange={handleChange}
                                                formname={"Contacts"}
                                                control={control}
                                                customClassName={"custom-mention-style"}
                                                customPanelClassName={"custom-mention-style"}
                                                placeholder={type === "send_sms" ? "Write SMS Content or ‘#’ to insert variables..." : "Write Email Content or ‘#’ to insert variables..."}
                                                cols={100}
                                                rows={15}
                                                agent_id={agent_id}
                                            />
                                        </div>
                                        // <Input 
                                        //     {...field}
                                        //     type="textarea"
                                        //     onChange={e => handleChange(e)}
                                        //     value={formData.content} 
                                        //     id="content"
                                        //     name="content"
                                        //     placeholder={type === "send_sms" ? "Enter SMS Content" : "Enter email Content"}
                                        //     rows="4"
                                        // />
                                    )
                                }}
                            />
                            {errors["content"] && <em className={`error invalid-feedback`}>{errors["content"].message}</em>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex">
                        <Button 
                            type="submit" 
                            className="ms-auto theme-bg-g">
                            Add Action
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    );
}

export default SendEmailSMSAction;