import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { Card, Col, Progress, Row } from "reactstrap";
import { getAllData } from "../../Common/Appcommon";

const Summary = (props) => {
    const [summaryData, setSummaryData] = useState([]);
    const { isAgency } = props;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getAllData("GET_PROFIT_SUMMARY", "", isAgency);
                setSummaryData(data);
            } catch (error) {
                console.error("Error fetching summary box value:", error);
            }
        };
    
        fetchData();
    }, []);
    
    

    return(
        <div className="usage-dashboard-container">
            <div className="usage-summary-container">
                <div className="summary-box-container mb-3">
                    <div className="summary-box-header mt-3 mb-3 fw-bold">
                        Summary Report
                    </div>
                    <Row className="usage-summary-box">
                        <Col lg="12">
                            <Row>
                                {
                                    summaryData && summaryData.map(({header, value, body, type, footer}) => {
                                        if(type === "summary_box"){
                                            return(
                                                <Col md="6" lg="6">
                                                    <div className="card mb-3 widget-chart text-start usage-summary-cont m-1">
                                                        <div className="widget-chart-content ms-2 mt-2">
                                                            <div className="mb-3 box-header">{header}</div>
                                                            <div className="usage-summary-body">
                                                                <div className="widget-numbers theme-c-g no-content">
                                                                    {value}
                                                                </div>
                                                                <div className='theme-c-g txt-content'>
                                                                    {body}
                                                                </div>
                                                            </div>
                                                            <div className="widget-description">
                                                                {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                                                                {footer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }else if(type === "progress_box"){
                                            return (
                                                <Col md="6" lg="6">
                                                    <Card body className="m-1">
                                                        <div className="box-header">{header}</div>
                                                        {
                                                            value.map((val, index) => {
                                                                return(
                                                                    <div className="widget-content p-0 mt-2">
                                                                        <div className="widget-progress-wrapper mt-2">
                                                                            <div className="progress-sub-label m-1">
                                                                                <div className="sub-label-left">{val.bar_title}</div>
                                                                                <div className="sub-label-right">{val.bar_body}</div>
                                                                            </div>
                                                                            <Progress
                                                                                className={"progress-bar-sm progress-bar-animated-alt " + (value.length === (index + 1) ? "mb-1" : "" ) } 
                                                                                color={val.color} 
                                                                                value={val.bar_percentage}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                        return null;
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
