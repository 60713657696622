import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import AppMobileMenu from "../AppMobileMenu";
// import AppLogo from "../../assets/utils/images/logo-inverse.png";
// import appLightLogo from "../../assets/utils/images/logo.png";
import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";
import { getAgencyInfo, isAgentAccount } from "../../Common/Appcommon";
import { useTheme } from "../../Context/ThemeContext";

const HeaderLogo = (props) => {
  /* const [active, setActive] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [activeSecondaryMenuMobile, setAtiveSecondaryMenuMobile] = useState(false); */
  let appLightLogo = process.env.REACT_APP_DEFAULT_LIGHT_LOGO; //process.env.REACT_APP_LIGHT_LOGO ;
  let AppLogo = process.env.REACT_APP_DEFAULT_DARK_LOGO;//process.env.REACT_APP_DARK_LOGO;
  const [defaultLogo, setDefaultLogo] = useState("");
  const { theme } = useTheme();
  let isagent = isAgentAccount();
  useEffect(() => {
    (async () => {
      let logoField = "Logo";
      let logo = ""; //AppLogo;
      if(isagent){
        logo =  AppLogo;
      }
      if (theme === "dark-theme") {
        if(isagent){
          logo = appLightLogo;
        }
        logoField = "Dark_Logo";
      }
      let agencyLogoInfo = getAgencyInfo(logoField);
      let agencyLogo = "";
      if (agencyLogoInfo !== "" && agencyLogoInfo !== undefined && agencyLogoInfo !== null) {
        //agencyLogo = agencyLogoInfo;
        if (agencyLogoInfo.indexOf(",") > 0) {
          let agentLArr = agencyLogoInfo.split(",");
          agencyLogo = agentLArr[1];
          logo = agencyLogo;
        }
      }
      setDefaultLogo(logo);
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, [theme]);

 /*  const toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = props;
    setEnableClosedSidebar(!enableClosedSidebar);
  }; */


  return (
    <Fragment>
      <div className="app-header__logo">
      <div className="logo-src">
        {defaultLogo && <img src={defaultLogo} alt="Logo" />}
      </div>
      </div>
      <AppMobileMenu  mobileViewNeedLeftMenu = {props.mobileViewNeedLeftMenu}/>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
