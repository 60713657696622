import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import Tabs from "react-responsive-tabs";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import Common from "../Common";
import ListTable from "../../Common/ListTable";
import { Button, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import NoRecordFound from "../Common/NoRecordFound";
import requestApiService from "../../services/requestApi.service";
import "./index.css";
import { convertUTCToLocalTime, formatDate, isMobile, parseJSON } from "../../Common/Appcommon";
import { tabs, tabContent } from "./constant";

const Logs = (props) => {
    const [formData, setFormData] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("request");
    const [rowData, setRowData] = useState({});
    const [showModal, setShowModal] = useState(false);

    const { type } = props;

    let title = "";
    let titleContent = "";
    if(type === "webhook"){
        title = "Webhook Logs";
        titleContent = "Webhook Logs";
    }

    useEffect(() => {
        getListViewData();
    }, [])

    const getListViewData = async () => {
        setLoading(true);
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CALLS_LOGS",
            type: type,
            filterValue: filterValue
        }
        let response = await requestApiService.processServerRequest(params);
        if(response.issuccess){
            setFormData(response.data)
        }
        setLoading(false);
    };

    const onFilterChange = (e) => setFilterValue(e.target.value);

    const getSearchBox = (isHeader) => {

        const searchBtnClicked = (e) => {
            e.preventDefault()
            getListViewData();
        }
        return;
        return(
            <Form onSubmit={e => searchBtnClicked(e)}>
                {
                    (isHeader && !isMobile()) ? (
                        <div className="m-3 ms-auto d-flex gap-1">
                            <Input type="text" placeholder="Search all Columns" onChange={e => onFilterChange(e)} value={filterValue}/>
                            <Button className="theme-bg-g fw-bold" type="submit"><i class="fi fi-rr-search"></i></Button>
                        </div>
                    ) : (!isHeader && isMobile()) ? ( 
                        <div className={cx("m-3 ms-auto d-flex gap-3 w-25")}>
                            <Input type="text" placeholder="Search all Columns" onChange={e => onFilterChange(e)} value={filterValue}/>
                            <Button className="theme-bg-g fw-bold" type="submit"><i class="fi fi-rr-search"></i></Button>
                        </div>
                    ) : null
                }
            </Form>
        )
    }

    const onSelectRow = (row = {}) => {
        setRowData(row);
        setShowModal(prev => !prev);
    }

    const getSimpleTabs = () => {
        return tabs.map(({ label, value, icon }, index) => ({
            key: index,
            title: label,
            getContent: () => logInfoComponent(tabContent[value]),
        }));
    }

    const logInfoComponent = (data) => {
        return (
            <div className="log-expand-view gap-3">
                <div className="bg-gray-800 ps-3 pe-3 rounded-lg max-h-60 overflow-y-auto body-view">
                    {
                        data.map((item, index) => (
                            <div key={index} className="mt-3">
                                {
                                    rowData[item.value] && rowData[item.value] !== "" ? (
                                        <>
                                            <div className="label gap-2 pb-2 gap-2 d-flex">
                                                <i class={item.icon}></i>
                                                <div>
                                                    {item.label}
                                                </div>
                                            </div>
                                            <p className="text-gray-300 text-sm ml-6 value">{item.datatype === "object" ? ( <pre>{JSON.stringify(parseJSON(rowData[item.value]), null, 2)}</pre>) : rowData[item.value]}</p>
                                        </>
                                    ) : null
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    const getModalContent = () => {
        return(
            <Modal isOpen={showModal} className={'vm-modal-lg'}>
                <ModalHeader toggle={e => onSelectRow()} className="header-log-expand-view">
                    Log Details
                </ModalHeader>
                <ModalBody>
                    <div className='calls-details'>
                        <Tabs
                            tabsWrapperClass="body-tabs" 
                            activeTab={"1"}
                            showMore={true}
                            transform={true}
                            showInkBar={true}
                            selectedTabKey={0}
                            transformWidth={400}
                            items={getSimpleTabs()} 
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let columns = [
        {
            name: "Time",
            sortable: true,
            selector: (data) => formatDate(convertUTCToLocalTime(data["createdon"]), "mmm dd, yyyy, hh:MM:ss TT"),
            width: "22% !important"
        },
        {
            name: "Type",
            sortable: true,
            selector: (data) => data["server_message"],
            width: "17% !important",
        },
        {
            name: "Agent",
            sortable: true,
            selector: (data) => data["Agent_Name"],
            width: "20% !important"
        },
        {
            name: "Request HTTP Method",
            sortable: true,
            selector: (data) => {
                let method = data["method"];
                let pclass = "success-badge";
                if(method === "GET"){
                    pclass = "info-badge";
                }else if(method === "PUT"){
                    pclass = "warning-badge";
                }else if(method === "DELETE"){
                    pclass = "danger-badge";
                }
                return(
                    <span className={pclass}>
                        {method}
                    </span>
                )
            },
            width: "20% !important"
        },
        {
            name: "Response HTTP Code",
            sortable: true,
            selector: (data) => {
                let status = data["status"];
                let pclass = "success-badge";
                if(status !== "200"){
                    pclass = "danger-badge";
                }
                return(
                    <span className={pclass}>
                        {status}
                    </span>
                )
            },
        },
        {
            name: "View",
            width: "60px !important",
            selector: (row) => {
                return (
                    <span onClick={e => onSelectRow(row)} className="cursor-pointer">
                        <i class="fi fi-rr-angle-double-left"></i>
                    </span>
                )
            },
            width: "7% !important"
        }
    ];

    return(
        <Fragment>
            <Common
                title={title}
                titleContent={titleContent}
                titleBody={getSearchBox(true)}
                mainLoader={loading}
                mainContent={
                    <Container>
                        {
                            getSearchBox(false)
                        }
                        {
                            formData.length > 0 ? (
                                <ListTable data={formData} columns={columns}/>
                            ) : (
                                <NoRecordFound isOnlyContent={true} content={"No Logs Found."}/>
                            )
                        }
                        {
                            getModalContent()
                        }
                    </Container>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);