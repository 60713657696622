import React, { Fragment, useEffect, useState } from 'react';
import requestApi from '../../services/requestApi.service';
import ReactApexChart from 'react-apexcharts';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { callUsageList } from '../Agency/AgencyDashBoard/constant';
import { getDarkThemeColor } from '../../Common/Appcommon';

export const CallsUsage = ({ callList }) => {
    const [chartOptionData, setChartOptionData] = useState({});
    const [chartSeriesData, setChartSeriesData] = useState({});
    const [chartTypes, setChartTypes] = useState([]);
    const [chartLabel, setChartLabels] = useState({});
    useEffect(() => {
        setChartOptionData({});
        setChartSeriesData({});
        setChartLabels({});
        setChartTypes([]);
        if (callList && callList.length > 0) {
            callList.map((value, index) => {
                setDataForChart(value, callUsageList[index]);
            });
        }
    }, [callList]);

    const setDataForChart = (data, chart) => {
        let { type, isHorizontal, colordistributed, tooltip } = chart;
        let tooltipFormate = {};
        let yaxis = {};
        let series = data[1];
        let callcountseries = data[2];
        tooltipFormate = {
            enabled: true,
            custom: function ({ seriesIndex,dataPointIndex}) {
                let totalCalls = 0 
                let callMinutes = 0; 
                let callType = data[1][seriesIndex]['name'].toLowerCase();
                let subAccountName = callcountseries['subAccount'][dataPointIndex];
                if (callType.includes("total")) {
                    totalCalls = callcountseries['Total_Calls'][dataPointIndex]['count'];
                    callMinutes = callcountseries['Total_Calls'][dataPointIndex]['minutes'];
                }else if (callType.includes("inbound")) {
                    totalCalls = callcountseries['Inbound_Calls'][dataPointIndex]['count'];
                    callMinutes = callcountseries['Inbound_Calls'][dataPointIndex]['minutes'];
                }else if (callType.includes("outbound")) {
                    totalCalls = callcountseries['Outbound_Calls'][dataPointIndex]['count'];
                    callMinutes = callcountseries['Outbound_Calls'][dataPointIndex]['minutes'];
                }else if (callType.includes("web")) {
                    totalCalls = callcountseries['Web_Calls'][dataPointIndex]['count'];
                    callMinutes = callcountseries['Web_Calls'][dataPointIndex]['minutes'];
                }
                let avgCallLength = totalCalls > 0 ? (callMinutes / totalCalls).toFixed(2) : 0;
                callType = callType.split(" ");
                let newCallType = callType[0].toLowerCase(); 
                newCallType = newCallType.charAt(0).toUpperCase() + newCallType.slice(1);

                return `
                    <div class="cus-tooltip-box">
                        <div class="cus-tooltip-body">
                            <span>Name: ${subAccountName}</span> <br>
                            <span>${newCallType} Calls: ${totalCalls}</span> <br>
                            <span>Minutes: ${callMinutes}</span> <br>
                            <span>Avg. Call Length: ${avgCallLength} mins</span> <br>
                        </div>
                    </div>
                `;
            }
        };
        
        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: isHorizontal,
                    distributed: colordistributed,
                    startingShape: "flat",
                    endingShape: "rounded",
                    dataLabels: {
                        position: 'bottom',
                        enabled: false,
                    },
                }
            },
            xaxis: {
                categories: data[0],
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: true,
                },
            },
            yaxis: yaxis,
            tooltip: tooltipFormate,
            colors: [getDarkThemeColor(), '#1e90ff', '#ff6347', '#ffa500'],
        };

        setChartOptionData((prev) => ({ ...prev, [type]: options }));
        setChartSeriesData((prev) => ({ ...prev, [type]: series }));
        setChartLabels((prev) => ({ ...prev, [type]: chart.label }));
        setChartTypes((prev) => [...prev, type]);
    }

    return (
        <Fragment>
            <Row className='summary-container'>
                {
                    chartTypes !== undefined && chartTypes.map((type, index) => {
                        if (typeof chartOptionData[type] !== undefined && typeof chartSeriesData[type] !== undefined) {
                            let label = chartLabel[type];
                            return (
                                <>
                                    <Col lg="12" className="call-log-charts pe-0 ps-0">
                                        <Card className={"main-card mb-3"}>
                                            <CardBody>
                                                <span className="widget-subheading">{label}</span>
                                                <div className="bar mt-2">
                                                    <ReactApexChart
                                                        options={chartOptionData[type]}
                                                        series={chartSeriesData[type]}
                                                        type="bar"
                                                        width={"100%"}
                                                        height={450}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </>
                            )
                        }
                    })
                }
            </Row>
        </Fragment>
    );
};
