import React, { useState, Fragment, useEffect } from 'react';
import Loading from '../../Common/Loading';
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import requestApi from "../../services/requestApi.service";
import { getContactLinkedData, getPickListItems, getPickLists, getSelectedFormIds, handleErrorResponse, populateFormDetails, showToast } from '../../Common/Appcommon';
import { ContactStatusOptions } from './Constant';

const MassUpdateModal = (props) => {
    let { isModalOpen, formDetail, selectedRows, toggleModal, setFormDetail } = props;
    console.log("selectedRows: ",selectedRows)
    let contactform = "table_74";
    const [showLoading, setShowLoading] = useState(false);
    const [optionsData, setOptionsData] = useState({});
    const [status, setStatus] = useState(null);
    const [listData, setListData] = useState([]);
    const [tagData, setTagData] = useState([]);

    let buttonClickRef = React.createRef();
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();

    useEffect(() => {
        (async () => {
            const listOptions = await getContactLinkedData("table_81");
            const tagOptions = await getContactLinkedData("table_82");
            setListData(listOptions);
            setTagData(tagOptions);
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const onFieldSave = async () => {
        await buttonClickRef.current.click();
    }

    const onFormSubmit = async () => {
        let formIds = getSelectedFormIds(selectedRows); 
        if(Object.keys(optionsData).length === 0){
            showToast("No action to perform update.", "top-right","warning");
            return;
        }
        setShowLoading(true);
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "MASS_UPDATE",
            "UPDATE_OPTIONS": optionsData,
            "FORMNAME": contactform,
            "FORM_IDs" : formIds
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            showToast("Contacts Updated Successfully.", "top-right","succes");
            setShowLoading(false);
            toggleModal(true);
        }
    }

    const onFieldChange = (selectedOption, name) => {
        setOptionsData((prevData) => {
            const newData = {
                ...prevData,
                [name]: selectedOption,
            };
            console.log("Updated OptionsData: ", newData); // This will log the correct updated value
            return newData;
        });
    }

    const getModalBodyContent = () => {
        return (
            <Fragment><div className='vm-modal-header'>
                <div className='d-flex'>
                    <div className='col-md-6 d-flex align-center'>
                        <span className='modal-title'>{"Update Contacts"}</span>
                    </div>
                    <div className='col-md-6 d-flex justify-end'>
                        <a className='custom-btn-1 btn btn-dark cursor-pointer mr-1' onClick={(e) => { toggleModal() }}>Cancel</a>
                        <Button onClick={onFieldSave} className="theme-bg-g btn btn-dark ml-1 ms-3">{"Update"}</Button>
                    </div>
                </div>
            </div>
                <div>
                    <form id={`custom-field}`} name={'custom-fieldform'} onSubmit={handleSubmit(onFormSubmit)}>
                        <div className='mass-update data-form'>
                            <Row className='p-3'>
                                <Col md={12} className='mt-3'>
                                    <div className='d-grid'>
                                        <label className={'form-label'}>Add to List</label>
                                        <Select
                                            value={optionsData.add_list}
                                            // placeholder='Choose List to Add'
                                            onChange={(e) => {
                                                onFieldChange(e, "add_list");
                                            }} options={listData}
                                            className={`basic-multi-select`}
                                            classNamePrefix="wa-select"
                                            isMulti={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3'>
                                    <div className='d-grid'>
                                        <label className={'form-label'}>Remove from List</label>
                                        <Select
                                            value={optionsData.remove_list}
                                            // placeholder='Choose list to remove'
                                            onChange={(e) => {
                                                onFieldChange(e, "remove_list");
                                            }} options={listData}
                                            className={`basic-multi-select`}
                                            classNamePrefix="wa-select"
                                            isMulti={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3'>
                                    <div className='d-grid'>
                                        <label className={'form-label'}>Add to Tag</label>
                                        <Select
                                            value={optionsData.add_tag}
                                            // placeholder='Choose tag to Add'
                                            onChange={(e) => {
                                                onFieldChange(e, "add_tag");
                                            }} options={tagData}
                                            className={`basic-multi-select`}
                                            classNamePrefix="wa-select"
                                            isMulti={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3'>
                                    <div className='d-grid'>
                                        <label className={'form-label'}>Remove from Tag</label>
                                        <Select
                                            value={optionsData.remove_tag}
                                            // placeholder='Choose Tag to remove'
                                            onChange={(e) => {
                                                onFieldChange(e, "remove_tag");
                                            }} options={tagData}
                                            className={`basic-multi-select`}
                                            classNamePrefix="wa-select"
                                            isMulti={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className='mt-3'>
                                    <div className='d-grid'>
                                        <label className={'form-label'}>Contact Status</label>
                                        <Select
                                            value={optionsData.status}
                                            // placeholder='Choose Contact Status'
                                            onChange={(e) => {
                                                onFieldChange(e, "status");
                                            }} options={ContactStatusOptions}
                                            className={`basic-multi-select`}
                                            classNamePrefix="wa-select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={'mt-3 text-center hide'}>
                            <button ref={buttonClickRef}
                                className="btn-wide mb-2 me-2 lyte-button lytePrimaryBtn"
                                size="lg"
                                color="primary">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Modal className={''} isOpen={isModalOpen} >
                <ModalBody>
                    <Loading showLoader={showLoading} mainContent={getModalBodyContent()} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MassUpdateModal;