import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, InputGroup, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Switch from "react-switch";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import cx from "classnames";
import { stepConfigurations, plan_type } from '../assets/css/constants';
import requestApi from '../../../services/requestApi.service';
import Loading from '../../../Common/Loading';
import { useTheme } from '../../../Context/ThemeContext';
import TooltipItem from '../../../Common/ToolTip';

const AddPlanModal = (props) => {
    let { 
        isOpen, 
        toggle, 
        newPlanData, 
        account_id, 
        row, 
        account_appid, 
        isSubAccountView, 
    } = props ;
    const { control, handleSubmit, formState: { errors }, trigger } = useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const { subCurrencyIcon } = useTheme();
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState(row);
    const emojiList = ['✔️', '👉', '😍', '🤝', '⏰', '🔥', '❌'];
    const [showEmojiIndex, setShowEmojiIndex] = useState(-1);

    useEffect(() => {
        if(!rowData["plan_id"]){
            let row = {
                "plan_cost": "0",
                "annual_discount": "0",
                "type": plan_type[0],
                "free_minutes": "0",
                "addon_cost_per_call": "0",
                "max_customers": "100",
                "actual_customers": "100",
            }
            setRowData(row);
        }
    }, [])

    const nextStep = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            setCurrentStep((prev) => Math.min(prev + 1, stepConfigurations.length - 1));
        }
    };

    const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

    const onSubmit = async () => {
        setLoading(true);
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_PLAN_PRICING",
            account_id: account_id,
            FORMINPUT: rowData,
            account_appid: account_appid,
            isSubAccountView: isSubAccountView,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                newPlanData(rowData);
                toggle();
            }
        } catch (error) {
            console.error("Error save to Plan:", error);
        }
        setLoading(false);
    };

    const handleChange = (e, type, name, maxlength) => {
        let value = "";
        if(type === "toggle" || type === "select"){
            setRowData((prevFormData) => ({
                ...prevFormData,
                [name]: e
            }));
        }else{
            value = e.target.value;
            if (!value.startsWith("0.") && parseInt(value) > 0 && name === "plan_cost") {
                value = parseInt(value, 10);
            } 
            if (type === "number"){
                if(/^\d*\.?\d*$/.test(value)){
                    setRowData((prevFormData) => ({
                        ...prevFormData,
                        [name]: value
                    }));
                }
                //showToast("Error! Only integers are allowed.", "top-right", "warning");
            }else if(maxlength >= value.length){
                setRowData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            }
        }
        
    };
    
    const toggleEmojiOptions = (index) => {
        if(showEmojiIndex === index){
            index = -1;
        }
        setShowEmojiIndex(index);
    };

    const handleEmojiClick = (emoji) => {
        let name = "cus_icon_" + showEmojiIndex;
        setShowEmojiIndex(-1);
        setRowData((prevFormData) => ({
            ...prevFormData,
            [name]: emoji
        }));
    };

    const popover = (
        <Popover id="emoji-popover">
            <Popover.Body>
                {emojiList.map((emoji, index) => (
                    <span
                        key={index}
                        style={{ margin: '0 5px', fontSize: '1.5em', cursor: 'pointer' }}
                        onClick={() => handleEmojiClick(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
            </Popover.Body>
        </Popover>
    );

    const hideAndShowValidation = (name) => {
        let response = true;
        let type_value = rowData["type"] ? rowData["type"]["value"] : "";
        if(type_value === "pay_as_you_go"){
            if(name === "plan_cost" || name === "annual_discount"){
                response = false;
            }
        }else if(type_value === "LTD"){
            if(name === "annual_discount" || name === "free_minutes" || name === "addon_cost_per_call"){
                response = false;
            }
        }
        return response;
    }

    const getFieldLabel = (label, requiredmsg, name) => {
        let response = label;
        let type_value = rowData["type"] ? rowData["type"]["value"] : "";
        if(type_value === "LTD"){
            if(name === "plan_cost"){
                response = "Charges";
            }
        }
        if(requiredmsg === ""){
            response = response + " (Optional)";
        }
        return response;
    }

    const setDescFontStyle = (index, fontStyle) => {
        let name = "cus_" + fontStyle + "_" + index;
        setRowData((prevFormData) => ({
            ...prevFormData,
            [name]: !prevFormData[name]
        }));        
    }

    const renderFields = (fields) => {
        return fields.map(({ name, label, type, placeholder, requiredmsg, icon, options, suffix, groupclass, group, maxlength, minValue}, index) => (
            hideAndShowValidation(name) &&
            <Row className='add-plan-madal' key={name}>
                <FormGroup className={groupclass}>
                    <InputGroup>
                    <Label for={name}>{getFieldLabel(label, requiredmsg, name)}</Label>
                    {(name === "type" || group === "custom_desc") && (
                        <span className='pl-2'>
                            <TooltipItem id={"1"} text={name === "type" ? "Choose your pricing model" : "Edit your icons and enter a custom description."} content={<i class="fi fi-ss-info"></i>} />
                        </span>
                        )
                    }
                    </InputGroup>
                    <span className='hide'>
                        {
                            icon === "dollar" ? (icon = subCurrencyIcon ?? "fi fi-br-dollar") : ("")
                        }
                    </span>
                    <Controller
                        name={name}
                        control={control}
                        rules={{
                            required: (rowData[name] === undefined || rowData[name] === "") && (requiredmsg === undefined || requiredmsg !== "") ? requiredmsg : false,
                            validate: {
                                inRange: (value) => {
                                    if(!value){
                                        value = rowData[name];
                                    }
                                    let plan_type = rowData["type"] && rowData["type"]["value"] ? rowData["type"]["value"] : "Subscription";
                                    if(type === "number" && minValue){
                                        const numberValue = parseFloat(value, 10);
                                        if(numberValue < minValue && plan_type !== 'pay_as_you_go' && name === "plan_cost"){
                                            return "Must be filled and greater than zero";
                                        }else if(numberValue <= 0 && plan_type !== 'LTD' && name === "addon_cost_per_call"){
                                            return "Must be filled and greater than zero";
                                        }
                                    }
                                    return true;
                                },
                            },
                        }}
                        render={({ field }) => (
                            <Row>
                                <Col lg="12">
                                    <InputGroup className={cx("", {"d-block" : type === "select"})}>
                                        {
                                            group !== "custom_desc" && icon && (
                                                <div className="input-group-text">
                                                    <i class={icon}></i>
                                                    {/* <img src={icon} alt={`${label} icon`}/> */}
                                                </div>
                                            )
                                        }
                                        {
                                            group === "custom_desc" && icon && (
                                                <OverlayTrigger 
                                                    show={showEmojiIndex === index} 
                                                    trigger="click" 
                                                    placement="top" 
                                                    overlay={popover}
                                                    onToggle={e => {toggleEmojiOptions(index, name)}}
                                                    >
                                                    <div className="input-group-text cursor-pointer">
                                                        {
                                                            <TooltipItem id={"1"} text={name === "type" ? "Choose your pricing model" : "Edit your icons and enter a custom description."} content={rowData['cus_icon_' + (index)] !== undefined ? (rowData['cus_icon_' + (index)] ) : (emojiList[0])}/>
                                                        }
                                                    </div>
                                                </OverlayTrigger>
                                            )
                                        }
                                        {
                                            type === "select" ? (
                                                <Select
                                                    {...field}
                                                    name={name}
                                                    options={options}
                                                    onChange={e => {handleChange(e, type, name)}}
                                                    value={rowData[name]}
                                                    className="basic-select"
                                                    classNamePrefix="sub-select wa-select"
                                                />
                                            ) : (
                                                type === "toggle" ? (
                                                    <Switch 
                                                        height={15} 
                                                        width={30} 
                                                        name ={name} 
                                                        onColor={"#23ae73"} 
                                                        checked={rowData[name]} 
                                                        onChange={e => {handleChange(e, type, name)}}
                                                    />
                                                ) : (
                                                    <Fragment>
                                                        <Input
                                                            {...field}
                                                            type={"text"}
                                                            placeholder={placeholder}
                                                            onChange={e => {handleChange(e, type, name, maxlength)}}
                                                            value={
                                                                group === "custom_desc" && 
                                                                rowData["cus_desc_" + (index)] !== undefined && 
                                                                rowData["cus_desc_" + (index)]["cus_desc_" + (index)] !== undefined ? 
                                                                rowData["cus_desc_" + (index)]["cus_desc_" + (index)] : (rowData[name] ? rowData[name] : "")
                                                            }
                                                        />
                                                        {
                                                            group === "custom_desc" && (
                                                                <>
                                                                    <div className={cx("input-group-text cursor-pointer", {"custom-plan-btn-selected" : rowData["cus_bold_" + index]})} onClick={e => setDescFontStyle(index, "bold")}>
                                                                        <i class="fi fi-br-bold"></i>
                                                                    </div>
                                                                    <div className={cx("input-group-text cursor-pointer", {"custom-plan-btn-selected" : rowData["cus_underline_" + index]})} onClick={e => setDescFontStyle(index, "underline")}>
                                                                        <i class="fi fi-br-underline"></i>
                                                                    </div>
                                                                </>
                                                            )
                                                            
                                                        }
                                                    </Fragment>
                                                )
                                            )
                                        }
                                        {
                                            suffix !== undefined && suffix !== "" && (
                                                <div className="input-group-text">
                                                    {suffix}
                                                </div>
                                            )
                                        }
                                    </InputGroup>
                                </Col>
                            </Row>
                        )}
                    />
                    {errors[name] && <span className="text-danger">{errors[name].message}</span>}
                </FormGroup>
            </Row>
        ));
    };

    const renderStepContent = () => {
        const stepConfig = stepConfigurations[currentStep];
        return (
            <div>
                {renderFields(stepConfig.fields)}
            </div>
        );
    };

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={toggle}> {rowData && rowData.plan_id ? 'Edit Plan' : 'Create New Plan'}</ModalHeader>
            <Form>
                <ModalBody>
                    {renderStepContent()}
                </ModalBody>
                <ModalFooter>
                    <a className='cursor-pointer font-bold theme-c-g' onClick={toggle}>Cancel</a>
                    {currentStep > 0 && <Button className='theme-bg-g' onClick={prevStep}>Previous</Button>}
                    {currentStep < stepConfigurations.length - 1 ? (
                        <Button className='theme-bg-g' onClick={nextStep}>Next</Button>
                    ) : (
                        <Loading
                            showLoader={loading} 
                            mainContent={
                                <Button className='theme-bg-g' onClick={handleSubmit(onSubmit)}>Submit</Button>
                            }
                        />
                    )}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddPlanModal;