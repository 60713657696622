import React, { Fragment, useState, useEffect } from "react";
import Gohighlevelpng from "../../assets/utils/images/Intagration image/gohighlevel.png"
import { cardData } from './Constants';
import requestApiService from "../../services/requestApi.service";
import { loadConnectedIntegration } from "../../Common/Appcommon";
import ShowIntegrationInfo from "./ShowIntDetails.js";

const GoHighlevel = (props) => {
    let { install,isInstalation, buttonValue, unInstall,name } = props;
    const [carddata, setData] = useState(cardData);
    const [isConnected, setConnectionStatus] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);
    let integrationData = [];
    const [tabInfo, setTabInfo] = useState([]);
    let key = '';
    let foundData = carddata.find((data) => data.name === "ghl_calendar");
    if (foundData) {
        key = foundData.component.button.key;
    }

    useEffect(() => {
        (async () => {
            if(install === true){
                onInstallGHL();
            }
            await fetchGHLAccountDetails();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [install,buttonValue]);

     const fetchGHLAccountDetails = async () => {
        let integrationInfo = await loadConnectedIntegration("ghl_calendar");
        if (integrationInfo.length > 0) {
            setConnectionStatus(true);
            integrationData = integrationInfo;
            let connectedInfo = integrationInfo[0][2];
            connectedInfo = JSON.parse(connectedInfo);
            let acctoken = connectedInfo['token']["access_token"];
            let companyid = connectedInfo['token']["companyId"];
            let locationId = connectedInfo['token']["locationId"];
            let userId = connectedInfo['token']["userId"];
            let refresh_token = connectedInfo['token']["refresh_token"];
            let IntegrationAccountInfo = [
                { type: "id", id: integrationInfo[0][0] },
                { type: "text", label: "Access_Token ", value: acctoken },
                { type: "text", label: "Refresh_Token", value: refresh_token },
                { type: "text", label: "CompanyID", value: companyid },
                { type: "text", label: "LocationID", value: locationId },
                { type: "text", label: "UserID", value: userId },
            ];
            setAccountInfo(IntegrationAccountInfo);
            } else {
            integrationData = [];
            setConnectionStatus(false);
            setTabInfo([]);
        }
    } 

    const integrationHeadData = {
        name: "HighLevel",
        tag: "Calendar",
        image: Gohighlevelpng,
        installButtonText: "Install App",
        uninstallButtonText: "Uninstall App",
    };

    const integrationContent = [
        { heading: "Website", subheading: "www.gohighlevel.com", type: "url" },
        { heading: "Overview", subheading: "The Voicemetrics integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting OneInbox’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.", type: "text" },
        { heading: "Key Benefits", 
            subheading: [
                { 
                    title: "Automated Appointment Management",
                    description: "Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking." 
                },
                { 
                    title: "Streamlined Client Interactions", 
                    description: "Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed." 
                },
                { 
                    title: "Integrated Workflows", 
                    description: "Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort." 
                },
        ], type: "list" }
    ];


    const onInstallGHL = async (e) => {
        let appHost = window.location.hostname;
        let protocol = window.location.protocol;
        let callback = protocol + "//" + appHost + "/#/oauth/callback";
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_OAUTH_URL",
            NAME: "GHL",
            CALLBACK: callback
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let authUrl = response.data;
            window.parent.location.href = authUrl;
        }
    }

    return (
        <Fragment>
            <div className="agent-details">
                <ShowIntegrationInfo
                    keyname={name}
                    headData={integrationHeadData}
                    integrationData={integrationContent}
                    isInstalled={buttonValue.includes(name)}
                    isConnected={isConnected}
                    accountInfo={accountInfo}
                    onInstall={onInstallGHL}
                    onRemove={unInstall}
                />
            </div>
        </Fragment>
    );

}

export default GoHighlevel;