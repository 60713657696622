import React, { Fragment, useEffect, useState } from "react";
import { Button, CardBody, Col, Progress, Row } from "reactstrap";
import { 
    parseJSON, 
    formatDate, 
    getSessionValue, 
    currencyFormatter, 
    getLicenseInfo, 
    addExtraDate, 
    getLiceneInfo, 
    getCurrentPlanInfo, 
    getAllData
} from "../../Common/Appcommon";
import { useStepsContext } from "../Subscription/context";
import { Card } from "reactstrap";
import requestApiService from "../../services/requestApi.service";
import DateFilter from "../../Common/DateFilter";
import Loading from "../../Common/Loading";
import ListTable from "../../Common/ListTable";
import { useTheme } from "../../Context/ThemeContext";
import Payments from "./Payments";

const CurrentPlan = () => {
    const formname = "table_37";
    const {subCurrency, agencyCurrency} = useTheme();
    const { 
        currentplan, 
        paymentDetails, 
        currentStep, 
        setCurrentStep, 
        isAgency, 
        setShowLoading, 
        showLoading, 
        setPaymentDetails, 
        setCurrentPlan,
        currencyInfo,
        plansList
    } = useStepsContext();
    const [summaryData, setSummaryData] = useState([]);
    const [dateFilter, setDateFilter] = useState([]);
    const [formData, setFormData] = useState([]);

    let { plan_name, plan_cost, other_features, annual_discount, plan_type } = currentplan;
    let { modeofpayment, status, paymentid, upcoming_plans, noofMonths } = paymentDetails;
    let subscriptionActive = status === "Active";

    useEffect(() => {
        const fetchData = async () => {
            let data = await getAllData("GET_USAGE_SUMMARY", formname, isAgency);
            setSummaryData(data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const licenseInfo = await getLiceneInfo();
            const currentplan = await getCurrentPlanInfo(isAgency);
            setPaymentDetails(licenseInfo[0]?.["paymentdetails"]);
            setCurrentPlan(currentplan);
        }
        fetchData();
    }, [currentStep]);

    useEffect(() => {
        if(!isAgency){
            getUsageDataForListView();
        }
    }, [dateFilter]);

    const getUsageDataForListView = async () => {
        if(dateFilter.length === 0){
            dateFilter[0] = (() => { const date = new Date(); date.setDate(date.getDate() - 29); return date; })();
            dateFilter[1] = new Date();
        }
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_USAGE_LIST_DATA",
            FORMNAME: formname,
            datefilter: dateFilter,
            isOtherApiReq: true,
        };
    
        try {
            setShowLoading(true);
            let response = await requestApiService.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                setFormData(data);
                setShowLoading(false);
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    } 
    
    const handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            setDateFilter([startDate, endDate]);
        }
    }

    const btnClick = async (name) => {
        if(name === "upgrade_btn" || name === "subscribe_btn"){
            setCurrentStep(1);
        }else if(name === "update_btn"){
            setCurrentStep(4);
        }else if(name === "cancel_btn"){
            setCurrentStep(5);
        }
    }

    if(other_features !== undefined && other_features !== null && other_features !== ""){
        other_features = parseJSON(other_features);
    }else{
        other_features = []
    }

    const getDisplayAmount = (plan_cost, annual_discount, noofMonths, currency, plan_type) => {
        let Currency = currency;
        if(noofMonths === 12){
            let dis_plan_cost = plan_cost * annual_discount / 100;
            plan_cost = plan_cost - dis_plan_cost;
        }
        if (plan_cost) {
            plan_cost = Number(plan_cost).toFixed(0); // Converts to a number and rounds to integer
        }
        if(isAgency){
            Currency = agencyCurrency;
        }
        plan_cost = Currency + plan_cost;
        if(plan_type === "LTD"){
            plan_cost = plan_cost + "/One-time";
        }else{
            plan_cost = plan_cost + "/m";
        }
        return plan_cost;
    }


    const columns = [
        {
            name: (
                <div>
                    S.No
                </div>
            ),
            selector: (row, index) => index + 1,
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "60px !important",
        },
        {
            name: (
                <div>
                    Date
                </div>
            ),
            selector: row => row["date"],
            sortable: true,
        },
        {
            name: (
                <div>
                    Total Calls
                </div>
            ),
            id: "total_calls",
            selector: row => row["total_calls"],
            sortable: true,
        },
        {
            name: (
                <div>
                    Total Minutes
                </div>
            ),
            id: "minutes",
            selector: row => row["minutes"],
            sortable: true,
        },
      
        {
            name: (
                <div>
                    Cost
                </div>
            ),
            selector: row => row["amount"],
            sortable: true,
        }
    ];
    let isHaveUpcomingPlan = upcoming_plans && upcoming_plans.length > 0;

    if(other_features !== undefined && other_features !== null && other_features !== ""){
        other_features = parseJSON(other_features);
    }else{
        other_features = []
    }

    let license_end_date = getLicenseInfo("license_end_date");
    let next_payment_date = "";
    if(license_end_date !== ""){
        next_payment_date = addExtraDate(license_end_date);
    }
    if(next_payment_date !== ""){
        next_payment_date = formatDate(next_payment_date, "mmm dd,yyyy");
    }

    let subscription_status = "";
    let status_class = "";
    let subHeader = "";
    let subBody = "";

    if(upcoming_plans && upcoming_plans.length > 0){
        let up_cycle = upcoming_plans[0]['frequency'] ?? "";
        let up_plan_cost = upcoming_plans[0]['amount'] / 100 ?? "";
        let modeofpayment = upcoming_plans[0]['modeofpayment'];
        up_plan_cost = subCurrency + up_plan_cost;
        up_cycle = up_cycle.toLowerCase();
        subHeader = "Upcoming plan: " + up_plan_cost + "/month , billed " + modeofpayment + up_cycle;
        subBody = "Your plan change has been received and will take effect from the next billing cycle " + next_payment_date + ".";
        next_payment_date = "NA";
    }
    
    if(status === "Active"){
        subscription_status = "ACTIVE";
        status_class = "text-success";
        if(modeofpayment){
            modeofpayment = modeofpayment.toUpperCase();
        }
    }else if(status === "Cancelled"){
        subscription_status = "CANCELLED";
        status_class = "text-danger";
        if(!isHaveUpcomingPlan){
            let end_date = formatDate(license_end_date, "mmm dd,yyyy");
            subHeader = "Your subscription has been canceled.";
            subBody = "Your cancellation will take effect at the end of your current billing period on " + end_date + ".";
        }
        modeofpayment = "NA";
        next_payment_date = "NA"
    }else if(status === "Failed"){
        subHeader = "Your recent payment failed!";
        subBody = "Please check your card details or try a different payment method. For further assistance, contact our support team.";    
        subscription_status = "PAYMENT FAILED";
        status_class = "text-danger";
    };


    let currency_symbol =  subCurrency ?? agencyCurrency;
    let buttons = [];
    let isSubAccountView = getSessionValue("isSubAccountView");
    isSubAccountView = isSubAccountView === "true";
    if(!isSubAccountView){
        if(subscriptionActive || isHaveUpcomingPlan){
            buttons.push(
                {
                    label: "Upgrade Plan",
                    name: "upgrade_btn",
                    classNames: "theme-bg-g m-1",
                }
            );
            buttons.push(
                {
                    label: "Manage Subscription",
                    name: "update_btn",
                    classNames: "theme-bg-g m-1",
                }
            );
            buttons.push(
                {
                    label: "Cancel Subscription",
                    name: "cancel_btn",
                    classNames: "cancel-sub-btn",
                }
            );
        }

        if(!subscriptionActive && !isHaveUpcomingPlan){
            buttons.push(
                {
                    label: "Subscribe Plan",
                    name: "subscribe_btn",
                    classNames: "theme-bg-g m-1",
                }
            );
        }

        let current_plan_is_ltd = !isHaveUpcomingPlan && plan_type === "LTD";
        let up_plan = plansList && plansList.length <= 1;
        if(!current_plan_is_ltd && isHaveUpcomingPlan){
            let up_type = upcoming_plans[0]['plan_type'];
            current_plan_is_ltd = up_type === "LTD";
        }
        if(current_plan_is_ltd && up_plan){
            buttons = [
                {
                    label: "Upgrade Plan",
                    name: "upgrade_btn",
                    classNames: "theme-bg-g m-1 hide",
                }
            ];
        }else if(current_plan_is_ltd && !up_plan){
            buttons = [
                {
                    label: "Upgrade Plan",
                    name: "upgrade_btn",
                    classNames: "theme-bg-g m-1",
                }
            ];
        }
    }   

    return(
        <Fragment>
            <div className="usage-dashboard-container">
                {
                    !isAgency &&
                    <div className="usage-summary-container">
                        <div className="summary-box-container mb-3">
                            <div className="summary-box-header mt-3 mb-3 fw-bold">
                                Usage Summary
                            </div>
                            <Row className="usage-summary-box">
                                <Col lg="12">
                                    <Row>
                                        {
                                            summaryData && summaryData.map(({header, value, body, type, footer}) => {
                                                if(type === "summary_box"){
                                                    return(
                                                        <Col md={isAgency ? "6" : "4"} lg={isAgency ? "6" : "4"}>
                                                            <div className="card mb-3 widget-chart text-start usage-summary-cont m-1">
                                                                <div className="widget-chart-content ms-2 mt-2">
                                                                    <div className="mb-3 box-header">{header}</div>
                                                                    <div className="usage-summary-body">
                                                                        <div className="widget-numbers theme-c-g no-content">
                                                                            {value}
                                                                        </div>
                                                                        <div className='theme-c-g txt-content'>
                                                                            {body}
                                                                        </div>
                                                                    </div>
                                                                    <div className="widget-description">
                                                                        {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                                                                        {footer}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }else if(type === "progress_box"){
                                                    return (
                                                        <Col md={isAgency ? "6" : "4"} lg={isAgency ? "6" : "4"}>
                                                            <Card body className="m-1">
                                                                <div className="box-header">{header}</div>
                                                                {
                                                                    value.map((val, index) => {
                                                                        return(
                                                                            <div className="widget-content p-0 mt-2">
                                                                                <div className="widget-progress-wrapper mt-2">
                                                                                    <div className="progress-sub-label m-1">
                                                                                        <div className="sub-label-left">{val.bar_title}</div>
                                                                                        <div className="sub-label-right">{val.bar_body}</div>
                                                                                    </div>
                                                                                    <Progress
                                                                                        className={"progress-bar-sm progress-bar-animated-alt " + (value.length === (index + 1) ? "mb-1" : "" ) } 
                                                                                        color={val.color} 
                                                                                        value={val.bar_percentage}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Card>
                                                        </Col>
                                                    )
                                                }
                                                return null;
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                <div className="summary-box-container mb-3">
                    <div className="usage-summary-container">            
                        <div className="current-plan-container m-3">
                            {
                                subHeader && 
                                <div className='card-fields d-grid current-plan-notification'>
                                    <span class="header-desc m-auto " >{subHeader}</span>
                                    <span className='header-desc m-auto'>{subBody}</span>
                                </div>
                            }
                            <Row className={`billing-content-wrapper me-2 ms-1 mt-5 mb-4 `}>
                                <Col md={'12'}>
                                    <div className={`billing-subscription-info mb-3`}>
                                        <span className={`fw-bold`}>Subscription Details</span>
                                        {
                                            false && paymentid !== "" && 
                                            <span className={`ms-auto fw-normal`}>Payment ID : {paymentid}</span>
                                        }
                                    </div>
                                    <div className={`billing-subscription-table`}>
                                        <table className={'le-table no-border-table table table-bordered table-hover billing-subscription-table'}>
                                            <thead> 
                                                <tr>
                                                    <td className={'text-left text-uppercase'}>Plan Name</td>
                                                    <td className={'text-center text-uppercase'}>Status</td>
                                                    <td className={'text-center text-uppercase'}>Charges</td>
                                                    <td className={'text-center text-uppercase'}>Frequency</td>
                                                    <td className={'text-center text-uppercase'}>Next Payment</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className={'text-left'}>{plan_name}</th>
                                                    <th className={'text-center fw-bold ' + status_class}>{subscription_status}</th>
                                                    <th className={'text-center'}>{getDisplayAmount(plan_cost, annual_discount, noofMonths, currency_symbol, plan_type)}</th>
                                                    <th className={'text-center'}>{plan_type === "LTD" ? "One-time" : modeofpayment}</th>
                                                    <th className={'text-center'}>{plan_type === "LTD" ? "Not Applicable" : next_payment_date}</th>
                                                </tr>
                                                {
                                                    upcoming_plans && upcoming_plans.map(({plan_name, plan_cost, annual_discount, modeofpayment, status, noofMonths, plan_type}, index) => {
                                                        status_class = status === "Active" ? "text-success" : "text-danger";
                                                        next_payment_date = addExtraDate(license_end_date, 1, "days");
                                                        plan_cost = getDisplayAmount(plan_cost, annual_discount, noofMonths, currency_symbol, plan_type);
                                                        next_payment_date = formatDate(next_payment_date, "mmm dd,yyyy");
                                                        plan_name = plan_type === "LTD" ? plan_name + " - Lifetime deal" : plan_name;
                                                        return(
                                                            <tr>
                                                                <th className={'text-left'}>{plan_name}</th>
                                                                <th className={'text-center fw-bold text-info'}>{"UPCOMING"}</th>
                                                                <th className={'text-center'}>{plan_cost}</th>
                                                                <th className={'text-center text-uppercase'}>{plan_type === "LTD" ? "One-time" : modeofpayment}</th>
                                                                <th className={'text-center'}>{plan_type === "LTD" ? "Not Applicable" : next_payment_date}</th>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={`text-center mt-4 mb-4`}>
                                        {
                                            buttons && buttons.map(({label, name, classNames, isDisabled}, index) => {
                                                let key = "btn_list_" + index;
                                                return(
                                                    <Button key={key} name={name} className={classNames + " m-2"} onClick={e => {btnClick(name)}} disabled={isDisabled}>
                                                        {label}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                {
                    isAgency && 
                    <div className='agency-details mb-3'>
                        <div className='field-details'>
                            <Card className='card-br'>
                                <CardBody className='pb-2'>
                                    <div className='card-fields profile-title-info d-grid'>
                                        <span class="heading" >Invoice History</span>
                                    </div>
                                    <Payments isAgent={isAgency}/>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                }
                {
                    !isAgency &&
                    <div className="usage-list-view">
                        <div className="list-view-header mb-3 mt-4 ms-2">
                            <div className="usage-list-header mt-3 fw-bold">
                                Usage Log
                            </div>
                            <div className="date-filter">
                                <DateFilter handleDateChange={handleDateChange} defaultSelectIndex={3}/>
                            </div>
                        </div>
                        <div className="list-view-box ms-1 mr-1 mb-5">
                            <Loading 
                                showLoader={showLoading} 
                                mainContent={
                                    <div className="usage-report ms-3 me-3">
                                        <ListTable columns={columns} data={formData} />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )

} 

export default CurrentPlan;