import React, { useState, Fragment, useEffect } from 'react';
import Loading from '../../Common/Loading';
import { Button } from "reactstrap";
import requestApi from "../../services/requestApi.service";
import { showToast } from '../../Common/Appcommon';

const DoImport = (props) => {
    let {importParams, selectedList, onImportComplete, setCurrentStep,selectedTag} = props;
    const [showLoading, setShowLoading] = useState(false);
    let [importInfo, setImportInfo] = useState({});
    const inString = process.env.REACT_APP_MAX_IMPORT_SIZE_FORMAT;

    useEffect(() => {
        (async () => {
            if(Object.keys(importParams).length > 0){
                setShowLoading(true);
                await doImportAction(importParams);
            }
        })();
    }, [importParams]);

    const doImportAction = async (params) =>{
        if(selectedList.length > 0 || selectedTag.length > 0){
            params['list'] = {'table_81' : selectedList,'table_82':selectedTag};
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let importRes = response.data;
            let insertCount = importRes[1];
            let updateCount = importRes[2];
            let skippedCount = importRes[0];
            let totalCount = importRes[6];
            let errorMsg = importRes[4];
            if(errorMsg != ""){
                showToast(errorMsg, "top-right", "error");
            }
            let newInfo = {};
            newInfo['total'] = totalCount - 1;
            newInfo['insert'] = insertCount;
            newInfo['update'] = updateCount;
            newInfo['skip'] = skippedCount;
            setImportInfo(newInfo);
            setShowLoading(false);
        } else {
            showToast(response.errormsg, "top-right", "error");
            setCurrentStep(2);
        }
    }

    const onImportCompleteAction = () => {
        onImportComplete();
    }

    return (
        <Fragment>
            <div>
                <div className='p-3'>
                    <div className=''>
                        <span className='heading'>Import</span>
                    </div>
                    <div className='csv-import-stats'>
                        {showLoading &&
                            <Loading showLoader={showLoading} mainContent={
                                <Fragment>
                                    <div className='h-100-p text-center m-auto import-processing-panel'>
                                        <span>Processing importing contacts...</span>
                                    </div>
                                </Fragment>
                            } />
                        }
                        {Object.keys(importInfo).length > 0 && <div className='h-100-p import-stats-info pt-3'>
                            <div className='processing-info d-flex bb-1'>
                                <div className='col-md-8 td'>
                                    Processed:
                                </div>
                                <div className='col-md-4 td'>
                                    {importInfo['total']} / {importInfo['total']} contacts
                                </div>
                            </div>
                            <div className='processing-info d-flex bb-1'>
                                <div className='col-md-8 td'>
                                    Newly added:
                                </div>
                                <div className='col-md-4 td lite'>
                                {importInfo['insert']} contacts
                                </div>
                            </div>
                            <div className='processing-info d-flex bb-1'>
                                <div className='col-md-8 td'>
                                    Updated:
                                </div>
                                <div className='col-md-4 td lite'>
                                {importInfo['update']} contacts
                                </div>
                            </div>
                            <div className='processing-info d-flex bb-1'>
                                <div className='col-md-8 td'>
                                    Skipped:
                                </div>
                                <div className='col-md-4 td lite'>
                                {importInfo['skip']} contacts
                                </div>
                            </div>
                        </div>}
                        
                    </div>
                </div>
                {Object.keys(importInfo).length > 0 && <div class="d-flex justify-end p-3">
                    <Button onClick={(e) => onImportCompleteAction(e)} size="lg" className='theme-bg-g'>Done</Button>
                </div>}
            </div>
        </Fragment>
    )
}

export default DoImport;