import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { Mention } from 'primereact/mention';
import cx from "classnames";

import {
    getFieldsByFormname,parseJSON
} from "../../../Common/Appcommon";
import requestApi from "../../../services/requestApi.service";

const CratioMention = (props) => {
    let {
        name,
        value,
        fieldkey,
        type,
        formname,
        mentionTrigger,
        handleOnChange,
        control,
        inputID,
        customClassName,
        placeholder,
        suggestionList,
        agent_id
    } = props;
    const [suggestions, setSuggestions] = useState([]);
    const [options, setOptions] = useState([]);
    let orgFormName = process.env.REACT_APP_ORGANIZATION_FORMNAME;
    suggestionList = suggestionList !== undefined && suggestionList !== null &&  suggestionList !== "" ? suggestionList : ""

    useEffect(() => {
        (async () => {
            if(suggestionList !== ""){
                setOptions(suggestionList);
            }else {
                let selectedfields = await getFieldsByFormname(formname, "vm_templates");
                if(agent_id !== undefined && agent_id !== null && agent_id !== ""){
                    let params = {
                        "ENTITY": "AIPROCESSOR",
                        "OPERATION": "GET_EXTRACT_INFO",
                        'agent_id': agent_id,
                        'action_type':'extract_info'
                    };
                    let response = await requestApi.processServerRequest(params);
                    if(response.issuccess){
                        let data = response.data;
                        if(data[0] !== undefined && data[0] !== undefined && data[0] !== undefined){
                            let action_details = data[0]['action_details'];
                            let extractInfoArr = parseJSON(action_details);
                            let infoData = extractInfoArr['infoData'];
                            for (let i = 0; i < infoData.length; i++) {
                                selectedfields.push({
                                    id: `#${infoData[i]['infoname']}#`,
                                    text: `$${infoData[i]['infoname']}$`,
                                    name: infoData[i]['infoname'],
                                    label: `ㅤ${infoData[i]['infoname']}`,
                                    url: infoData[i]['infoname'],
                                    value: `$${infoData[i]['infoname']}$`,
                                    group: "Text",
                                    formname: "webhook"
                                });
                            }
                        }
                    }
                }
               
                if(formname == "Contacts"){
                    const fieldData = [
                        {
                            id: "#$From Number$",
                            text: "$From Number$",
                            name: "From Number",
                            label: "ㅤFrom Number",
                            url: "From Number",
                            value: "$From Number$",
                            group: "Text",
                            formname: "webhook"
                        },
                        {
                            id: "#$To Number$",
                            text: "$To Number$",
                            name: "To Number",
                            label: "ㅤTo Number",
                            url: "To Number",
                            value: "$To Number$",
                            group: "Text",
                            formname: "webhook"
                        },
                        {
                            id: "#$Call Direction$",
                            text: "$Call Direction$",
                            name: "Call Direction",
                            label: " Call Direction",
                            url: "Call Direction",
                            value: "$Call Direction$",
                            group: "Text",
                            formname: "webhook"
                        },
                        {
                            id: "#$Call Summary$",
                            text: "$Call Summary$",
                            name: "Call Summary",
                            label: "ㅤCall Summary",
                            url: "Call Summary",
                            value: "$Call Summary$",
                            group: "Text",
                            formname: "webhook"
                        }
                    ];
                    selectedfields.push(...fieldData);
                }
                // let orgfields = await getFieldsByFormname(orgFormName, "organization");
                // const mergedArray = [...selectedfields, ...orgfields.filter(obj2 => !selectedfields.some(obj1 => obj1.value === obj2.value))];
                setOptions(selectedfields);
            }
        })();
    }, [formname]);

    const onSearch = (event) => {
        //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
        setTimeout(() => {
            const query = event.query;
            let suggestions;
            if (!query.trim().length) {
                suggestions = [...options];
            }else {
                suggestions = options.filter((field) => {
                    return field.id.toLowerCase().startsWith(query.toLowerCase());;
                });
            }
            setSuggestions(suggestions);
        }, 250);
    }

    const itemTemplate = (suggestion) => {
        return (
            <span className={"d-flex flex-column custom-suggestion-editor"}>
                {suggestion.name}
            </span>
        );
    }
    const onMentionChange = (e) => {
        /*
            '#$' to '$' for '#$ContactName$' to '$ContactName$' for replace '#$ to $'
            '#[' to '$' for '#[@LeadOwner@Name@]' to '[@LeadOwner@Name@]'
            '&nbsp;' to '$' for '[@LeadOwner@Name@]' to '[@Lead Owner@Name@]' givin white space for variables
        */
        let name = e.target.name;
        let value = e.target.value.replace("#$", "$").replace("#[", "[").replace("#@", "@").replaceAll("&nbsp;", " ");
        // suggestions.map((option, index) => {
        //     let tmpValue = option['text'].replaceAll(" ", "").trim();
        //     value = value.trim();
        //     if(tmpValue === value){
        //         value = option['value'] !== undefined && option['value'] !== null && option['value'] !== "" ? option['value'] : value;
        //     }
        // })
        handleOnChange({ target: { name: e.target.name, value }},"mention");
    }

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{ required: value !== undefined && value !== "" ? false : props.message !== undefined ? props.message : "Please choose " + name }}
                render={({ field }) => (
                    <Mention
                        name = {name}
                        inputId={inputID}
                        value={value}
                        onChange={(e) => {
                            onMentionChange(e);
                            field.onChange(e);
                        }}
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field={fieldkey}
                        rows={props.rows !== undefined ? props.rows : 4}
                        cols={props.cols !== undefined ? props.cols : 40}
                        itemTemplate={itemTemplate}
                        inputClassName={customClassName}
                        trigger={mentionTrigger}
                        placeholder={placeholder}
                    />
                )}
            />  
        </>
    )
}

export default CratioMention;