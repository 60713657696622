import React, { Fragment,useState,useEffect } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Label,Input,FormText,Button,Col,Row,InputGroup,Table } from "reactstrap";
import { getServerDomainURL,showToast,handleErrorResponse,confirmBoxWithImage, getContactLinkedData,parseJSON, translateContent, getCustomeDominUrl } from "../../Common/Appcommon";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import requestApi from '../../services/requestApi.service';
import TooltipItem from "../../Common/ToolTip";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Loading from "../../Common/Loading";



export const  FieldsMapping = ({ formInfo,onBackAction,mappingCol,webhookurl,webhookNameVal,formfildefault,leadstatus }) => {
    const baseUrl = getServerDomainURL();
    const [copied,setCopied] = useState(false);
    const [webhookdisplayUrl,setWebhookDisplayUrl] = useState("");
    const [mappingInfo, setMappingInfo] = useState([]);
    const [mappingIds,setMappingIds] = useState([]);
    const [mappingCount,setMappingCount] = useState(0);
    const [currentMappings, setCurrentMappings] = useState([]);
    const [webhookName, setWebhookName] = useState(webhookNameVal);
    const [fieldsobject, setFieldObject] = useState({});
    const [mappingneeded,setMappingNeeded] = useState(false);
    const [mandataryFields, setMandataryFields] = useState("");
    const [pickListItems,setPickListName] = useState([]);
    const leadFormname = "table_74";
    const [assignTo ,setAssignedTo] = useState("");
    const [fieldsOptions, setFieldOptions] = useState([]);
    const [clickedMapId, setClickedMapId] = useState([]);
    // const [formFilOptions, setFormFilOptions] = useState(formfildefault);
    const [statusText, setstatusText] = useState(leadstatus !== "inactive" ? "active" : leadstatus);
    const fieldempty = mappingneeded ?  "Webhook Field/ Default value is required.":"";
    const [googleAdsFieldOptions, setGoogleAdsFieldOptions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showLoading, setShowLoading] = useState(false);


    const form = useForm();
    const { handleSubmit,control, formState: { errors } ,setError} = form;

    let leadFormDetail = [];
    let payload = [];
    let mappingColumns = [];
    let mappingList = [];
    let obj = [];
    const [listData,setListData] = useState({});
    const [tagData,setTagData] = useState({});
    const [listTagInfo, setListTagInfo] = useState([]);
    const listtagOptions = [
        { label:'Contact List',id: "table_81", type: "googleads", data: listData, labelAdd: "Add to List", labelRemove: "Remove from List" },
        { label:'Contact Tag',id: "table_82", type: "googleads", data: tagData, labelAdd: "Add to Tag", labelRemove: "Remove from Tag" }
    ];
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['id'] !== undefined && formInfo['id'] !== null ? formInfo['id'] : formInfo[0]) : "");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['webhookurl'] !== undefined && formInfo['webhookurl'] !== null ? formInfo['webhookurl'] : formInfo[4]) : "");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['formname'] !== undefined && formInfo['formname'] !== null ? formInfo['formname'] : formInfo[2]) :"");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['identifier'] !== undefined && formInfo['identifier'] !== null ? formInfo['identifier'] : formInfo[3]) : "");
    useEffect(()=>{
        (async () => {
            await getFieldMappingInfo();
            await processEditInfo();
            await getLeadFieldsInfo();

        })();
    },[formInfo]);

    useEffect(()=>{
        (async () => {
            await processEditInfo();
        })();
    },[currentMappings,fieldsobject]);

    useEffect(()=>{
        (async () => {
            if(webhookurl !== ""){
                await setWebhookUrl();
            }
        })();
    },[webhookurl])

    const copyUrl = () => {
        setCopied(true);
    }

    const setWebhookUrl = async ()=>{
        let domainurl = await getCustomeDominUrl();
        let url = domainurl +"/server/"+webhookurl;
        setWebhookDisplayUrl(url);
    }
    const fetchSuggestions = async (query,mappingInfo,mappingId,pickListItems,assignTo) => { 
        let suggestion = [];
        let label = "";
        for (let i = 0; i < mappingInfo.length; i++) {
            label = mappingInfo[mappingId]['fieldselected']['label'];
            if (query !== "" && label !== undefined) {
                if (label === assignTo) {
                    let params = {
                        "ENTITY": "CLOUDTELEPHONYLEADSPROCESS",
                        "OPERATION": 'USER_DROP_DOWN',
                    };
                    let response = await requestApi.processServerRequest(params, "WebV2");
                    suggestion = response.data[0];
                } else {
                    pickListItems.map((item, index) => {
                        if (label === item['label']) {
                            // suggestion = getPickListItems(item['value']);
                        }
                    });
                }
            } else {
                setSuggestions([]);
            }
        }            
        let  filteredSuggestions= [];      
        for(let i = 0; i < suggestion.length; i++) {
            let data = suggestion[i];
            if(data !== undefined && data !== null && data !== ""){
                if (data.toLowerCase().startsWith(query.toLowerCase())) {
                    filteredSuggestions[i] = data;
                }  
            }
        }  
        setSuggestions(filteredSuggestions);
    } 
    const handleSuggestionClick = (data, name,mappingId) => {
    const suggestionText = data.textContent; 
    mappingInfo[mappingId][name] = suggestionText;
    setSuggestions([]);
    };
    const handleWebhookChange = async (e) => {
        setWebhookName(e.target.value);
    }

    const getFieldMappingInfo = async () => {
        if(formInfo !== undefined && formInfo !== null){
            setShowLoading(true);
            let entity = "WEBHOOKSLEADSPROCESS";
            let params = {
                "ENTITY" : entity,
                "OPERATION" : 'GET_FIELD_MAPPING',
                "ID" : obj
            }
            let response = await requestApi.processServerRequest(params, "WebV2");
            if(response.issuccess){
                if(response.data[0].length === 0){
                    setMappingNeeded(false);
                }else{
                    setMappingNeeded(true);
                }
                await setCurrentMappings([...response.data[0]]);
                await setMandataryFields(response.data[1]);
            }else{
                handleErrorResponse(response.errormsg);
            }
        }
        setShowLoading(false);
    }
    const getLeadFieldsInfo = async (name) => {
        let formnameVal = leadFormname;
        let formname = "Contacts";
        if(name !== undefined){
          formname = name;
        }
        let entity = 'WEBHOOKSLEADSPROCESS';
        let params = {
            "ENTITY" : entity,
            "OPERATION" : 'GET_FORMFIELDS',
            "formname" : formname
        }
        let response = await requestApi.processServerRequest(params, "WebV2");
        if(response.issuccess){
            leadFormDetail = response.data;
        }else{
            handleErrorResponse(response.errormsg);
        }
       
        // let skipFields = [
        //     'Lead_ID',
        //     'wsapp_recent_activity',
        //     'wsapp_convo_status',
        //     'wsapp_unread_count',
        //     'wsapp_optin_status',
        //     'wsapp_recent_msg_id',
        //     'wsapp_enabled',
        //     'wsapp_blocked',
        //     'wsapp_opt',
        //     'wsapp_last_delivered',
        //     'wsapp_last_read',
        //     'wsapp_last_replied',
        //     'wsapp_last_sent_msg_id',
        //     'wsapp_last_sent_msg_id',
        //     'wsapp_completed_campaign',
        //     'lead_assigned_at',
        //     'first_touch_at',
        //     'call_did_status'
        // ];  
        if(leadFormDetail !== undefined ){
            let fieldDetails = leadFormDetail;
            let fields = [];
            let fieldsObj = {};
            fieldDetails && fieldDetails.map((field) => {
                let fieldName = field[0];
                let fieldLabel = field[1];
                let fieldType = field[2];
                let isConfigHide = parseInt(field[3]);
                let isViewHide = parseInt(field[4]);
                let defaultValue = field[5];
                if (!isConfigHide && !isViewHide) {
                    if ((fieldType !== 'subtable' && fieldType !== 'reference_entityid' && fieldType !== "reference_group" && fieldType !== "form_history")) {
                        // if (!skipFields.includes(fieldName)) {
                            if(fieldType === "ComboBox"){  
                                let pickListDetials = defaultValue.split(",");
                                let pickListName = pickListDetials[0]; 
                                    pickListItems.push({value: pickListName, label: fieldLabel});
                            }
                            if (fieldType === "entity_group") {
                                setAssignedTo(fieldLabel);
                            }
                            fields.push({
                                value: fieldName, label: fieldLabel
                            })
                            fieldsObj[fieldName] = {
                                value: fieldName, label: fieldLabel
                            };
                        // }
                    }
                }
                return true;
            });
            setFieldOptions([...fields]);
            await setFieldObject({...fieldsObj});
            await setPickListName([...pickListItems])
        }
    }

    const addField = () => {
        setMappingCount(mappingCount + 1);
    }

    const setTagListDetails = (selectedOption,mappingId="",fieldname="",selectedField="") => {
        let tmplistTagInfo = [...listTagInfo];
        if(tmplistTagInfo[mappingId] === undefined || tmplistTagInfo[mappingId] === null || tmplistTagInfo[mappingId] === ""){
            tmplistTagInfo[mappingId]= {googleadsfield : {},fieldselected : selectedField,fieldname : selectedField,googleadsvalue : '',defaultvalue : '',isDropDown : true,selectedOption: selectedOption,fieldOptions:selectedField=== 'table_81' ? listData : tagData};
        }
        if(fieldname === "googleads"){
            tmplistTagInfo[mappingId]['googleadsvalue'] = selectedOption;
        }else{
            tmplistTagInfo[mappingId]['defaultvalue'] = selectedOption;
            tmplistTagInfo[mappingId]['selectedOption'] = selectedOption;
        }
        setListTagInfo([...tmplistTagInfo]);
    }

    const processEditInfo = async () => {      
        
        let curMappingIds = [];
        let curMappingCount = 0;
        let curGoogleAdsOptions = [];
        let curGoogleAdsFields = [];
        let formvalue = {};
        let listTagCount = 0;

        let params = {
            "ENTITY": "WEBHOOKSLEADSPROCESS",
            "OPERATION" : 'GET_PAYLOADFIELDS_MAPPING',
            "RandomID"  : obj
        }
        let response = await requestApi.processServerRequest(params,"WebV2");
        if(response.issuccess){
            mappingCol = response.data;
            let listOptions = await getContactLinkedData("table_81");
            setListData(listOptions);
            let tagOptions = await getContactLinkedData("table_82");
            setTagData(tagOptions);

        }else{
            handleErrorResponse(response.errormsg);
        }
        for(let i=0;i<Object.keys(mappingCol).length;i++){
            const label = mappingCol[i] !== undefined ? mappingCol[i] : "";
            const value = mappingCol[i] !== undefined ? mappingCol[i] : "";
            curGoogleAdsFields[value] = {label,value};
            curGoogleAdsOptions.push({label,value});
        }
        if(currentMappings && currentMappings.length > 0){
            for(let i=0;i<currentMappings.length;i++){

                let googleadsfield = {};
                let label = '';

                if(currentMappings[i][1].includes('@') && currentMappings[i][1].length > 1){
                    label = currentMappings[i][1].split('@')[0];
                    if(curGoogleAdsFields[label]){
                        googleadsfield = curGoogleAdsFields[label];
                    }
                }

                const fieldname = currentMappings[i][0] ? currentMappings[i][0] : '';
                const defaultval = currentMappings[i][2] ? currentMappings[i][2] : '';

                let options = {};
                let selectedOption = [];
                let tagFormname = '';
                const isDropDown = (currentMappings[i][0].includes('table_81') || currentMappings[i][0].includes('table_82')) ? true : false;
                if(isDropDown){
                    let addTagField = "";
                    const currentTag = currentMappings[i][0];
                    if (currentTag.includes('table_82')) {
                        tagFormname = 'table_82';
                    } else if (currentTag.includes('table_81')) {
                        tagFormname = 'table_81';
                    }
                    if(parseJSON(currentMappings[i][2]).length > 0){
                        selectedOption = parseJSON(currentMappings[i][2]);
                    }
                    let data = currentMappings[i][1].split('@');
                    if(parseJSON(data[0]).length > 0){
                        label = parseJSON(data[0]);
                    }
                    if(tagFormname == "table_81" || tagFormname == "table_82"){
                        listTagInfo[listTagCount] = {selectedOption : selectedOption,defaultvalue : selectedOption,googleadsvalue:label,isDropDown:true,fieldname:fieldname};
                    }
                    listTagCount++;
                }
                formvalue = currentMappings[i][3] ? currentMappings[i][3] : '';
                formvalue = {value: formvalue, label: formvalue};
                const selectedField = fieldsobject[fieldname] ? fieldsobject[fieldname] : {};
                if(tagFormname == ""){
                    mappingInfo[i] = {
                        googleadsfield : googleadsfield,
                        fieldselected : selectedField,
                        fieldname : fieldname,
                        googleadsvalue : label,
                        defaultvalue : defaultval,
                        isDropDown : isDropDown,
                        selectedOption: selectedOption,
                        fieldOptions:options
                    };
                    curMappingIds.push(i);
                    curMappingCount++;
                }
            }
        }else{
            curMappingIds.push(0);
            curMappingCount++;
        }
        
        await setMappingInfo([...mappingInfo]);
        await setMappingIds([...curMappingIds]);
        await setMappingCount(curMappingCount);
        await setGoogleAdsFieldOptions([...curGoogleAdsOptions]);


    }

    const onSave = async (e) => {
        if(webhookName.trim() === ""){
            // handleErrorResponse(translateContent("vm.webhook.error.msg"));
            setError("webhook_name", {
                type: "manual",
                message: "Please provide webhook name",
            })
            return;
        }
        let mappings = [];
        let mappedFields = [];
        if(mappingInfo){
            let maxMappingId = (+mappingIds[mappingIds.length - 1]);

            for(let i=0;i<=maxMappingId;i++){
                if(mappingInfo[i] !== undefined){

                    if(mappedFields.includes(mappingInfo[i].fieldname)){
                        showToast(`Duplicate values not allowed in Field Name. Please choose different option.`,"top-right","error");
                        return;
                    }
                    mappedFields.push(mappingInfo[i].fieldname);
                    mappings.push(mappingInfo[i]);
                }
            }
        }
        if(listTagInfo){
            for(let j=0;j<listTagInfo.length;j++){
                if(listTagInfo[j] !== undefined){
                    mappedFields.push(listTagInfo[j].fieldname);
                    mappings.push(listTagInfo[j]);
                }
            }
        }

        let formfilter = "";
        let formids = formInfo['identifier'];
        let entity = "WEBHOOKSLEADSPROCESS";
        formids = obj;
        formfilter = "Contacts";
        let params = {
            "ENTITY":entity,
            "OPERATION" : 'UPDATE_FIELD_MAPPING',
            "ID" : formids,
            "fields" : mappings,
            "formfilter" : formfilter,
            "webhookname" : webhookName,
            "mappingneeded" : mappingneeded
        }
        let response = await requestApi.processServerRequest(params,"WebV2");
        if(response.issuccess){
            if(response.data != ""){
                let header = response.data[0];
                let message = response.data[1];
                let isConfirm = await confirmBoxWithImage(header,message,"", "", "", true, "Yes", false, false);
                if(isConfirm){
                    params["skipmandotory"] = true;
                    response = await requestApi.processServerRequest(params,"WebV2");
                }
            }
            if(response.data == ""){
                onBackAction();
                showToast(translateContent("vm.save.success"));
            }
        }else{
            handleErrorResponse(response.errormsg);
        }
    }
    const onLeadFieldChange = async (data, name,mappingId) => {
        if(mappingInfo[mappingId] === undefined){
            mappingInfo[mappingId] = {};
        }
        if(name === "defaultvalue"){
            setClickedMapId(mappingId);
        }
        mappingInfo[mappingId][name] = data;
        // fetchSuggestions(data,mappingInfo,mappingId,pickListItems,assignTo);
        if(name === "fieldselected" && data !== undefined && data.value !== undefined){
            mappingInfo[mappingId]['fieldname'] = data.value;
            let dropdownLabels = { 'Lists': 'table_81', 'Tags': 'table_82' };
            mappingInfo[mappingId]['selectedOption'] = "";
            mappingInfo[mappingId]['defaultvalue'] = "";
            mappingInfo[mappingId]['isDropDown'] = false;
            if(data.value !== undefined && data.value !== null && (data.label in dropdownLabels) ){
                mappingInfo[mappingId]['isDropDown'] = true;
                let tagName = dropdownLabels[data.label]; 
                let tagData = await getContactLinkedData(tagName);
                mappingInfo[mappingId]['fieldOptions'] = tagData;
            }
        }else if(name === "googleadsfield" && data !== undefined && data.value !== undefined){
            mappingInfo[mappingId]['googleadsvalue'] = data.value;
        }
        await setMappingInfo([...mappingInfo]);
    }

    const onHandleDropdownChange = async (selectedOption,mappingId="",fieldname="",selectedField="") =>{
        setTagListDetails(selectedOption,mappingId,fieldname,selectedField);
    }
    for(let i=0;i<mappingCount;i++){

        let mappingId = undefined;
        if(mappingIds[i] !== undefined){
            mappingId = mappingIds[i];
        }else{
            if(mappingIds.length > 0){
                mappingId = (mappingIds[mappingIds.length-1]) + 1;
            }else{
                mappingId = 0;
            }
            mappingIds[i] = mappingId;
            setMappingIds([...mappingIds]);
        }


        mappingList.push(
            <Fragment>
                <Row className={'mt-2 mb-3'}>
                    <Col md={"11"}>
                        <Col md={"10"} className={`offset-1`}>
                            <Row className={`google-ads-mapper`}>
                                <Col md={"4"}>
                                    <Controller
                                        name={`lead_field_${mappingId}`}
                                        control={control}
                                        rules={{ required: ( (!mappingInfo[mappingId]) || (mappingInfo[mappingId] && (mappingInfo[mappingId][`fieldname`] === ""))) ?`Field name is required.` : false}}
                                        render={({ field }) => {
                                            return (
                                            <Select
                                                {...field}
                                                id={`lead_field_${mappingId}`}
                                                name={`lead_field_${mappingId}`}
                                                onChange={(e) => {
                                                    onLeadFieldChange(e, "fieldselected",mappingId)
                                                    field.onChange(e);
                                                }}
                                                value={
                                                    mappingInfo[mappingId] && mappingInfo[mappingId][`fieldselected`] !== undefined ? mappingInfo[mappingId][`fieldselected`] : {}
                                                }
                                                options={fieldsOptions}
                                                className={`basic-multi-select field-selected-dropdown`}
                                                classNamePrefix="wa-select"
                                                menuPlacement={"top"}
                                                menuPosition={'absolute'}
                                            />
                                            );
                                        }}
                                    />
                                    {errors[`lead_field_${mappingId}`] && <em className={`error invalid-feedback`}>{errors[`lead_field_${mappingId}`].message}</em>}
                                </Col>
                                <Col md={"4"}>
                                    <div className={`googleads-column-name`}>
                                        <Controller
                                            name={`googleads_field_${mappingId}`}
                                            control={control}
                                            rules={{ required: ((!mappingInfo[mappingId] || (mappingInfo[mappingId] && (mappingInfo[mappingId][`defaultvalue`] === undefined || mappingInfo[mappingId][`defaultvalue`] !== undefined && mappingInfo[mappingId][`defaultvalue`] === "")))
                                                && (!mappingInfo[mappingId] || (mappingInfo[mappingId] && (mappingInfo[mappingId][`googleadsvalue`] === undefined || mappingInfo[mappingId][`googleadsvalue`] !== undefined && mappingInfo[mappingId][`googleadsvalue`] === "")))) ?`${fieldempty}` : false}}
                                            render={({ field }) => {
                                                const isDropdown = mappingInfo[mappingId]?.isDropDown;
                                                return isDropdown ? (
                                                    <div className="w-100">
                                                        <Select
                                                            onChange={(e) => onHandleDropdownChange(e, mappingId,"googleads")}
                                                            className="basic-multi-select"
                                                            classNamePrefix="wa-select"
                                                            isMulti={true}
                                                            menuPlacement="top"
                                                            options={mappingInfo[mappingId]['fieldOptions']}
                                                            value={mappingInfo[mappingId]['googleadsvalue']}
                                                        />
                                                        <Label className="font-bold pt-1 ps-2">Add {mappingInfo[mappingId]['fieldselected']['label']} </Label>
                                                    </div>
                                                ) : (
                                                    <Select
                                                    {...field}
                                                    id={`googleads_field_${mappingId}`}
                                                    name={`googleads_field_${mappingId}`}
                                                    onChange={(e) => {
                                                        onLeadFieldChange(e, "googleadsfield",mappingId)
                                                        field.onChange(e);
                                                    }}
                                                    value={
                                                        mappingInfo[mappingId] && mappingInfo[mappingId][`googleadsfield`] !== undefined ? mappingInfo[mappingId][`googleadsfield`] : {}
                                                    }
                                                    options={googleAdsFieldOptions}
                                                    className={`basic-multi-select `}
                                                    classNamePrefix="wa-select"
                                                    menuPlacement={"top"}
                                                    menuPosition={'absolute'}
                                                />
                                                );
                                            }}
                                        />
                                        {errors[`googleads_field_${mappingId}`] && <em className={`error invalid-feedback`}>{errors[`googleads_field_${mappingId}`].message}</em>}
                                    </div>
                                </Col>
                                <Col md={"4"}>
                                    <div className="faXMark">
                                        <Controller
                                            name={`default_value_${mappingId}`}
                                            control={control}
                                            render={({ field }) => (
                                                !mappingInfo[mappingId]?.isDropDown !== undefined && !mappingInfo[mappingId]?.isDropDown ? (
                                                    <input
                                                    {...field}
                                                    type="text"
                                                    name={`default_value_${mappingId}`}
                                                    id="campaign-name"
                                                    placeholder={`Default Value`}
                                                    value={mappingInfo[mappingId] && mappingInfo[mappingId][`defaultvalue`] !== undefined ? mappingInfo[mappingId][`defaultvalue`] : ""}
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        onLeadFieldChange(e.target.value, "defaultvalue", mappingId);
                                                        field.onChange(e);
                                                    }}
                                                    className="form-control"
                                                />
                                                ): (
                                                    <div className="w-100">
                                                    <Select value={mappingInfo[mappingId]['selectedOption']}
                                                        onChange={(e) => {
                                                            onHandleDropdownChange(e,mappingId);
                                                        }} 
                                                        options={mappingInfo[mappingId]['fieldOptions']}
                                                        className={`basic-multi-select`}
                                                        classNamePrefix="wa-select"
                                                        isMulti={true}
                                                        menuPlacement={"top"}
                                                    />
                                                    <Label className="font-bold pt-1 ps-2">Remove {mappingInfo[mappingId]['fieldselected']['label']} </Label>
                                                    </div>
                                                )
                                            )}
                                        />
                                        {clickedMapId === mappingId && suggestions && (
                                            <ul className="drop-down-list">
                                                {suggestions.map((suggestion, index) => (
                                                    <li key={index} onClick={(e) => handleSuggestionClick(e.target, "defaultvalue", mappingId)}>
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <i
                                            className={`x-mark-outside fi fi-rr-cross-small ml-2 input-variation-remove ${mappingCount === 1 ? "d-none" : ""}`}
                                            onClick={() => removeField(i, mappingId)}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    const removeField = (removedIndex,variationId) => {
        if(mappingInfo[variationId] != undefined && mandataryFields.includes("#"+mappingInfo[variationId][`fieldselected`]['value']+"#")){
            showToast(`This field cannot be removed.`,'top-right','error');
        }else{
            delete mappingInfo[variationId];
            delete mappingIds.splice(removedIndex, 1);
            setMappingCount(mappingCount - 1);
            setMappingIds([...mappingIds]);
            setMappingInfo([...mappingInfo]);
        }
    }
    const shouldShowSaveButton = !mappingneeded;
    const shouldShowForm = statusText === 'active' ? true : false;
    const shouldHideForm = !mappingneeded;
    // let sampleData = '"contact_name": "John","phone_number": "+1","lead_source": "Facebook","lead_stage": "New"';
    // const formattedData = sampleData.split(',').map((pair, index, array) => {
    //     const [key, value] = pair.split(':').map(str => str.trim());
    //     return (
    //       <div key={index} className="ps-4">
    //         <span>{key}</span>:<span>{value}</span>
    //         {index < array.length - 1 && ','}
    //       </div>
    //     );
    // });

    return(
        <Fragment>
            <div className="webhook-scroll"> 
                <div className={'cursor-pointer ps-4'} onClick={(e) => {onBackAction(e)}}>
                    <FontAwesomeIcon className={`phone-icon`} icon={faArrowLeft}/> Go Back
                </div>
                <Loading showLoader={showLoading} mainContent={
                    <>
                        <form  name={'googleads_mapping'} onSubmit={handleSubmit(onSave)}>
                            <div className={`mb-3 formname-column-name offset-1 col-8`}>
                                {shouldShowForm  &&
                                    <>
                                    <br></br>
                                        <Label className='text-header font-bold mb-0'>Add Contacts via Webhooks</Label>
                                            <p className="desc-text gen-description">Send an API request to the specified webhook URL to add or update contacts. If the contact doesn't exist, it will be added. If it already exists, its details will be updated.</p>
                                    </>
                                }
                                 <div className={`formname-column-name`}>
                                    <Col md={"10"}>
                                        <div className={`googleads-column-header`}>{`Webhook Name`}</div>
                                    </Col>
                                    <Controller
                                        name="webhook_name"
                                        control={control}
                                        rules={{
                                            required: webhookName !== undefined && webhookName !== null && webhookName !== "" ? false : "Webhook name is required.", 
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <div>
                                                <Input 
                                                    {...field}
                                                    className="googleads-column-header"
                                                    md="10"
                                                    type="text"
                                                    id="webhook_name"
                                                    autoComplete="off"
                                                    value={webhookName || ""} 
                                                    onChange={(e) => {
                                                        field.onChange(e); 
                                                        handleWebhookChange(e); 
                                                    }}
                                                />
                                                {error && (
                                                    <em className="error invalid-feedback">{error.message}</em>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>  
                                <Label className={`googleads-column-header`} for="webhookUrl">Webhook URL</Label>
                                <div className={`flex`}>
                                    <Col md={12} className=''>
                                        <div className="d-flex align-items-center">
                                            <InputGroup>
                                                <Input value={webhookdisplayUrl} className={`google-ads-webhook-url`} disabled={true} />
                                                <div className="input-group-text theme-bg-g">
                                                    <CopyToClipboard onCopy={copyUrl} text={webhookdisplayUrl}>
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </CopyToClipboard>
                                                </div>
                                            </InputGroup>
                                        </div>
                                        {copied && (
                                            <FormText color="success" className="mb-2 ">Text has been copied.</FormText>
                                        )}
                                    </Col>
                                </div>
                                <div className="hide">
                                    <Col md={"10"}>
                                        <div className={`googleads-column-header`}>{`Module Name`}</div>
                                    </Col>
                                    <Input value={"Contacts"} disabled={true}/>
                                </div>
                               
                            </div>
                            
                            {shouldShowForm && !shouldHideForm && (
                                <div>
                                    <Label className={`webhook-column-header`} for="webhookUrl">Manage Contact List & Tags</Label> 
                                    {listtagOptions.map((listTag, index) => (
                                        <div className={`ps-3 googleads-mapping-wrapper  "webhooks-mapping-wrapper"}`}>
                                            <Row className={'mt-2'} key={listTag.id}>
                                                <Col md={"11"}>
                                                    <Col md={"10"} className={`offset-1`}>
                                                        <Row>
                                                            <Col md={"4"}>
                                                                <div className="w-100">
                                                                    <Input
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="wa-select"
                                                                        menuPlacement="top"
                                                                        disabled={true}
                                                                        value={listTag.label}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={"4"}>
                                                                <div className="w-100">
                                                                    <Select
                                                                        onChange={(e) => onHandleDropdownChange(e, index, listTag.type, listTag.id)}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="wa-select"
                                                                        isMulti={true}
                                                                        menuPlacement="top"
                                                                        options={listTag.data}
                                                                        value={listTagInfo[index]?.[`${listTag.type}value`] || ""}
                                                                        placeholder={"––Select––"}
                                                                    />
                                                                </div>
                                                                <Label className="ps-2 pt-2">{listTag.labelAdd}</Label>
                                                            </Col>
                                                            <Col md={"4"}>
                                                                <div className="w-100">
                                                                    <Select
                                                                        onChange={(e) => onHandleDropdownChange(e, index, "", listTag.id)}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="wa-select"
                                                                        isMulti={true}
                                                                        menuPlacement="top"
                                                                        options={listTag.data}
                                                                        value={listTagInfo[index]?.defaultvalue || ""}
                                                                        placeholder={"––Select––"}
                                                                    />
                                                                </div>   
                                                                <Label className="ps-2 pt-2">{listTag.labelRemove}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {shouldShowForm && !shouldHideForm && (  
                                <div>
                                   {<Label className={`webhook-column-header`} for="webhookUrl">Map Contact Fields</Label>}
                                   {Object.keys(mappingList).length > 0 && (
                                    <div className={`ps-3 pb-2 mb-3 googleads-mapping-wrapper  "webhooks-mapping-wrapper"}`}>
                                        <Row className={'mt-2 mb-3'}>
                                            <Col md={"11"}>
                                                <Col md={"10"} className={`offset-1`}>
                                                    <Row>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-mapping-header`}>{`Contact Fields`}
                                                            <span className="ms-2 mt-1">
                                                            <TooltipItem id={"1"} text={"The mandatory fields will be listed by default only for the first time."} content={<i class="fi fi-rr-info"></i>} /></span></div>
                                                        </Col>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-mapping-header ps-4`}>{`Webhook Fields`}</div>
                                                        </Col>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-mapping-header ps-4`}>{`Fallback Value`}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>

                                        {mappingList.map((column) => {
                                            return column;
                                        })}
                                        <Col md={"10"}>
                                        <div className={'save-btn mt-2 mb-3'}>
                                            <div className={`webhooks-btn theme-bg-g custom-save mt-3 me-2`} onClick={addField} >
                                                <FontAwesomeIcon icon={faPlus} className={`mr-1`}/> Add New Field
                                            </div>
                                            <button type={'submit'} className={"webhooks-btn theme-bg-g"}>
                                                {`${shouldShowSaveButton ? 'Save' : 'Save Mapping'}`}
                                            </button>                                                    
                                        </div>

                                        </Col>
                                        
                                    </div>
                                )}
                                </div>
                            )}
                            <div className={`text-center webhooksave ${shouldShowSaveButton ? 'mt-5': "hide"}`}>
                            <Button  type={'submit'} className={"theme-bg-g"}>
                                {`${shouldShowSaveButton ? 'Save' : 'Save Mapping'}`}
                            </Button>
                            </div>
                            {/* <div className="hide mb-3 formname-column-name offset-1 col-12 mt-4">
                                <strong className="">Webhook Request Example Data</strong>
                                <div className="webhook-sample mt-2">
                                    <span className="ms-2">{"{"}</span>
                                        {formattedData}
                                    
                                    <span className="ms-2">{"}"}</span>
                                </div>
                                <div className="desc-text">
                                    Send your data in the specified format as a POST request to the webhook URL. 
                                    Ensure the Content-Type <br/>in your request is set to application/json.
                                </div>
                                <div className="mt-4">
                                    <Table>
                                        <td colSpan={2}>
                                            <tr className="sample-table-border">
                                                <td className="sample-table p-2 font-bold">Field</td>
                                                <td className="sample-table p-1 font-bold">Description</td>
                                            </tr>
                                            <tr>
                                                <td className="sample-table p-2">contact_name<span className="invalid-feedback ps-1">*</span></td><td className="p-2 sample-table">Full name of the contact. <span className="font-bold">string | required</span></td>
                                            </tr>
                                            <tr>
                                                <td className="sample-table p-2">phone_number<span className="invalid-feedback ps-1">*</span></td><td className="p-2 sample-table">Phone number of the contact with country extension. <span className="font-bold">string | required</span></td>
                                            </tr>
                                            <tr>
                                                <td className="sample-table p-2">any_custom_field_data</td><td className="p-2 sample-table">Contact attributes. <span className="font-bold">string | optional</span></td>
                                            </tr>
                                        </td>
                                    </Table>
                                </div>
                            </div> */}
                            
                        </form>
                    </> 
                }/>
            </div>
        </Fragment>

    );
}

export default FieldsMapping;