export const PropertyDataType = [
    { value: 'string', label: 'String' },
    { value: 'number', label: 'Number' },
    { value: 'boolean', label: 'Boolen' },
    { value: 'object', label: 'Object' },
    { value: 'array', label: 'Array' },
];

export const DefaultMessage = {
    startMessage: "It Start",
    successMessage: "It Sucess",
    failMessage: "It failed",
    delayMessage: "It Delay",
};

export const DropdownMenuOption = [
    {name:"Delete",action:'delete'},
    {name:"Duplicate",action:'duplicate'}
]
