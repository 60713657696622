import React, { Fragment, useEffect, useState } from "react";
import gCalendar from "../../assets/utils/images/Intagration image/google_calendar.svg"
import cx from "classnames";
import { Button, Row, Col } from "reactstrap";
import requestApiService from "../../services/requestApi.service";
import Tabs from "react-responsive-tabs";
import { confirmBox, loadConnectedIntegration } from "../../Common/Appcommon";
import { cardData } from "./Constants";
import ShowIntegrationInfo from "./ShowIntDetails.js";

const GoogleCalendar = (props) => {
    let { install, installedButton, buttonValue, name } = props;
    const [isConnected, setConnectionStatus] = useState(false);
    const [integrationId, setintegrationId] = useState('');
    const [carddata, setData] = useState(cardData);
    const [accountInfo, setAccountInfo] = useState([]);
    let key = '';
    let foundData = carddata.find((data) => data.name === "gcalendar");
    if (foundData) {
        key = foundData.component.button.key;
    }
    //let [integrationData, setIntegrationData] = useState([]);
    let integrationData = [];
    const [tabInfo, setTabInfo] = useState([]);
    useEffect(() => {
        (async () => {
            //await onInstallGoogle();
            if(install === true){
                onInstallGoogle();
            }
            await fetchGoogleAccountDetails();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [install]);

    const fetchGoogleAccountDetails = async () => {
        let integrationInfo = await loadConnectedIntegration(name);
        //alert(integrationInfo);
        if (integrationInfo.length > 0) {
            setConnectionStatus(true);
            let connectedInfo = integrationInfo[0][2];
            connectedInfo = JSON.parse(connectedInfo);
            let name = connectedInfo['name'];
            let email = connectedInfo['email'];
            let IntegrationAccountInfo = [
                { type: "id", id: integrationInfo[0][0] },
                { type: "text", label: "Name", value: name },
                { type: "text", label: "Email", value: email },
            ];
            setAccountInfo(IntegrationAccountInfo);
        } else {
            integrationData = [];
            setConnectionStatus(false);
            setTabInfo([]);
        }

    };


    const onInstallGoogle = async (e) => {
        let appHost = window.location.hostname;
        let protocol = window.location.protocol;
        let callback = protocol + "//" + appHost + "/#/oauth/callback";
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_OAUTH_URL",
            NAME: "GOOGLE_CALENDAR",
            CALLBACK: callback
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let authUrl = response.data;
            window.parent.location.href = authUrl;
        }
    }

    const removeIntegration = async (e, integrationId) => {
        let confirm = await confirmBox("Are you sure want to Uninstall " + integrationHeadData.name + "?", "Uninstalling the " + integrationHeadData.name + " app all actions linked to it will stop working.", 'warning', 'Uninstall', 'Cancel');
        if (!confirm) {
            return;
        }
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "REMOVE_INTEGRATION",
            ID: integrationId
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            installedButton();
            setintegrationId('');
            await fetchGoogleAccountDetails();
        }
    }

    /* const getSimpleTabs = () => {
        return tabInfo.map(({ name, biography }, index) => ({
            key: index,
            title: name,
            getContent: () => biography, // Render function if it's a component
        }));
    } */


    const integrationHeadData = {
        name: "Google Calendar",
        tag: "Calendar",
        image: gCalendar,
        installButtonText: "Install App",
        uninstallButtonText: "Uninstall App",
    };

    const integrationContent = [
        { heading: "Website", subheading: "https://calendar.google.com", type: "url" },
        { heading: "Overview", subheading: "The integration with Google Calendar simplifies scheduling for individuals and businesses by connecting advanced AI calling capabilities to Google’s reliable calendar platform. With this integration, users can automate appointment bookings, check real-time slot availability, and manage their schedules effortlessly.", type: "text" },
        { heading: "Key Benefits", 
            subheading: [
                { 
                    title: "AI-Powered Scheduling",
                    description: "Enable AI agents to book and reschedule appointments by checking your Google Calendar availability, reducing manual effort." 
                },
                { 
                    title: "Improved Accessibility", 
                    description: "Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time." 
                },
                { 
                    title: "Seamless Synchronization", 
                    description: "Automatically sync appointments with Google Calendar to keep your schedule up-to-date and eliminate double bookings." 
                },
        ], type: "list" }
    ];


    return (
        <Fragment>
            <div className="agent-details">
                <ShowIntegrationInfo
                    keyname={name}
                    headData={integrationHeadData}
                    integrationData={integrationContent}
                    isInstalled={buttonValue.includes(name)}
                    isConnected={isConnected}
                    accountInfo={accountInfo}
                    onInstall={onInstallGoogle}
                    onRemove={removeIntegration}
                />
            </div>
        </Fragment>
    );


}

export default GoogleCalendar;
