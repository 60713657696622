import React, { Fragment, useEffect, useState, useRef } from "react";
import { CSSTransition } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { barChartsList } from "./Constant";
import ListTable from "../../Common/ListTable";
import cloneDeep from 'lodash/cloneDeep';
import { useTheme } from "../../Context/ThemeContext";
import { useReactToPrint } from 'react-to-print';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { getDarkThemeColor } from "../../Common/Appcommon";
export default function DashboardGraph(props){
    const [chartOptionData, setChartOptionData] = useState({});  
    const [chartSeriesData, setChartSeriesData] = useState({});
    const [chartLabel, setChartLabels] = useState({});
    const [chartTypes, setChartTypes] = useState([]);
    const [tablesGraphDatas, setTablesGraphDatas] = useState([]);
    const [chartValues, setDashboardChart] = useState([]);
    const {subCurrency} = useTheme();
    const printRef = useRef(null);
    useEffect(() => {
        setChartOptionData({});
        setChartSeriesData({});
        setChartLabels({});
        setChartTypes([]);
        let dashboard = props.dashBoardData;
        dashboard.map((value, index) => {
            setDataForChart(value, barChartsList[index]);
        });
    }, [props.dashBoardData]);
    
    useEffect(() => {
        if (props.tablesGraphDatas !== tablesGraphDatas) {
            setTablesGraphDatas(props.tablesGraphDatas);
        }
    }, [props.tablesGraphDatas, tablesGraphDatas]);
    

    const setDataForChart = (data, chart) => {
        let {type, isHorizontal, colordistributed, tooltip} = chart;
        let isTooltipNeeded = false;
        let tooltipFormate = {};
        let yaxis = {};
        let label = data[3];
        let series = data[1];
        if(tooltip === 'time'){
            isTooltipNeeded = true
            tooltipFormate = {
                enabled: isTooltipNeeded,
                y: {
                    formatter: function (value) {
                        const hours = Math.floor(value / 3600);
                        const minutes = Math.floor((value % 3600) / 60);
                        const seconds = value % 60;
                        return `${hours}h ${minutes}m ${seconds}s`;
                    },
                },
            };
            yaxis = {
                labels: {
                    formatter: function (value) {
                        const hours = Math.floor(value / 3600);
                        const remainingSeconds = value % 3600;
                        const minutes = Math.floor(remainingSeconds / 60);

                        return `${hours}h ${minutes}m`;
                    },
                },
            }
        }else if(tooltip === 'dollar'){
            if(type === "total_spent"){
                tooltipFormate = {
                    enabled: true,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        // Determine the type of chart
                        const isHorizontalBarChart = w.config.chart.type === 'bar' && w.config.plotOptions.bar.horizontal;
                        const xAxisLabel = isHorizontalBarChart ? w.globals.labels[dataPointIndex] : w.globals.categoryLabels[dataPointIndex]; // For horizontal bar, tooltip shows y-axis labels // For line chart, use x-axis labels
                        
                        // Tooltip HTML
                        return (
                            `<div class="cus-tooltip-box">
                                <div class="cus-tooltip-header">
                                    <strong>${xAxisLabel}</strong>
                                    <span style="background-color: ${w.globals.colors[seriesIndex]}; width: 15px; height: 15px; display: inline-block; margin-left: 8px; border-radius:50%;"></span>
                                </div>
                                <div class="cus-tooltip-body">
                                    <span> Call Spent: ${subCurrency ?? '$'}${series[seriesIndex][dataPointIndex]} </span> <br>
                                    <span> Call Minutes: ${label[dataPointIndex][seriesIndex][1]} </span> <br>
                                    <span> Avg. Cost: ${subCurrency ?? '$'}${label[dataPointIndex][seriesIndex][0]} </span> <br>
                                </div>
                            </div>`
                        );
                    },
                };
            }/*else if(type === "goal_achieved"){
                tooltipFormate = {
                    enabled: true,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            `<div class="cus-tooltip-box">
                                <div class="cus-tooltip-header">
                                    <strong>${seriesIndex === 0 ? " Goal Complete" : "Goal Incomplete"}</strong>
                                    <span style="background-color: ${w.globals.colors[seriesIndex]}; width: 15px; height: 15px; display: inline-block; margin-left: 8px; border-radius:50%;"></span>
                                </div>
                                <div class="cus-tooltip-body">
                                    <span> ${seriesIndex === 0 ? "Complete" : "Incomplete"}: ${series[seriesIndex][dataPointIndex]}</span> <br>
                                    <span class='hide'> Per Goal Spent: $${label[dataPointIndex][seriesIndex]} </span>
                                </div>
                            </div>`
                        );
                    },
                };
            }*/
        }else if (tooltip === 'count') {
            if(type === "total_calls_by_source" || type === "goal_achieved" || type === "total_calls_by_status"){
                isTooltipNeeded = true;
                tooltipFormate = {
                    //shared: true, 
                    //intersect: true, 
                    isTooltipNeeded: true, 
                    y: {
                        formatter: function (value, { series, dataPointIndex, w }) {
                            let totalSum = 0;
                            series.forEach((seriesData) => {
                                totalSum += seriesData[dataPointIndex];
                            });
                            const percentage = ((value / totalSum) * 100).toFixed(2);
                            return `${value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} (${percentage}%)`;
                        },
                    }
                };
            } 
        }
    

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: isHorizontal,
                    distributed: colordistributed,
                    startingShape: "flat",
                    endingShape: "rounded",
                    dataLabels: {
                        position: 'bottom',
                        enabled: false,
                    },
                }
            },
            xaxis: {
                categories: data[0],
                labels: {
                    rotate: -45,
                    rotateAlways:true,
                    hideOverlappingLabels: true,
                },    
            },
            yaxis: yaxis,
            tooltip: tooltipFormate,
            colors: [getDarkThemeColor(), '#1e90ff', '#ff6347','#ffa500'],
            //colors: ['#DDFBE8', '#F5E8FF', '#FDE3E5','#FDF5DE']
        };
        
        setChartOptionData((prev) => ({ ...prev, [type]: options }));
        setChartSeriesData((prev) => ({ ...prev, [type]: series }));
        setChartLabels((prev) => ({ ...prev, [type]: chart.label }));
        setChartTypes((prev) => [...prev, type]);
    }
    
    const handleButtonClick = (index,graphtype) => {
        setDashboardChart((prev) => {
            const updatedValues = [...prev];
            updatedValues[index] = graphtype; // Set the type for the specific index
            return updatedValues;
        });
    };


const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent; // Replace page with printable content
    window.print();
    document.body.innerHTML = originalContent; // Restore the original page
    window.location.reload(); // Refresh to restore event listeners
};

const exportToPDF = () => {
    const input = printRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 190; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("dashboard.pdf");
    });
  };

    return(
        <Fragment>
            <div className="text-right mb-3 hide">
                <button color="primary" onClick={exportToPDF}>Print to PDF</button>
            </div>
            <div>
            <div ref={printRef} className="listview-container pb10 kv-main-wrapper h100per w-100">
                <CSSTransition component="div" classNames="TabsAnimation " appear={true}
                    timeout={0} enter={false} exit={false} >
                        <div className="summary-container">
                        <Row>
                            {chartTypes.map((type, index) => {
                                const label = chartLabel[type];
                                const options = cloneDeep(chartOptionData[type]); // parseJSON(JSON.stringify(chartOptionData[type])); // JSON.parse(JSON.stringify(chartOptionData[type]));// chartOptionData[type];
                                const series = cloneDeep(chartSeriesData[type]); // parseJSON(JSON.stringify(chartSeriesData[type])); // JSON.parse(JSON.stringify(chartSeriesData[type])); // chartSeriesData[type];
                                const currentType = chartValues[index] || "bar"; // Default to "bar" if not set

                                // Only render if options and series are defineds
                                if (!options || !series) return null;
                                return (
                                    <Col lg="6" className="call-log-charts" key={index}>
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md={8} className="d-flex align-items-center">
                                                        <span className="widget-subheading">{label}</span>
                                                    </Col>
                                                    <Col md={4} className="d-flex justify-content-end">
                                                        <Button key={`${index}_line`} className={currentType === "area" ? "btn-1 m-1 theme-bg-g" : "custom-btn-1 m-1"} color="dark" onClick={() => handleButtonClick(index, "area")}>
                                                            <div className="dashboard-chart-icon">
                                                                <i className="fi fi-br-chat-arrow-grow"></i>
                                                            </div>
                                                        </Button>
                                                        <Button key={`${index}_bar`} className={currentType === "bar" ? "btn-1 m-1 theme-bg-g" : "custom-btn-1 m-1"} color="dark" onClick={() => handleButtonClick(index, "bar")}>
                                                            <div className="dashboard-chart-icon">
                                                                <i className="fi fi-ss-chart-simple"></i>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <div className="bar mt-2">
                                                    <ReactApexChart
                                                        key={`${type}_${currentType}`} // Force re-render on type change
                                                        options={options}
                                                        series={series}
                                                        type={currentType}
                                                        width="100%"
                                                        height={450}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                );
                            })}
                                {tablesGraphDatas.map((val, index) => (
                                    <Col lg="6" className="call-log-charts" key={`table_${index}`}>
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <span className="widget-subheading">{val.label}</span>
                                                <div className="bar mt-2">
                                                    <ListTable isDashboard={true} data={val.data} columns={val.columns} />
                                                </div>
                                            </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>                           
                        </div>
                </CSSTransition>
            </div> 
            </div>
        </Fragment>
    )

}