import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import SplitCommon from '../Common/SplitCommon';
import { prepareLabelNameMapper, showToast,formatTime, getSessionValue, syncOperation, isValidData, stopAudio, checkMicrophonePermission, setSessionValue, translateContent, confirmBox, handleErrorResponse } from '../../Common/Appcommon';
import AIAgentsSideBar from './AgentsSideBar';
import { populateFormDetails, getTemplatesFormDetails, loadFormData,getRetelllToken} from '../../Common/Appcommon';
import AgentMainContent from './AgentMainContent';
import {providerConn,getProviderKey,updateBlandAgent,fetchPhNum,generateBlandAgent,getAssistantDetails, updateAgentInfo,fetchvoiceLib} from '../Agents/ProviderDetails';
import "./assets/index.css";
import AgentMainHeader from "./AgentMainHeader";
import requestApi from '../../services/requestApi.service';
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png"
import Select from "react-select";
import call_cancel from "./assets/images/call-cancel.svg";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Popover,Container} from "reactstrap";
import CustomTable from '../../Common/CustomTable';
import { PopupContext } from "./../../Common/WebCallContext";
import CreateAgentModal from './CreateAgentModal';
class Agents extends React.Component {
    formname = "table_250";
    subAppid = getSessionValue("subAccountAppid");
    isAllowNewConfig = getSessionValue('isAllowNewAgentConfig') === true || getSessionValue('isAllowNewAgentConfig') === "true" ? true : false;
    static contextType = PopupContext;
    constructor(props) {
        super(props);
        this.state = {
            formData : [],
            formDetails: [],
            templates: [],
            selectedTemplate: [],
            listFields: [],
            page: 1,
            limit: 50,
            rowData: {},
            nameLabelMapper: {},
            sideBarLoader : false,
            mainLoader : false,
            currentagentindex: 0,
            isNoDataFound : false,
            assistantId : "",
            popoverOpen: false,
            isCallReady: false,
            callStartTime:"",
            cDur :"",
            showLoading : false,
            providerCon :{},
            providerName : "",
            blandCon : "",
            agentname:"",
            isModalOpen: false, 
            voice_data : [],
            voice_column : [],
            selectedRow : [],
            defAccentDrop : {},
            accentDropList :{},
            defGenderDrop :{},
            genderDropList : {},
            defAgeDrop : {},
            ageDropList : {},
            accentFilter : "",
            ageFilter : "",
            genderFilter : "",
            selectVoiceRow : "",
            isAgentLoading :false,
            audioStates : {},
            isRefresh : false,
            agentList : {},
            newAgentModalOpen : false,
            curRowId : null,
            showAgentOptionLoading : false,
            knowledgeBase:[],
            agentVoiceId : [],
            selectedVoice:"",
        }
    }

    componentDidMount = async () => {
        let proName = getSessionValue("agent_provider_name");
        let proCon = "";
        //let apiKey = getProviderKey("retell");
        //let res = await fetchvoiceLib(apiKey);
        if(proName !== "bland"){
            proCon = await providerConn(proName);
        }
        
        this.setState({
            sideBarLoader: true,
            mainLoader : true,
            providerCon: proCon,
            providerName :proName,
        });
        await this.fetchVoiceList();
        await syncOperation("START_AGENT_SYNC");
        await this.fetchFormDetails();
        await this.fetchAIAgents();
        await this.loadKnowledgeBaseList();
        this.setState({
            sideBarLoader: false,
            mainLoader : false,
        });
        let blandCon = this.state.blandCon;
        if(proName.toLowerCase() === "vapi" && proCon !== ""){
            proCon.on('error', (e) => {
                clearInterval(this.state.intervalId);
                proCon.stop();
                this.setState({
                    showLoading : false,
                });
                this.setCallEndProcess();
                let error = e.error !== undefined && e.error !== null ? (e.error.blockedMedia !== undefined && e.error.blockedMedia !== null ? e.error.blockedMedia[0] + " " +e.error?.msg?.toLowerCase(): e.error?.message?.toLowerCase()) : "Unable to talk right now.";

                showToast("ERROR! "+error,'top-right','error');
            })

            proCon.on("call-end", () => {
                if(this.state.isCallReady){
                    this.setCallEndProcess();
                }
            });
            proCon.on("call-start", () => {
                this.callStartProcess();
            });
    
        }else if(proName.toLowerCase() === "retell" && proCon !== ""){
            proCon.on("error", (error) => {
                clearInterval(this.state.intervalId);
                this.setState({
                    showLoading : false,
                });
                showToast("ERROR! " +error,'top-right','error');
                proCon.stopCall();
                this.setCallEndProcess();
            });

            proCon.on("call_started", () => {
                this.callStartProcess();
              });

            proCon.on("call_ended", () => {
                this.setCallEndProcess();
            });
              
        }else if(proName.toLowerCase() === "bland" && blandCon !== ""){
            blandCon.on('conversationStarted', (e) => {
            });
            
            blandCon.on('speaking', () => {
            });
        
            blandCon.on('conversationEnded', () => {
                this.setCallEndProcess();
            });

            blandCon.on('error', (e) => {
                let error = e.error !== undefined && e.error !== null ? (e.error.blockedMedia !== undefined && e.error.blockedMedia !== null ? e.error.blockedMedia[0] + " " +e.error?.msg?.toLowerCase(): e.error?.message?.toLowerCase()) : "Unable to talk right now.";
                showToast(translateContent("vm.agents.convo.error"),'top-right','error');
                this.setState({
                    showLoading : false,
                });
                this.setCallEndProcess();
                console.error('An error occurred during the conversation:', error);
            });

        }
    };
   
    fetchFormDetails = async () => {
        let formDetail = await populateFormDetails(this.formname);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail);
        this.setState({
            formDetails: formDetail,
            templates, 
            selectedTemplate
        })
    };

    fetchAIAgents = async () => {
        let { formDetails,selectedTemplate,providerName,curRowId } = this.state;
        let adFilterSearch = "";
        if(providerName !== "" && providerName !== null ){
            adFilterSearch = "nondate@table_250@Provider@is@"+providerName+"@AND#";
        }
        let agentData = await loadFormData(this.formname, formDetails, selectedTemplate,"",adFilterSearch);
        if (agentData.issuccess) {
            let { formData, listFields } = agentData;
            let nameLabelMapper = await prepareLabelNameMapper(formDetails[1]);
            let rowData = formData && Array.isArray(formData) && formData.length > 0 
            ? formData.find(row => row.table_250_id === curRowId) || formData[0] 
            : {};
            let selectedVoice = rowData?.voice_id !== undefined ? rowData.voice_id : "";
            this.setState({
                formData, listFields, nameLabelMapper, rowData, isNoDataFound: Object.keys(rowData).length === 0,selectedVoice
            })
        }
    }

    onAgentChange = (agent, index) => {
        let selectedVoice = agent?.voice_id !== undefined ? agent.voice_id : "";
        this.setState({
            rowData : agent,
            currentagentindex: index,
            selectedVoice:selectedVoice,
        })
    }


    loadKnowledgeBaseList = async () => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "LOAD_KNB",
        }
        let response = await  requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            let Option = [];
            if(data.length > 0) {
                data.map((item, index) => { 
                    Option.push({value: item['id'], label: item['name'] });
                });
                this.setState({ knowledgeBase: Option || [] });
            }
        }
    }
    getSideBarTitleContent = (agentcount = 0) => {
        return(
            <>
                <span>Agents<span class="count">{agentcount}</span></span>
            </>
        )
    }

    handleDropDownChange = (value,option) => {
        if(value === "webcall"){
            this.startCall();
        }else{
            showToast("Coming Soon!");
        }
    };

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpen: !prevState.popoverOpen
        }));
    };


    callStartProcess = () => {
        const {setCurDuaration} = this.context
        let startTime = new Date();
        this.setState({ 
            isCallReady: true, 
            callStartTime: startTime, 
            cDur: "0:00",
            showLoading: false,
        }, async () => {
            this.togglePopover();
        });
        this.handlePopOver();
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        
        let intervalId = setInterval(() => {
            let currentTime = new Date();
            let duration = Math.floor((currentTime - startTime) / 1000);
            let formattedDuration = formatTime(duration);
            this.setState({ cDur: formattedDuration });
            setCurDuaration(formattedDuration); // Initialize call duration
        }, 1000);

        this.setState({ intervalId });
    }

    startCall = async () => {
        if(await checkMicrophonePermission()){
            this.setState({ showLoading: true });
            try{
                let {providerCon,providerName} = this.state;
                if(providerName !== "bland" && providerCon === ""){
                    showToast(translateContent("vm.agents.call.error"),'top-right','error');
                    return;
                }else if (this.state.isCallReady) {
                    showToast(translateContent("vm.agents.oncall.error"));
                    this.setState({showLoading:false});
                    return; 
                }
                let {rowData,nameLabelMapper} = this.state;
                let agentId = rowData[nameLabelMapper['Agent_ID']];
                if(providerName === "vapi"){
                    let pubKey = getProviderKey(this.state.providerName);
                    let agentDetails = await getAssistantDetails(pubKey,agentId,this.state.providerName);
                    if(agentDetails !== null){
                        this.initiateVapiCall(providerCon,agentId);
                    }else {
                        this.setState({ showLoading: false });
                    }
                }else if(providerName === "bland"){
                    let agId = rowData[this.formname + "_id"];
                    let apiKey = getProviderKey(this.state.providerName);
                    let num = await fetchPhNum(agId,this.formname);
                    if(typeof agentId !== "undefined" && agentId !== null && agentId !== ""){
                        agentId = await updateBlandAgent(apiKey,agentId,num,rowData,nameLabelMapper);
                    }else{
                        agentId = await generateBlandAgent(agId,apiKey,num,rowData,nameLabelMapper);
                    }
                
                    let proCon = await providerConn(providerName,agentId);
                    this.setState({
                        blandCon: proCon,
                    });
                    
                    if(proCon !== ""){
                        this.initiateBlandCall(proCon);
                    }else {
                        this.setState({ showLoading: false });
                        showToast(translateContent("vm.agents.connection.error"), 'top-right', 'error');
                    }
                }else if(providerName = "retell"){
                    let apiKey = getProviderKey(this.state.providerName);
                    let agentDetails = await getAssistantDetails(apiKey,agentId,this.state.providerName);
                    if(agentDetails != null){
                        this.initiateRetailsAiCall(providerCon,agentId);
                    }else {
                        this.setState({ showLoading: false });
                    }
                }
                this.setState({
                    agentname:rowData[nameLabelMapper['Agent_Name']]
                });
            }catch(error){
                this.setState({ showLoading: false });
            }
        }else{
            showToast(translateContent("vm.agents.permission.error"), 'top-right', 'error');
        }
       
    };


    initiateVapiCall = (proCon,agentId) =>{
        try{
            proCon.start(agentId);
            this.setState({
                showLoading: true,
            });
        }catch(error){
            this.setState(prevState => ({
                showLoading: false
            }));
            console.error("Error during Vapi call:", error.message || error);
        }
    } 

    initiateRetailsAiCall = async (proCon, agentId) => {
        try {
            let apiKey = getProviderKey(this.state.providerName);
            let res = await getRetelllToken(apiKey, agentId);
            if (res.issuccess) {
                let retailsDetails = res.data;
                let retellToken = retailsDetails['access_token'];
                this.retryWithBackoff(() => proCon.startCall({ accessToken: retellToken }));
            } else {
                this.setState({ showLoading: false });
                showToast(translateContent("vm.agents.token.error"), 'top-right', 'error');
            }
        } catch (error) {
            this.setState({ showLoading: false });
            console.error("Error during Retail AI call:", error.message || error);
        }
    }
    
    initiateBlandCall = (proCon) => {
        proCon.initConversation({
            sampleRate: 44100
        })
        .then(() => {
            this.callStartProcess();
        })
        .catch((error) => {
            console.error('Error initiating call:', error);
        });
    } 

    stopCall = () => {
        let {providerCon,providerName,blandCon} = this.state;
        if(providerName === "vapi"){
            providerCon.stop();
        }else if(providerName === "bland"){
            blandCon.stopConversation();
            this.setCallEndProcess();
        }else if(providerName === "retell"){
            providerCon.stopCall();
        }
    };

    setCallEndProcess = () => {
        clearInterval(this.state.intervalId);
        this.setState(
            { 
                isCallReady: false, 
                callStartTime: "", 
                cDur: "0:00",
                showLoading: false,
            }, async () => {
                this.togglePopover();
            }
        );
        this.handlePopOver(false);
        showToast("The call has ended.");

    }

    uploadVoice = async (data) =>{
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_VOICE_LIST",
            FORMINPUT: data,
            Provider : "retell",
            isOtherApiReq: true
        };
        return;
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }

    fetchVoiceList = async (filter = "") => {
        const { providerName } = this.state;
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_VOICE_PROVIDER",
            PROVIDER: providerName,
            FILTER: filter,
            isOtherApiReq: true
        };
    
        try {
            const response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                const data = response.data;
                if(data.length > 0){
                    const column = await this.constructVoiceData(data);
                    const [transformedVoiceData, accentDropList, genderDropList, ageDropList] = [
                        data[0].map((item) => ({ ...item, Icon: '' })),
                        this.createDropDownList(data[0], 'Accent'),
                        this.createDropDownList(data[0], 'Gender'),
                        this.createDropDownList(data[0], 'Age')
                    ];
        
                    const stateUpdates = {
                        voice_data: transformedVoiceData,
                        voice_column: column,
                    };
        
                    if (!filter) {
                        Object.assign(stateUpdates, {
                            defAccentDrop: accentDropList[0],
                            defGenderDrop: genderDropList[0],
                            defAgeDrop: ageDropList[0],
                            accentDropList,
                            genderDropList,
                            ageDropList,
                        });
                    }
        
                    this.setState(stateUpdates);
                }else {
                    this.setState({
                        voice_data: [],
                    });
                }
                
            }
        } catch (error) {
            console.error("Error fetching voice list:", error);
        }
        this.setState({
            isRefresh : true,
            selectVoiceRow : "",
        });
    };
    
   
    constructVoiceData = async (data) => {  
        const voice_column = [];
        const fields = data[1][0];  
        const aliases = data[1][1]; 
        voice_column.push({name: "", alias: "Icon"});
        fields.forEach((field, index) => {
            voice_column.push({
                name: field, 
                alias: aliases[index],
            });
        });
        voice_column.push({name: "", alias: "Url"});
        return voice_column; 
    };
    
    submitForm = async (data, type = "") => {
        let {nameLabelMapper, formData, currentagentindex,providerName,voice_data,isAgentLoading} = this.state;
        let isNameEmpty = (data) => !data?.["Agent Name"]?.trim();
        if (isNameEmpty(data)) {
            showToast(translateContent("vm.agents.name.requird"),"top-right", "error");
            return;
        }
        if(data["voice_id"] == "" || data["voice_id"] == undefined){
            showToast(translateContent("vm.agents.voice.requird"),"top-right", "error");
            return;
        }
        if (
            !data[nameLabelMapper['Call_Start_Message']] || 
            !data[nameLabelMapper['System_Prompt']]
        ) {
            if (!data[nameLabelMapper['Call_Start_Message']]) {
                showToast(translateContent("vm.agent.startmsg.error"), "top-right", "error");
            }
            
            if (!data[nameLabelMapper['System_Prompt']]) {
                showToast(translateContent("vm.agent.systemprompt.error"), "top-right", "error");
            }
        
            return;
        }
        
        
        this.setState({
            mainLoader : true,
        });
        let apiKey = getProviderKey(providerName);
        //let providerRes = await updateAgentInfo(apiKey,data,nameLabelMapper,providerName,this.formname,voice_data);
       // if(providerRes !== null){
            // if(providerName == "bland"){
            //     data[nameLabelMapper['Agent_ID']] = providerRes;
            // }
            const params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "SAVE_AGENT_DETAILS",
                FORMINPUT: data,
                nameLabelMapper: nameLabelMapper,
                PROVIDER : providerName,
                SUBACCOUNTID : this.subAppid,
                isProviderUpdate : this.isAllowNewConfig && type !== "adv_set",
                isOtherApiReq: true,
            };
            try {
                let response = await requestApi.processServerRequest(params);
                if (response.issuccess) {
                    formData[currentagentindex] = data;
                    await this.fetchAIAgents();
                    this.setState({
                        rowData: data,
                        formData: formData,
                        mainLoader : false,
                    })
                    showToast(translateContent("vm.update.success"))
                }else {
                    showToast(response.errormsg,"top-right", "error")
                }
            } catch (error) {
                showToast(translateContent("vm.agents.update.failed"))
                console.error("Error fetching plan pricing:", error);
            }
        // }else {
        //     showToast("Error! Agent Details Updated Failed.")
        // }
        this.setState({
            mainLoader : false,
        });
        
    }

    handleRowChange = (name, value) => {
        this.setState(prevState => {    
            const updatedRowData = {
                ...prevState.rowData,
                [name]: value,
            };    
            return {
                rowData: updatedRowData
            };
        });
    };

    getTitleContent = () => {
        return(
            <>
                <div className='d-flex'>
                    <div>
                        <span>Agents</span>
                    </div>
                    
                </div>
            </>
        )
    }
    getBodyContent = () => {
        return(
            <div>
                {this.isAllowNewConfig && (
                    <div className='app-header-right'>
                        <div>
                            <Button onClick={(e) => this.handleCreateAgentModal()} className="agent-list mb-2 cursor-pointer btn btn-secondary theme-bg-g" type="submit">
                                <span className="agent-create-btn-spn-1">
                                    <i class="fi fi-br-plus"></i>Add Agent
                                </span>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )}
    
    handleModal = async (voiceId = "") =>{
        if(!this.state.isModalOpen){
            await this.fetchVoiceList();
        }else{
            let {audioStates} = this.state;
            stopAudio(audioStates)
        }

        if(!this.state.voice_data.length > 0){
            showToast(translateContent("vm.agents.no.voices"),'top-right', 'error');
            return;
        }
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen,
            selectVoiceRow : "",
        }));
        if(voiceId !== ""){
            this.setState({
                agentVoiceId : voiceId,
            });
        }
    };

    toggle = () =>{
        this.handleModal();
    };

    setFilterValue = async (filed, data) => {
        let val = data.value;
        if(val == "All"){
            val = "";
        }
        this.setState({
            filed : val,
        });
    };

    onTemplateChange = async (data) => {
        if(await isValidData(data)){
            let val = data.value;
            const filters = {
                "Accent": "accentFilter",
                "Gender": "genderFilter",
                "Age": "ageFilter",
            };

            const dropDown = {
                "Accent": "defAccentDrop",
                "Gender": "defGenderDrop",
                "Age": "defAgeDrop",
            };
            if (val === "All") {
                val = "";
            }
            const filterKey = filters[data.name];
            const dropDownKey = dropDown[data.name];
            if (filterKey) {
                this.setState({ [filterKey]: val }, async () => {
                });
            }

            if (dropDownKey) {
                this.setState({ [dropDownKey]: data }, async () => {
                    await this.fetchFilVoice();
                });
            }
        }else{
            
        }        
    }

    fetchFilVoice = async () => {
        const { accentFilter, ageFilter, genderFilter } = this.state;
        const conditions = [
            accentFilter && `Accent = '${accentFilter}'`,
            ageFilter && `Age = '${ageFilter}'`,
            genderFilter && `Gender = '${genderFilter}'`
        ].filter(Boolean);
    
        const cond = conditions.join(" and ");
        await this.fetchVoiceList(cond);
    };

    createDropDownList = (data, key) => {
        const uniqueValues = Array.from(new Set(data.map(item => item[key])));
        const dropDownList = [{ value: "All", label: 'All' ,name : key}];
        uniqueValues.forEach(value => {
            dropDownList.push({ value, label: value,name : key });
        });
        return dropDownList;
    };

    selectVoiceRow = (row) => {
        this.setState({
            selectVoiceRow : row,
            isRefresh : false,
        });
    }

    saveSelectVoice = () => {
        let { rowData, nameLabelMapper, selectVoiceRow,voice_data } = this.state;
        if(selectVoiceRow === "" && voice_data.length > 0){
            showToast(translateContent("vm.agents.select.voice"));
            return;
        }
        let voiceId = selectVoiceRow['Voice_Id'];
        // const updatedRowData = { ...rowData, [nameLabelMapper['voice_id']]: voiceId };
        this.setState({
            selectedVoice: voiceId,
        });
        this.handleModal();
    }

    handlePopOver = (isCall = true) =>{
        const { setIsCallReady, setCurDuaration,setPopoverOpen,setAgentname,setStopCall,setToggleFun} = this.context; // Access context setters
        setPopoverOpen(isCall);
        setIsCallReady(isCall); 
        setAgentname(this.state.agentname);
        setToggleFun(() => this.togglePopover);
        setStopCall(() => this.stopCall);
        setCurDuaration("0:00"); // Initialize call duration
    }

    retryWithBackoff = async (fn, retries = 3, delay = 1000) => {
        for (let i = 0; i < retries; i++) {
            try {
                return await fn();
            } catch (err) {
                if (i === retries - 1) throw err;
                console.warn(`Retrying in ${delay}ms...`);
                await new Promise(res => setTimeout(res, delay));
                delay *= 2; // Exponential backoff
            }
        }
    };

    handleCreateAgentModal = () =>{
        let apiDetails = getSessionValue("agent_api_details");
        if(apiDetails == "" || apiDetails == null || apiDetails == undefined ||  apiDetails == "null"){
            handleErrorResponse(translateContent("vm.agent.provider.empty"));
            return;
        }
        this.setState(prevState => ({
            newAgentModalOpen: !prevState.newAgentModalOpen
        }));
    }

    saveAgentInfo = async (data,isblank = false) => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "CREATE_AGENT_DETAILS",
            FORMINPUT: data,
            PROVIDER : this.state.providerName,
            SUBACCOUNTID : this.subAppid,
            isOtherApiReq: true,
            ISBLANK : isblank,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                await this.fetchAIAgents();
                this.setState({
                    curRowId: data[0],
                })
                showToast(translateContent("vm.agents.create.succuss"))
            }else {
                showToast(translateContent("vm.agents.create.failed",{errorMsg: response.errormsg}),"top-right", "error")
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }finally{
            this.handleCreateAgentModal();
        }
    }

    openCreateAgentModal = (rowData) =>{
        return (
            <CreateAgentModal 
                isOpen = {this.state.newAgentModalOpen}
                handleSave = {this.saveAgentInfo}
                handleToggle = {this.handleCreateAgentModal}
                blankData = {""}
                providerName = {this.state.providerName}
            /> 
        );
    }

    handleAgentOptionChange = (name,value) => {
        this.setState({
            mainLoader : true,
        })
        let {rowData,nameLabelMapper,providerName} = this.state;
        let agentId = rowData[nameLabelMapper['Agent_ID']];
        if(value == "delete"){
            this.handleDeleteAgent(agentId,providerName);
        }else{
            this.handleCloneAgent(agentId,providerName);
        }
    }

    handleDeleteAgent = async (agentId,provider) =>{
        let {rowData,nameLabelMapper} = this.state;
        let agentName = rowData[nameLabelMapper['Agent_Name']];
        let confirm = await confirmBox("Are you sure want to Delete " + agentName + " Agent ?", '', 'warning', 'Delete', 'Cancel');
        if (!confirm) {
            return;
        }
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "DELETE_AGENT_INFO",
            AGENT_ID : agentId,
            PROVIDER : provider,
            isOtherApiReq: true
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                showToast(translateContent("vm.agent.delete.success"));
                await this.fetchAIAgents();
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
        this.setState({
            mainLoader : false,
        })
    }

    handleCloneAgent = async (agentId,provider) =>{
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "CLONE_AGENT_INFO",
            AGENT_ID : agentId,
            PROVIDER : provider,
            isOtherApiReq: true
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                await this.fetchAIAgents();
                showToast(translateContent("vm.agents.clone.succuss"))
            }else {
                showToast(translateContent("vm.agents.clone.failed",{cloneFailedError: response.errormsg}),"top-right", "error")
            }
        } catch (error) {
            console.error("Error agent Clone:", error);
        }
        this.setState({
            mainLoader : false,
        })
    }

    getModalContent = () => {
        const {
            isModalOpen,
            voice_data,
            voice_column,
            accentDropList,
            defAccentDrop,
            genderDropList,
            defGenderDrop,
            ageDropList,
            defAgeDrop,
            isRefresh,
            agentVoiceId
        } = this.state;
        const dropdowns = [
            { name: "Accent", list: accentDropList, default: defAccentDrop },
            { name: "Gender", list: genderDropList, default: defGenderDrop },
            { name: "Age", list: ageDropList, default: defAgeDrop }
        ];
    
        return (
            <Modal isOpen={isModalOpen} contentClassName="voice-model-wid">
                <ModalHeader toggle={this.toggle}>
                    <span>Voice</span>
                    <span className='vm-voice-cnt'>{voice_data.length}</span>
                </ModalHeader>
                <ModalBody className="voice-modal-bdy">
                    <div className="d-flex-alone voice-drop">
                        {dropdowns.map(({ name, list, default: defaultVal }) => (
                            <div key={name} className="voice-drop-wid">
                                <Select
                                    placeholder={`---Choose ${name}---`}
                                    name={`${name.toLowerCase()}-fill`}
                                    className="basic-select"
                                    classNamePrefix="sub-select wa-select"
                                    value={defaultVal || null} // Fallback to null if no default value
                                    onChange={this.onTemplateChange}
                                    options={list && Object.keys(list).length ? list : []} // Fallback to empty array if list is empty
                                    isDisabled={!list || !Object.keys(list).length} // Disable if list is empty
                                    getOptionLabel={({ label }) => label}
                                    formatOptionLabel={({ value, label }, { context }) =>
                                        context === "value" ? (
                                            <div>{`${name} : ${label}`}</div> // Show "Accent: All" only for the selected value
                                        ) : (
                                            <div>{label}</div> // Show just the label in the dropdown list
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <CustomTable
                        agentVoiceId = {agentVoiceId}
                        data={voice_data}
                        columns={voice_column}
                        isSelect={true}
                        selectRow = {this.selectVoiceRow}
                        handleAudio = {this.setAudioStates}
                        isRefresh = {isRefresh}
                    />
                </ModalBody>
                <ModalFooter className ={"custom-modal-footer"}>
                    <Button
                        className="btn btn-secondary theme-bg-g"
                        onClick={(e) => this.saveSelectVoice()}
                    >
                        Select Voice
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    setAudioStates = (audio) => {
        this.setState({
            audioStates :audio,
        });
    } 
    render() {
        let {formData, rowData, sideBarLoader, mainLoader, nameLabelMapper, isNoDataFound,cDur,popoverOpen,isCallReady,showLoading,isModalOpen,voice_data,isAgentLoading,newAgentModalOpen,providerName,knowledgeBase,selectedVoice} = this.state;
        let agentcount = formData && formData.length ? formData.length : 0;
        if(isNoDataFound && Object.keys(rowData).length == 0){
            return (
                <>
                    <NoRecordFound 
                        title="AI Agents"
                        titleContent={this.getTitleContent()}
                        titleBody = {this.getBodyContent()}
                        img={aiData}
                        content={"No AI Agent Found"}
                    />
                    {newAgentModalOpen &&  this.openCreateAgentModal()}
                </>
                
            )
        }
        return (
            <Fragment>
                <SplitCommon
                    mobileViewNeedLeftMenu={true}
                    sideBarLoader = {sideBarLoader}
                    mainLoader = {mainLoader}
                    title="AI Agents"
                    sideBarTitleContent={
                        this.getSideBarTitleContent(agentcount)
                    }
                    mainTitleContent={
                        <>
                            {
                                Object.keys(rowData).length > 0 && <AgentMainHeader
                                    rowData={rowData}
                                    fieldNames={nameLabelMapper}
                                    handleDropDownChange={this.handleDropDownChange}
                                    handleAgentOptionChange = {this.handleAgentOptionChange}
                                    isLoading = {showLoading}
                                    showAgentLoading = {this.state.showAgentOptionLoading}
                                />
                            }
                        </>
                    }
                    sideBarContent={
                        <Container fluid>
                            {
                                agentcount > 0 && Object.keys(rowData).length > 0 && 
                                <AIAgentsSideBar
                                    formname={this.formname}
                                    formData={formData}
                                    curRowId={rowData[this.formname+"_id"]}
                                    onAgentChange={this.onAgentChange}
                                    fieldNames={nameLabelMapper}
                                    handleCreateModal = {this.handleCreateAgentModal}
                                    allowNewConfig = {this.isAllowNewConfig}
                                />
                            }
                        </Container>
                    }
                    mainContent={
                        <Fragment>
                            {
                                Object.keys(rowData).length > 0 &&
                                <>
                                    <AgentMainContent 
                                        rowData={rowData} 
                                        onChange={this.handleRowChange}
                                        onSubmit={this.submitForm} 
                                        fieldNames={nameLabelMapper}
                                        modalOpen = {this.handleModal}
                                        voiceList = {voice_data}
                                        isLoading = {isAgentLoading}
                                        providerName = {providerName}
                                        knowledgeBase= {knowledgeBase}
                                        allowNewConfig = {this.isAllowNewConfig}
                                        selectedVoice = {selectedVoice}
                                    />
                                    {isModalOpen && this.getModalContent()}
                                    {newAgentModalOpen &&  this.openCreateAgentModal(rowData)}
                                </>
                            }
                                {/* <div className='app-footer-right agent-footer'>
                                    <div id="WebCall"></div>
                                        {isCallReady && (
                                            <Popover
                                                className="rm-max-width"
                                                innerClassName="agent-popover"
                                                placement="bottom-start"
                                                fade={false}
                                                hideArrow
                                                isOpen={popoverOpen}
                                                target="WebCall"
                                                toggle={this.togglePopover}
                                            >
                                                <div className='agent-popover-heading'>
                                                    <span className='agents-call-details'>
                                                        <span className="agent-call-icon"><i class="fi fi-ss-user-headset"></i></span> 
                                                        {this.state.agentname}
                                                    </span>
                                                    <span>{cDur|| "0:00"}</span>
                                                </div>
                                                <div className='agent-popover-footer'>
                                                    <img className="agent-cancel-call" src={call_cancel} alt="Call Cancel" onClick={this.stopCall}/>
                                                </div>
                                            </Popover>
                                        )}
                                        
                                </div> */}
                        </Fragment>
                    }                           
                />
        </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
