import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Switch from "react-switch";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Common from '../Common';
import {
    Row,
    Col,
    Container,
    Button,
    Card,
    CardBody,
    InputGroup
} from "reactstrap";

import "./assets/css/index.css"
import CratioFileUploader from '../Common/CratioFileUploader';
import { faPlus, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { getLinkData, handleErrorResponse, setLinkData, showToast, updateOrganizationInfo, uploadFileIntoS3, confirmBox, parseJSON, setSessionValue, getSessionValue, updateAgencyInfo,checkIsAdmin, translateContent,getDeleteIcon } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import { PROTOCOL_LISTS, CONFIGURE_PROTOCOL_LISTS } from '../Dashboard/Constant';
import Testsmtp from '../Integrations/TestSMTPConnection'; 
import { useTranslation } from "react-i18next";
import "../../i18n"; // Ensure i18n is initialized
import TooltipItem from '../../Common/ToolTip';


const General = (props) => {
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors }, trigger } = useForm();
    let type = props.type;
    const saveBtnRef = useRef(null);
    const [pageTitle, setPageTitle] = useState(translateContent("brand_info"));
    const [formData, setFormData] = useState({});
    const [verified, setVerified] = useState(false);
    const [verifyStatus, setVerifyStatus] = useState(false);
    //let [linkData, setLinkData] = useState([]);
    let [variationList, setVariationsList] = useState([]);
    let [variationCount, setVariationCount] = useState(0);
    const [showLoading, setShowLoading] = useState(false);
    const [isCustomSMTP, setIsCustomSMTP] = useState(false);
    const [isSMTPConfigured, setIsSMTPConfigured] = useState(false);
    const [showtestModal,setTestModal] = useState(false);
    const maxVariactionCount = 4;
    const [clickEdit,setclickEdit] = useState(false);
    const [selectedOption, setSelectedOption] = useState(CONFIGURE_PROTOCOL_LISTS[0]); // Default to AWS
    const [isAdmin ,setAdmin] = useState(true);
    const { i18n } = useTranslation();

    const switchLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
    useEffect(() => {
        (async () => {
            await fetchAgencyDetails();
            await loadSenderData();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        onAddMoreVariants(variationCount);
        return () => {
            // this now gets called when the component unmounts
        };
    }, [variationCount]);

    useEffect(() => {
        if(type === "info"){
            setPageTitle(translateContent("brand_info"));
        } else if(type === "smtp-setup"){
            setPageTitle("Email SMTP Setup");
        } else if(type === "support-links"){
            setPageTitle("Support Links");
        } else if(type === "billing-info"){
            setPageTitle("Billing Information");
        }
        return () => {
            // this now gets called when the component unmounts
        };
    }, [type]);

    const clearFile = (name) => {
        formData[name] = "";
    }
    const fetchAgencyDetails = async () => {
        let adminProfile = await checkIsAdmin();
        setAdmin(adminProfile);
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_AGENCY_DETAILS",
        }
        setShowLoading(true);
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let data = formresponse.data;
            let helpInfo = data['help_info'];
            if (helpInfo !== "" && helpInfo !== null && helpInfo.length > 0) {
                let linkData = helpInfo;
                setLinkData(linkData);
                setVariationCount(helpInfo.length);
                //setLinkData([...helpInfo]);
            } else {
                setVariationCount(1);
            }
            if (data.isCustomSMTP !== undefined && data.isCustomSMTP) {
                 setIsCustomSMTP(true);
                 setIsSMTPConfigured(true);
                 setSelectedOption(CONFIGURE_PROTOCOL_LISTS[1]);
            }
            if (data.protocol !== undefined && data.protocol !== "") {
                data['smtp_protocol'] = { value: data.protocol, label: data.protocol.toUpperCase() };
            } else {
                data['smtp_protocol'] = { value: "ssl", label: "SSL" };
            }
            setFormData(data);
        }
        setShowLoading(false);
    }

    const submitAgencyDetails = async () => {
        const isValidEmail = verified;
        if (!isValidEmail && !isCustomSMTP) {
            handleErrorResponse(translateContent("vm.agency.email.smtp.error"));
            return false;
        }
        await trigger('agent_email');
        let helpInfo = getLinkData();
        let uhelpInfo = [];
        for (let hi = 0; hi < helpInfo.length; hi++) {
            let helpName = helpInfo[hi]['help-name-' + hi];
            let helpLink = helpInfo[hi]['help-link-' + hi];
            if (helpName !== "" && helpName !== undefined && helpLink !== "" && helpLink !== undefined) {
                let newHelpInfo = {};
                newHelpInfo['help-name-' + uhelpInfo.length] = helpName;
                newHelpInfo['help-link-' + uhelpInfo.length] = helpLink;
                uhelpInfo.push(newHelpInfo);
            } 
            if(helpLink === undefined || helpLink === null || !helpLink.includes(".") || helpLink.startsWith(".")){
                handleErrorResponse(translateContent("vm.agency.link.error"));
                return;
            }else{
                const domainArr = helpLink.split(".");
                if (domainArr.length < 2 || (domainArr[1] === undefined || domainArr[1] === null || domainArr[1] === "")) {
                    handleErrorResponse(translateContent("vm.agency.link.error"));
                    return;
                }
            }
            if((helpLink !== undefined && helpLink !== "") && (helpName === undefined || helpName === "")){
                handleErrorResponse(translateContent("vm.agency.supportname.error"));
                return;
            }
        }
        formData['help_info'] = uhelpInfo;
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_AGENCY_DETAILS",
            "formData": formData,
            "isCustomSMTP": isCustomSMTP
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let orgDetail = formresponse.data;
            updateOrganizationInfo(orgDetail);
            let agencyDetails = [];
            agencyDetails.push(orgDetail);
            updateAgencyInfo(agencyDetails);
            showToast(translateContent("vm.agency.save.success"));
            window.location.reload();
        }
    }

    const removeVariations = async (removedIndex) => {
        delete variationList.splice(removedIndex, 1);
        let tmpLinkData = getLinkData();
        let tmpArray = [];
        let newindex = -1;
        for (let i = 0; i < tmpLinkData.length; i++) {
            let data = tmpLinkData[i];
            if (i !== removedIndex) {
                newindex = newindex + 1;
                let newdata = {};
                for (let key in data) {
                    if (key.indexOf('help-name') > -1) {
                        newdata['help-name-' + newindex] = data[key];
                    } else if (key.indexOf('help-link') > -1) {
                        newdata['help-link-' + newindex] = data[key];
                    }
                }
                tmpArray.push(newdata);
            }
        }
        setLinkData(tmpArray)
        setVariationCount(prevCount => prevCount - 1);
        setVariationsList(variationList);
    }

    const addVariation = () => {
        if (variationCount <= maxVariactionCount) {
            setVariationCount(prevCount => prevCount + 1);
            onAddMoreVariants(variationCount + 1);
        }
    };

    const onAddMoreVariants = (variationCount) => {
        if (variationCount <= maxVariactionCount) {
            let data = getLinkData();
            let vl = [];
            for (let vi = 0; vi < variationCount; vi++) {
                let nl = "help-name-" + vi;
                let ll = "help-link-" + vi;
                vl.push(
                    <div className='d-flex align-center'>
                        <Col md={6}>
                            <input type="text"
                                defaultValue={data[vi] ? data[vi][nl] : ''}
                                name={nl}
                                onChange={(e) => {
                                    onLinkInputChange(e, vi);
                                }}
                                className="form-control"
                                key={vi} 
                                disabled={isAdmin ? false : true}
                                maxLength={40}
                            />
                        </Col>
                        <Col md={6} className='d-flex align-center'>
                            <input type="text"
                                defaultValue={data[vi] ? data[vi][ll] : ''}
                                name={ll}
                                onChange={(e) => {
                                    onLinkInputChange(e, vi);
                                }}
                                className="form-control"
                                key={vi} 
                                disabled={isAdmin ? false : true}
                                />
                            {
                                variationCount > 1 && <a className={`${isAdmin ? 'cursor-pointer ml-2' : 'hide'}`} onClick={() => removeVariations(vi)}>
                                    <FontAwesomeIcon icon={faWindowClose} fontSize={2} className={`mr-1 theme-c-g`} />
                                </a>
                            }
                        </Col>
                    </div>
                )
            }
            setVariationsList(vl);
        }
    }

    const onLinkInputChange = async (e, vi) => {
        let linkData = getLinkData();
        const { name, value } = e.target;
        if (linkData[vi] === undefined) {
            linkData[vi] = {};
        }
        linkData[vi][name] = value;
        setLinkData(linkData);
    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const onSaveBtnClick = () => {
        saveBtnRef.current.click();
    }

    const handleFileUpload = async (e, name) => {
        let { formInputData } = await uploadFileIntoS3(e, formData, name);
        setFormData(formInputData);
    };

    const verifyEmail = async () => {
        const isValidEmail = await trigger('sender_email');
        if (!isValidEmail) {
            return;
        }
        let fromName = formData.sender_name;
        let fromAddress = formData.sender_email;
        if (fromName !== undefined && fromName !== "" && fromAddress !== undefined && fromAddress !== "") {
            let params = {
                "ENTITY": "SenderAddress",
                "OPERATION": "SAVE",
                "fromaddress": fromAddress,
                "fromname": fromName,
            };
            let response = await requestApiService.processServerRequest(params);
            if (response.issuccess) {
                setVerified(true);
                showToast(translateContent("vm.agency.email.send.success"))
            } else {
                handleErrorResponse(translateContent("vm.agency.general.error"));
            }
        } else {
            handleErrorResponse(translateContent("vm.agency.email.smtp.error"))
        }
    }
    const resendEmailVerification = async () => {
        if (formData['status'] === "Verified") {
            showToast(translateContent("vm.agency.email.smtp.success"), "", "error")
            return
        };
        let fromAddress = formData.sender_email;
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "RESEND",
            "fromaddress": fromAddress,
        }
        let response = await requestApiService.processServerRequest(params);
        if (!response.issuccess) {
            handleErrorResponse(translateContent("vm.agency.general.error"), "", "error");
        } else {
            showToast(translateContent("vm.agency.email.resent.success"));
        }

    }

    const deleteVerifiedEmail = async () => {

        let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"), getDeleteIcon(), 'Delete', 'Cancel');
        if (!confirm) {
            return;
        }
        let fromAddress = formData.sender_email;
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "DELETE",
            "fromaddress": fromAddress,
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            showToast(translateContent("vm.agency.email.remove.msg"));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            showToast(translateContent("vm.agency.general.error"), "", "error");
        }
    }

    const loadSenderData = async () => {
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "GETALL",
            "isWhatsapp": true
        };
        setShowLoading(true);
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            if (data.length > 0 && data[0][0] !== undefined) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    sender_name: data[0][0]['fromname'],
                    sender_email: data[0][0]['fromaddress']
                }));
                if (data[0][0]['status'] !== undefined && data[0][0]['status'] === "Verified") {
                    setVerifyStatus(true);
                }
                setVerified(true);
            }
        }
        setShowLoading(false);
    }

    const showSMPTSettings = (selectedOption) => {
        setSelectedOption(selectedOption);
        //setclickEdit(false);
        setIsCustomSMTP(selectedOption.value === "custom"); // Update isSMTPConfigured based on selection
        //setIsSMTPConfigured(selectedOption.value === "custom");
    };
    

    const handleSelectChange = (name, option) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: option,
        }));
    };

    const handleBtnClick = () => {
        setIsSMTPConfigured(!isSMTPConfigured)
        setclickEdit(!clickEdit)    
    }

    const ckeckConnection = () =>{
        setTestModal((prevState) => !prevState);
    }

    /* const handleChange = (selectedOption) => {
        setIsCustomSMTP(selectedOption.value === "custom");
        showSMPTSettings(); // Call your function when value changes
    }; */

    return (
        <Fragment>
            <Common title={pageTitle} titleContent={
                <div className='d-grid'>
                    <span>{pageTitle}</span>

                </div>
            }
                titleBody={
                    isAdmin && (
                        <Fragment>
                            {showLoading? (
                                <></>
                            ) : (
                                <Button onClick={(e) => onSaveBtnClick(e)} className="btn-hover-shine theme-bg-g custom-display" color="light"><i className="fi fi-br-disk"></i> <span>Save & Publish</span></Button>
                            )}
                        </Fragment>
                    )
                }
                mainContent={
                    <Container className='max-width-65'>
                        {showLoading ? (
                            <Loading showLoader={showLoading} />
                        ) : (
                            <form id="agency-form" name={'agency'} onSubmit={handleActionFormSubmit((data, event) => submitAgencyDetails(event))}>
                                {type=== "info" && <div className='agency-details'>
                                    <div className='card-fields pb-3 hide'>
                                        <span class="heading layout-title" >Agency Profile</span>
                                        <span className='header-desc'>Manage your agency information</span>
                                    </div>
                                    <div className='field-details'>
                                        <div className='hide'>
                                            <a className="cursor-pointer text-d-underline font-bold theme-c-link" onClick={() => switchLanguage("en")}>English</a>
                                            <a className="cursor-pointer text-d-underline font-bold theme-c-link" onClick={() => switchLanguage("es")}>Español</a>
                                        </div>
                                        <Card className='card-br'>
                                            <CardBody className='pb-6'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >{translateContent("primary_title")}</span>
                                                    <span className='header-desc'>{translateContent("primary_desc")}</span>
                                                </div>
                                                <Row>
                                                    <Col md={10} className='field-section'>
                                                        <label className={'form-label'} htmlFor="agent_name">Contact Name</label>
                                                        <Controller
                                                            name="agent_name"
                                                            control={control}
                                                            rules={{ required: formData.agent_name === undefined ? "Please provide Name" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="agent_name"
                                                                        id="agent_name"
                                                                        value={formData.agent_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.agent_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.agent_name && <em className={`error invalid-feedback`}>{errors.agent_name.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2 hide'>
                                                        <label className={'form-label'} htmlFor="agent_email">Email Address</label>
                                                        <Controller
                                                            name="agent_email"
                                                            control={control}
                                                            rules={{ required: formData.agent_email === undefined ? "Please provide Email Address" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="email"
                                                                        name="agent_email"
                                                                        id="agent_email"
                                                                        value={formData.agent_email}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.agent_email ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.agent_email && <em className={`error invalid-feedback`}>{errors.agent_email.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="agent_mobile">Phone Number (with country code)</label>
                                                        <Controller
                                                            name="agent_mobile"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="agent_mobile"
                                                                        id="agent_mobile"
                                                                        maxLength={14}
                                                                        value={formData.agent_mobile}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            if (/^\+?\d*$/.test(value)) {
                                                                                onInputChange(e);
                                                                                field.onChange(e);
                                                                            }
                                                                        }}
                                                                        className={`form-control ${errors.agent_mobile ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.agent_mobile && <em className={`error invalid-feedback`}>{errors.agent_mobile.message}</em>}
                                                    </Col>

                                                </Row>
                                            </CardBody>
                                        </Card>


                                    </div>
                                </div> 
                                }
                                {type === "info" && <div className='agency-details'>

                                    <div className='field-details'>
                                        <Card className='card-br'>
                                            <CardBody className='pb-6'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >Brand Information</span>
                                                    <span className='header-desc'>Enables you to rebrand the app with your own brand name.</span>
                                                </div>
                                                <Row>
                                                    <Col md={10} className='field-section'>
                                                        <label className={'form-label'} htmlFor="brand_name">Brand Name</label>
                                                        <Controller
                                                            name="brand_name"
                                                            control={control}
                                                            rules={{ required: formData.brand_name === undefined ? "Please provide Name" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="brand_name"
                                                                        id="brand_name"
                                                                        value={formData.brand_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.brand_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.brand_name && <em className={`error invalid-feedback`}>{errors.brand_name.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section'>
                                                        <label className={'form-label'} htmlFor="brand_website">Brand Website</label>
                                                        <Controller
                                                            name="brand_website"
                                                            control={control}
                                                            rules={{ required: formData.brand_website === undefined ? "Please provide Website" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="brand_website"
                                                                        id="brand_website"
                                                                        value={formData.brand_website}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.brand_website ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.brand_website && <em className={`error invalid-feedback`}>{errors.brand_website.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2'>
                                                        <InputGroup>
                                                            <label className={'form-label'} htmlFor="brand_logo">Brand Logo (Dark)</label>
                                                            <div className='pl-2 pt-1'>
                                                                <TooltipItem id={"1"} text={"Logo to be displayed when dark theme is selected"} content={<i class="fi fi-ss-info"></i>} />
                                                            </div>
                                                        </InputGroup>
                                                        <CratioFileUploader name="brand_logo" comWidth={196} comHeight={28} fileName={formData['brand_logo'] !== undefined && formData['brand_logo'] !== null ? formData['brand_logo']['fileName'] : ""} mediaUrl={formData['brand_logo'] !== undefined && formData['brand_logo'] !== null ? formData['brand_logo']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} clearFile={clearFile} isAdmin={isAdmin}/>
                                                        <span className='field-desc gen-description'>Recommended size: 196px (width) x 28px (height), 7:1 aspect ratio</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2'>
                                                        <InputGroup>
                                                            <label className={'form-label'} htmlFor="brand_logo">Brand Logo (Light)</label>
                                                            <div className='pl-2 pt-1'>
                                                                <TooltipItem id={"2"} text={"Logo to be displayed when light theme is selected"} content={<i class="fi fi-ss-info"></i>} />
                                                            </div>
                                                        </InputGroup>
                                                        <CratioFileUploader name="brand_logo_light" comWidth={196} comHeight={28} fileName={formData['brand_logo_light'] !== undefined && formData['brand_logo_light'] !== null ? formData['brand_logo_light']['fileName'] : ""} mediaUrl={formData['brand_logo_light'] !== undefined && formData['brand_logo_light'] !== null ? formData['brand_logo_light']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} clearFile={clearFile} isAdmin={isAdmin}/>
                                                        <span className='field-desc gen-description'>Recommended size: 196px (width) x 28px (height), 7:1 aspect ratio</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="brand_favicon">Brand Favicon</label>
                                                        <CratioFileUploader name="brand_favicon" comWidth={96} comHeight={96} fileName={formData['brand_favicon'] !== undefined && formData['brand_favicon'] !== null ? formData['brand_favicon']['fileName'] : ""} mediaUrl={formData['brand_favicon'] !== undefined && formData['brand_favicon'] !== null ? formData['brand_favicon']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} clearFile={clearFile} isAdmin={isAdmin}/>
                                                        <span className='field-desc gen-description'>Recommended size: 96px (width) x 96px (height), 1:1 aspect ratio</span>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                }
                                {type === "info" && <div className='agency-details'>
                                    <div className='field-details'>
                                        <Card className='card-br'>
                                            <CardBody className='pb-6'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >Billing Information</span>
                                                    <span className='header-desc'>This information will be used for generating subscription invoices on your behalf.</span>
                                                </div>
                                                <Row>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="billing_name">Billing Name</label>
                                                        <Controller
                                                            name="billing_name"
                                                            control={control}
                                                            rules={{ required: formData.billing_name === undefined || formData.billing_name === '' ? "Please Billing Name" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="billing_name"
                                                                        id="billing_name"
                                                                        value={formData.billing_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.billing_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                        maxLength={100}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.billing_name && <em className={`error invalid-feedback`}>{errors.billing_name.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="agent_email">Email Address</label>
                                                        <Controller
                                                            name="agent_email"
                                                            control={control}
                                                            rules={{
                                                                required: (formData.agent_email === undefined || formData.agent_email === null || formData.agent_email === "" ? "Please provide Email Address" : ""),
                                                                pattern: {
                                                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                                                    message: "Please provide a valid Email Address"
                                                                }
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="email"
                                                                        name="agent_email"
                                                                        id="agent_email"
                                                                        value={formData.agent_email}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.agent_email ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.agent_email && <em className={`error invalid-feedback`}>{errors.agent_email.message}</em>}
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    
                                                </Row> */}
                                                <Row>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="address_1">Address Line 1</label>
                                                        <Controller
                                                            name="address_1"
                                                            control={control}
                                                            rules={{
                                                                required: formData.address_1 === undefined ? "Please provide Address Line 1" : "",
                                                                maxLength: {
                                                                    value: 40,
                                                                    message: "Address Line 1 cannot be longer than 40 characters."
                                                                }
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="address_1"
                                                                        id="address_1"
                                                                        value={formData.address_1}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.address_1 ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.address_1 && <em className={`error invalid-feedback`}>{errors.address_1.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="address_2">Address Line 2</label>
                                                        <Controller
                                                            name="address_2"
                                                            control={control}
                                                            rules={{
                                                                required: formData.address_2 === undefined ? "Please provide Address Line 2" : "",
                                                                maxLength: {
                                                                    value: 40,
                                                                    message: "Address Line 2 cannot be longer than 40 characters."
                                                                }
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="address_2"
                                                                        id="address_2"
                                                                        value={formData.address_2}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.address_2 && <em className={`error invalid-feedback`}>{errors.address_2.message}</em>}
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    
                                                </Row> */}
                                                <Row>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="city_name">City</label>
                                                        <Controller
                                                            name="city_name"
                                                            control={control}
                                                            rules={{ required: formData.city_name === undefined ? "Please provide City" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="city_name"
                                                                        id="city_name"
                                                                        value={formData.city_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.city_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                        maxLength={100}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.city_name && <em className={`error invalid-feedback`}>{errors.city_name.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="zip_code">Zip Code</label>
                                                        <Controller
                                                            name="zip_code"
                                                            control={control}
                                                            rules={{ required: formData.zip_code === undefined ? "Please provide Zip Code" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="zip_code"
                                                                        id="zip_code"
                                                                        value={formData.zip_code}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.zip_code ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.zip_code && <em className={`error invalid-feedback`}>{errors.zip_code.message}</em>}
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    
                                                </Row> */}
                                                <Row>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="state_name">State</label>
                                                        <Controller
                                                            name="state_name"
                                                            control={control}
                                                            rules={{ required: formData.state_name === undefined ? "Please provide State" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="state_name"
                                                                        id="state_name"
                                                                        value={formData.state_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.state_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                        maxLength={100}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.state_name && <em className={`error invalid-feedback`}>{errors.state_name.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="country_name">Country</label>
                                                        <Controller
                                                            name="country_name"
                                                            control={control}
                                                            rules={{ required: formData.country_name === undefined ? "Please provide Country" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="country_name"
                                                                        id="country_name"
                                                                        value={formData.country_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.country_name ? 'is-invalid' : ''}`}
                                                                        disabled={isAdmin ? false: true}
                                                                        maxLength={100}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.country_name && <em className={`error invalid-feedback`}>{errors.country_name.message}</em>}
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                   
                                                </Row> */}
                                            </CardBody>
                                        </Card>
                                    </div>

                                </div>}
                                {type === "smtp-setup" && <div className='agency-details'>

                                    <div className='field-details'>
                                        <Card className='card-br'>
                                            <CardBody className='pb-6'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >Email SMTP Setup</span>
                                                    <span className='header-desc'>This email address will be used to send notifications to your customers on your behalf (e.g., app usage, payment issues, user invites, password resets, etc.)</span>
                                                </div>
                                                {
                                                    isSMTPConfigured && <Fragment>
                                                        <Row>
                                                            <Col md={12}  className="d-flex justify-end mt-3 me-3">
                                                                <Button
                                                                    name={"edit_config"}
                                                                    onClick={() => handleBtnClick("cancel")}
                                                                    className={`${isAdmin ? '"badge badge-theme badge-btn custom-display p-2 ' : 'hide'}`}
                                                                    
                                                                >
                                                                    <span>{"Edit"}</span>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={10} className='d-grid mt-3'>
                                                                <span className='form-label'>Server Host  : {formData['smtp_url']}</span>
                                                                <span className='form-label'>Port         : {formData['smtp_port']}</span>
                                                                <span className='form-label'>Protocol     : {formData['smtp_protocol']['label']}</span>
                                                                <span className='form-label'>Username     : {formData['smtp_username']}</span>
                                                                <span className='form-label'>Sender Name  : {formData['sender_name']}</span>
                                                                <span className='form-label'>Sender Email : {formData['sender_email']}</span>
                                                            </Col>
                                                        </Row>
                                                            <Col className='d-flex'>
                                                                <Button name={"cancel"} className={"theme-bg-g badge-btn"} color="dark" onClick={() => ckeckConnection()} disabled={isAdmin ? false : true}>
                                                                    <i class="fi fi-rs-plug-connection"></i> 
                                                                    {<span>{" Test Connection"}</span>}
                                                                </Button>
                                                            </Col>
                                                    </Fragment>
                                                }
                                                {
                                                    !isSMTPConfigured && <Fragment>
                                                        <Row>
                                                            <Col md={12} className="mt-3">
                                                            <div>
                                                                <InputGroup>
                                                                <label className={'form-label mb-0'} htmlFor="brand_favicon">SMTP Provider</label>
                                                                <div className='pl-2 pt-1'>
                                                                    <TooltipItem id={"3"} text={selectedOption.value !== undefined && selectedOption.value === "custom" ? "Email will be sent using your SMTP server as per the below configuration." : "Email will be sent via the configured your branded email address by using AWS SMTP service."} content={<i class="fi fi-ss-info"></i>} />
                                                                </div>
                                                            </InputGroup>
                                                            </div>
                                                            <Col md={12} className="d-flex"> {/*  */}
                                                                <Col className="w-25">
                                                                    <Select
                                                                        name="selected_form"
                                                                        id="selected_form"
                                                                        className="basic-select"
                                                                        classNamePrefix="sub-select wa-select"
                                                                        options={CONFIGURE_PROTOCOL_LISTS}
                                                                        value={selectedOption}
                                                                        onChange={showSMPTSettings}
                                                                        isDisabled={isAdmin ? false :true}

                                                                    />
                                                                </Col>
                                                                <Col md={8} className='d-flex justify-content-end ms-5'>
                                                                    {clickEdit && isCustomSMTP && (
                                                                        <Button
                                                                            name="edit_config"
                                                                            onClick={() => handleBtnClick("cancel")}
                                                                            className="badge badge-theme badge-btn custom-display ms-2 p-2 btn-lg custom-btn-1 btn btn-dark btn-lg"
                                                                            size="sm"
                                                                        >
                                                                            <span className="p-1">Cancel</span>
                                                                        </Button>
                                                                    )}
                                                                </Col>
                                                            </Col>
                                                                  {/* <Col md={2} className='d-flex'> 
                                                                     <Switch
                                                                        className="outbound-advanced-switch"
                                                                        onColor={"#034737"}
                                                                        onChange={() => showSMPTSettings()}
                                                                        checked={isCustomSMTP}
                                                                        checkedIcon={
                                                                            <div className='on-switch-btn'>

                                                                            </div>
                                                                        }
                                                                        uncheckedIcon={
                                                                            <div className='off-switch-btn'>

                                                                            </div>
                                                                        }
                                                                        width={60}
                                                                        height={25}
                                                                    />
                                                                   </Col> */}
                                                            </Col>
                                                        </Row>
                                                        {
                                                            isCustomSMTP && <Fragment>
                                                                <Row>
                                                                    <Col md={10} className='field-section'> {/* field-section */}
                                                                        <InputGroup>
                                                                            <label className={'form-label'} htmlFor="smtp_url">SMTP Server URL</label>
                                                                            <div className='pl-2 pt-1'>
                                                                                <TooltipItem id={"4"} text={"E.g smtp.gmail.com or your SMTP server URL"} content={<i class="fi fi-ss-info"></i>} />
                                                                            </div>
                                                                        </InputGroup>
                                                                        <Controller
                                                                            name="smtp_url"
                                                                            control={control}
                                                                            rules={{ required: formData.smtp_url === undefined ? "Please provide SMTP URL" : "" }}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        name="smtp_url"
                                                                                        id="smtp_url"
                                                                                        value={formData.smtp_url}
                                                                                        autoComplete={"off"}
                                                                                        onChange={(e) => {
                                                                                            onInputChange(e);
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                        className={`form-control ${errors.smtp_url ? 'is-invalid' : ''}`}
                                                                                        disabled={isAdmin ? false: true}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        {errors.smtp_url && <em className={`error invalid-feedback`}>{errors.smtp_url.message}</em>}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={10} className='field-section'>
                                                                        <InputGroup>
                                                                        <label className={'form-label'} htmlFor="smtp_port">Port</label>
                                                                        <div className='pl-2 pt-1'>
                                                                                <TooltipItem id={"5"} text={"The default port is 465 or as per your SMTP configuration"} content={<i class="fi fi-ss-info"></i>} />
                                                                            </div>
                                                                        </InputGroup>
                                                                        <Controller
                                                                            name="smtp_port"
                                                                            control={control}
                                                                            rules={{ required: formData.smtp_port === undefined ? "Please provide Port" : "" }}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        name="smtp_port"
                                                                                        id="smtp_port"
                                                                                        value={formData.smtp_port}
                                                                                        autoComplete={"off"}
                                                                                        onChange={(e) => {
                                                                                            onInputChange(e);
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                        className={`form-control ${errors.smtp_port ? 'is-invalid' : ''}`}
                                                                                        disabled={isAdmin ? false: true}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        {errors.smtp_port && <em className={`error invalid-feedback`}>{errors.smtp_port.message}</em>}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={10} className='field-section'>
                                                                        <label className={'form-label'} htmlFor="smtp_protocol">Encryption Protocol</label>

                                                                        <Controller
                                                                            name={"smtp_protocol"}
                                                                            control={control}
                                                                            rules={{
                                                                                required: !formData["smtp_protocol"] ? "This field is required" : false,
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    options={PROTOCOL_LISTS}
                                                                                    onChange={(e) => handleSelectChange("smtp_protocol", e)}
                                                                                    value={formData["smtp_protocol"]}
                                                                                    classNamePrefix="wa-select"
                                                                                    className="basic-select"
                                                                                    menuPlacement={"auto"}
                                                                                    isDisabled={isAdmin ? false :true}
                                                                                />
                                                                            )
                                                                            }
                                                                        />
                                                                        {
                                                                            errors["smtp_protocol"] && (
                                                                                <em className="error invalid-feedback d-flex">{errors["smtp_protocol"].message}</em>
                                                                            )
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={10} className='field-section'>
                                                                        <label className={'form-label'} htmlFor="smtp_username">SMTP Username</label>
                                                                        <Controller
                                                                            name="smtp_username"
                                                                            control={control}
                                                                            rules={{ required: formData.smtp_username === undefined ? "Please provide Username" : "" }}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        name="smtp_username"
                                                                                        id="smtp_username"
                                                                                        value={formData.smtp_username}
                                                                                        autoComplete={"off"}
                                                                                        onChange={(e) => {
                                                                                            onInputChange(e);
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                        className={`form-control ${errors.smtp_username ? 'is-invalid' : ''}`}
                                                                                        disabled={isAdmin ? false: true}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        {errors.smtp_username && <em className={`error invalid-feedback`}>{errors.smtp_username.message}</em>}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={10} className='field-section'>
                                                                        <label className={'form-label'} htmlFor="smtp_password">SMTP Password</label>
                                                                        <Controller
                                                                            name="smtp_password"
                                                                            control={control}
                                                                            rules={{ required: formData.smtp_password === undefined ? "Please provide Password" : "" }}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        name="smtp_password"
                                                                                        id="smtp_password"
                                                                                        value={formData.smtp_password}
                                                                                        autoComplete={"off"}
                                                                                        onChange={(e) => {
                                                                                            onInputChange(e);
                                                                                            field.onChange(e);
                                                                                        }}
                                                                                        className={`form-control ${errors.smtp_password ? 'is-invalid' : ''}`}
                                                                                        disabled={isAdmin ? false: true}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        {errors.smtp_password && <em className={`error invalid-feedback`}>{errors.smtp_password.message}</em>}
                                                                    </Col>
                                                                </Row>

                                                            </Fragment>
                                                        }
                                                        <Fragment>
                                                            <Row>
                                                                <Col md={10} className='field-section'>
                                                                    <label className={'form-label'} htmlFor="sender_name">Sender Name</label>
                                                                    <Controller
                                                                        name="sender_name"
                                                                        control={control}
                                                                        rules={{ required: formData.sender_name === undefined ? "Please provide Sender Name" : "" }}
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <input
                                                                                    disabled={(verified && !isCustomSMTP) || !isAdmin}
                                                                                    {...field}
                                                                                    type="text"
                                                                                    name="sender_name"
                                                                                    id="sender_name"
                                                                                    value={formData.sender_name}
                                                                                    autoComplete={"off"}
                                                                                    onChange={(e) => {
                                                                                        onInputChange(e);
                                                                                        field.onChange(e);
                                                                                    }}
                                                                                    className={`form-control ${errors.sender_name ? 'is-invalid' : ''}`}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                    {errors.sender_name && <em className={`error invalid-feedback`}>{errors.sender_name.message}</em>}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={isCustomSMTP ? 10 : 8} className="field-section mt-2">
                                                                    <label className="form-label" htmlFor="sender_email">
                                                                        Sender Email
                                                                    </label>
                                                                    <Controller
                                                                        name="sender_email"
                                                                        control={control}
                                                                        rules={{
                                                                            required: formData.sender_email === undefined,
                                                                            pattern: {
                                                                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                                                                message: "Please provide valid sender Email Address"
                                                                            }
                                                                        }}
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <input
                                                                                    disabled={(verified && !isCustomSMTP) || !isAdmin}
                                                                                    {...field}
                                                                                    type="email"
                                                                                    name="sender_email"
                                                                                    id="sender_email"
                                                                                    value={formData.sender_email}
                                                                                    autoComplete={"off"}
                                                                                    onChange={async (e) => {
                                                                                        onInputChange(e);
                                                                                        field.onChange(e);
                                                                                    }}
                                                                                    className={`form-control ${errors.sender_email ? 'is-invalid' : ''}`}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                {!isCustomSMTP &&
                                                                    <Col md={4} className='sender-div field-section'>
                                                                        <div className='d-flex'>
                                                                            <div className={`${isAdmin ? 'cursor-pointer' : 'hide'}`} style={{ height: '1px' }}>
                                                                                {verified ? (
                                                                                    <>
                                                                                        {!verifyStatus ? (
                                                                                            <>
                                                                                                <div className='badge bg-warning me-2' title='Unverified'>Unverified</div>
                                                                                                <div onClick={loadSenderData} className="badge bg-success me-2" title='Refresh'>
                                                                                                    <i className='pe-7s-refresh-2'></i>
                                                                                                </div>
                                                                                                <div className='badge bg-info me-2' onClick={resendEmailVerification} title='Resend'>
                                                                                                    <i class="fi fi-rs-paper-plane"></i>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className="badge bg-success me-2" title='Verified'>Verified</div>
                                                                                        )}
                                                                                        <div className="badge bg-danger" title='Delete' onClick={deleteVerifiedEmail}>Delete</div>
                                                                                    </>
                                                                                ) : (
                                                                                    <span onClick={verifyEmail} title='Click to Verify' className='badge bg-success me-2'>Verify</span>
                                                                                )}
                                                                            </div>

                                                                        </div>

                                                                    </Col>
                                                                }
                                                                {errors.sender_email && (
                                                                    <em className="error invalid-feedback d-block">{errors.sender_email.message}</em>
                                                                )}

                                                            </Row>
                                                            {!isCustomSMTP && <Fragment>
                                                                <div className='mt-4'>
                                                                    <label className={'form-label'}>
                                                                        Steps to verify your email address:
                                                                    </label>
                                                                    <li className='sender-list'>1. Enter the Sender Name and Email Address, then click <span className='font-bold'>"VERIFY"</span>.</li>
                                                                    <li className='sender-list'>2. Check your inbox for a verification email from Amazon Web Services.</li>
                                                                    <li className='sender-list'>3. Click the verification link in the email.</li>
                                                                    <li className='sender-list'>4. Return to the settings page and click <span className='font-bold'>"REFRESH"</span>.</li>
                                                                    <li className='sender-list'>5. The status will update to <span className='font-bold'>"CONNECTED"</span> once verified.</li>
                                                                </div>
                                                                <div className='mt-4'>
                                                                    <label className={'form-label'}>
                                                                        Note:
                                                                    </label>
                                                                    <li className=''>Check your spam folder if you don’t see the email in your inbox.</li>
                                                                    <li className=''>If not received, click <span className='font-bold'>"RESEND"</span> to send it again.</li>
                                                                </div>
                                                            </Fragment>}
                                                        </Fragment>
                                                    </Fragment>
                                                }
                                            </CardBody>
                                        </Card>
                                    </div>

                                </div>
                                }
                                {type === "info" && <div className='agency-details'>

                                    <div className='field-details'>
                                        <Card className='card-br'>
                                            <CardBody className='pb-6'>
                                                <div className='card-fields profile-title-info d-grid'>
                                                    <span class="heading" >Support Links</span>
                                                    <span className='header-desc'>Offer links to support your customers, such as support email, knowledge base, and community links.</span>
                                                </div>
                                                <Row>
                                                    <Col md={10} className='field-section mt-2'>
                                                        <label className={'form-label hide'} htmlFor="support_links">Support Links</label>
                                                        <div className='d-flex align-center'>
                                                            <Col md={6}>
                                                                <label className='form-label' >Name</label>
                                                            </Col>
                                                            <Col md={6}>
                                                                <label className='form-label' >Link</label>
                                                            </Col>
                                                        </div>
                                                        {
                                                            variationList.length > 0 &&
                                                            variationList.map((variation) => {
                                                                return variation;
                                                            })
                                                        }
                                                        {
                                                            variationList.length < maxVariactionCount &&
                                                            <div className={`${isAdmin ? 'button-panel' : 'hide'}`}>
                                                                <a className={`cursor-pointer option-addmore ${variationCount >= maxVariactionCount ? 'btn-disabled' : ''}`} onClick={(e) => { addVariation(e) }}>
                                                                    <FontAwesomeIcon className='theme-c-g' icon={faPlus} /> <span className='theme-c-g'>Add More</span>
                                                                </a>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>


                                    </div>
                                </div>
                                }
                                <div className='hide'>
                                    <button ref={saveBtnRef} class="btn btn-outline-primary">Save & Activate</button>
                                </div>
                            </form>
                        )}
                    </Container>

                } />
            {showtestModal && <Testsmtp closeModal={ckeckConnection} formData = {formData}></Testsmtp>}
        </Fragment>
    );
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
