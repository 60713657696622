import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import SplitCommon from '../Common/SplitCommon';
import FilesSideBase from "./FilesSideBase";
import FilesMainContent from "./FilesMainContent";
import AddFilesModal from './AddFilesModal';
import requestApiService from '../../services/requestApi.service';

const KnowledgeBase = (props) => {
    const [sideBarLoader, setSideBarLoader] = useState(false);
    const [mainLoader, setMainLoader] = useState(false);
    const [isModelOpen, setModelOpen] = useState(false);
    const [curRowId, setCurrentRowId] = useState(0);
    const [knbData, setKNBData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [curRowIndex, setCurrentRowIndex] = useState(-1);

    useEffect(async () => {
        await loadKnowledgeBaseList();
    }, []);

    const getSideBarTitleContent = () => {
        return <div>Knowledge Base</div>;
    };

    const onAddKnowledgeBase = () => {
        setModelOpen(!isModelOpen);
    }

    const onKNBChange = (rowData, rowIndex) => {
        setRowData(rowData);
        setCurrentRowIndex(rowIndex);
        setCurrentRowId(rowData['id']);
    }

    const loadKnowledgeBaseList = async () => {
        setSideBarLoader(true);
        setMainLoader(true);
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "LOAD_KNB",
        }
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if (response.issuccess) {
            let data = response.data;
            setSideBarLoader(false);
            setMainLoader(false);
            if(data.length > 0) {
                setRowData(data[0]);
                setCurrentRowId(data[0]['id']);
                setCurrentRowIndex(0);
                setKNBData(data);
            }
        }
    }

    const onAddFileSuccess = async () => {
        await loadKnowledgeBaseList();
        setModelOpen(false);
    }

    return (
        <Fragment>
            <SplitCommon mobileViewNeedLeftMenu={true}
                sideBarLoader={sideBarLoader}
                mainLoader={mainLoader}
                title="Knowledge Base"
                sideBarTitleContent={
                    getSideBarTitleContent()
                }
                mainTitleContent={
                    <div></div>
                }
                sideBarContent={
                    <Fragment>
                        <FilesSideBase showLoading={sideBarLoader} curRowId={curRowId} onChange={onKNBChange} knbData={knbData} onAdd={onAddKnowledgeBase} />
                    </Fragment>
                }
                mainContent={
                    <Fragment>
                        {isModelOpen && <AddFilesModal onAddFileSuccess={onAddFileSuccess} isModelOpen={isModelOpen} onAdd={onAddKnowledgeBase} />}
                        <FilesMainContent onFileSuccess={onAddFileSuccess} showLoading={mainLoader} knbData={rowData} />
                    </Fragment>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase);