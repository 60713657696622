export const tabs = [
    { value: "request", label: "Request", icon: "fi fi-rr-list" },
    { value: "response", label: "Response", icon: "fi fi-rr-clone" },
];
  
export const tabContent = {
    request: [
        { label: "Started At", value: "start_time", datatype: "time", icon: "fi fi-rr-alarm-clock" },
        { label: "Finished At", value: "end_time", datatype: "time", icon: "fi fi-rr-alarm-clock" },
        { label: "Duration", value: "req_duration", datatype: "int", icon: "fi fi-rr-alarm-clock" },
        { label: "URL", value: "url", datatype: "string", icon: "fi fi-rr-link-alt" },
        { label: "Path", value: "path", datatype: "string", icon: "fi fi-rr-code-branch" },
        { label: "Method", value: "method", datatype: "string", icon: "fi fi-rr-arrow-right" },
        { label: "Query", value: "query", datatype: "string", icon: "fi fi-br-question" },
        { label: "Headers", value: "req_headers", datatype: "object", icon: "fi fi-rr-key" },
        { label: "Body", value: "req_body", datatype: "object", icon: "fi fi-rr-document" }
    ],
    response: [
        { label: "Status Code", value: "status", datatype: "int", icon: "fi fi-rr-flag" },
        { label: "Response Time", value: "res_duration", datatype: "int", icon: "fi fi-rr-alarm-clock" },
        { label: "Headers", value: "res_headers", datatype: "object", icon: "fi fi-rr-key" },
        { label: "Body", value: "res_body", datatype: "object", icon: "fi fi-rr-document" }
    ],
};