import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useForm, Controller } from "react-hook-form";
import { Row, Col, Card, CardBody, Button, Label, Input, InputGroup } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormText } from 'react-bootstrap';
import { getServerDomainURL } from './Appcommon';
import TooltipItem from './ToolTip';


const Form = (props) => {
    const { pname, fields, submitAction, formData: initialFormData, account_id,lastSyncTime,syncStatus,submitBeforebtn } = props;
    const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const [formData, setFormData] = useState(initialFormData || {});
    const [isCopied, setTextCopied] = useState(false);
    const baseUrl = getServerDomainURL();
    let Url = "events/vmhooks.php?accountid=" + account_id;
    const webhookUrl = baseUrl + "/" + Url;

    useEffect(() => {
        setFormData(initialFormData || {});
    }, [initialFormData]);


    /*
        const numbers = [1, 2, 3, 4];
        reduce() is a method in JavaScript used to "reduce" an array into a single value, 
        typically an object, array, or even a number. 
        It applies a function to each item in the array and accumulates a result, which is passed on to the next iteration.
        // Using reduce to sum the numbers
        const sum = numbers.reduce((acc, num) => {
            return acc + num;
        }, 0);  // Initial accumulator value is 0
    */

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitProviderCredentials = async (e, name) => {
        await submitAction(name, formData);
    }
    const copyUrl = () => {
        setTextCopied(true);
    }

    return (
        <Fragment>
            <form id={`agency-form-${pname}`} name={'agency-' + pname} onSubmit={handleActionFormSubmit((data) => submitProviderCredentials(pname, data))}>
                <div className='field-details'>
                    <Row>
                        {
                            fields.map((field) => {
                                const { name, label, type } = field;
                                let fname = pname + "_" + name;
                                return (
                                    <Fragment>

                                        <div>
                                            <Col md={12} className={(pname === "vapi" || pname === "bland" || pname === "retell") ? 'provider-top' : "field-section mt-2"}>
                                                <InputGroup>
                                                <label className={'form-label'} htmlFor={fname}>{label}</label>
                                                <div className='pl-2'>
                                                    <TooltipItem id={name == "pub_key" ? "1" : "2"} text={`${name == "pub_key" ? "Needed for server-side integration such as call logs": "Needed for client-side integration such as web calls"}`} content={<i class="fi fi-ss-info"></i>} />
                                                </div>
                                                </InputGroup>
                                                <Controller
                                                    name={fname}
                                                    control={control}
                                                    rules={{ required: (formData[fname] === undefined || formData[fname] === "") ? "Please provide " + label : "" }}
                                                    render={({ field }) => {
                                                        return (
                                                            <input
                                                                {...field}
                                                                type={type}
                                                                name={fname}
                                                                id={fname}
                                                                value={formData[fname]}
                                                                autoComplete={"off"}
                                                                onChange={(e) => {
                                                                    onInputChange(e);
                                                                    field.onChange(e);
                                                                }}
                                                                className={`form-control ${errors[fname] ? 'is-invalid' : ''}`}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors[fname] && <em className={`error invalid-feedback`}>{errors[fname].message}</em>}
                                            </Col>

                                        </div>

                                    </Fragment>
                                )
                            })
                        }
                        {
                            (pname === "vapi" || pname === "bland" || pname === "retell") && (
                                <div className='hide'>
                                    <Col md={12} className='provider-top'>
                                        <Label>Server URL (Messaging)</Label>
                                        <div className="d-flex align-items-center">
                                            <InputGroup>
                                                <Input value={webhookUrl} className={`google-ads-webhook-url`} disabled={true} />
                                                <CopyToClipboard onCopy={copyUrl} text={webhookUrl}>
                                                    <Button className='theme-bg-g'>
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </Button>
                                                </CopyToClipboard>
                                            </InputGroup>
                                        </div>
                                        {isCopied && (
                                            <FormText color="success" className="mb-2 provider-url-btn">Text has been copied.</FormText>
                                        )}
                                        <p className='ps-1 pt-2'>Please ensure the above URL is configured to receive post-call events from voice platforms. <a href='https://help.voicemetrics.ai/en/category/ai-voice-platforms' target='_blank' className='provider-url-btn'>(Learn more)</a></p>
                                    </Col>
                                    <Col md={12} className='provider-top'>
                                        <Label>Set the Maximum Concurrent Outbound Calls Allowed</Label> {/* Call Concurrency Limit (for Outbound Calls) */}
                                    </Col>
                                    <Col md={12} className="mt-2">
                                        <div className="d-flex align-items-center">
                                            <Input
                                                type="number"
                                                defaultValue={8}
                                                value={formData.concurrency_limit}
                                                onChange={onInputChange}
                                                name='concurrency_limit'
                                                className={`form-control ${errors.concurrency_limit ? 'is-invalid' : ''}`}
                                            />
                                        </div>
                                    </Col>
                                    <p className='gen-description ps-1 pt-2'>Please specify the Concurrent Calls Limit for outbound calls (e.g., if the limit is 10, allocate 8 for outbound calls).</p>
                                </div>
                            )
                        }
                        {
                            (pname === "vapi" || pname === "bland" || pname === "retell") && (
                                <div className=''>
                                    <Col md={12} className='provider-top'>
                                        <Label>Status</Label>
                                    </Col>
                                    <Col md={1} className="mt-2">
                                        <div className={`d-flex align-items-center badge ${syncStatus ? 'sub-sts-active' : 'sub-sts-pym-fail'}`}>
                                            <div className={`d-flex align-items-center ${syncStatus ? 'ps-2' : 'ps-1'}`}>{syncStatus ? 'Active' : 'Inactive'}</div>
                                            
                                        </div>
                                    </Col>
                                    <div className='pt-2 d-flex'>
                                        <span class="">Last sync time: {lastSyncTime}</span>
                                    </div>
                                </div>
                            )
                        }

                        <div className={pname === "vapi" || pname === "bland" || pname === "retell" ? "d-flex justify-center mt-4 gap-3" : 'form-footer d-block text-end mt-3'}>
                            <div className='d-flex'>
                                {submitBeforebtn && (pname === "vapi" || pname === "bland" || pname === "retell") && submitBeforebtn}
                            </div>
                            <Button size="lg" className='theme-bg-g'>
                                {pname === "vapi" || pname === "bland" || pname === "retell" ? "Save Credentials" : "Save"}
                            </Button>
                        </div>
                    </Row>
                </div>

            </form>
        </Fragment>
    )
}

export default Form;
