import React, { Fragment, useState, useEffect } from 'react';

const CallsAnalysis = (props) => {
    let { rowdata, nameLabelMapper, lablename } = props;
    const [callAnalysis, setCallAnalysis] = useState({});

    useEffect(() => {
        try {
            let jsonData = rowdata?.[nameLabelMapper?.["Call_Analysis"]];
            if (jsonData) {
                let data = JSON.parse(jsonData);
                setCallAnalysis(data);
            } else {
                setCallAnalysis({});
            }
        } catch (error) {
            console.error("Error parsing Call_Analysis JSON:", error);
            setCallAnalysis({});
        }
    }, [rowdata,nameLabelMapper]);

    return (
        <Fragment>
            <div className='call-summary-container'> 
                <div>
                    <span className='call-summary-label'>{lablename}</span>
                </div>
                {Object.keys(callAnalysis).length > 0 ? (
                    <ul>
                        {Object.entries(callAnalysis).map(([key, value]) => (
                            <li key={key}>
                                <strong className='me-2'>{key}: </strong> {value}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className='call-summary-content'>No Analysis Found</div>
                )}
            </div>
        </Fragment>
    );
};

export default CallsAnalysis;
