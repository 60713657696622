import React, { Fragment, useEffect, useState } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from "../Common";
import Filter from "./Filter";
import { Col, Row, CardBody, } from "reactstrap";
import "./index.css";
import requestApi from "../../services/requestApi.service";
import { CallDurationTable,CallTypeOption } from "./constant";
import ListTable from '../../Common/ListTable';
import Loading from "../../Common/Loading";
import { isMobile } from "../../Common/Appcommon";

const CallDuration = () => {
    const [agent, setAgent] = useState("all_agent");
    const [callType, setCallType] = useState('total_calls');
    const [demo, setDemo] = useState(false);
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 29);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);   
    const [dateFilter, setDateFilter] = useState({
        Start_DateTime: [startDate, endDate],
        noofdays: 30,
    });
    const [showLoader, setShowLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const mobile = isMobile();

    const generateParams = (operation) => {
        return {
            ENTITY: "AIPROCESSOR",
            OPERATION: operation,
            FORMNAME: "table_37",
            FILTEROPTION: dateFilter,
            agent: agent,
            demo:demo,
            callType: callType, 
            isOtherApiReq: true,
        };
    };

    const prepareTableData = async () =>{
        setShowLoader(true);
        const updatedColumns = await Promise.all(
            CallDurationTable.map(async (val) => {
                let operation = val.operation;
                let params = generateParams(operation);
                let response = await requestApi.processServerRequest(params);
                if (response.issuccess) {
                    let data = response.data;
                    return { ...val, data };
                }
                return val;
            })  
        );
        let tableDataFromResponse = updatedColumns[0]; 
        setTableData(tableDataFromResponse);
        setShowLoader(false);
    }



    const handleAgentValue = (selectedAgent) => {
        setAgent(selectedAgent.value); 
    };

    const handleDateValue = (selectedDate) => {
        setDateFilter(selectedDate); 
    };

    const handlecallTypeValue = (selectedCallType) => {
        setCallType(selectedCallType.value); 
    };

    const handleDemo = (ischecked) =>{
        setDemo(ischecked);
    }

    useEffect(() => {
        prepareTableData();
    }, [dateFilter, agent, callType,demo]);  

    const setMainContent = () => {
        return (
            <Col md={12}>
                <Row className="ms-0 me-0 gx-0 gy-0">
                    {mobile && 
                        getTitleBody()
                    }
                    <CardBody className="mt-2">
                        <Loading 
                            showLoader = {
                                showLoader
                            }
                            mainContent={
                                <>
                                    {/* <div className="call-log-charts"> */}
                                        {/* <Card className="main-card mb-3 ">*/}
                                            {/* <CardBody>  */}
                                                {/* <div className="bar mt-4"> */}
                                                    <ListTable isDashboard={true} columns={tableData.columns} data={tableData.data} />
                                                {/* </div> */}
                                            {/* </CardBody> */}
                                         {/*</Card> */}
                                    {/* </div> */}
                                </>
                            }
                        />                            
                    </CardBody>
                </Row>
            </Col>
        );
    };

    const getTitleBody = () =>{
        return (
            !demo && 
            <Filter
                getAgentvalue={handleAgentValue} 
                getdate={handleDateValue} 
                getcallType={handlecallTypeValue}
                CallTypeOption = {CallTypeOption}
                needCallFilter={true}             
                needAgentFilter={true}           
                needDateFilter={true}
                needDemo={true}
                getDemo={handleDemo}
                setDemo={demo}
            />
        );
    }
    return (
        <Fragment>
            <Common
                demoPopup = {
                    demo
                }
                demoHandler = {
                    handleDemo
                }
                titleBody={
                    !mobile &&  getTitleBody()
                }
                title={
                    "Timing Analysis"
                }
                titleContent={
                    "Timing Analysis"
                }
                mainContent={setMainContent()} 
                titleKey={"duration_enabled"} 
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallDuration);