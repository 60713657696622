import { formatDateTime, convertUTCToLocalTime} from "../../../Common/Appcommon";
export const DateFilterOptions = [
    {
        label : "Today",
        value : "today"
    },
    {
        label : "Yesterday",
        value : "yesterday"
    },
    {
        label : "Last 7 Days",
        value : "last_7_days"
    },
    {
        label : "Last 30 Days",
        value : "last_30_days"
    },
    {
        label : "This Month",
        value : "this_month"
    },
    {
        label : "Last Month",
        value : "last_month"
    },
    {
        label : "Custom",
        value : "custom"
    },
];

export const graphAgenListColumns = [
    {
        label: " Recent Calls",
        operation: "GET_RECENT_CALLS_BY_SUB_ACCOUNT",
        type:"table", 
        columns: [
            {
                name: "Sub Account Name",
                selector: row => row["account_name"],
                sortable: true,
                width: '50%',
            },
            {
                name: "AI Agent Name",
                selector: row => row["last_call_agent"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Type",
                selector: row => row["last_call_type"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Time",
                selector: row => formatDateTime(convertUTCToLocalTime(row["last_call_time"])),
                sortable: true,
                width: '25%',
            },
        ],
    },
    {
        label: "Recent Logins",
        operation: "GET_RECENT_LOGIN_BY_SUB_ACCOUNT",
        type:"table", 
        columns: [
            {
                name: "Sub Account Name",
                selector: row => row["account_name"],
                sortable: true,
                width: '40%',
            },
            {
                name: "User Name",
                selector: row => row["last_login_user"],
                sortable: true,
                width: '30%',
            },
            {
                name: "Last Login Time",
                selector: row => formatDateTime(convertUTCToLocalTime(row["last_login_at"])),
                sortable: true,
                width: '30%',
            },
        ],
    },
];
export const callUsageList = [
    {
        label: 'Call Usage', value: 'total_calls', isHorizontal : false, type: 'verticalbar', colordistributed: false,operation: 'GET_TOTAL_CALLS'
    },
];
export const paymentType = [
    {label:'All',value:'all'},
    {label:'Trial',value:'Trial'},
    {label:'Paying',value:'Customer'},
];