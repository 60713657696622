import React, { Fragment, useState, useEffect } from 'react';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, InputGroup } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import TooltipItem from '../../Common/ToolTip';
import CratioFileUploader from '../Common/CratioFileUploader';
import { confirmBox, getDeleteIcon, getSessionValue, handleErrorResponse, showToast, translateContent, uploadFileIntoS3 } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import requestApiService from '../../services/requestApi.service';
import { getProviderKey } from '../Agents/ProviderDetails';


const AddFilesModal = (props) => {
    let { onAdd, isModelOpen, onAddFileSuccess } = props;
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors }, trigger,setError } = useForm();
    const [formData, setFormData] = useState({});
    const [showButtonLoading, setShowButtonLoading] = useState(false);

    const submitFilesDetails = async () => {
        let isNameEmpty = (data) => !data?.["base_name"]?.trim();
        if (isNameEmpty(formData)) {
            setError("base_name", {
                type: "manual",
                message: "Invalid Action name.",
            });
            return;
        }
        setShowButtonLoading(true);
        let fileResult = await uploadFile();
        if (fileResult) {
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "SAVE_KNOWLEDGE_BASE",
                "formData": formData
            }
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
                setShowButtonLoading(false);
                onAddFileSuccess();
            }
        }else{
            showToast("No file selected",'top-right', "error");
        }
        setShowButtonLoading(false);
    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleFileUpload = async (e, name) => {
        //let { formInputData } = await uploadFileIntoS3(e, formData, name);
        let fData = formData;
        fData[name] = e.target.files[0];
        fData["mediaType"] = e.target.files[0]['type'];
        fData["fileName"] = e.target.files[0]['name'];
        setFormData(fData);
        //await uploadFile(e);
    };

    const clearFile = (name) => {
        formData[name] = "";
    }

    const uploadFile = async () => {
        let isFileUploaded = false;
        let proName = getSessionValue("agent_provider_name");
        let apikey = getProviderKey(proName);
        const file = formData['knowledge-document'];//e.target.files[0]; 
        if (!file) {
            console.error('Error: No file selected.');
            return;
        }

        const fData = new FormData();
        fData.append('file', file);

        try {
            const response = await fetch('https://api.vapi.ai/file', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + apikey
                    // Note: Do NOT set 'Content-Type' here; `fetch` will automatically set it to multipart/form-data with the correct boundary.
                },
                body: fData
            });

            const result = await response.json();
            let tmpFormData = formData;
            tmpFormData["files_result"] = result;
            setFormData(tmpFormData);
            isFileUploaded = true;
        } catch (error) {
            isFileUploaded = false;
            console.log("file upload error: ", error)
        }
        return isFileUploaded;
    };

    return (
        <Modal isOpen={isModelOpen}>
            <ModalHeader toggle={onAdd}>Add Knowledge Base</ModalHeader>
            <form id="knowledge-base" name={'file'} onSubmit={handleActionFormSubmit((data, event) => submitFilesDetails(event))}>
                <ModalBody>
                    <div className='agency-details p-0 g-1'>
                        <Row>
                            <Col md={12} className='field-section'>
                                <label className={'form-label'} htmlFor="base_name">Knowledge Base Name</label>
                                <Controller
                                    name="base_name"
                                    control={control}
                                    rules={{ required: !formData.base_name ? "Please provide Name" : ""}}
                                    render={({ field }) => {
                                        return (
                                            <input
                                                {...field}
                                                type="text"
                                                name="base_name"
                                                id="base_name"
                                                value={formData.base_name}
                                                autoComplete={"off"}
                                                onChange={(e) => {
                                                    onInputChange(e);
                                                    field.onChange(e);
                                                }}
                                                className={`form-control ${errors.base_name ? 'is-invalid' : ''}`}
                                            />
                                        );
                                    }}
                                />
                                {errors.base_name && <em className={`error invalid-feedback`}>{errors.base_name.message}</em>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='field-section mt-2'>
                                <InputGroup>
                                    <label className={'form-label'} htmlFor="brand_logo">Document</label>
                                    <div className='pl-2 pt-1'>
                                        <TooltipItem id={"1"} text={"Allowed file types: .pdf, .doc, .docx, .txt"} content={<i class="fi fi-ss-info"></i>} />
                                    </div>
                                </InputGroup>
                                <CratioFileUploader
                                    name="knowledge-document"
                                    comWidth={120}
                                    comHeight={24}
                                    fileName={formData['fileName']}
                                    errors={{}}
                                    handleFile={handleFileUpload}
                                    clearFile={clearFile}
                                    filesAccept={".pdf,.doc,.docx,.txt"}
                                    isAdmin={true}
                                    isDocument={true}
                                />
                                <span className='field-desc'></span>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Loading showLoader={showButtonLoading} mainContent={
                        <Fragment>
                            <Button className='d-flex flex-center align-center theme-bg-g'>
                                Save
                            </Button>
                        </Fragment>
                    } />
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddFilesModal;