import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import CustomDomain from './CustomDomain';
import EmailTemplates from './EmailTemplates';
import General from './General';

const WhiteLabel = (props) => {
    const { subMenu } = useParams();
    if(subMenu === "info"){
        return (
            <General type={subMenu} />
        )
    } else if(subMenu === "custom-domain"){
        return (
            <CustomDomain />
        )
    } else if(subMenu === "smtp-setup"){
        return (
            <General type={subMenu} />
        )
    } else if(subMenu === "support-links"){
        return (
            <General type={subMenu} />
        )
    } else if(subMenu === "email-templates"){
        return (
            <EmailTemplates />
        )
    } else if(subMenu === "billing-info"){
        return (
            <General type={subMenu} />
        )
    } 
}

export default WhiteLabel;