export const modalContent = {
    "book_calendar": [
        {
            label: "Action Name",
            name: "action_name",
            type: "text",
        },
        {
            label: "Calendar App Selection",
            name: "calendar",
            type: "dropdown",
            options: [
                {
                    label: "Google Calendar",
                    value: "gcalendar"
                },
                {
                    label: "GHL Calendar",
                    value: "ghl_calendar"
                },
                {
                    label: "Cal.com",
                    value: "cal.com"
                },
            ]
        },
        // {
        //     label: "Connected Accounts",
        //     name: "connected_accounts",
        //     type: "dropdown",
        //     options: [
        //         {
        //             label: "Google Calendar",
        //             value: "gcalendar"
        //         },
        //         {
        //             label: "GHL Calendar",
        //             value: "ghl_calendar"
        //         },
        //         {
        //             label: "Cal.com",
        //             value: "cal.com"
        //         },
        //     ]
        // },
        {
            label: "Calendar",
            name: "calendar_type",
            type: "dropdown",
            options: [
            ]
        },
        {
            label: "Call Type",
            name: "call_type",
            type: "dropdown",
            options: [
                {
                    label: "Inbound",
                    value: "inbound"
                },
                {
                    label: "Outbound",
                    value: "outbound",
                },
                {
                    label: "Both",
                    value: "both",
                },
            ]
        },
        // {
        //     label: "Meeting Location",
        //     name: "location",
        //     type: "dropdown",
        //     options: [
        //         {
        //             label: "Google Meet",
        //             value: "gmeet"
        //         },
        //         {
        //             label: "Zoom Meet",
        //             value: "zmeet",
        //         },
        //         {
        //             label: "Custom",
        //             value: "custom",
        //         },
        //     ]
        // },
        {
            label: "Start Date",
            name: "start_date",
            type: "date",
        },
        {
            label: "Working Hours",
            name: "working_hours",
            type: "week",
        },
        {
            label: "Duration",
            name: "duration",
            type: "number",
            info:'Meeting time duration in minutes.',
            infoNeeded:true
        },
        {
            label: "Booking Window",
            name: "booking_window",
            type: "number",
            info:'Bookings are allowed for the next number of days from start date',
            infoNeeded:true
        },
        {
            label: "Break between slots",
            name: "slots_break",
            type: "number",
            info:'Time buffer between scheduled slots in minutes.',
            infoNeeded:true
        },
        {
            label: "Delay",
            name: "delay",
            type: "number",
            info:'',
            infoNeeded:false
        },
        {
            label: "Slots per Day",
            name: "slots_per_day",
            type: "number",
            info:'Daily available booking slots.',
            infoNeeded:true
        }
    ]
};
export const modalHeaders = {
    "book_calendar": [
        {
            header: "Calendar",
            description: "Use Calendar to schedule appointments.",
        },
    ],
    "send_sms": [
        {
            header: "Send SMS",
            description: "Send appointment details via SMS"
        }
    ],
    "send_email": [
        {
            header: "Send Email",
            description: "Send appointment details via Email"
        }
    ],
    "transfer_call":[
        {
            header: "Call Transfer",
            description: "Transfer call to a number or human", 
        }
    ],
    "end_call":[
        {
            header: "End Call Message",
            description: "This is the message that the assistant will say if the call is ended.", 
        }
    ],
    "extract_info":[
        {
            header: "Extract Info",
            description: "Capture relevant information from the call conversations", 
        }
    ],
    "vmail_message":[
        {
            header: "Voicemail Message",
            description: "This is the message that the assistant will say if the call is forwarded to voicemail.", 
        }
    ],
    "custom_action":[
        {
            header: "Custom Action",
            description: "", 
        }
    ]
}
export const afterCallActions = [
    {
        label: "Send SMS",
        value: "send_sms",
        icon: <i class="fi fi-rr-comment-sms"></i>,
    },
    {
        label: "Send Email",
        value: "send_email",
        icon: <i class="fi fi-rr-envelope"></i>,
    },
    {
        label: "Extract Info",
        value: "extract_info",
        icon: <i class="fi fi-rr-info"></i>
    }
    // {
    //     label: "Custom Action",
    //     value: "custom_action",
    //     icon: <i class="fi fi-rs-trash"></i>,
    // },
];
export  const duringCallActions = [
    {
        label: "Transfer Call",
        value: "transfer_call",
        icon: <i class="fi fi-br-call-outgoing"></i>,
    },
    {
        label: "End Call",
        value: "end_call",
        icon: <i class="fi fi-br-phone-slash"></i>,
    },
    // {
    //     label: "Check Calendar",
    //     value: "check_calendar"
    // },
    {
        label: "Book Calendar",
        value: "book_calendar",
        icon: <i class="fi fi-rr-calendar-lines-pen"></i>,
    },
    {
        label: "Voicemail Message",
        value: "vmail_message",
        icon: <i class="fi fi-rr-salary-alt"></i>,
    },
    {
        label: "Custom Action",
        value: "custom_action",
        icon: <i class="fi fi-rr-tools"></i>,
    },
];

export const timeoptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
];

export const callOptions = [
    { value: "cold_transfer", label: "Cold transfer", description: "AI transfers the call to the next user without a debrief.." },
    { value: "warm_transfer", label: "Warm transfer", description: "AI provides a debrief to the next user after transferring the call." },
];

export const defaultStructureData = `
You will be given a call transcript and the AI system prompt. 
Extract the following Key Performance Indicators (KPIs):

    1. **Call Rating**
    - **Type**: Dropdown
    - **Options**: Excellent, Good, Average, Poor, Very Poor
    - **Purpose**: Measures customer satisfaction with service quality; “Excellent” shows strong customer happiness and loyalty.

    2. **Call Sentiment**
    - **Type**: Dropdown
    - **Options**: Very Positive, Positive, Neutral, Negative, Very Negative
    - **Purpose**: Gauges customer sentiment; “Very Positive” indicates high satisfaction and loyalty.`;

    export const backgroundSoundOptions = {
        "vapi": [
            { value: "off", label: "off" },
            { value: "office", label: "office" },
            { value: "default", label: "default" }
        ],
        "retell" : [ 
            { value: "coffee-shop", label: "coffee-shop" },
            { value: "convention-hall", label: "convention-hall" },
            { value: "summer-outdoor", label: "summer-outdoor" },
            { value: "mountain-outdoor", label: "mountain-outdoor" },
            { value: "static-noise", label: "static-noise" },
            { value: "call-center", label: "call-center" }
        ]
    }

    export const providerHideFields = {
        "vapi": [],
        "retell": ["detect_emotion", "filter_background_noice"], 
    }