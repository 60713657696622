export const defaultOption = {
    "Sub Account Permissions": {
        "manage_contacts":[
            {
                label: "AI Agents",
                name: "assistant_allowed",
                description: "Enable Contact to show in menu",
                type: "Switch",
            },
            {
                label: "Contacts/Outbound Calling",
                name: "contact_allowed",
                description: "Enable Contact to show in menu",
                type: "Switch",
            },
            {
                label: "Outbound Campaigns",
                name: "campaign_allowed",
                description: "Enable campaign to show in menu",
                type: "Switch",
            },
        
            {
                label: "Outbound Automation",
                name: "automation_allowed",
                description: "Enable automation to show in menu",
                type: "Switch",
            },
        {
            label: "Custom Fields",
            name: "custom_fields_allowed",
            description: "Enable customfields to show in menu",
            type: "Switch",
        },
        {
            label: "Import / Export",
            name: "import_export_allowed",
            description: "Enable import/export to show in menu",
            type: "Switch",
        },
        {
            label: "Webhook",
            name: "webhook_allowed",
            description: "Enable webhook to show in menu",
            type: "Switch",
        },
        ],
        "manage_insights":[
            {
                label: "Phone Numbers",
                name: "create_number",
                description: "Enable Contact to show in menu",
                type: "Switch",
            },
            {
                label: "Call Insights",
                name: "insights_allowed",
                description: "Enable Contact to show in menu",
                type: "Switch",
            },
            {
                label: "Call Volume",
                name: "calls_volume_enabled",
                description: "",
                type: "Switch",
            },
            {
                label: "Goal Conversion",
                name: "won_lost_enabled",
                description: "",
                type: "Switch",
            },
            {
                label: "Timing Analysis",
                name: "duration_enabled",
                description: "",
                type: "Switch",
            },
            {
                label: "Heat Map",
                name: "heat_map_enabled",
                description: "",
                type: "Switch",
            },

            {
                label: "A/B Testing",
                name: "ab_testing_enabled",
                description: "",
                type: "Switch",
            },
            {
                label: "Call Details",
                name: "call_logs_enabled",
                description: "",
                type: "Switch",
            },
           
        ],
    },
};

export const permissionsHeader = [
    {
        header: "Permissions",
        description: "Use Calendar to schedule appointments.",
    },
];