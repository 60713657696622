import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../Context/LanguageContext';

const GoogleTranslateWidget = () => {
  const { language } = useLanguage();
  useEffect(() => {
    const scriptId = "google-translate-script";

    // Only add the script if the selected language is not English and it's not already added
    if (language !== "" && language !== 'en' && !document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);

      window.googleTranslateElementInit = () => {
        if (!document.getElementById('google_translate_element_initialized')) {
          const translateElement = document.createElement('div');
          translateElement.id = 'google_translate_element_initialized';
          translateElement.style.display = 'none'; // Keep it hidden
          document.body.appendChild(translateElement);

          new window.google.translate.TranslateElement(
            { pageLanguage: 'en' },
            translateElement.id
          );
        }
      };
    }

    // Cleanup: Remove widget and script if switching back to English
    if (language !== "" && language === 'en' && document.getElementById(scriptId)) {
      const translateScript = document.getElementById(scriptId);
      if (translateScript) {
        translateScript.remove(); 
      }
      const translateElement = document.getElementById('google_translate_element_initialized');
      if (translateElement) {
        translateElement.remove();
      }
      window.location.reload();
    }
  }, [language]);

  useEffect(() => {
    
    if (language !== "" && language !== 'en') {
      handleLanguageChange(language);
    }
  }, [language]);

  // Function to change the language using the Google Translate dropdown
  const handleLanguageChange = (languageCode) => {
    setTimeout(() => {
      const selectElement = document.querySelector('select.goog-te-combo'); // Locate the dropdown
      if (selectElement) {
        selectElement.value = languageCode; // Set the desired language (e.g., 'es' for Spanish)
        selectElement.dispatchEvent(new Event('change')); // Trigger the change event
      }
    }, 1500); // Delay of 500ms
  };

  return (
    <div>
      
      <div className='translate_widget'>
        <div id="google_translate_element" style={{ display: 'none' }}></div>

      </div>
    </div>
  );
}

export default GoogleTranslateWidget;
