import React, { Fragment, useEffect, useState } from "react";
import { showToast, translateContent } from "../../Common/Appcommon";
import Loading from "../../Common/Loading";
import { Modal, ModalHeader, ModalBody, Button, Label, Input } from "reactstrap"; // ModalFooter
import requestApiService from "../../services/requestApi.service";


const Testsmtp = (props) => {
    let {closeModal,formData} = props;
    const [connectionvalue,setConnectionValues] = useState([]);
    const [IntegrationsKey, setIntegrationsKey] = useState({ toaddress: '', description: 'This is a system-generated e-mail. You’re receiving this as per your request to confirm your email setup connection. No further action is needed.'});
    const [errorMessage,seterrorMessage] = useState('');
    const [showButtonLoading ,setButtonLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await fetchEmailAccountDetails();
        })();
        return () => {
        };
    }, []);
    
    const fetchEmailAccountDetails = async () => {
        if(formData !== undefined && formData !== null){
            let connectionData = {
                data: {
                    name : formData['smtp_username'],
                    password: formData['smtp_password'],
                    url: formData['smtp_url'],
                    port: formData['smtp_port'],
                    protocol: formData['smtp_protocol']['value'],
                    sender: formData['sender_name'],
                    fromaddress: formData['sender_email'], 
                }
            }
            setConnectionValues(JSON.stringify(connectionData));
        }else{
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "LOAD_INTEGRATIONS",
                TYPE: "Email"
            }
            let response = await requestApiService.processServerRequest(params);
            if (response.issuccess) {
                let integrationInfo = response.data;
                if (integrationInfo.length > 0) {
                    setConnectionValues(response.data[0][2]);
                }
            }
        }
    }

    const handleInputChange = (e,field) =>{
        let value = e.target.value;
        setIntegrationsKey((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const ckeckConnection = async (to,description,values) => {
        setButtonLoading(true);
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "CHECK_EMAIL_CONNECTION",
            TYPE: "Email",
            toaddress: to,
            description: description,
            value: values
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            closeModal();
            showToast(translateContent("vm.testsmtp.msg"));
            setButtonLoading(false);
        }else if(response.errormsg){
            seterrorMessage(response.errormsg);
            setButtonLoading(false);
        }
    }


    const getMainContent = () => {
        return(
            <Fragment>
                <Fragment>
                <Label>To Address</Label>
                <Input className="mb-2" onChange={(e) => handleInputChange(e, 'toaddress')} maxLength={100} required value={IntegrationsKey.toaddress} />
                <Label>Description</Label>
                <Input className="mb-2" type="textarea" onChange={(e) => handleInputChange(e, 'description')} maxLength={100} required value={IntegrationsKey.description} />
                <div>
                {errorMessage && <em className="error">{errorMessage}</em>}
                </div>
                </Fragment> 
                <div className="agent-action-dropdown mt-4">
                <Loading
                    showLoader={showButtonLoading}
                    mainContent={
                    <Button
                    className= "container-footer-save theme-bg-g"
                    onClick={() => ckeckConnection(IntegrationsKey.toaddress,IntegrationsKey.description,connectionvalue)}
                    >
                    Send Test Email
                    </Button>}
                    >
                </Loading>
                </div>
            </Fragment>
        )
    } 


    return (
        <Fragment>
            <div className="agent-details">
                {
                    <Fragment>
                        <Modal className={'cal-action'} isOpen={true}> {/* isOpen={isOpen} */}
                            <ModalHeader toggle={closeModal}>
                                {"Email Integrations"}
                                <div className='md-2 del-subaccount-header'>
                                    {"Test SMTP Connection"}
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Fragment>
                                    {getMainContent()}
                                </Fragment>
                            </ModalBody>
                        </Modal>
                    </Fragment>
                }
            </div>
        </Fragment>
        
    )
}

export default Testsmtp;