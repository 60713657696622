import React, { Fragment, useState, useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Tabs from "react-responsive-tabs";
import {
    Row, Col, Button, UncontrolledDropdown,
    DropdownMenu,
    DropdownItem, DropdownToggle, InputGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png";
import ActionModal from './ActionModal';
import ListTable from '../../Common/ListTable';
import requestApi from '../../services/requestApi.service';
import { prepareCustomTableData,formatDate,confirmBox, showToast, handleErrorResponse, getSessionValue, translateContent } from '../../Common/Appcommon';
import TooltipItem from '../../Common/ToolTip';
import { Icon } from '@iconify/react';
import { afterCallActions,duringCallActions,defaultStructureData } from './constants';
import { createorUpdateActionForAgent, getAssistantDetails, getProviderKey } from './ProviderDetails';


const AgentActions = (props) => {
    const [isOpen, setModalOpen] = useState(false);
    let agent_id = props.agentID !== undefined && props.agentID !== null && props.agentID !== "" ? props.agentID : "";
    const [timeModal, setTimeModalOpen] = useState(false);
    const [curAction ,setCurAction] = useState("");
    const [actionsData, setActionsData] = useState([]);
    const [actionsColumn, setActionsColumn] = useState([]);
    const [actionId,setActionId] = useState("");
    const [actionUsed, setActionUsed] = useState([]); 
    const [rowData, setRowData] = useState([]); 
    const [currentTab,setCurrentTab] = useState("during_call");
    const proName = getSessionValue("agent_provider_name");

    const toggleModal = async (isReset = false) => {        
        setModalOpen(prevState => !prevState);
        if(typeof(isReset) !== "boolean" || isReset){
            setCurAction();
            setActionId();
            setRowData([]);
        }
    }

    const handleMenus = (value) => {
        setCurAction(value);
        toggleModal();
    }

    useEffect(async () => {
        setActionUsed([]);
        await getActions(currentTab);
    }, [agent_id]);
    
    const getActions = async(action="") =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_AGENT_ACTIONS",
            'agent_id': agent_id,
            "action":action
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            let actionfields = data[0] ?? [];
            let columndetails = data[1] ?? [];
            await prepareActionsData(actionfields, columndetails);
        }
    }

    const getEmptyParams = async (type) =>{
        let params = {};
        if(proName !== 'bland'){
            if (type === "extract_info"){
                params = {
                    analysisPlan: {
                        structuredDataPrompt : defaultStructureData ,
                        structuredDataSchema : {
                            "type": "object",
                            "properties": {
                                "Call Rating": { "type": "string" },
                                "Call Sentiment": { "type": "string" },
                            }
                        }
                    }
                }
            }else if (type === "vmail_message"){
                params = {
                    voicemailMessage:""
                }
            }else if (type === "transfer_call"){
                params = {
                    forwardingPhoneNumber:null
                }
            }else if(type === "end_call"){
                params = {
                    endCallFunctionEnabled : false,
                    endCallMessage : "",
                }
            }
        }
        return params;
    };

    const handleActionButtons = async(name, row) => {
        if(name === "edit"){
            setActionId(row.data_row.id);
            setCurAction(row.data_row.action_type);
            setRowData(row);
            toggleModal();
        }else if(name === "delete"){
            let confirm = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"),'warning','Delete','Cancel');
            if (!confirm) {
                return;
            }
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "DELETE_AGENT_ACTIONS",
                'agent_id': agent_id,
                "actionid":row.data_row.id,
                "action_type":row.data_row.action_type, 
                "action_details":row.data_row.action_details,               
            };
            let emptyParams = await getEmptyParams(row.action_type);
            if (Object.keys(emptyParams).length !== 0) {
                let res = await createorUpdateActionForAgent(proName,agent_id,emptyParams,props.agentDetails);
                if(!res.issuccess){
                    return;
                }
            }
            let response = await requestApi.processServerRequest(params);
            if(response.issuccess){
                showToast(translateContent("vm.taglist.delete.msg",{label : "Actions"}));
                setActionUsed((prev) => prev.filter(action => action !== row.data_row.action_type));
                await getActions(row.onaction);
            }else{
                handleErrorResponse(translateContent("vm.delete.unable.msg"));
            }
        }
    }

    const handleTabChange = async (key) => {
        let actionsArray = ["during_call","after_call"];
        setActionUsed([]);
        setCurrentTab(actionsArray[key]);
        await getActions(actionsArray[key]); 
    };
    const prepareActionsData = async (formData, listFields) => {
        let data = [];
        let columns = [];
        let tableData = await prepareCustomTableData(formData, listFields, false, false);
        data = tableData.data;
        columns = tableData.columns;
        columns.push(
            {
                name: "Actions",
                alias: 'actions',
                width: '20% !important',
                maxWidth: '0% !important',
                center: true,
                cell: (row) => (
                    <div>
                    </div>
                ),
            },
        );
        for (let cl = 0; cl < columns.length; cl++) {
            let column = columns[cl];
            if (column.alias === "actions") {
                column['cell'] = (row) => {
                    return (
                        <Fragment>
                            <div className={`theme-bg-info ms-4 me-3 cursor-pointer`} onClick={e => handleActionButtons("edit", row)}>
                                <TooltipItem
                                    id={'1'}
                                    text={"Edit Action"}
                                    content={<Icon icon="bx:edit-alt"/>}
                                />
                            </div>
                            <div className={`theme-bg-danger me-3 mt-1 cursor-pointer`} onClick={e => handleActionButtons("delete", row)}>
                                <TooltipItem
                                    id={'3'}
                                    text={"Delete Action"}
                                    content={<i class="fi fi-rs-trash"></i>}
                                />
                            </div>
                        </Fragment>
                    )
                }
            }
            if(column.alias === "lasthittime"){
                column['cell'] = (row) => {
                    let value = row.data_row.lasthittime !== undefined && row.data_row.lasthittime !== null ? row.data_row.lasthittime : "";
                    const date = value !== "NA" ? new Date(value): "NA";
                    if (!isNaN(date)) {
                        const formattedDate = formatDate(date, "mmmm dd, yyyy hh:MM TT"); 
                        return <span>{formattedDate}</span>;
                    }
                    return (
                        <span>{value}</span>
                    )
                }
            }
            if (column.name === "Action") {
                column["cell"] = (row) => {
                    setActionUsed(prevActions => [...prevActions, row.data_row.action_type]);
                    return (
                        <span>{row.data_row.action_type}</span>
                    )
                };
            }
        }
        setActionsData(data);
        setActionsColumn(columns);
    }
    
    const getOptions = (value) => {
        let afterCallAction = value !== "during_call" ? true : false;
        let options = afterCallAction ? afterCallActions : duringCallActions;
        return (
            <Fragment>
                <div className='w-100 agent-action-details'>
                    <UncontrolledDropdown className='agent-action-dropdown'>
                        <DropdownToggle className="p-0" color="link">
                            {options.some(option => !actionUsed.includes(option.value)) && (
                                <Button className="btn-icon date-filter-toggle theme-bg-g btn-hover-shine" color="link">
                                    <span className='pe-1'>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </span> {"Add Action"}
                                </Button>
                            )}
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu-right dropdown-menu-rounded">
                            {
                                options &&
                                options.map((option, index) => {
                                    let label = option.label;
                                    let value = option.value;
                                    if (actionUsed.includes(value)) {
                                        return null; 
                                    }
                                    let icon = option.icon !== undefined && option.icon !== null ? option.icon : "";
                                    return (
                                        <div>
                                            <DropdownItem onClick={e => handleMenus(value)}>
                                                <InputGroup>
                                                    <div className='d-flex align-items-center'>{icon}<span className='ps-2'>{label}</span></div>
                                                </InputGroup>
                                            </DropdownItem>
                                        </div>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {
                        actionsData.length > 0 ?
                            <ListTable data={actionsData} columns={actionsColumn} />
                        :
                        <div className='agent-nodata'>
                            <NoRecordFound
                                img={aiData}
                                isOnlyContent={true}
                                content={"No Users Found."}
                            />
                        </div>
                    }
                </div>
            </Fragment>
        );
    };

    const subtables = [
        {
            name: "During The Call",
            component:
                <Fragment>
                    {getOptions("during_call")}
                </Fragment>,
        },
        {
            name: "After The Call",
            component:
                <>
                    {getOptions("after_call")}
                </>,
        },
    ];

    const getSimpleTabs = () => {
        return subtables.map(({ name, component }, index) => ({
            key: index,
            title: name,
            getContent: () => component,
        }));
    }

  
    const getModalContent = () => {
        return (
            <>
                <Fragment>
                    <ActionModal
                        isOpen={isOpen}
                        toggle={toggleModal}
                        currentAction={curAction}
                        agent_id={agent_id}
                        getAgentAction={getActions}
                        actionId={actionId}
                        onAction={currentTab}
                        rowData={rowData}
                        selectedAgentDetails = {props.agentDetails}
                    />
                </Fragment>
            </>
        );
    }
    

    return (

        <Fragment>
            
            <div className='calls-details p-0'>
                <Row>
                    <Col md="12">
                        <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                            showMore={true}
                            transform={true}
                            showInkBar={true}
                            selectedTabKey={0}
                            transformWidth={400}
                            onChange={handleTabChange}
                            items={getSimpleTabs()} />
                    </Col>
                </Row>
            </div>
            {isOpen && getModalContent()}
            {/* {timeModal && getTimeModalContent()} */}
        </Fragment>
    );
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentActions);