import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { subDays } from 'date-fns';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Common from "../Common";
import DateFilter from "../../Common/DateFilter";
import { Container, Input } from "reactstrap";
import Summary from "./Summary";
import { isAgentAccount } from "../../Common/Appcommon";
import ProfitListView from "./ProfitListView";

class ProfitReport extends Component{
    isAgency = isAgentAccount();

    constructor(props) {
        super(props);
        const startDate = subDays(new Date(), 30); // Default to 7 days ago
        const endDate = new Date(); 
        this.state = {
            dateFilter: [startDate, endDate],
            showLoader : false,
            summary : [],
            Loader : false,
            searchTxt: ""
        };
    }

    getTitleContent = () => {
        return(
            <>
                <span>
                    Profit Report
                </span>
            </>
        )
    }

    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            this.setState({
                dateFilter : [startDate, endDate]
            });
        }
    }
    
    handleSearchChange = (e) => {
        let {name, value} = e.target;
        this.setState(prev => ({
            ...prev,
            searchTxt: {
                ...prev.searchTxt,
                [name]: value
            }
        }));
        
    }

    render(){

        const { dateFilter, searchTxt } = this.state;

        return(
            <Fragment>
                <Common
                    title={"Profit Report"}
                    titleContent={
                        this.getTitleContent()
                    }
                    mainContent={
                        <Fragment>
                            <Container fluid>
                                <Summary isAgency={this.isAgency}/>
                                <div className="d-flex">
                                    <div className="ms-auto mt-3 ">
                                        <Input
                                            name="account_name"
                                            type="text"
                                            placeholder="Subaccount Name Search..."
                                            value={searchTxt.account_name}
                                            onChange={e => this.handleSearchChange(e)}
                                            className=""
                                        />
                                    </div>
                                    <div className="ms-3 mt-3">
                                        <DateFilter 
                                            handleDateChange={this.handleDateChange}
                                            defaultSelectIndex={3}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <ProfitListView dateFilter={dateFilter} searchTxt={searchTxt}/>
                                </div>
                            </Container>
                        </Fragment>
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitReport);