export const DateFilterOptions = [
    {
        label : "Today",
        value : "today"
    },
    {
        label : "Yesterday",
        value : "yesterday"
    },
    {
        label : "Last 7 Days",
        value : "last_7_days"
    },
    {
        label : "Last 30 Days",
        value : "last_30_days"
    },
    {
        label : "This Month",
        value : "this_month"
    },
    {
        label : "Last Month",
        value : "last_month"
    },
    {
        label : "Custom",
        value : "custom"
    },
];

export const barChartsList = [
    {
        label: 'Calls by Type', value: 'total_calls_by_source', isHorizontal : false, type: 'total_calls_by_source', colordistributed: false, tooltip: 'count', operation: 'TOTAL_CALLS_BY_SOURCE'
    },
    {
        label: 'Calls by Status', value: 'total_calls_by_status', isHorizontal : false, type: 'total_calls_by_status', colordistributed: false, tooltip: 'count', operation: 'TOTAL_CALL_BY_STATUS'
    },
    {
        label: 'Calls by Goal Status', value: 'goal_achieved', isHorizontal : true, type: 'goal_achieved', colordistributed: false, tooltip: 'count', operation: 'GOAL_ACHIEVED'
    },
    {
        label: 'Add-on Spent', value: 'total_spent', isHorizontal : true, type: 'total_spent', colordistributed: false, tooltip: 'dollar', operation: 'GET_TOTAL_SPENT'
    },
    
    
];

export const graphListColumns = [
    {
        label: "Call Ended by Reason",
        operation: "GET_CALL_ENDED_BY_REASON",
        columns: [
            {
                name: "Reason",
                selector: row => row["reason"],
                sortable: true,
                width: '50%',
            },
            {
                name: "Call Count",
                selector: row => row["count"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Minutes",
                selector: row => row["minutes"],
                sortable: true,
                width: '25%',
            },
        ],
    },
    {
        label: "Call Minutes by Agents",
        operation: "GET_CALL_MINUTES_BY_ASSISTANT",
        columns: [
            {
                name: "AI Agent Name",
                selector: row => row["Agent_Name"],
                sortable: true,
                width: '50%',
            },
            {
                name: "Call Count",
                selector: row => row["count"],
                sortable: true,
                width: '25%',
            },
            {
                name: "Call Minutes",
                selector: row => row["minutes"],
                sortable: true,
                width: '25%',
            },
        ],
    },
];

export const PROTOCOL_LISTS = [
    {
        value : "ssl",
        label : "SSL"
    },
    {
        value : "tls",
        label : "TLS"
    }
]

export const CONFIGURE_PROTOCOL_LISTS = [
    { value: "aws", label: "Whitelabelled AWS SMTP" },
    { value: "custom", label: "Your Own SMTP" }
  ];

  export const agentLanguage = [
    {
        label: 'English (United States)', value: 'en_us', isHorizontal: false, type: 'en_us', colordistributed: false, tooltip: 'count', operation: 'ENGLISH_US'
    },
    {
        label: 'English (India)', value: 'en_in', isHorizontal: false, type: 'en_in', colordistributed: false, tooltip: 'count', operation: 'ENGLISH_INDIA'
    },
    {
        label: 'English (United Kingdom)', value: 'en_uk', isHorizontal: false, type: 'en_uk', colordistributed: false, tooltip: 'count', operation: 'ENGLISH_UK'
    },
    {
        label: 'German (Germany)', value: 'de_de', isHorizontal: false, type: 'de_de', colordistributed: false, tooltip: 'count', operation: 'GERMAN_GERMANY'
    },
    {
        label: 'Spanish (Spain)', value: 'es_es', isHorizontal: false, type: 'es_es', colordistributed: false, tooltip: 'count', operation: 'SPANISH_SPAIN'
    },
    {
        label: 'Spanish (Latin America)', value: 'es_la', isHorizontal: false, type: 'es_la', colordistributed: false, tooltip: 'count', operation: 'SPANISH_LATAM'
    },
    {
        label: 'Hindi (India)', value: 'hi_in', isHorizontal: false, type: 'hi_in', colordistributed: false, tooltip: 'count', operation: 'HINDI_INDIA'
    },
    {
        label: 'Japanese (Japan)', value: 'ja_jp', isHorizontal: false, type: 'ja_jp', colordistributed: false, tooltip: 'count', operation: 'JAPANESE_JAPAN'
    },
    {
        label: 'Portuguese (Portugal)', value: 'pt_pt', isHorizontal: false, type: 'pt_pt', colordistributed: false, tooltip: 'count', operation: 'PORTUGUESE_PORTUGAL'
    },
    {
        label: 'Portuguese (Brazil)', value: 'pt_br', isHorizontal: false, type: 'pt_br', colordistributed: false, tooltip: 'count', operation: 'PORTUGUESE_BRAZIL'
    },
    {
        label: 'French (France)', value: 'fr_fr', isHorizontal: false, type: 'fr_fr', colordistributed: false, tooltip: 'count', operation: 'FRENCH_FRANCE'
    },
    {
        label: 'Chinese (China)', value: 'zh_cn', isHorizontal: false, type: 'zh_cn', colordistributed: false, tooltip: 'count', operation: 'CHINESE_CHINA'
    },
    {
        label: 'Russian (Russia)', value: 'ru_ru', isHorizontal: false, type: 'ru_ru', colordistributed: false, tooltip: 'count', operation: 'RUSSIAN_RUSSIA'
    },
    {
        label: 'Italian (Italy)', value: 'it_it', isHorizontal: false, type: 'it_it', colordistributed: false, tooltip: 'count', operation: 'ITALIAN_ITALY'
    },
    {
        label: 'Korean (Korea)', value: 'ko_kr', isHorizontal: false, type: 'ko_kr', colordistributed: false, tooltip: 'count', operation: 'KOREAN_KOREA'
    },
    {
        label: 'Dutch (Netherlands)', value: 'nl_nl', isHorizontal: false, type: 'nl_nl', colordistributed: false, tooltip: 'count', operation: 'DUTCH_NETHERLANDS'
    },
    {
        label: 'Polish (Poland)', value: 'pl_pl', isHorizontal: false, type: 'pl_pl', colordistributed: false, tooltip: 'count', operation: 'POLISH_POLAND'
    },
    {
        label: 'Turkish (Turkey)', value: 'tr_tr', isHorizontal: false, type: 'tr_tr', colordistributed: false, tooltip: 'count', operation: 'TURKISH_TURKEY'
    },
    {
        label: 'Vietnamese (Vietnam)', value: 'vi_vn', isHorizontal: false, type: 'vi_vn', colordistributed: false, tooltip: 'count', operation: 'VIETNAMESE_VIETNAM'
    },
    {
        label: 'Multilingual (English and Spanish)', value: 'multilingual_en_es', isHorizontal: false, type: 'multilingual_en_es', colordistributed: false, tooltip: 'count', operation: 'MULTILINGUAL_EN_ES'
    },
];