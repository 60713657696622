import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import Pricing from './Pricing';
import { getOrganizationInfo } from '../../Common/Appcommon';

class PricingList extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            showloader:true,
        }
    }

    componentDidMount = async () => {

    };

    showloader = (status) => {
        this.setState({ isLoading: status });
    };

    render() {
        let accountinfo = {
            account_id : getOrganizationInfo("Account_ID"),
            data_row: {
                appid : getOrganizationInfo("appid"),
            }
        };
        return (
            <Fragment>
                <Common
                    title={
                        "Subscription Tiers"
                    }
                    titleContent={
                        <span>Subscription Tiers</span>
                    }
                    mainContent={
                        <Pricing 
                            accountinfo={accountinfo} 
                            isSubAccountView={false} 
                            showloader={this.showloader}
                            currency={this.props.currency}
                        />
                    }
                    mainLoader={this.state.isLoading}  
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingList);