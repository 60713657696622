import React, { useState, Fragment, useEffect } from 'react';
import Loading from '../../Common/Loading';
import { Button, Modal, ModalBody, Row,Col } from "reactstrap";
import { FieldTypeOptions } from './Constant';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import requestApi from "../../services/requestApi.service";
import { getPickListItems, getPickLists, handleErrorResponse, populateFormDetails, showToast,isMobile, translateContent } from '../../Common/Appcommon';
import { use } from 'react';


const FieldModal = (props) => {
    let { isModalOpen, fd, formDetail, isNew, toggleModal, setFormDetail } = props;
    let contactform = "table_74";
    const [showLoading, setShowLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOptionNeeded, setIsOptionsNeeded] = useState(false);
    const [uformDetail, setUpdateFormDetail] = useState(formDetail);
    const [optionsCount, setOptionsCount] = useState(1);
    const [newOptions, setNewOptions] = useState({});
    const [formData, setFormData] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    let buttonClickRef = React.createRef();
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();

    useEffect(() => {
        (async () => {
            if (!isNew && fd.length > 0) {
                await initiateEditFormData(fd);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const initiateEditFormData = async (fd) => {
        let fieldName = fd[2];
        let fieldLabel = fd[3];
        let fieldType = fd[4];
        let isMandatory = parseInt(fd[16]);
        let data = {};
        data['fieldName'] = fieldLabel;
        data['columnName'] = fieldName;
        setFormData(data);
        setIsChecked(isMandatory === 1);
        let foptions = FieldTypeOptions;
        for (let fo = 0; fo < foptions.length; fo++) {
            let opt = foptions[fo];
            if (opt.value === fieldType) {
                setSelectedOption(opt);
                break;
            }
        }
        if (fieldType === "ComboBox") {
            let defaultValue = fd[19];
            if (defaultValue !== "") {
                let pickListDetials = defaultValue.split(",");
                let pickListName = pickListDetials[0];
                let pickListItems = getPickListItems(pickListName);
                if (pickListItems.length > 0) {
                    let pickOptions = {};
                    pickListItems.map((listItem, index) => {
                        pickOptions[index] = listItem;
                    });
                    setOptionsCount(Object.keys(pickOptions).length);
                    setNewOptions(pickOptions);
                    setIsOptionsNeeded(true);
                } else {
                    setOptionsCount(1);
                    setIsOptionsNeeded(true);
                }
            } else {
                setOptionsCount(1);
                setIsOptionsNeeded(true);
            }
        }

    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const onDropDownOptionInputChange = async (e, index) => {
        let name = e.target.name;
        let value = e.target.value;
        setNewOptions((prevData) => ({
            ...prevData,
            [index]: value
        }));
    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const onFieldTypeSelection = (selected) => {
        setSelectedOption(selected);
        if (selected.value === "ComboBox") {
            if(optionsCount == 0){
                setOptionsCount(1);
            }
            setIsOptionsNeeded(true);
        }else{
            setIsOptionsNeeded(false);
        }
    }
    const onFieldSave = async () => {
        await buttonClickRef.current.click();
    }

    const onFormSubmit = async () => {
        let formInfo = formDetail[0];
        let moduleName = formInfo[0];
        let formName = formInfo[1];
        let formLabel = formInfo[2];
        let fieldName = formData.columnName !== undefined && formData.columnName !== null && formData.columnName !== "" ? formData.columnName : formData.fieldName;
        let fieldLabel = formData.fieldName;
        if(fieldLabel.trim() === ""){
            // handleErrorResponse(translateContent("vm.customfield.empty.msg"));
            setError("fieldName", {
                type: "manual",
                message: "Field name is required",
            });
            return;
        }
        fieldName = fieldName.replace(/ /g, "_");
        let fieldType = selectedOption.value;
        if(fieldType === "ComboBox"){
            if(Object.keys(newOptions).length === 0){
                showToast("Atleast 1 option is required.", "top-right", "error");
                return;
            }
        }
        let customFieldArr = formDetail[1];
        for (let a = 0; a < customFieldArr.length; a++) {
            let customLabel = customFieldArr[a][3];
            if((isNew) || (!isNew && fd[3] !== fieldLabel)){
                if((fieldLabel === customLabel)){
                    handleErrorResponse(translateContent("vm.customfield.duplicate.msg"));
                    return;
                }
            }
        }
        setShowLoading(true);
        let params = {
            "OPERATION": isNew ? "FIELDADD" : "FIELDUPDATE",
            "ENTITY": "NGBuilder",
            "modulename": moduleName,
            "screenname": formName,
            "formtype": "",
            "fieldoldname": isNew ? fieldName : fd[2],
            "fieldname": fieldName,
            "label": fieldLabel,
            "langth": "100",
            "seatchtype": "ALL",
            "fieldtype": fieldType,
            "enabletype": "Normal",
            "isguifield": false,
            "ismandatary": isChecked,
            "isunique": false,
            "isquickcreate": false,
            "ismassedit": false,
            "defaultval": "",
            "defaultvisible": true,
            "fieldorder": isNew ? "0" : fd[22],
            "issystemfield": false,
            "operationType": 1,
            "isprimarychanged": false,
            "isfiedtypechanged": false,
            "linkname": "",
            "linkurl": "",
            "tooltip": "",
            "instruction": "",
            "isconfighide": isNew ? false : parseInt(fd[10]) === 1,
            "isviewhide": false,
            "viewcolminwidth": "75",
            "viewcolmaxwidth": "225",
            "viewcolprefwidth": "150",
            "issearch": "0",
            "isdisplay": false,
            "groupname": "",
            "islabeldisplay": true,
            "isviewmandatory": false,
            "isrollingupdate": true,
            "iscustomfield": true,
            "fieldvisiblefor": "ALL",
            "ismobileiew": false,
            "combofieldvalues": "",
            "isrefformnamechanged": false,
            "isreffieldnamechanged": false,
            "filter_formname": "",
            "filter_formtype": "0",
            "filter_list": "",
            "isMinibuilder": true,
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            if (fieldType === "ComboBox") {
                await createDropDownItems(fieldName, formName);
            } else {
                //let newFD = await populateFormDetails(contactform);
                //setUpdateFormDetail(newFD);
                //setFormDetail(newFD);
                setShowLoading(false);
                toggleModal(true);
            }
        } else {
            setShowLoading(false);
            if(response.errormsg.toLowerCase().includes('duplicate')){
                response.errormsg = translateContent("vm.customfield.duplicate.msg");
            }
            handleErrorResponse(response.errormsg)
        }
    }

    const createDropDownItems = async (fieldName, formname) => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "PICKLIST_ITEMS",
            "ITEMS": newOptions,
            "FIELDNAME": fieldName,
            "FORMNAME": formname
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            //let newFD = await populateFormDetails(contactform);
            //setUpdateFormDetail(newFD);
            //setFormDetail(newFD);
            await getPickLists();
            setShowLoading(false);
            toggleModal(true);
        }
    }

    const addMoreOption = (e, index) => {
        setOptionsCount((prevCount) => prevCount + 1);
        /*setNewOptions((prevData) => ({
            ...prevData,
            [index]: ""
        }));*/
    }

    const removeMoreOption = (e, rmindex) => {
        let newOpt = newOptions;
        let reArragedOptions = {};
        Object.keys(newOpt).map((key, index) => {
            let option = newOpt[key];
            if (index !== rmindex) {
                console.log(rmindex + " -- > " + index)
                let newIndex = Object.keys(reArragedOptions).length;
                console.log(newIndex + " <NEW> " + option)
                reArragedOptions[newIndex] = option;
            }
        })
        setNewOptions(reArragedOptions);
        setOptionsCount((prevCount) => prevCount - 1);
    }

    const getHeaderContent = () =>{
        return(
        <div className="body-header ps-3 pe-3">
                <Row>
                    { 
                        isMobile() ? (
                            <Fragment>
                                {/* <Col > */}
                                    <div className='custom-field-type'>
                                    <label className={'form-label'}>Field Label </label>
                                        <Controller
                                            name={"fieldName"}
                                            control={control}
                                            rules={{ required: (formData.fieldName === undefined || formData.fieldName === "" || formData.fieldName === null) ? "Field name is required" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        name="fieldName"
                                                        id="fieldName"
                                                        defaultValue={formData.fieldName}
                                                        placeholder='Enter field name'
                                                        autoComplete={"off"}
                                                        onChange={(e) => 
                                                            onInputChange(e)
                                                        }
                                                        className={`form-control mt-1 ${errors.fieldName ? 'is-invalid' : ''}`}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors["fieldName"] &&
                                            <em className={`error invalid-feedback`}>{errors["fieldName"].message}</em>}    
                                    </div>
                                {/* </Col> */}
                                    <div className='custom-field-type'>
                                        <label className={'form-label'}>Field Type </label>
                                    </div>
                                    <div className='mb-2 custom-field-type'>
                                        <Controller
                                            name={"fieldType_Options"}
                                            control={control}
                                            rules={{ required: (selectedOption === undefined || selectedOption === "" || selectedOption === null) ? "Field type is required" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        name="fieldType_Options"
                                                        id="fieldType_Options"
                                                        value={selectedOption}
                                                        placeholder='Choose field type'
                                                        onChange={(e) => {
                                                            onFieldTypeSelection(e);
                                                        }} options={FieldTypeOptions}
                                                        className={`basic-multi-select`}
                                                        classNamePrefix="wa-select"
                                                    />
                                                )
                                            }}
                                        />
                                        {errors["fieldType_Options"] &&
                                            <em className={`error invalid-feedback`}>{errors["fieldType_Options"].message}</em>} 
                                    </div>
                                <Col>
                                    <div>
                                        <label className={'form-label custom-checkbox'}>
                                            <input
                                                type="checkbox"
                                                checked={isChecked} // Bind to state
                                                onChange={handleCheckboxChange} // Update state on change
                                            />
                                            <span className="checkmark"></span>
                                            <span> Mandatory Field</span>
                                        </label>
                                    </div>
                                </Col>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={"field-section mt-1 w-100 d-flex"}>
                                    <div className='col-md-3 d-flex justify-end align-center'>
                                        <label className={'form-label'}>Field Label </label>
                                    </div>
                                    <div className='col-md-7 justify-end align-center ml-3'>

                                        <Controller
                                            name={"fieldName"}
                                            control={control}
                                            rules={{ required: (formData.fieldName === undefined || formData.fieldName === "" || formData.fieldName === null) ? "Field name is required" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        name="fieldName"
                                                        id="fieldName"
                                                        defaultValue={formData.fieldName}
                                                        placeholder='Enter field name'
                                                        autoComplete={"off"}
                                                        onChange={(e) => 
                                                            onInputChange(e)
                                                        }
                                                        className={`form-control ${errors.fieldName ? 'is-invalid' : ''}`}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors["fieldName"] &&
                                            <em className={`error invalid-feedback`}>{errors["fieldName"].message}</em>}
                                    </div>
                                </div>
                                <div className={"field-section mt-1 w-100 d-flex"}>
                                    <div className='col-md-3 d-flex justify-end align-center'>
                                        <label className={'form-label'}>Field Type </label>
                                    </div>
                                    <div className='col-md-7 justify-end align-center ml-3'>

                                        <Controller
                                            name={"fieldType_Options"}
                                            control={control}
                                            rules={{ required: (selectedOption === undefined || selectedOption === "" || selectedOption === null) ? "Field type is required" : "" }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        name="fieldType_Options"
                                                        id="fieldType_Options"
                                                        value={selectedOption}
                                                        placeholder='Choose field type'
                                                        onChange={(e) => {
                                                            onFieldTypeSelection(e);
                                                        }} options={FieldTypeOptions}
                                                        className={`basic-multi-select`}
                                                        classNamePrefix="wa-select"
                                                        isDisabled={!isNew}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors["fieldType_Options"] &&
                                            <em className={`error invalid-feedback`}>{errors["fieldType_Options"].message}</em>}
                                    </div>
                                </div>
                                <div className={"field-section mt-1 w-100 d-flex"}>
                                    <div className='col-md-3 d-flex justify-end align-center'>

                                    </div>
                                    <div className='col-md-7 justify-end align-center ml-3'>
                                        <label className={'form-label custom-checkbox'}>
                                            <input
                                                type="checkbox"
                                                checked={isChecked} // Bind to state
                                                onChange={handleCheckboxChange} // Update state on change
                                            />
                                            <span className="checkmark"></span>
                                            <span> Mandatory Field</span>
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                </Row>
            </div>
        );
    }

    const getModalBodyContent = () => {
        return (
            <Fragment><div className='vm-modal-header'>
                <div className='d-flex'>
                    {isMobile() ? (
                    <>
                        <div className="create-field-btn">
                            <Col>
                                <span className="modal-title">{isNew ? "Create Field" : "Edit Field"}</span>
                            </Col>
                            <Col>
                                <a
                                    className="custom-btn-1 btn btn-dark cursor-pointer mr-1 m-1"
                                    onClick={toggleModal}
                                >
                                    Cancel
                                </a>
                                <Button onClick={onFieldSave} className="theme-bg-g btn btn-dark ml-1 m-1">
                                    Save
                                </Button>
                            </Col>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-6 col-lg-6 d-flex align-center">
                            <span className="modal-title">{isNew ? "Create Field" : "Edit Field"}</span>
                        </div>
                        <div className="col-md-6 col-lg-6 d-flex justify-end">
                            <a
                                className="custom-btn-1 btn btn-dark cursor-pointer mr-1 m-1"
                                onClick={toggleModal}
                            >
                                Cancel
                            </a>
                            <Button onClick={onFieldSave} className="theme-bg-g btn btn-dark ml-1 m-1">
                                Save
                            </Button>
                        </div>
                    </>
                )}
                </div>
            </div>
                <div>
                    <form id={`custom-field}`} name={'custom-fieldform'} onSubmit={handleSubmit(onFormSubmit)}>
                        <div className='field-details data-form'>
                            <Row className='p-3'>
                                {getHeaderContent()}
                                <div className={"field-section mt-1 w-100 d-flex"}>
                                    <div className='col-md-3 d-flex justify-end align-center'>
                                    </div>
                                    {/* <div className='col-md-7 justify-end align-center ml-3'>
                                        <label className={'form-label custom-checkbox'}>
                                            <input
                                                type="checkbox"
                                                checked={isChecked} // Bind to state
                                                onChange={handleCheckboxChange} // Update state on change
                                            />
                                            <span className="checkmark"></span>
                                            <span> Mandatory Field</span>
                                        </label>
                                    </div> */}
                                </div>
                                {
                                    isOptionNeeded && <Fragment>
                                        {
                                            Array.from({ length: optionsCount }).map((_, index) => {
                                                return (
                                                    <div className={"field-section mt-1 w-100 d-flex"}>
                                                        <div className={`${isMobile() ? '' : 'col-md-3'} d-flex justify-end align-center`}>

                                                        </div>
                                                        <div className={`col-md-7 justify-end align-center ${isMobile() ? '' :'ml-3'}`}>
                                                            <input
                                                                type="text"
                                                                className='form-control'
                                                                value={newOptions[index] !== undefined ? newOptions[index] : ""}
                                                                placeholder={"Dropdown option " + (index + 1)}
                                                                onChange={(e) => { onDropDownOptionInputChange(e, index) }} // Update state on change
                                                            />
                                                        </div>
                                                        <div className='col-md-2 d-flex justify-start align-center'>
                                                            <div onClick={(e) => { addMoreOption(e, index) }} className='p-1 cursor-pointer'>
                                                                <i class="fi fi-rr-add add-bg"></i>
                                                            </div>
                                                            {optionsCount > 1 &&
                                                            <div onClick={(e) => { removeMoreOption(e, index) }} className='p-1 cursor-pointer'>
                                                                <i class="fi fi-rr-cross-circle remove-bg"></i>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Fragment>
                                }
                            </Row>
                        </div>
                        <div className={'mt-3 text-center hide'}>
                            <button ref={buttonClickRef}
                                className="btn-wide mb-2 me-2 lyte-button lytePrimaryBtn"
                                size="lg"
                                color="primary">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Modal className={'vm-modal-sg'} isOpen={isModalOpen} >
                <ModalBody>
                    <Loading showLoader={showLoading} mainContent={getModalBodyContent()} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default FieldModal;