import React, { Fragment, useEffect, useState } from 'react';
import Switch from "react-switch";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { Form, Row, Col, Label, Input, Container, Button, FormGroup, InputGroup } from "reactstrap";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import PopOverItem from "../../Common/PopOverItem"
import Loading from '../../Common/Loading';
import { checkIsAdmin, getSessionValue,parseJSON } from '../../Common/Appcommon';
import { useTheme } from '../../Context/ThemeContext';
import Select from "react-select";
import Tabs from "react-responsive-tabs";
import AgentActions from './AgentActions';
import { backgroundSoundOptions, providerHideFields } from './constants';
import { solarizedDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const AgentMainContent = (props) => {
    const { rowData: initialRowData, fieldNames,voiceList,isLoading,allowNewConfig,knowledgeBase,selectedVoice,providerName} = props;
    const { handleSubmit, control, reset, formState: { errors } ,trigger} =useForm({});
    const [showSecret, setShowSecret] = useState(true);
    let isAllowEdit = getSessionValue('isAllowEditAgent') === true || getSessionValue('isAllowEditAgent') === "true" ? true : false;
    const [isAdmin,setAdmin] = useState(true);
    const [voice,setVoice] = useState(null);
    let isAllowVoiceField = getSessionValue('isAllowVoiceField') === true || getSessionValue('isAllowVoiceField') === "true" ? true : false;
    useEffect(() => {
        const fetchData = async () => {
            reset(initialRowData);
            let adminProfile = await checkIsAdmin();
            setAdmin(adminProfile);
        };
    
        fetchData();
    }, [initialRowData, reset]); // Ensure dependencies are correctly handled
    

    const [draggingSlider, setDraggingSlider] = useState(null);
    const { theme } = useTheme();
    const [fileTypeValues,setFileTypeValues] = useState({
        "knowledgebase": initialRowData?.[fieldNames["knowledgebase"]] ?? "",
    });
    const [selectedOption, setSelectedOption] = useState({
        "play_background_sound" : null,
        "idle_message": null,
        "knowledgebase": null,
    });
    const [sliderValues, setSliderValues] = useState({
        "speech_temperature": 0,
        "voice_speed": 0,
        "interruption_sensitivity": 0,
        "max_duration": 0,
        "silence_timeout": 0,
        "idle_max_message":0,
        "idle_timeout": 0,
    });
    const [switchIsActive, setSwitchActive] = useState({
        "detect_emotion": false,
        "filter_background_noice" : false,
    });

    const isDark = theme === 'dark-theme'? true : false;
    const configViewFieldSave = [
        {
            label: "AI Agent Name",
            name: "Agent Name",
            datalabel: fieldNames['Agent_Name'],
            type: "text",
            description: "",
            placeholder: "Name Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "Voice",
            name: "Voice",
            datalabel: fieldNames['voice_id'],
            type: "text",
            description: "Voice.",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
        {
            label: "First Message",
            name: "Call Start Message",
            datalabel: fieldNames['Call_Start_Message'],
            type: "textarea",
            description: "The first message that the AI Agent will say.",
            placeholder: "First Message Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "System Prompt",
            name: "System Prompt",
            datalabel: fieldNames['System_Prompt'],
            type: "textarea",
            description: "Give AI agents intelligence with information (context, role, personality, instructions, etc.)",
            placeholder: "System Prompt Here",
            required: false,
            isDisabled: !isAllowEdit,
        }
    ];
    const roleTabFields = [
        {
            label: "Welcome Message",
            name: "Call Start Message",
            datalabel: fieldNames['Call_Start_Message'],
            type: "textarea",
            description: "The first message that the AI Agent will say.",
            placeholder: "First Message Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "Knowledge Base",
            name: "knowledgebase",
            datalabel: fieldNames['knowledgebase'],
            type: "dropdown",
            description: "knowledgebase",
            placeholder: "Knowledge Base Here",
            required: false,
            options : knowledgeBase || [],
            isDisabled: !isAllowEdit,
            allowMulti:false,
        },
        {
            label: "Task Prompt",
            name: "System Prompt",
            datalabel: fieldNames['System_Prompt'],
            type: "textarea",
            description: "Give AI agents intelligence with information (context, role, personality, instructions, etc.)",
            placeholder: "System Prompt Here",
            required: true,
            isDisabled: !isAllowEdit,
        }
    ];

    const agentInfoFields = [
        {
            label: "AI Agent Name",
            name: "Agent Name",
            datalabel: fieldNames['Agent_Name'],
            type: "text",
            description: "",
            placeholder: "Name Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "Voice",
            name: "Voice",
            datalabel: fieldNames['voice_id'],
            type: "text",
            description: "Voice.",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
    ]

    const SpeechSettingsFields = [
        {
            label: "Speech Temperature",
            name: "speech_temperature",
            datalabel: fieldNames['speech_temperature'],
            type: "slider",
            description: "Speech Temperature",
            placeholder: "Name Here",
            required: false,
            isDisabled: !isAllowEdit,
            min : 0,
            max : 2,
            step : 1,
            marks : {0:"0",2:"2"}
        },
        {
            label: "Detect Emotion",
            name: "detect_emotion",
            datalabel: fieldNames['detect_emotion'],
            type: "switch",
            description: "Detect Emotio",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
        // {
        //     label: "Voice Speed",
        //     name: "voice_speed",
        //     datalabel: fieldNames['voice_speed'],
        //     type: "slider",
        //     description: "Voice.",
        //     placeholder: "Choose a voice",
        //     required: false,
        //     isDisabled: !isAllowEdit,
        //     autoComplete :"off",
        //     min : 0,
        //     max : 2,
        //     step : 1,
        //     marks : {0:"0",2:"2"}
        // },
        {
            label: "Interruption Sensitivity",
            name: "interruption_sensitivity",
            datalabel: fieldNames['interruption_sensitivity'],
            type: "slider",
            description: "Interruption Sensitivity",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            min : 0,
            max : 10,
            step : 1,
            marks : {0:"0 (sec)",10:"10 (sec)"}
        },
        {
            label: "Filter Background Noise",
            name: "filter_background_noice",
            datalabel: fieldNames['filter_background_noice'],
            type: "switch",
            description: "Filter Background Noise",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
        {
            label: "Play Background Sound",
            name: "play_background_sound",
            datalabel: fieldNames['play_background_sound'],
            type: "dropdown",
            description: "Play Background Sound",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            options: backgroundSoundOptions[providerName],
            allowMulti : false,
        },
    ]

    const CallSettingsFields = [
        {
            label: "Maximum call duration",
            name: "max_duration",
            datalabel: fieldNames['max_duration'],
            type: "slider",
            description: "Maximum call duration",
            placeholder: "Name Here",
            required: false,
            isDisabled: !isAllowEdit,
            min : 10,
            max : 43200,
            step : 1,
            marks : {10:"10 (sec)",43200:"43200 (sec)"}
        },
        {
            label: "End call on silence",
            name: "silence_timeout",
            datalabel: fieldNames['silence_timeout'],
            type: "slider",
            description: "End call on silence",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            min : 10,
            max : 3600,
            step : 1,
            marks : {10:"10 (sec)",3600:"3600 (sec)"}
        },
        {
            label: "Idle Messages",
            name: "idle_message",
            datalabel: fieldNames['idle_message'],
            type: "dropdown",
            description: "Idle Messages",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            options :[
                { value: "Are you still there?", label: "Are you still there?" },
                { value: "Is there anything else you need help with?", label: "Is there anything else you need help with?" },
                { value: "Feel free to ask me any questions.", label: "Feel free to ask me any questions." },
                { value: "How can I assist you further?", label: "How can I assist you further?" },
                { value: "Let me know if there's anything you need.", label: "Let me know if there's anything you need." },
                { value: "I'm still here if you need assistance.", label: "I'm still here if you need assistance." },
                { value: "I'm ready to help whenever you are.", label: "I'm ready to help whenever you are." },
                { value: "Is there something specific you're looking for?", label: "Is there something specific you're looking for?" },
                { value: "I'm here to help with any questions you have.", label: "I'm here to help with any questions you have." }
            ],
            allowMulti : true,
            
        },
        {
            label: "Max Idle Messages",
            name: "idle_max_message",
            datalabel: fieldNames['idle_max_message'],
            type: "slider",
            description: "Max Idle Messages",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            min : 1,
            max : 10,
            step : 1,
            marks : {1:"1",10:"10"}
        },
        {
            label: "Idle Timeout",
            name: "idle_timeout",
            datalabel: fieldNames['idle_timeout'],
            type: "slider",
            description: "Idle Timeout",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off",
            min : 5,
            max : 10,
            step : 1,
            marks : {5: "5 (sec)",10:"10 (sec)"}
        },
    ]

    /* const actionInfoFields = [
        {
            label: "AI Agent Name",
            name: "Agent Name",
            datalabel: fieldNames['Agent_Name'],
            type: "text",
            description: "",
            placeholder: "Name Here",
            required: true,
            isDisabled: !isAllowEdit,
        },
        {
            label: "Voice",
            name: "Voice",
            datalabel: fieldNames['voice_id'],
            type: "text",
            description: "Voice.",
            placeholder: "Choose a voice",
            required: false,
            isDisabled: !isAllowEdit,
            autoComplete :"off"
        },
    ]

    const agentTabs = [
        {
            name :"Profile",
            id : "profile"
        },
        {
            name :"Role",
            id : "role"
        },
        {
            name :"Actions",
            id : "action"
        },
    ]; */

    const advancedSettingsFields = [
        {
            label: "Server URL",
            name: "server_url",
            type: "text",
            description: "This is the URL that will be used to communicate messages via HTTP POST requests for retrieving context, making function calls, and generating end-of-call reports.",
            placeholder: "Give a webhook URL",
            required: true,
        },
        {
            label: "Server URL Secret",
            name: "secret",
            type: "text",
            description: "For security reasons, you can set a secret key to be sent with each request to the server URL as header X-SECRET.",
            placeholder: "Give a webhook URL Secret",
            required: true,
            autoComplete :"off"
        },
    ]

    useEffect(() => {
        // Reset initial row data
        reset(initialRowData);
        let idleMessages = initialRowData?.[fieldNames["idle_message"]] ?? [] ;
        if (typeof idleMessages === "string" && idleMessages != "") {
            idleMessages = idleMessages.split(",").map(msg => {
                const trimmedMsg = msg.trim();
                return { value: trimmedMsg, label: trimmedMsg };
            });
        }
        let backgroundOption =  initialRowData?.[fieldNames["play_background_sound"]];
        if(typeof backgroundOption === "string"){
            let backgroundMsg = parseJSON(initialRowData?.[fieldNames["play_background_sound"]] ?? "") || [];
            backgroundOption = backgroundMsg[0] 
                ? { value: backgroundMsg[0], label: backgroundMsg[0] } 
            : null;
        }

        let knBase = initialRowData?.[fieldNames["knowledgebase"]] ?? "";
        if(typeof knBase === "string"){
            knBase = parseJSON(initialRowData?.[fieldNames["knowledgebase"]]) ?? null;
        }
        setSelectedOption((prevValues) => ({
            ...prevValues,
            idle_message: idleMessages, // Array for multi-select
            play_background_sound: backgroundOption, // Object for single select
            knowledgebase: knBase,
        }));

        setSliderValues((prevValues) => ({
            ...prevValues,
            "speech_temperature": initialRowData?.[fieldNames["speech_temperature"]] ?? 0,
            "voice_speed": initialRowData?.[fieldNames["voice_speed"]] ?? 0,
            "interruption_sensitivity": initialRowData?.[fieldNames["interruption_sensitivity"]] ?? 0,
            "max_duration": initialRowData?.[fieldNames["max_duration"]] ?? 0,
            "silence_timeout": initialRowData?.[fieldNames["silence_timeout"]] ?? 0,
            "idle_max_message": initialRowData?.[fieldNames["idle_max_message"]] ?? 0,
            "idle_timeout": initialRowData?.[fieldNames["idle_timeout"]] ?? 0,            
        }))

        setSwitchActive((prevValues) => ({
            ...prevValues,
            "detect_emotion": initialRowData?.[fieldNames["detect_emotion"]] ?? false,
            "filter_background_noice" : initialRowData?.[fieldNames["filter_background_noice"]] ?? false,                   
        }));
    }, [initialRowData, reset, fieldNames]);

    useEffect(()=>{
        let voiceId = selectedVoice;
        let voice = voiceList.flat().find(voice => voice.Voice_Id === voiceId);
        setVoice(voice);
    },[selectedVoice])

    const handleFileUpload = async (event,field) => {
        const fileUploaded = event.target.files[0];
        if (!fileUploaded) {
          console.error("No file selected.");
          return;
        }
        const fileName = fileUploaded.name;
        field.onChange(fileName);
        // setFileTypeValues((prevValues) => ({
        //     ...prevValues,
        //     [name]: fileName,
        // }));    
        // Prepare the FormData
        const formData = new FormData();
        formData.append("file", fileUploaded); // 'file' matches the expected field in the API
        try {
            const response = await fetch("https://api.vapi.ai/file", {
            method: "POST",
            headers: {
                "Authorization": "Bearer 9b2b38fe-7c17-4ec6-85e3-7a13b377747d", // Replace with your actual API key
            },
            body: formData,
        });
        if (response.ok) {
            //const body = await response.json();
        } else {
            console.error("Error response:", response.status, await response.text());
        }
        } catch (error) {
            console.error("Upload failed:", error);
        }
    };

    const onSubmit = async (data) => {
        const isValid = await trigger();
        if (!isValid) {
            console.log("Validation failed!", errors);
            return;
        }
        let  Tabs = [
            configViewFieldSave, roleTabFields, advancedSettingsFields,
            SpeechSettingsFields, CallSettingsFields
        ];
        
        let stateVariable = {
            dropdown: selectedOption,
        };
        Tabs.forEach((tab) => {
            tab.forEach((field) => {
                if (typeof stateVariable[field.type] === 'string') {
                    if(data[field.name]){
                        data[field.name] = stateVariable[field.type][field.name];
                    }
                }
            });
        });
        data['voice_id'] =voice?.Voice_Id;
        props.onSubmit(data);
    };

    const handleChange = (e, type = "") => {
        let {name, value} = e.target;
        if(type === "adv_settings"){
            let adv_settings = {...parseJSON(initialRowData["advanced settings"]) };
            adv_settings[name] = value;
            props.onChange("advanced settings", JSON.stringify(adv_settings));
        }else{
            props.onChange(name, value)
        }
    };

    const openModel = (e) => {
        props.modalOpen(voice);
    };

    const handleSliderChange = (e,field,name) => {
        const newValue = e.target.value;
        field.onChange(newValue);
        //value = newValue;
        setSliderValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    };

    const handleSliderDragStart = (name) => {
        setDraggingSlider(name); // Set the currently dragged slider
    };

    const handleSliderDragEnd = () => {
        setTimeout(() => {
            setDraggingSlider(null);
        }, 1000);
    };

    const sliderButton = (name = "", marks, min, max, step,field) => {
        let value = sliderValues[name]; // Get the current slider value
        value = parseInt(value);
        return (
            <div className="slider-container" style={{ position: "relative" }}>
                {/* Tooltip for the currently dragged slider */}
                {draggingSlider === name ? (
                    <span
                        className={`tooltip slider-tooltip `}
                        style={{
                            position: "absolute",
                            left: `${((value - min) / (max - min)) * 100}%`,
                            transform: "translateX(-50%)", // Center tooltip
                            whiteSpace: "nowrap",
                        }}
                    >
                        {value}
                    </span>
                ) : null}

                {/* Slider input */}
                <input
                    name={name}
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    className="custom-slider"
                    onChange={e => handleSliderChange(e,field,name)}
                    onMouseDown={e => handleSliderDragStart(name)}
                    onMouseUp={e => handleSliderDragEnd}
                    onTouchStart={e => handleSliderDragStart(name)}
                    onTouchEnd={e => handleSliderDragEnd}
                    style={{
                        background: `linear-gradient(to right, var(--theme-main) 0%, var(--theme-main) ${
                            ((value - min) / (max - min)) * 100
                        }%, #e0e0e0 ${
                            ((value - min) / (max - min)) * 100
                        }%, #e0e0e0 100%)`,
                    }}
                />

                {/* Dynamic marks */}
                <div className="marks" style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    {Object.entries(marks).map(([key, label], index) => (
                        <span key={index}>{label}</span>
                    ))}
                </div>
            </div>
        );
    };

    const handleSwitch = (name,newValue,field) =>{
        //const value = switchIsActive[name]; // Get the current slider value
        field.onChange(newValue);
        setSwitchActive((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    }

    const onSelectChange = (name,option,field) =>{
        //let op = option ?.length > 0 ? option : {}; 
        field.onChange(option);
        setSelectedOption((prevValues) => ({
            ...prevValues,
            [name]: option,
        }));
    }

    const customFilter = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase()); // Default filtering
      };

    const switchButton = (name,field) =>{
        let isActive = switchIsActive[name]; 
        return(
            <Switch
                onColor= "#034737"
                offColor={theme === "dark-theme" ?  "#495057" :"#E5E7EB"}
                onChange={(e) => handleSwitch(name,!isActive ? true: false,field)} 
                checked={isActive}
                checkedIcon={
                <></>
                }
                uncheckedIcon={
                    <></>
                }
            />
        )
    }
      
    const roleTab = () =>{
        let mapField = roleTabFields;
        return(
            <Container fluid className='p-0'>
                <div className='agent-config-container'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='agent-config-form'>
                            <Row className="">
                                {mapField && mapField.length > 0 && mapField.map((field, index) => {
                                    const { label, name, datalabel, type, placeholder, required, description, isDisabled,autoComplete ,min,max,step,marks,options,allowMulti} = field;
                                    return (
                                        <Col md="12" lg="12" className='mb-3'>
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index + "role"}
                                                        body={description}
                                                        theme={isDark}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => {
                                                    if (type !== "slider" && type !== "switch" && type !== "dropdown") {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                type={type}
                                                                placeholder={placeholder}
                                                                value={
                                                                    name === "Voice"
                                                                    ? voice?.Name || ""
                                                                    : type === "file"
                                                                    ? fileTypeValues[name] // Prevent setting value for file input
                                                                    : initialRowData[datalabel] ?? ""
                                                                }
                                                                className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-pointer" : ''}`}
                                                                onChange={type !== "file" ? e => handleChange(e) : e => handleFileUpload(e,field)}
                                                                onClick={name === "Voice" ? e => openModel(e) : undefined}
                                                                disabled={isDisabled}
                                                                autoComplete={autoComplete}
                                                                options = {options}
                                                            />
                                                        );
                                                    }else if (type === "slider") {
                                                        return (
                                                            sliderButton(name,min,max,step,marks,field)
                                                        );
                                                    }else if (type === "switch") {
                                                        return (
                                                            switchButton(name,field)
                                                        );
                                                    }else if (type === "dropdown"){
                                                        return(
                                                            <Select
                                                                name={name}
                                                                id={name}
                                                                className="basic-select"
                                                                classNamePrefix="sub-select wa-select"
                                                                value={selectedOption?.[name] ? selectedOption[name] : null}
                                                                onChange={e => onSelectChange(name,e,field)}
                                                                options={options}
                                                                isMulti={allowMulti}
                                                            />
                                                        )
                                                    }
                                                    return null; // Render nothing if type is neither "text" nor "slider"
                                                }}
                                            />
                                        
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                        {isAllowEdit &&
                        <Row className='agent-config-form mb-5'>
                            <Col md="2">
                            <Loading 
                                showLoader={isLoading} 
                                mainContent = {
                                    <Button className="agent-save-btn btn-icon submit-btn btn-background" type="submit">
                                        Save
                                    </Button>}
                            />
                            </Col>
                        </Row>
                        }
                    </Form>
                </div>
            </Container>
        )
    } 

    const hanleNewAgentConfiguration = () =>{
        return(
            <div className='agent-config-container'>
                <div className=''>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='pb-1 agent-div-container agent-config-form'>
                            <span className='d-flex mb-3 agent-header-title'>Agent Info</span>
                            <Row className="mb-3">
                                {agentInfoFields.map((field, index) => {
                                    const { label, name, datalabel, type, placeholder, required, description, isDisabled,autoComplete,marks,min,max,step } = field;
                                    return (
                                        <Col md="4" lg="6" className='mb-3'>
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index +"-agent"}
                                                        body={description}
                                                        theme={isDark}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => {
                                                    if (type !== "slider" && type !== "switch") {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                type={type}
                                                                placeholder={placeholder}
                                                                value={name === "Voice" ? (voice ? voice.Name : '') : initialRowData[datalabel] ?? ""}
                                                                className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-pointer" : ''}`}
                                                                onChange={e => handleChange(e)}
                                                                onClick={name === "Voice" ? e => openModel(e) : undefined}
                                                                disabled={isDisabled}
                                                                autoComplete={autoComplete}
                                                            />
                                                        );
                                                    }else if (type === "slider") {
                                                        return (
                                                            sliderButton(name,min,max,step,marks,field)
                                                        );
                                                    }else if (type === "switch") {
                                                        return (
                                                            switchButton(name,field)
                                                        );
                                                    }
                                                    return null; // Render nothing if type is neither "text" nor "slider"
                                                }}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                        <div className='pb-1 agent-div-container agent-config-form'>
                            <span className='d-flex mb-3 agent-header-title'>Speech Settings</span>
                            <Row className="mb-3">
                                {SpeechSettingsFields.map((field, index) => {
                                    const { label, name, datalabel, type, placeholder, required, description, isDisabled,autoComplete,marks,min,max,step,options,allowMulti } = field;
                                    if(providerHideFields[providerName].includes(name)){
                                        return;
                                    }
                                    return (
                                        <Col md="6" lg="6" className='mb-3'>
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index + "-speech"}
                                                        body={description}
                                                        theme={isDark}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => {
                                                    if (type !== "slider" && type !== "switch" && type !== "dropdown") {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                type={type}
                                                                placeholder={placeholder}
                                                                value={name === "Voice" ? (voice ? voice.Name : '') : initialRowData[datalabel] ?? ""}
                                                                className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-pointer" : ''}`}
                                                                onChange={e => handleChange(e)}
                                                                onClick={name === "Voice" ? e => openModel(e) : undefined}
                                                                disabled={isDisabled}
                                                                autoComplete={autoComplete}
                                                            />                                                                       
                                                        );
                                                    }else if(type === "slider" ){
                                                        return (
                                                            sliderButton(name,marks,min,max,step,field)
                                                        );
                                                    }else if(type === "switch"){
                                                        return (
                                                            switchButton(name,field)
                                                        );
                                                    }else if (type === "dropdown"){
                                                        return(
                                                            <Select
                                                                name={name}
                                                                id={name}
                                                                className="basic-select"
                                                                classNamePrefix="sub-select wa-select"
                                                                value={selectedOption?.[name] ? selectedOption[name] : options[2]}
                                                                onChange={e => onSelectChange(name,e,field)}
                                                                options={options}
                                                                isMulti={allowMulti}
                                                            />
                                                        )
                                                    }

                                                    return null; // Render nothing if type is neither "text" nor "slider"
                                                }}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                        <div className='agent-config-form'>
                            <span className='d-flex mb-3 agent-header-title'>Call Settings</span>
                            <Row className="mb-3">
                                {CallSettingsFields.map((field, index) => {
                                    const { label, name, datalabel, type, placeholder, required, description, isDisabled, autoComplete, marks, min, max, step, options } = field;
                                    if(providerName == "retell" && ["idle_message"].includes(name)){
                                        return;
                                    }
                                    return (
                                        <Col md="6" lg="6" className='mb-3'>
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index + "-call"}
                                                        body={description}
                                                        theme={isDark}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => {
                                                    if (type !== "slider" && type !== "switch" && type !== "dropdown") {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                type={type}
                                                                placeholder={placeholder}
                                                                value={name === "Voice" ? (voice ? voice.Name : '') : initialRowData[datalabel] ?? ""}
                                                                className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-pointer" : ''}`}
                                                                onChange={e => handleChange(e)}
                                                                onClick={name === "Voice" ? e => openModel(e) : undefined}
                                                                disabled={isDisabled}
                                                                autoComplete={autoComplete}
                                                            />
                                                        );
                                                    }else if (type === "slider") {
                                                        return (
                                                            sliderButton(name,marks,min,max,step,field)
                                                        );
                                                    }else if(type === "switch"){
                                                        return (
                                                            switchButton(name,field)
                                                        );
                                                    }else if (type === "dropdown"){
                                                        return(
                                                            <>
                                                                <Select
                                                                    name="selected_form"
                                                                    id="selected_form"
                                                                    className="basic-select"
                                                                    classNamePrefix="sub-select wa-select"
                                                                    value={selectedOption?.[name] ? selectedOption[name] : ""}
                                                                    onChange={e => onSelectChange(name,e,field)}
                                                                    options={options}
                                                                    isMulti={true}
                                                                    isClearable={true}
                                                                    filterOption={customFilter}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                    return null; // Render nothing if type is neither "text" nor "slider"
                                                }}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>                             
                        {isAllowEdit &&
                            <Row className='agent-config-form mb-5'>
                                <Col md="2">
                                <Loading 
                                    showLoader={isLoading} 
                                    mainContent = {
                                        <Button className="agent-save-btn mb-2 btn-icon submit-btn btn-background" type="submit">
                                            Save
                                        </Button>}
                                />
                                </Col>
                            </Row>
                        }
                    </Form>
                </div>
            </div>
        );
            
    }

    const getProfileData = () =>{
        return(
            <Container fluid className='p-0'>
                {allowNewConfig ? (
                    hanleNewAgentConfiguration()
                ) : (
                    <div className='agent-config-container'>
                        <div className='agent-config-form'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                            {
                                configViewFieldSave.map(({ label, name, datalabel, type, placeholder, required, description, isDisabled, autoComplete }, index) => 
                                !isAllowVoiceField ? null : (
                                    <Row key={index} className="mb-3">
                                        <Col md="12" lg="12">
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {description && <PopOverItem id={index + "-config"} body={description} theme={isDark} />}
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        value={name === "Voice" ? (voice?.Name || '') : initialRowData[datalabel]}
                                                        className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')} ${name === "Voice" ? "cursor-pointer" : ''}`}
                                                        onChange={handleChange}
                                                        onClick={name === "Voice" ? openModel : undefined}
                                                        disabled={isDisabled}
                                                        autoComplete={autoComplete}
                                                    />
                                                )}
                                            />
                                            {errors[name] && <em className="text-danger">{errors[name].message}</em>}
                                        </Col>
                                    </Row>
                                ))
                            }
    
                            {isAdmin && isAllowEdit &&
                                <Row>
                                    <div className='col-2'>
                                    <Loading 
                                        showLoader={isLoading} 
                                        mainContent = {
                                            <Button className="agent-save-btn mb-2 btn-icon submit-btn btn-background" type="submit">
                                                Save
                                            </Button>}
                                    />
                                    </div>
                                </Row>
                            }
                            </Form>
                        </div>
                    </div>
                )}
            </Container>
        );
    };

    const advancedSettings = () => {   
        let adv_set = initialRowData?.["advanced settings"] != undefined ? parseJSON(initialRowData["advanced settings"]) : []; 
        return (
            <Fragment>
                <Form className="m-4" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit((data) => props.onSubmit(data, "adv_set"))(e); // Call the function properly
                    }
                }>
                    {
                        advancedSettingsFields && advancedSettingsFields.map((field) => {
                            const { label, name, type, placeholder, description, isDisabled, autoComplete } = field;
                            return(
                                <FormGroup>
                                    <Label for={name} className="agent-header-title">{label}</Label>
                                    <p>{description}</p>
                                    <Controller
                                        name={name}
                                        control={control}
                                        rules={{ required: !adv_set[name] || adv_set[name] === "" ? `${label} is required` : false }}
                                        render={({ field }) => (
                                            <>
                                                <InputGroup>
                                                    <Input
                                                        {...field}
                                                        type={showSecret && name === "secret" ? "password" : type}
                                                        placeholder={placeholder}
                                                        value={adv_set[name] ?? ""}
                                                        className={`${errors[name] ? 'is-invalid' : ''}`}
                                                        onChange={e => {handleChange(e, "adv_settings")}}
                                                        disabled={isDisabled}
                                                        autoComplete= {autoComplete}
                                                    />
                                                    {
                                                        name === "secret" && 
                                                            <div className={"input-group-text cursor-pointer"} onClick={ e => setShowSecret(!showSecret)}>
                                                            {
                                                                showSecret ? (
                                                                    <i class="fi fi-rr-eye-crossed"></i>
                                                                ) : (
                                                                    <i class="fi fi-rr-eye"></i>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </InputGroup>
                                            </>
                                        )}
                                    />
                                    {errors[name] && <em className={`error invalid-feedback`}>{errors[name].message}</em>}
                                </FormGroup>
                            )
                        })
                    }
                    <FormGroup>
                        <Button className="theme-bg-g ms-auto" type="submit">
                            Save
                        </Button>
                    </FormGroup>
                </Form>
            </Fragment>
        )
    }

    const subtables = [
        {
            name: "Profile",
            component: <Fragment>{getProfileData()}</Fragment>
        },
        {
            name: "Role",
            component: <Fragment> {roleTab()} </Fragment>
        },
        {
            name: "Action",
            component: <AgentActions agentID={initialRowData['Agent ID']} agentDetails = {initialRowData}/>,
        },
        {
            name: "Advanced",
            component: <Fragment>{advancedSettings()}</Fragment>,
        },        
    ];


    const getSimpleTabs = () => {
        return subtables.map(({ name, component }, index) => ({
            key: index,
            title: name,
            getContent: () => component,
        }));
    }
   
    return (
        <Fragment>
            <div className='agent-details calls-details p-0'>
                <Row>
                    <Col md="12">
                        <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                            showMore={true}
                            transform={true}
                            showInkBar={true}
                            selectedTabKey={0}
                            transformWidth={400}
                            items={getSimpleTabs()} />
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};




const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMainContent);
