import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import SplitCommon from '../Common/SplitCommon';
import { Button } from "reactstrap";

const FilesSideBase = (props) => {
    let {onAdd, knbData, showLoading, curRowId, onChange} = props;
    const [formData, setFormdata] = useState([]);
    
    useEffect(() => {
        setFormdata(props.knbData)
    }, [props]);
    return (
        <Fragment>
            <div className='agent-bar'>
                <div className='agent-container'>
                    <div className='data-row-container'>
                        <div>
                            <Button onClick={onAdd} className="agent-list mb-2 cursor-pointer agent-create-btn" type="submit">
                                <span className="ms-auto agent-create-btn-spn">
                                    <i class="fi fi-br-plus"></i>Knowledge Base
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className='data-row-container'>
                        <div className='agent'>
                        {
                                formData.length > 0 && formData.map((rowdata, index) => {
                                    let id = rowdata['id'];
                                    let knbName = rowdata['name'];
                                    let fileDetails = rowdata['details'];
                                    return (
                                        <div className={`agent-list mb-2 cursor-pointer${curRowId === id ? ' selected-bg' : ''}`} onClick={() => {onChange(rowdata, index) }}>
                                            <div className='user-pic'>
                                                <span className='d-flex'>
                                                    <i class="fi fi-ss-user-headset"></i>
                                                </span>
                                            </div>
                                            <div className='user-detail-wrap'>
                                                <div className='user-name-Name-time-wrap'>
                                                    <div className='user-name-Name-wrap'>
                                                        <span className='user-name'>
                                                            {knbName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (formData === undefined || formData.length === 0) && <div>No Knowledge Base Found</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FilesSideBase;