import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import {
    Row,
    Col,
    Button
} from "reactstrap";
import Select from "react-select";
import { components } from "react-select";
import TooltipItem from '../../Common/ToolTip';
import Loading from '../../Common/Loading';
import { getSessionValue } from '../../Common/Appcommon';
import { createorUpdateActionForAgent } from './ProviderDetails';
import {timeoptions,  callOptions} from './constants'
import requestApi from '../../services/requestApi.service';

const CallTransfer = ({agent_id, saveDetails, actionId, onAction}) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        trigger,
        setError
    } = useForm();

    const [callTransferData,setCallTransferData] = useState({});
    const [showLoader,setShowLoader] = useState(false);
    const proName = getSessionValue("agent_provider_name");

    const handelInputChange = (e) => {
        const { name, value } = e.target;
        setCallTransferData({ ...callTransferData, [name]: value });
    };

    const handelSelectChange = (name, value) => {
        setCallTransferData((prevData) => ({ ...prevData, [name]: value}));
    };

    const constructorCallTransferParams = () =>{
        if(proName === 'vapi'){
            return {
                forwardingPhoneNumber: callTransferData['phone_number']
                //forwardingPhoneNumber: "+91" + callTransferData['phone_number']
            }
        }
    }
    const onSubmit = async () => {   
        const isValid = await trigger();        
        if(!isValid){
            return;
        }
        let isNameEmpty = (callTransferData) => !callTransferData?.["action_name"]?.trim();
        if (isNameEmpty(callTransferData)) {
            setError("action_name", {
                type: "manual",
                message: "Invalid Action name.",
            });
            return;
        }
        let phoneNumber = callTransferData['phone_number'];
        if (!phoneNumber.match(/^\+\d{1,3}\d{6,15}$/)) { 
            setError("phone_number", {
                type: "manual",
                message: "Invalid Phone Number format. Include country code (e.g., +1, +91).",
            });
            return;
        }
        setShowLoader(true);
        let params = constructorCallTransferParams();
        let response = await createorUpdateActionForAgent(proName,agent_id,params)
        if(response.issuccess){
            await saveDetails(callTransferData,callTransferData['action_name'],'transfer_call',onAction);
        }        
        setShowLoader(false);
    };

    const CustomOption = (props) => (
        <components.Option {...props}>
            <div>
                <strong>
                    {props.data.label}
                </strong>
                <p>
                    {props.data.description}
                </p>
            </div>
        </components.Option>
    );

    const CustomSingleValue = (props) => (
        <components.SingleValue {...props}>
            {props.data.label}
        </components.SingleValue>
    );

    const getMainContent = () =>{
        console.log("callTransferData.action_name",callTransferData.action_name);
        return(
            <Loading 
                mainContent = {
                    <>
                        <div className='w-100 agency-details p-0'>
                            <Row className='mt-1'>
                                <Col md={12} className='field-section'>
                                    <label className={'form-label'} htmlFor="action_name">Action name</label>
                                    <Controller
                                        name="action_name"
                                        control={control}
                                        rules={{ required:  !callTransferData.action_name ? "Please provide Action Name": false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    name="action_name"
                                                    id="action_name"
                                                    value={callTransferData.action_name}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handelInputChange(e);
                                                    }}
                                                    className={`form-control ${errors.action_name ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors.action_name && <em className={`error invalid-feedback`}>{errors.action_name.message}</em>}
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col md={12} className='field-section'>
                                    <label className={'form-label'} htmlFor="phone_number"> Phone Number to Transfer the Call (Include Country Code)</label>
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        rules={{ required: callTransferData.phone_number === undefined || callTransferData.phone_number === null || callTransferData.phone_number === "" ? "Please provide Phone Number": false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    value={callTransferData.phone_number}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^[+]?[0-9]*$/.test(value)) {
                                                            field.onChange(e);
                                                            handelInputChange(e);
                                                        }
                                                    }}
                                                    className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors.phone_number && <em className={`error invalid-feedback`}>{errors.phone_number.message}</em>}
                                </Col>
                            </Row>
                            {proName !== 'vapi' && proName !== 'bland' && proName !== 'retell' && proName !== '' && 
                                (
                                    <>
                                        <Row className='mt-1'>
                                            <Col md={12} className='field-section'>
                                                <label className={'form-label'} htmlFor="transfer_condition">When should the transfer happen?</label>
                                                <Controller
                                                    name="transfer_condition"
                                                    control={control}
                                                    rules={{ required: callTransferData.transfer_condition === undefined || callTransferData.transfer_condition === null || callTransferData.transfer_condition === "" ? "Please provide transfer Action": false }}
                                                    render={({ field }) => {
                                                        return (
                                                            <textarea
                                                                {...field}
                                                                id="transfer_condition"
                                                                name="transfer_condition"
                                                                value={callTransferData.transfer_condition}
                                                                placeholder="When a user is upset, requests to speak with a human, or specifically asks for a manager or business owner"
                                                                className={`form-control ${errors.transfer_condition ? "is-invalid" : ""}`}
                                                                rows="3"
                                                                maxLength="1000"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handelInputChange(e);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors.transfer_condition && <em className={`error invalid-feedback`}>{errors.transfer_condition.message}</em>}
                                            </Col>
                                        </Row>
                                        <Row className={`d-flex align-items-center mt-2 `}>
                                            <Col md={7}>
                                                <label className={'form-label d-flex'} htmlFor="delay">
                                                    Delay
                                                    <span className='ms-1'>
                                                        <TooltipItem
                                                            id={'1'}
                                                            text={"Add a slight, intentional delay to the AI’s responses, making it a more natural conversation"}
                                                            content={<i class="fi fi-rr-info"></i>}
                                                        />
                                                    </span>
                                                </label>
                                            </Col>
                                            <Col md={3} className='field-section'>
                                                <Controller
                                                    name="delay"
                                                    control={control}
                                                    rules={{ required: callTransferData.delay === undefined || callTransferData.delay === null || callTransferData.delay === "" ? "Please provide Delay Second": false }}
                                                    render={({ field }) => {
                                                        return (
                                                            <>  
                                                            <div className='d-flex align-items-end'>
                                                                <div className='w-100'>
                                                                    <Select
                                                                        id="delay"
                                                                        name='delay'
                                                                        options={timeoptions}
                                                                        className="basic-select"
                                                                        classNamePrefix="sub-select wa-select"
                                                                        defaultValue={callTransferData.delay}
                                                                        onChange={(selectedOption) => handelSelectChange('delay', selectedOption)}
                                                                    />
                                                                </div>
                                                                <div className='ms-2'>
                                                                    <lable> Sec</lable>                                           
                                                                </div>
                                                            </div>
                                                            </>
                                                        );
                                                    }}
                                                />
                                                {errors.delay && <em className={`error invalid-feedback`}>{errors.delay.message}</em>}
                                            </Col>
                                        </Row>
                                        <Row className={`mt-1`}>
                                            <Col md={12} className='field-section'>
                                                <label className={'form-label'} htmlFor="transfer_type">Type</label> 
                                                <Controller
                                                    name="transfer_type"
                                                    control={control}
                                                    rules={{ required: callTransferData.transfer_type === undefined || callTransferData.transfer_type === null || callTransferData.transfer_type === "" ? "Please provide Transfer Type": false }}
                                                    render={({ field }) => {
                                                        return (
                                                            <Select
                                                                id="transfer_type"
                                                                name="transfer_type"
                                                                options={callOptions}
                                                                placeholder="Select transfer type..."
                                                                className="basic-select"
                                                                defaultValue={callTransferData.transfer_type}
                                                                classNamePrefix="sub-select wa-select"
                                                                onChange={(selectedOption) => handelSelectChange('transfer_type', selectedOption)}
                                                                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors.transfer_type && <em className={`error invalid-feedback`}>{errors.transfer_type.message}</em>}
                                            </Col>
                                        </Row>
                                        {
                                            callTransferData?.transfer_type?.value === "warm_transfer" && (
                                                <Row className='mt-1'>
                                                    <Col md={12} className='field-section'>
                                                        <label className={'form-label'} htmlFor="handoff_info">Handoff instruction</label> 
                                                        <Controller
                                                            name="handoff_info"
                                                            control={control}
                                                            rules={{ required: callTransferData.handoff_info === undefined || callTransferData.handoff_info === null || callTransferData.handoff_info === "" ? "Please provide Handoff instruction": false }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <textarea
                                                                        {...field}
                                                                        id="handoff_info"
                                                                        name="handoff_info"
                                                                        value={callTransferData.handoff_info}
                                                                        placeholder="When a user is upset, requests to speak with a human, or specifically asks for a manager or business owner"
                                                                        className={`form-control ${errors.handoff_info ? "is-invalid" : ""}`}
                                                                        rows="3"
                                                                        maxLength="1000"
                                                                        onChange={(e) => {
                                                                            field.onChange(e);
                                                                            handelInputChange(e);
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.handoff_info && <em className={`error invalid-feedback`}>{errors.handoff_info.message}</em>}
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                proName === 'vapi' && (
                                    <Row className='mt-3'>
                                        <Col md={12} className='d-flex justify-content-end'>
                                            <Button className="container-footer-save theme-bg-g" type="submit" onClick={handleSubmit(onSubmit)}>
                                                <i className="fi fi-rs-plus"></i> {'Add Action'}
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                    </>
                }
                showLoader = {
                    showLoader
                }
            />
        )
    }

    const getActionDetails = async() =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            setCallTransferData(data)
        }

    }

    useEffect(() => {
        const fetchActionDetails = async () => {
            if (agent_id !== undefined || actionId !== undefined) {
                await getActionDetails();
            }
        };
        fetchActionDetails();
    }, [agent_id, actionId]);


    return ( getMainContent() ) 
};

export default CallTransfer;
