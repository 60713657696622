import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import SplitCommon from '../Common/SplitCommon';
import { Button } from 'reactstrap';
import Select from "react-select";
import {
    DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown,
    DropdownItem
} from 'reactstrap';
import './assets/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { formatDate, getTemplatesFormDetails, populateFormDetails } from '../../Common/Appcommon';
import incoming from "./assets/images/incoming.svg";
import outgoing from "./assets/images/outgoing.svg";
import missed from "./assets/images/missed.svg";
import phone from "./assets/images/phone.svg";
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';
import Loading from '../../Common/Loading';

const ConversationSideBar = (props) => {
    let { fromDetails, searchTemplates, selectedTemplate, data, viewFields, onCallLogClick, nameLabelMapper, onTemplateChange,agentDropDownList,defAgntDrpDwnLst,loadMoreData,conLoader } = props;
    const [selectedRow, setSelectedRow] = useState("");
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const templateHolderRef = useRef(null);
    const containerRef = useRef(null); 
    useEffect(() => {
        (async () => {
            if (data.length > 0 && selectedRow === "") {
                let defaultRowId = data[0]['table_37_id'];
                setSelectedRow(defaultRowId);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [data]);

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            loadMoreData(50);
        }
    };

    useEffect(() => {
    }, [agentDropDownList])

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropDownOpen]);

    const handleClickOutside = (event) => {
        if (isDropDownOpen && templateHolderRef.current && !templateHolderRef.current.contains(event.target)) {
            setIsDropDownOpen(false);
        }
    };

    if (Object.keys(nameLabelMapper).length === 0) {
        return <Fragment></Fragment>
    }

    const onCallLogSelection = async (e, rowId, rowData, nameLabelMapper) => {
        setSelectedRow(rowId);
        onCallLogClick(e, rowData, nameLabelMapper);
    }

    const onTemplateSelectionChange = async (e) => {
        setIsDropDownOpen(false);
        setSelectedRow("");
        onTemplateChange(e);
    }

    return (
        <Fragment>

            <div className='conversation-bar'>
                <div className='filter-dropdown mt-2 me-3 ms-4'>
                    <div className='dropdown' ref={templateHolderRef}>
                        {
                            <Select
                                placeholder={'---Choose One---'}
                                name="selected_form"
                                id="selected_form"
                                className={`basic-select`}
                                classNamePrefix="sub-select wa-select"
                                value={defAgntDrpDwnLst}
                                onChange={(e) => onTemplateSelectionChange(e)}
                                options={agentDropDownList}
                            />
                        }
                        
                        { false &&
                            <UncontrolledButtonDropdown className='' isOpen={isDropDownOpen}>
                            <DropdownToggle className="p-0" >
                                <button className='btn btn-custom-dropdown variable-dropdown' onClick={e => { setIsDropDownOpen(!isDropDownOpen) }}>
                                    <span className='cloud-number-name-number'>
                                        {false && <span className='number-icon'>
                                            <img src={phone} />
                                        </span>
                                        }
                                        <span className=''>
                                            <p className='name'>{defAgntDrpDwnLst.label}</p>
                                        </span>
                                    </span>
                                    <FontAwesomeIcon icon={faAngleDown} className='filter-dropdown-icon theme-c-g' size="1.5x" />
                                </button>
                            </DropdownToggle>
                            <DropdownMenu end className="rm-pointers dropdown-menu-md">
                                { false &&
                                    searchTemplates.length > 0 && searchTemplates.map((template) => {
                                        let templateName = template[1];
                                        return (
                                            <button onClick={(e) => onTemplateSelectionChange(e, template)} className='btn btn-custom-dropdown variable-dropdown'>
                                                <span className='cloud-number-name-number'>
                                                    {false &&<span className='number-icon'>
                                                            <img src={phone} />
                                                    </span>
                                                    }
                                                    <span className=''>
                                                        <p className='name'>{templateName}</p>
                                                    </span>
                                                </span>
                                            </button>
                                        );
                                    })
                                }

                                { false && agentDropDownList.length > 0 && agentDropDownList.map((template) => {
                                    let templateName = template.label; 
                                    return (
                                        <button 
                                            onClick={(e) => onTemplateSelectionChange(e, template)} 
                                            className='btn btn-custom-dropdown variable-dropdown'
                                        >
                                            <span className='cloud-number-name-number'>
                                                {false && (
                                                    <span className='number-icon'>
                                                        <img src={phone} />
                                                    </span>
                                                )}
                                                <span className=''>
                                                    <p className='name'>{templateName}</p> {/* Display the template label */}
                                                </span>
                                            </span>
                                        </button>
                                    );
                                })}
                                
                            </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        }
                        
                    </div>
                </div>
                <Loading className={`w-100`} showLoader={conLoader} mainContent={
                    <div className="w-100">
                        {<div className='convo-logs-container'>
                            <div className='data-row-container'>
                                <div className='call-conversations' onScroll={handleScroll}>
                                    {
                                        Object.keys(nameLabelMapper).length > 0 && data.length > 0 && data.map((rowData) => {
                                            let callerAgentLabel = nameLabelMapper['table_250_0_table_250_id'] !== undefined ? nameLabelMapper['table_250_0_table_250_id'] : "";
                                            let callerNoLabel = nameLabelMapper['displayNumber'] !== undefined ? nameLabelMapper['displayNumber'] : "";
                                            let callerNameLabel = nameLabelMapper['Receiver'] !== undefined ? nameLabelMapper['Receiver'] : "";
                                            let callDateLabel = nameLabelMapper["Start_DateTime"] !== undefined ? nameLabelMapper['Start_DateTime'] : "";
                                            let callTypeLabel = nameLabelMapper["Call_Type"] !== undefined ? nameLabelMapper['Call_Type'] : "";
                                            let durLabel = nameLabelMapper["Durationsec"] !== undefined ? nameLabelMapper['Durationsec'] : "";

                                            let rowId = rowData['table_37_id'];
                                            let icon = incoming;
                                            if (rowData[callTypeLabel].toLowerCase() === "web_call" || rowData[callTypeLabel].toLowerCase().includes("outbound")) {
                                                icon = outgoing;
                                            } else if (rowData[callTypeLabel].toLowerCase().includes("missed")) {
                                                icon = missed;
                                            }
                                            let phFldValue = (rowData[callerNameLabel] !== null && rowData[callerNameLabel] !== undefined && rowData[callerNameLabel] !== "") ? rowData[callerNameLabel] : (rowData[callerNoLabel] !== null && rowData[callerNoLabel] !== undefined && rowData[callerNoLabel] !== "") ? rowData[callerNoLabel]: rowData[callTypeLabel]; 
                                            if(phFldValue.toLowerCase().includes("web")){
                                                phFldValue = "Web Call";
                                            }
                                            return (
                                                <div className={`chat-list-item mb-2 cursor-pointer ${selectedRow === rowId ? 'selected-bg' : ''}`} onClick={(e) => { onCallLogSelection(e, rowId, rowData, nameLabelMapper) }}>
                                                    <div className='user-pic'>
                                                        <span className='d-flex'>
                                                            <img src={icon} />
                                                        </span>
                                                    </div>
                                                    <div className='user-detail-wrap'>
                                                        <div className='user-name-label-time-wrap'>
                                                            <div className='user-name-label-wrap'>
                                                                <span className='chat-sinppet'>
                                                                    {phFldValue}
                                                                </span>
                                                            </div>
                                                            <span className="chat-sinppet">{formatDate(rowData[callDateLabel], "mmm-dd")}</span>
                                                        </div>
                                                        <p className='chat-sinppet-with-count'>
                                                            <span className='tick-marks-msg-wrap'>
                                                                {false && <i className='pe-7s-smile'></i>}
                                                                <span className="chat-sinppet">{rowData[durLabel]}</span>
                                                            </span>
                                                            <span className="chat-sinppet chat-sinppet-wid">
                                                                {rowData[callerAgentLabel]}
                                                                {false && <img src="https://cdn.oneinbox.ai/assets/agents/profile/Adrian+AI.jpg" />}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        (data === undefined || data.length === 0) && <div></div>
                                    }
                                </div>
                            </div>
                        </div>}
                    </div>
                }/>
               
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSideBar);
