import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import SplitCommon from '../Common/SplitCommon';
import ConversationSideBar from './ConversationSideBar';
import { getTemplatesFormDetails, loadFormData, populateFormDetails, prepareLabelNameMapper,getReferenceList } from '../../Common/Appcommon';
import ConversationConfigTitle from './ConversationConfigTitle';
import ConversationConfig from './ConversationConfig';

class Conversations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formDetails: [],
            templates: [],
            selectedTemplate: [],
            formData: [],
            listFields: [],
            page: 1,
            limit: 50,
            rowData: [],
            nameLabelMapper: {},
            sideBarLoader : false,
            mainLoader : false,
            adFilterSearch :"",
            agentDropDown : [],
            defaultAgentDropDown : {},
            callTCnt : 0,
            limitCount : 0,
            isAllowLoad:true,
            conLoader : false
        }
    }

    componentDidMount = async () => {
        this.setState({
            sideBarLoader: true,
            mainLoader : true,
        });
        await this.fetchFormDetails();
        await this.fetchAgentLogs();
        let { selectedTemplate } = this.state;
        await this.fetchConversionLogs(selectedTemplate);
        this.setState({
            sideBarLoader: false,
            mainLoader : false,
        });
    };

    fetchFormDetails = async () => {
        let fDetail = await populateFormDetails("table_37");
        let { templates, selectedTemplate } = await getTemplatesFormDetails(fDetail,"Recently Created");
        this.setState({
            formDetails: fDetail,
            templates, selectedTemplate
        })
    };

    fetchConversionLogs = async (selectedTemplate) => {
        let { formDetails,adFilterSearch,limitCount,isAllowLoad } = this.state;
        if(isAllowLoad){
            let callData = await loadFormData("table_37", formDetails, selectedTemplate,"",adFilterSearch,true,[],"",limitCount);
            if (callData.issuccess) {
                let { formData, listFields,totalCount } = callData;
                let nameLabelMapper = await prepareLabelNameMapper(formDetails[1]);
                let rowData = formData[0];
                if(totalCount === formData.length){
                    isAllowLoad = false;   
                }
                this.setState({
                    formData, listFields, nameLabelMapper, rowData,callTCnt: totalCount,isAllowLoad
                })
            }
        }
        this.setState({
            conLoader : false
        })
    }

    fetchAgentLogs = async () => {
        let {formDetails} = this.state;
        let fDetail = formDetails[1];

        let refDetail = fDetail.filter(record => record[2] === 'table_250_0_table_250_id')[0];
        if(refDetail && refDetail[35] !== undefined){
            let res =  await getReferenceList(refDetail[35]);
            if (res.issuccess) {
                let optionList   =  res.data;
                let dropDown = optionList[0];
                let allAgentsOption = { value: "All Agents", label: 'All Agents' };
                dropDown.unshift(allAgentsOption);
                this.setState ({
                    agentDropDown : dropDown,
                    defaultAgentDropDown : allAgentsOption,
                });
                
            }
        }
    }

    onTemplateChange = async (defaultAgentDropDownLst) => {
        let searchStr = defaultAgentDropDownLst.label.toLowerCase();
        let cond = "nondate@table_250_0@Agent_Name@is@"+searchStr+"@AND#"
        if(searchStr === "all agents"){
            cond = "";
        }
        let {selectedTemplate} = this.state;
        this.setState({ adFilterSearch : cond, defaultAgentDropDown :defaultAgentDropDownLst,isAllowLoad:true }, async () => {
            await this.fetchConversionLogs(selectedTemplate);
        });
        
    }

    loadMore = async (count) =>{
        let {selectedTemplate,limitCount } = this.state;
        count = limitCount + count;
        this.setState({ limitCount: count, conLoader: true}, async () => {
            await this.fetchConversionLogs(selectedTemplate);
        });
    }

    onCallLogClick = async (e, rowData, nameLabelMapper) => {
        this.setState({
            rowData, nameLabelMapper
        })
    }

    render() {
        let { formDetails, templates, selectedTemplate, formData, listFields, rowData, nameLabelMapper, sideBarLoader, mainLoader,agentDropDown,defaultAgentDropDown,callTCnt,conLoader } = this.state;
        return (
            <Fragment>
                <SplitCommon
                    mobileViewNeedLeftMenu = {true}
                    title={"Calls"}
                    sideBarLoader = {sideBarLoader}
                    mainLoader = {mainLoader}
                    sideBarTitleContent={<span>Calls<span class="count">{callTCnt}</span></span>}
                    mainTitleContent={    
                        <div className="app-header-right">
                            <div className="app-header-title">{<ConversationConfigTitle rowData={rowData} nameLabelMapper={nameLabelMapper} />}</div>
                        </div>
                    }
                    sideBarContent={
                        <Fragment>
                            {formDetails.length > 0 && <ConversationSideBar
                                formDetails={formDetails}
                                searchTemplates={templates}
                                selectedTemplate={selectedTemplate}
                                data={formData}
                                viewFields={listFields}
                                onCallLogClick={this.onCallLogClick}
                                nameLabelMapper={nameLabelMapper}
                                onTemplateChange={this.onTemplateChange}
                                agentDropDownList = {agentDropDown}
                                defAgntDrpDwnLst = {defaultAgentDropDown}
                                loadMoreData = {this.loadMore}
                                conLoader = {conLoader}
                            />}
                        </Fragment>
                    } mainContent={
                        <ConversationConfig rowData={rowData} nameLabelMapper={nameLabelMapper} />
                    } />


            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);
