import React, {Fragment, useEffect, useState} from "react";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import men_user from "./assets/images/agent.png";
import { Button } from "reactstrap";

const AIAgentsSideBar = (props) => {
    let {formData, curRowId, formname, fieldNames, onAgentChange,handleCreateModal,allowNewConfig} = props;
    const [formdata, setFormdata] = useState([]);
    
    useEffect(() => {
        setFormdata(props.formData)
    }, [props]);

    const handleModal = () => {
        handleCreateModal();
    }
    return(
        <Fragment>
            <div className='agent-bar'>
                <div className='agent-container'>
                    {allowNewConfig && (
                        <div className='data-row-container'>
                            <div>
                                <Button onClick={handleModal} className="agent-list mb-2 cursor-pointer agent-create-btn" type="submit">
                                    <span className="ms-auto agent-create-btn-spn">
                                        <i class="fi fi-br-plus"></i>Add Agent
                                    </span>
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className='data-row-container'>
                        <div className='agent'>
                            {
                                formData.length > 0 && formData.map((rowdata, index) => {
                                    let id = rowdata[formname+'_id'] !== undefined ? rowdata[formname+'_id'] : "";
                                    let Name = rowdata[fieldNames['Agent_Name']] !== undefined ? rowdata[fieldNames['Agent_Name']] : ""; // it was modifyed
                                    //let Gender = rowdata['Gender'] !== undefined ? rowdata['Gender'] : "";
                                    let icon = men_user;
                                    // if (Gender.toLowerCase() === "female") {
                                    //     icon = women_user;
                                    // }
                                    if(Name === ""){
                                        Name = "AI Agent";
                                    }
                                    return (
                                        <div className={`agent-list mb-2 cursor-pointer${curRowId === id ? ' selected-bg' : ''}`} onClick={() => {onAgentChange(rowdata, index) }}>
                                            <div className='user-pic'>
                                                <span className='d-flex'>
                                                    <i class="fi fi-ss-user-headset"></i>
                                                </span>
                                            </div>
                                            <div className='user-detail-wrap'>
                                                <div className='user-name-Name-time-wrap'>
                                                    <div className='user-name-Name-wrap'>
                                                        <span className='user-name'>
                                                            {Name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (formData === undefined || formData.length === 0) && <div>No Calls Found</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AIAgentsSideBar);