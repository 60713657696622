import React, { Fragment,useEffect, useState } from 'react';
import { graphAgenListColumns } from "./constant";
import { TableDashboard } from '../../Dashboard/TableDashboard';
import { AgencyCallDetails } from '../../Dashboard/agencyCallDetails';
export const Graphdashboard = ({ demo,paymentType,customerName,dateFilter }) => {
    return (
        <>
            {<AgencyCallDetails demo={demo} paymentType={paymentType} customerName={customerName} dateFilter={dateFilter}/>}
            {
                // graphAgenListColumns && graphAgenListColumns.map((val) => {
                //     if (val.type === "table") {
                //         let valArr = [];
                //         valArr.push(val);
                        // return
                        <TableDashboard tableColList={graphAgenListColumns} demo={demo} paymentType={paymentType} customerName={customerName} dateFilter = {dateFilter}/>
                //     }
                // })
            }
        </>
    )
}