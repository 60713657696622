import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { subDays } from 'date-fns';
import {
    Row,
    Col,
    Container
  } from "reactstrap";
import CountUp from "react-countup";
import Switch from "react-switch";  
import { connect } from "react-redux";
import Common from '../Common';
import requestApi from '../../services/requestApi.service';
import DateFilter from '../../Common/DateFilter';
import "./assets/index.css";
import { DashboardSummaryBox } from './Summary';
import { barChartsList, graphListColumns } from "./Constant";
import DashboardGraph from './DashboardGraph';
import { getDarkThemeColor, isMobile, translateContent } from '../../Common/Appcommon';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        const startDate = subDays(new Date(), 30); // Default to 7 days ago
        const endDate = new Date(); 
        this.state = {
            dateFilter: {
                Start_DateTime: [startDate, endDate],
                noofdays: 7
            },
            summary : [],
            showLoader: false,
            formname: process.env.REACT_APP_CALLLOG_FORMNAME,
            dashBoardData: [],
            tablesGraphDatas: [],
            isChecked: false,
            isMobile:isMobile()
        };
    }

    getSummaryBox = async () => {
        let { dateFilter,isChecked } = this.state;
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_DASHBOARD_SUMMARY",
            FORMNAME: 'table_37',
            datefilter: dateFilter,
            demo : isChecked,
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data
                this.setState({ summary: data });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }
    
    
    getTitleContent = () => {
        return(
            <>
                <span>{translateContent("overview")}</span> 
            </>
        )
    }

    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            let dateobj = {};
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
            dateobj['Start_DateTime'] = [startDate, endDate];
            dateobj['noofdays'] = numberOfDays;
            this.setState({ 
                dateFilter: dateobj, 
                dashBoardData: [], 
                showLoader: true 
            }, async () => {
                try {
                    // Run all the async functions in parallel
                    await Promise.all([
                        this.getSummaryBox(),
                        this.prepareChartData(),
                        this.tablesGraphData()
                    ]);
                } catch (error) {
                    console.error('Error during date change operations:', error);
                } finally {
                    // Ensure the loader is turned off after all operations are complete
                    this.setState({ showLoader: false });
                }
            });
        }
    }
    
    handleChange = (checked) => {
        this.setState({ showLoader: true, isChecked: checked }, async () => {
            await this.getSummaryBox();
            await this.prepareChartData();
            await this.tablesGraphData();
            this.setState({ showLoader: false });
        });
    }

    getTitleBody = () => {
        return(
        <Fragment>
            <div className={`d-flex justify-content-end ${this.state.isMobile && 'p-2'}`}>
                <div className='d-flex  align-items-center me-2'>
                    <span className='demon-header me-2'>
                        Demo
                    </span>
                    <span className='mt-2 me-2 d-inline-block'>
                        <Switch
                            height={20}
                            width={40}
                            onColor={getDarkThemeColor()}
                            className=''
                            disabled={this.state.showLoader}
                            checked={this.state.isChecked} // Use boolean value here
                            onChange={this.handleChange}
                        />
                    </span>
                </div>
                <div>
                    <DateFilter  handleDateChange={this.handleDateChange} defaultSelectIndex={3}/>
                </div>
            </div>
        </Fragment>
        )
    }

    getRequestJsonObj = (isDateFilter, operation) => {
        let {formname, dateFilter,isChecked} = this.state;
        let filterOption = [dateFilter];
        let neededFilters = [
            {'dateFilter' : isDateFilter},
            {'users' : false},
            {'type' : false},
        ];

        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": operation,
            "FORMNAME": formname,
            "FILTEROPTION" : filterOption,
            "NEEDEDFILTERS" : neededFilters,
            "demo" : isChecked,
            "isOtherApiReq" : true,
        };

        return params;
    }

    prepareChartData = async () => {
        const allProcessedData = await Promise.all(
            barChartsList.map(async (chart, index) => {
                let operation = chart.operation;
                let isDateFilter = true;
                let params = this.getRequestJsonObj(isDateFilter, operation);
                let response = await requestApi.processServerRequest(params);
                if (response.issuccess) {
                    let data = response.data;
                    let processedData = [...data];
                    processedData[2] = [];
                    return processedData;
                }
                return null;
            })
        );
        const validData = allProcessedData.filter(data => data !== null);
        this.setState({
            dashBoardData: validData
        });
    };

    tablesGraphData = async () => {
        try {
            const updatedColumns = await Promise.all(
                graphListColumns.map(async (val) => {
                    let operation = val.operation;
                    let params = this.getRequestJsonObj(true, operation);
                    let response = await requestApi.processServerRequest(params);
                    if (response.issuccess) {
                        let data = response.data;
                        return { ...val, data };
                    }
                    return val;
                })
            );
            this.setState({
                tablesGraphDatas: updatedColumns
            });
        } catch (error) {
            console.error("Error fetching table graph data:", error);
        }
    };
 
    
    getBodyComponent = () => {
        let {summary} = this.state;
        return (
            <Fragment>
                <Container>
                    <div className='dashboard-container'>
                        <div className='summary-container'>
                            <Row>
                                {
                                    summary && 
                                    summary.map((val, index) => {
                                        let header = val.header;
                                        let body = val.body;
                                        let footerheader = val.footerheader;
                                        let footerbody = val.footerbody;
                                        let prefix = val.prefix;
                                        let suffix = val.suffix;
                                        return (
                                            <>
                                                <Col md="6" lg="3">
                                                    <div className="card mb-3 widget-chart text-start">
                                                        <div className="widget-chart-content">
                                                        <div className="widget-subheading">{header}</div>
                                                        <div className="widget-numbers theme-c-g">
                                                            <CountUp 
                                                                start={0} 
                                                                end={body} 
                                                                separator="" 
                                                                decimals={0} 
                                                                decimal=","
                                                                prefix={prefix} 
                                                                useEasing={false} 
                                                                suffix={suffix} 
                                                                duration="3"
                                                            />
                                                        </div>
                                                        <div className="widget-description">
                                                            {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                                                            <span className="ps-1">
                                                                <span className='theme-c-g'>
                                                                    {footerbody}
                                                                </span>
                                                                <span className='dashboard-footerheader'>{footerheader}</span>
                                                            </span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }

    render() {
        let {showLoader, summary, tablesGraphDatas, dashBoardData,isChecked,dateFilter,isMobile} = this.state;

        return (
            <Fragment>
                <Common
                    mainLoader={showLoader}
                    demoPopup = {isChecked}
                    demoHandler = {this.handleChange}
                    title={"Overview"}
                    titleContent={
                        this.getTitleContent()
                    }
                    titleBody={
                        !isMobile && this.getTitleBody()
                    }
                    mainContent={
                        <Fragment>
                            <div className='dashboard-container w-100'>
                                {isMobile && this.getTitleBody()}
                                <DashboardSummaryBox summary={summary} dateFilter = {dateFilter}/>
                                <DashboardGraph dashBoardData={dashBoardData} tablesGraphDatas={tablesGraphDatas} />
                            </div>
                        </Fragment>
                    } 
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
