import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import Loading from '../../Common/Loading';
import Select from "react-select";
import TooltipItem from '../../Common/ToolTip';
import { getSessionValue, handleErrorResponse, showToast, translateContent } from '../../Common/Appcommon';
import { createorUpdateActionForAgent } from './ProviderDetails';
import requestApi from '../../services/requestApi.service';
import { defaultStructureData } from './constants';
const ExtractInfo = ({agent_id, saveDetails, actionId,agentDetails}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        trigger,
        setError
    } = useForm();
    const proName = getSessionValue("agent_provider_name");

    const [extractInfoData, setExtractInfoData] = useState({});

    const [infoData, setInfoData] = useState([{ 
        infodesc: "", 
        infoname: "", 
        infotype: { value: "string", label: 'Text'}, 
    }]);
    const [showLoader, setShowLoader] = useState(false);

    const trigger_option = [
        { value: "after_call", label: "After Call" },
        { value: "during_call", label: "During Call" }
    ];

    const getInfoTypeOption = (value) => {
        return infoTypeOptions.find(option => option.value === value) || null;
    };
    const infoTypeOptions = [
        { value: "integer", label: 'Number' },
        { value: "string", label: 'Text'},
        { value: "boolean", label: 'Yes/No'},
    ];

    const addInfoBlock = () => {
        setInfoData([...infoData, { 
            infodesc: "", 
            infoname: "", 
            infotype: getInfoTypeOption('string')
        }]);
    };

    const removeInfoBlock = (index) => {
        setInfoData(infoData.filter((_, i) => i !== index));
    };

    const handleInfoChange = (index, field, value) => {
        const updatedInfoData = [...infoData];
        updatedInfoData[index][field] = value;
        setInfoData(updatedInfoData);
    };

    const addSubInfo = (index) => {
        const updatedInfoData = [...infoData];
        updatedInfoData[index].subinfo.push("");
        setInfoData(updatedInfoData);
    };

    const removeSubInfo = (infoIndex, subInfoIndex) => {
        const updatedInfoData = [...infoData];
        updatedInfoData[infoIndex].subinfo.splice(subInfoIndex, 1);
        setInfoData(updatedInfoData);
    };

    const handleSubInfoChange = (infoIndex, subInfoIndex, value) => {
        const updatedInfoData = [...infoData];
        updatedInfoData[infoIndex].subinfo[subInfoIndex] = value;
        setInfoData(updatedInfoData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setExtractInfoData({ ...extractInfoData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setExtractInfoData({ ...extractInfoData, [name]: value });
    };
    const getformatExtractInfo = (Date) =>{
        let result = {};
        if(proName !== 'bland'){
            result = {
                "Call Rating": { type: "string" },
                "Call Sentiment": { type: "string" }
            };        
            Date.forEach(({ infodesc, infoname, infotype }) => {
                result[infoname] = {
                    description: infodesc,
                    type: infotype.value
                };
            });
        }
        return result;
    }
    
    
    const constructorExtractInfoParams = () =>{
        if(proName !== 'bland'){
            return {
                analysisPlan: {
                    structuredDataPrompt : defaultStructureData + extractInfoData['prompt'],
                    structuredDataSchema : {
                        "type": "object",
                        "properties": getformatExtractInfo(infoData),
                    }
                }
            }
        }
    }
    const onSubmit = async () => {
        const isValid = await trigger();
        if (!isValid) return;
        let isNameEmpty = (data) => !data?.["action_name"]?.trim();
        if (isNameEmpty(extractInfoData)) {
            setError("action_name", {
                type: "manual",
                message: "Invalid Action name.",
            });
            return;
        }
        setShowLoader(true);
        let params = constructorExtractInfoParams();
        let response = await createorUpdateActionForAgent(proName,agent_id,params,agentDetails)
        if(response?.issuccess){
            await saveDetails({extractInfoData,infoData},extractInfoData['action_name'],'extract_info','after_call')
        } else{
            handleErrorResponse(translateContent("vm.agent.extract.error"));
        }
        setShowLoader(false);
    };

    const getMainContent = () => {
        return (
            <div className='w-100'>
                <Loading
                    mainContent={
                        <>
                            <div className='w-100 p-0 agency-details'>
                                <Row className='mt-1'>
                                    <Col md={12} className='field-section'>
                                        <label className={'form-label'} htmlFor="prompt">Action name</label>
                                        <Controller
                                            name="action_name"
                                            control={control}
                                            rules={{ required:  extractInfoData.action_name === undefined || extractInfoData.action_name === null || extractInfoData.action_name === ""  ? "Please provide Action Name": false }}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    name="action_name"
                                                    id="action_name"
                                                    placeholder='e.g. customer details'
                                                    value={extractInfoData.action_name || ''}
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleInputChange(e);
                                                    }}
                                                    className={`form-control ${errors.action_name ? 'is-invalid' : ''}`}
                                                />
                                            )}
                                        />
                                        {errors.action_name && <em className='error invalid-feedback'>{errors.action_name.message}</em>}
                                    </Col>
                                </Row>
                                { 
                                    proName === 'vapi' && (
                                        <Row className='mt-1'>
                                        <Col md={12} className='field-section'>
                                            <label className={'form-label'} htmlFor="prompt">Prompt</label>
                                            <Controller
                                                name="prompt"
                                                control={control}
                                                rules={{ required: extractInfoData.prompt === undefined || extractInfoData.prompt === null || extractInfoData.prompt === "" ? "Please provide End Condition": false }}
                                                render={({ field }) => {
                                                    return (
                                                        <textarea
                                                            {...field}
                                                            id="prompt"
                                                            name="prompt"
                                                            value={extractInfoData.prompt}
                                                            placeholder="You will be given a transcript of a call and the system prompt of the AI participant. Extract..."
                                                            className={`form-control ${errors.prompt ? "is-invalid" : ""}`}
                                                            rows="3"
                                                            maxLength="1000"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                handleInputChange(e);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            {errors.prompt && <em className={`error invalid-feedback`}>{errors.prompt.message}</em>}
                                        </Col>
                                        </Row>
                                    )}
                                {
                                    proName !== 'vapi' && proName !== 'bland'  && proName !== 'retell' && proName !== '' && (
                                        <Row className='mt-1'>
                                            <Col md={12} className='field-section'>
                                                <label className={'form-label'} htmlFor="trigger">Trigger</label>
                                                <Controller
                                                    name="trigger"
                                                    control={control}
                                                    rules={{ required: extractInfoData.trigger || "Please provide Trigger" }}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="trigger"
                                                            name='trigger'
                                                            options={trigger_option}
                                                            className="basic-select"
                                                            classNamePrefix="sub-select wa-select"
                                                            value={extractInfoData.trigger}
                                                            onChange={(selectedOption) => handleSelectChange('trigger', selectedOption)}
                                                        />
                                                    )}
                                                />
                                                {errors.trigger && <em className='error invalid-feedback'>{errors.trigger.message}</em>}
                                            </Col>
                                        </Row>
                                    )
                                }
                                {infoData.map((infoBlock, index) => (
                                    <Card key={index} className="card-br mt-3">
                                        <CardBody>
                                            <Row className='mt-2'>
                                                <Col md={6} className="d-flex justify-content-start">
                                                    {`Info ${index + 1}`}
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-end">
                                                    {
                                                        infoData.length > 1 && (
                                                            <i className="fi fi-rs-trash" onClick={() => removeInfoBlock(index)}></i>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col md={12} className='field-section'>
                                                    <label className='form-label' htmlFor={`infodesc-${index}`}>What information should be extracted?</label>
                                                    <Controller
                                                        name={`infodesc-${index}`}
                                                        control={control}
                                                        rules={{ required: infoBlock.infodesc === undefined  ||  infoBlock.infodesc === null  || infoBlock.infodesc === ''  ?  "Please provide information" : false }}
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                type="text"
                                                                name={`infodesc-${index}`}
                                                                id={`infodesc-${index}`}
                                                                placeholder='e.g. Capture customer name'
                                                                value={infoBlock.infodesc || ''}
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleInfoChange(index, "infodesc", e.target.value);
                                                                }}
                                                                className={`form-control ${errors[`infodesc-${index}`] ? 'is-invalid' : ''}`}
                                                            />
                                                        )}
                                                    />
                                                    {errors[`infodesc-${index}`] && <em className='error invalid-feedback'>{errors[`infodesc-${index}`].message}</em>}
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col md={6} className='field-section'>
                                                    <label className="form-label" htmlFor={`infoname-${index}`}>Info Name</label>
                                                    <Controller
                                                        name={`infoname-${index}`}
                                                        control={control}
                                                        rules={{ required: infoBlock.infoname === undefined || infoBlock.infoname === null ||infoBlock.infoname === '' ? "Please provide Info Name" : true }}
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                type="text"
                                                                name={`infoname-${index}`}
                                                                id={`infoname-${index}`}
                                                                placeholder='e.g. customer name'
                                                                value={infoBlock.infoname || ''}
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleInfoChange(index, "infoname", e.target.value);
                                                                }}
                                                                className={`form-control ${errors[`infoname-${index}`] ? 'is-invalid' : ''}`}
                                                            />
                                                        )}
                                                    />
                                                    {errors[`infoname-${index}`] && <em className='error invalid-feedback'>{errors[`infoname-${index}`].message}</em>}
                                                </Col>
                                                <Col md={6} className='field-section'>
                                                    <label className="form-label" htmlFor={`infoType-${index}`}>Info Type</label>
                                                    <Select
                                                        id={`infoType-${index}`}
                                                        options={infoTypeOptions}
                                                        value={infoBlock.infotype}
                                                        onChange={(selectedOption) => handleInfoChange(index, "infotype", selectedOption)}
                                                        className="basic-select"
                                                        classNamePrefix="sub-select wa-select"
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                 proName !== 'vapi' && proName !== 'bland'  && proName !== 'retell' && (
                                                    <Row className="mt-2">
                                                        <Col md={12}>
                                                            {["text", "multichoice"].includes(infoBlock.infotype.value) && (
                                                                <>
                                                                    <Row className="d-flex justify-content-start">
                                                                        <Col className='d-flex justify-item-center' >
                                                                            {infoBlock.infotype.value === "text" ? "Examples" : "Choices"}
                                                                            {infoBlock.infotype.value === "text" &&   
                                                                                <div className='ms-2 header-desc'>
                                                                                    <TooltipItem
                                                                                        id={'1'}
                                                                                        text={"Provide data samples to help the AI better understand your needs and deliver more accurate results"}
                                                                                        content={<i className="fi fi-rr-info"></i>}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            {infoBlock.subinfo.map((subInfo, subIndex) => (
                                                                                <Controller
                                                                                    key={subIndex}
                                                                                    name={`info-${index}-${subIndex}`}
                                                                                    control={control}
                                                                                    rules={{ required: subInfo || "Please provide information" }}
                                                                                    render={({ field }) => (
                                                                                        <input
                                                                                            {...field}
                                                                                            type="text"
                                                                                            name={`info-${index}-${subIndex}`}
                                                                                            id={`info-${index}-${subIndex}`}
                                                                                            placeholder='e.g. Capture customer name'
                                                                                            value={subInfo || ''}
                                                                                            autoComplete="off"
                                                                                            onChange={(e) => {
                                                                                                field.onChange(e);
                                                                                                handleSubInfoChange(index, subIndex, e.target.value);
                                                                                            }}
                                                                                            className={`form-control ${errors[`info-${index}-${subIndex}`] ? 'is-invalid' : ''}`}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                // {errors[`info-${index}`] && <em className='error invalid-feedback'>{errors[`info-${index}`].message}</em>}
                                                                            ))}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button className="container-footer-save theme-bg-g" type="button" onClick={() => addSubInfo(index)}>
                                                                                <i className="fi fi-rs-plus"></i> Add {infoBlock.infotype.value === "text" ? "Examples" : "Choices"}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </CardBody>
                                    </Card>
                                ))}
                                <Row className="mt-1">
                                    <Col md={12} className="d-flex justify-content-start">
                                        <Button className="container-footer-save theme-bg-g" onClick={addInfoBlock}>
                                            <i className="fi fi-rs-plus"></i> Extract more
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    proName !== 'bland' && (
                                        <Row>
                                            <Col md={12} className='d-flex justify-content-end'>
                                                <Button className="container-footer-save theme-bg-g" type="submit" onClick={handleSubmit(onSubmit)}>
                                                    <i className="fi fi-rs-plus"></i> Add Action
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div>
                        </>
                    }
                    showLoader={showLoader}
                />
            </div>
        );
    };

    const getActionDetails = async() =>{
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_ACTION_DETAILS",
            'agent_id': agent_id,
            'actionid':actionId,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            if( data.extractInfoData !== undefined || data.infoData !== undefined){
                setExtractInfoData(data.extractInfoData)
                setInfoData(data.infoData)
            }
        }

    }
    useEffect(() => {
        const fetchActionDetails = async () => {
            if (agent_id !== undefined || actionId !== undefined) {
                await getActionDetails();
            }
        };
        fetchActionDetails();
    }, [agent_id, actionId]);

    return (getMainContent());
};

export default ExtractInfo;
