import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label, } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from "classnames";

import Common from '../Common';
import ListTable from '../../Common/ListTable';
import SubaccountDetails from './SubaccountDetails';
import aiData from "../../assets/utils/images/agent_no_records.png"
import { setSessionValue, isMobile, getSessionValue, prepareCustomTableData, getOrganizationInfo, parseJSON,checkIsAdmin, showToast, confirmBoxWithImage,handleErrorResponse, translateContent,getSubaccountCount } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import NoRecordFound from '../Common/NoRecordFound';
import Select from "react-select";
import { providersSelectList,providers } from './assets/css/constants';

class SubAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue:null,
            data: [],
            columns: [],
            showDetailed: false,
            accountInfo: {},
            isModalOpen: false,
            subAccountName: '',
            errorMessage: {},
            showLoader: false,
            showButtonLoading: false,
            searchStr: '',
            options: [
                {value: 'all_account', label: 'All Account'},
                { value: 'status,Active', label: 'Active' },
                { value: 'status,Deactive', label: 'In Active' },
                { value: 'type,Trial', label: 'Trial' }
            ],
            currentPlanInfo: {},
            isAdmin:false,
            selectProviderList : {},
            providerName : providersSelectList[0].value,
            providers:providers,
            showAPI : false,
            providerFields:[],
            providerData:{concurrency_limit:8},
            limitReached:false
        }
    }

    setShowLoaderStatus = async (status = false) => {
        this.setState({
            showLoader: status
        })
    }

    componentDidMount = async () => {
        this.setState({selectedValue:this.state.options[0]})
        await this.setShowLoaderStatus(true);
        await this.loadSubAccountData();
        await this.getCurrentPlanInfo();
        await this.setShowLoaderStatus();
    };

    getCurrentPlanInfo = async () => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_CURRENT_PLAN_INFO",
            "isAgency": true,
        }
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if(response.issuccess){
            this.setState({
                currentPlanInfo: response.data,
            })
        }
    }
    
    onViewAccountAction = (row) => {
        let { data_row } = row;
        let appid = data_row.appid;
        setSessionValue("isSubAccountView", true);
        setSessionValue("subAccountAppid", appid);
        window.location = "#/login_check";
    }

    onViewAccountSettings = (row) => {
        this.setState({
            accountInfo: row,
            showDetailed: true
        })
    }

    loadSubAccountData = async (searchValue) => {
        let agentId = getOrganizationInfo("Account_ID");
        let { selectedValue } = this.state;
        if (agentId !== "" && agentId !== undefined) {
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "LOAD_SUBACCOUNTS",
                "AGENT_ID": agentId,
                "SearchStr": searchValue,
                "filter":selectedValue
            }
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
                let data = formresponse.data;
                let subaccounts = data[0] ?? [];
                let columndetails = data[1] ?? [];
                let curCount = await getSubaccountCount();
                let maxAccounts = getSessionValue('max_accounts_allowed');
                let maxLimitReached = false;
                let balance_days = getSessionValue("balance_days");
                let customer_type = getSessionValue("customer_type");
                if(customer_type === "Trial" && (parseInt(curCount) >= parseInt(maxAccounts) || (maxAccounts === undefined || maxAccounts === null || maxAccounts === '' )) && balance_days !== "UL" && balance_days <= 0){
                    maxLimitReached =  true;
                }
                this.setState({
                    limitReached:maxLimitReached
                });
                await this.preparesubAccountData(subaccounts, columndetails);
            }
        }
    }

    /*loadSubAccountData = async () => {
        let formDetail = await populateFormDetails("table_201");
        let { selectedTemplate } = await getTemplatesFormDetails(formDetail);
        let SubAccountsData = await loadFormData("table_201", formDetail, selectedTemplate);
        if (SubAccountsData.issuccess) {
            let { formData, listFields } = SubAccountsData;
            this.preparesubAccountData(formData, listFields);
        }
    }*/
    preparesubAccountData = async (formData, listFields) => {
        let data = [];
        let columns = [];
        let isAdmin = await checkIsAdmin();
        this.setState({isAdmin:isAdmin});
        let tableData = await prepareCustomTableData(formData, listFields, isAdmin ? true : false, false, this.onViewAccountSettings);
        data = tableData.data;
        data = data.map((fd, index) => {
            fd["view_as_agency"] = 'Access';
            fd["account_settings"] = 'Manage';
            return fd;
        });
        columns = tableData.columns;

        isAdmin && !this.state.limitReached && columns.push(
             {
                name: "Action",
                selector: 'access',
                minWidth: '105px !important',
                cell: (row) => (
                    <div>
                        <Button onClick={() => this.onViewAccountAction(row)} className="access-account-btn theme-bg-g">
                            <span>{row['view_as_agency']}</span>
                            <i class="ml-2 fi fi-bs-arrow-up-right-from-square"></i>
                        </Button>
                    </div>
                ),
                style: {
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center'
                }
            },

        );
        this.setState({
            data, columns
        });
    }

    handleProviderChange = async (option) => {    
        const selectedProvider = providers.find(provider => provider.name === option.value);
        const providerFields = selectedProvider ? selectedProvider.fields : [];
        let concurrency_limit = 8;
        if(option.value === "bland" || option.value === "vapi"){
            concurrency_limit = 8;
        }else if(option.value === "retell"){
            concurrency_limit = 16;
        }
        this.setState({
            selectProviderList: option,
            providerName: option.value,
            showAPI: true,
            providerFields:providerFields,
            providerData:{concurrency_limit:concurrency_limit}
        });    
    };
    onProviderInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            providerData: {
                ...prevState.providerData,
                [name]: value,
            },
        }));
    };
    

    
    getModalContent = () => {
        let { isModalOpen, errorMessage, showButtonLoading,providerName,provider,showAPI,providerFields } = this.state;
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={this.closeModal}>
                        Add Sub Account
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            <Label className='field-container-title'>Provide a Sub Account Name</Label>
                                <Input className="mb-2" onChange={e => { this.handleInputChange(e) }} maxLength={100} required value={this.state.subAccountName}></Input>
                                <div>                            
                                    {errorMessage['subName'] !== "" && <em className="error">{errorMessage['subName']}</em>}
                                </div>
                            <Label className='field-container-title'>Choose an AI Voice Provider</Label>
                                <div className=''>
                                    <Select
                                        isClearable={false}
                                        onChange={e => { {this.handleProviderChange(e) }} }
                                        placeholder={"Choose a ai name"}
                                        options={providersSelectList}
                                        value={this.state.selectProviderList}
                                        className='basic-multi-select p-0'
                                        classNamePrefix="wa-select"
                                        isSearchable={false}
                                    />
                                </div>
                                {showAPI &&
                                    <div className='mt-4'>
                                        <Label className='field-container-title'>Connect {providerName.toUpperCase()} with Your API Key</Label>
                                        <div className='field-details'>
                                            {
                                                <>
                                                <Row key={providerName}>
                                                    {providerFields.map((field) => {
                                                        const { name, label, type } = field;
                                                        let fname = providerName + "_" + name;
                                                        return (
                                                            <Col key={fname} md={12} className={'pt-1'}>
                                                                <label className={'form-label'} htmlFor={fname}>{label}</label>
                                                                <input
                                                                    onChange={(e) => {this.onProviderInputChange(e);}}
                                                                    type={type}
                                                                    name={fname}
                                                                    id={fname}
                                                                    autoComplete={"off"}
                                                                    className={`form-control`}
                                                                />
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                             </>
                                            }
                                        </div>
                                    </div>
                                }
                        </Fragment>
                        <div>
                            {errorMessage['apiError'] && <em className="error">{errorMessage['apiError']}</em>}</div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-center'>
                        <Loading showLoader={showButtonLoading} mainContent={
                            <Fragment><Button className='container-footer-save d-flex flex-center align-center theme-bg-g' onClick={e => { this.addsubAccount(e) }}>
                                Connect  And Sync Account
                            </Button>
                            </Fragment>
                        } />
                    </ModalFooter>
                    {showAPI && 
                        <div className='ps-3'>
                            <p className='ps-1 pt-2'>Need help? <a href='https://help.voicemetrics.ai/en/category/ai-voice-platforms' target='_blank' className='provider-url-btn'>Click here</a> to learn how to generate your API key on AI Voice platforms.</p>
                        </div>
                    }
                </Modal>
            </>
        );
    }

    handleInputChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z0-9 _-]*$/;
        if (regex.test(value)) {
            this.setState(prevState => ({
                subAccountName: value,
                errorMessage: {
                    ...prevState.errorMessage, // Keep existing errors
                    subName: (value !== undefined && value !== "") ? '' : prevState.errorMessage.subName
                }
            }));
        } else {
            this.setState(prevState => ({
                errorMessage: {
                    ...prevState.errorMessage, // Keep existing errors
                    subName: "Special characters are not allowed." // Add/update the error for subName
                }
            }));
        }
    }

    addsubAccount = async (e) => {
        e.preventDefault();
        let { subAccountName,showAPI,providerData,providerName } = this.state;
        let errorMessage = "";
        let name = "";
        if(!showAPI){
            errorMessage = "Please Choose provider.";
            name = "apiError"
        }
        if(showAPI){
            if (providerData[providerName+"_api_key"] === undefined || providerData[providerName+"_api_key"] === null || providerData[providerName+"_api_key"].trim() === "") {
                errorMessage = "Please provide all keys.";
                name = "apiError"
            }
            if((providerName === "vapi") && providerData[providerName+"_pub_key"] === undefined || providerData[providerName+"_pub_key"] === null || providerData[providerName+"_pub_key"] === ""){
                errorMessage = "Please provide all keys.";
                name = "apiError"
            }
        }
        
        if (subAccountName.trim() === "" || subAccountName === undefined) {
            this.setState(prevState => ({
                errorMessage: {
                    ...prevState.errorMessage, // Keep existing errors
                    subName: "Sub Account Name is required." // Set the specific error
                }
            }));
        }else if(errorMessage !== ""){
            this.setState(prevState => ({
                errorMessage: {
                    ...prevState.errorMessage, // Keep existing errors
                    [name]: errorMessage // Set the specific error
                }
            }));
        } else {
            await this.setShowLoaderStatus(true);
            this.setState({
                errorMessage: {},
                showButtonLoading: true,
            })
            let params = {
                "ENTITY": "AIPROCESSOR",
                "OPERATION": "ADD_SUBACCOUNT",
                "accountname": subAccountName,
                "isAgencyInvite": true,
                "agency_id": getOrganizationInfo("Account_ID"),
                "agency_appid": getSessionValue('appid')
            }
            let formresponse = await requestApiService.processServerRequest(params, "WebV2");
            if (formresponse.issuccess) {
                let data = formresponse.data !== undefined && formresponse.data !== null && formresponse.data !== "" ? formresponse.data[0] : "";
                if(data !== undefined && data !== null &&data !== ""){
                    let appid = data.appid;
                    let account_id = data.account_id;
                    let params = {
                        'ENTITY': 'AIPROCESSOR',
                        'OPERATION' : 'SAVE_API_DETAILS',
                        'PROVIDER_DATA' : providerData,
                        'PROVIDER' : providerName,
                        'ACCOUNT_ID' : account_id,
                        "SUBACCOUNTID" : appid
                    };
                    let response = await requestApiService.processServerRequest(params);
                    if(response.issuccess){
                        showToast(translateContent("vm.subaccount.sync.success"));
                    }else{
                        handleErrorResponse(translateContent("vm.subaccount.sync.failed")+response.errormsg);
                    }
                }
                setSessionValue('menus', JSON.stringify(formresponse.data[1]));
                this.closeModal();
                setTimeout(async() => {
                    window.location.reload();
                    await this.loadSubAccountData();
                }, 2000);
               
            }
            await this.setShowLoaderStatus();
        }
    }
    handlesubAccount = async () => {
        let { currentPlanInfo, data } = this.state;
        let isBtnEnabled = false;
        let cur_sub = data.length;
        if(Object.keys(currentPlanInfo).length > 1){
            let feature = currentPlanInfo.features_included;
            if(feature !== ""){
                feature = parseJSON(feature);
                let max_sub = feature.max_subaccount;
                let cur_sub = data.length;
                if(max_sub === "UL" || max_sub > cur_sub){
                    isBtnEnabled = true;
                }else{
                    let header = "Kind Attention!";
                    let body = `You've reached your sub-account limit (${cur_sub}/${max_sub}). Upgrade now to add more!`;
                    let confirmbtn = "Upgrade Now";
                    let cancelbtn = "Cancel";
                    let confirm = await confirmBoxWithImage(header, body, 'warning', confirmbtn, cancelbtn);
                    if(confirm){
                        window.location.hash = "#/subscription";
                    }
                }
            }
        }else{
            // this is trial plan customers
            isBtnEnabled = true;
        }
        if(isBtnEnabled){
            this.setState((prevState) => ({
                isModalOpen: !prevState.isModalOpen,
            }));
        }
    }

    closeModal = (name) => {
        this.setState((prevState) => ({
            isModalOpen: !prevState.isModalOpen,
            errorMessage: {},
            showButtonLoading: false,
            subAccountName: '',
            selectProviderList : {},
            providerName : providersSelectList[0].value,
            providers:providers,
            showAPI : false,
            providerFields:[],
            providerData:{concurrency_limit:8}
        }));
    }

    backToViewAction = async () => {
        this.setState({
            accountInfo: {},
            showDetailed: false
        })
        await this.loadSubAccountData();
    }

    handleChange = async (selectedOption) => {
        this.setState({ selectedValue: selectedOption }, async () => {
            await this.loadSubAccountData();
        });
    };    
    getTitleBody = (showDetailed) => {
        if (showDetailed) {
            return "";
        }
        return <div className='d-flex align-center justify-end w-100'>
            <Col md={this.state.isAdmin && !this.state.limitReached ? 4 : 6}  className={cx("", {
                "hide" : isMobile()
            })}>
                <div className='le-form-row'>
                    <div className="input-with-icon">
                        <span className="search-icon">
                            <i class="icon-color fi fi-rs-search"></i>
                        </span>
                        <Input
                            id="userName"
                            autoComplete="off"
                            placeholder="Search by name"
                            onChange={(e) => {
                                this.handleSearch(e)
                            }}
                            value={this.state.searchStr}
                            className='search-input'
                        />
                    </div>
                </div>
            </Col>
            <Col md={this.state.isAdmin && !this.state.limitReached ? 4 : 6} className={cx("pl-2", {
                "hide" : isMobile()
            })}>
                <Select
                    name="selected_form"
                    id="selected_form"
                    placeholder="All Account"
                    className="basic-select"
                    classNamePrefix="sub-select wa-select"
                    value={this.state.selectedValue} 
                    options={this.state.options} 
                    onChange={this.handleChange}
                />
            </Col>
            {
                this.state.isAdmin && !this.state.limitReached &&
                <div className='pl-2'>
                    <Button onClick={e => this.handlesubAccount()} size="lg" className='theme-bg-g' color="dark"><FontAwesomeIcon icon={faPlus} /> Add Sub Account</Button>
                </div>
            }
        </div>;
    }

    handleSearch = async (e) => {
        let value = e.target.value;
        this.setState({
            searchStr: value
        })
        await this.loadSubAccountData(value)
    }

    render() {
        let { data, columns, showDetailed, accountInfo, isModalOpen } = this.state;


        return (
            <Fragment>
                <Common
                    title={"Sub Accounts"} titleContent={
                        <span>{showDetailed ? <span className='custom-list-title'>{"Sub Account / "}<span className='highlight-name'>{accountInfo.account_name}</span></span> : "Sub Accounts"}</span>
                    }
                    titleBody={this.getTitleBody(showDetailed)}
                    mainContent={
                        <Fragment>
                            <div className='d-grid  w-100 '>

                                {!showDetailed && <div className={`${data.length === 0 ? 'w-100' : 'subaccount-list'}`}>
                                    <Fragment>
                                        <div className='list-view-header mt-2 w-100 mb-2 d-flex hide'>
                                            <Row className='w-100'>
                                                <Col md={6} className='rdw-image-left'>
                                                    <div class="d-grid">
                                                        <span class="heading">{"Subaccounts"}</span>
                                                        <span class="header-desc">{""}</span>
                                                    </div>
                                                </Col>
                                                <Col md={6} className='rdw-image-right'>

                                                </Col>
                                            </Row>
                                        </div>

                                        {
                                            this.state.showLoader ? ( 
                                            <Loading showLoader={this.state.showLoader} mainContent={""}
                                            />) : (
                                                    !this.state.showLoader ? data.length === 0 ? <NoRecordFound
                                                    img={aiData}
                                                    isOnlyContent={true}
                                                    content={"No Users Found."}
                                                /> :
                                                    <ListTable data={data} columns={columns} onSort={this.onSort} /> : (data.length === 0 ? <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>:<ListTable data={data} columns={columns} onSort={this.onSort} />)
                                                    )
                                        }
                                        {/* {
                                            !this.state.showLoader ? data.length === 0 ? <NoRecordFound
                                            img={aiData}
                                            isOnlyContent={true}
                                            content={"No Users Found."}
                                        /> :
                                            <ListTable data={data} columns={columns} onSort={this.onSort} /> : (data.length === 0 ? <NoRecordFound img={aiData} isOnlyContent={true} content={"No Users Found."}/>:<ListTable data={data} columns={columns} onSort={this.onSort} />)
                                        } */}
                                    </Fragment>

                                </div>}
                                {showDetailed && <div className='col-md-12'>
                                    <SubaccountDetails backToViewAction={this.backToViewAction} openSubAccountView={this.onViewAccountAction} accountInfo={accountInfo} limitReached={this.state.limitReached}/>
                                </div>}
                            </div>
                            {
                                isModalOpen && this.getModalContent()
                            }
                        </Fragment>
                    } />


            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAccounts);
