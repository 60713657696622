import React, { Fragment, useState, useEffect } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import CountUp from "react-countup"; 
import requestApi from '../../services/requestApi.service';
import Loading from "../../Common/Loading";

const CallLogAnalysisSummary = (props) => {
    let { lg = 4, dateFilter, searchStr, totalCount, adFilter,adFilCond, isMobile} = props; 
    let outboundFilter = '';
    let statusFilters = '';
    if(adFilter && adFilter.filterValue){
        dateFilter = adFilter.filterValue.date;
        outboundFilter = adFilter.filterValue.outbound;
        statusFilters = adFilter.filterValue.status;
    }
    const [summaryData, setSummaryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const getSummaryBox = async () => {
        setLoading(true); 
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CALL_LOG_ANALYSIS_SUMMARY",
            FORMNAME: 'table_37',
            FILTER_COND: adFilCond,
            dateFilter: dateFilter,
            searchStr: searchStr,
            outboundFilter: outboundFilter,
            statueFilter: statusFilters,
            isOtherApiReq: true,
        };

        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                setSummaryData(response.data);  
                props.totalCount(response.data[0]['body']);
                setLoading(false); 
            }
        } catch (error) {
        } finally {
            setLoading(false);  
        }
    };
    useEffect(async () => {
        await getSummaryBox();
    }, [adFilter,adFilCond]);  

    return (
        <Fragment>
            <Loading 
                showLoader={loading} 
                mainContent={
                    <div className="summary-container ms-3">
                        <Row md={12}>
                            {summaryData && summaryData.map((val, index) => {
                                const { header, body, prefix, suffix,subValue } = val;
                                const isFloat = body % 1 !== 0;  
                                
                                return (                                        
                                    <Col md="3"  key={index} className="calllog-widget"> {/* lg={lg} */}
                                        <div className="card widget-chart text-start"> {/* widget-chart text-start */}
                                            <div className="widget-chart-content">
                                            <div className="widget-subheading callog-summary-header">{header}</div>
                                                <div className="widget-calllog-numbers callog-summary-footer"> {/* widget-numbers-calllog */}
                                                        <CountUp
                                                                start={0}
                                                                end={body}  
                                                                separator=","
                                                                decimals={isFloat ? 2 : 0}  
                                                                decimal="."
                                                                prefix={prefix}  
                                                                useEasing={false}
                                                                duration={3}  
                                                                className="vi-success-text"
                                                            />
                                                    <span className="mr-1">
                                                    {/* <span className="call-sub-count vi-success-text">{suffix}</span> */}
                                                    <span className="call-sub-count vi-success-text me-1">{suffix}</span>
                                                        {Object.keys(subValue).length > 0 &&(
                                                            (() => {
                                                                const { subprefix, subbody, subsuffix } = subValue??'';
                                                                return (
                                                                    <CountUp
                                                                        start={0}
                                                                        end={subbody}  
                                                                        prefix={subprefix}  
                                                                        suffix={subsuffix}  
                                                                        useEasing={false}
                                                                        duration={3}  
                                                                        className="vi-success-text call-sub-count"
                                                                    />
                                                                );
                                                            })()
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                }
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLogAnalysisSummary);
