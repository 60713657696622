import React, { Fragment, useEffect, useState } from 'react';
import requestApi from '../../services/requestApi.service';
import { CallsUsage } from './CallsUsage';
import { callUsageList } from '../Agency/AgencyDashBoard/constant';

export const AgencyCallDetails = ({ demo,paymentType,customerName,dateFilter }) => {
    const [callsGraphData, setCallsGraphData] = useState([]);
    useEffect(() => {
            fetchCombinedCallsGraphData(callUsageList);
    }, [demo,paymentType,customerName,dateFilter]);

    const getRequestJsonObj = (operation) => ({
        ENTITY: "AIPROCESSOR",
        OPERATION: operation,
        isOtherApiReq: true,
        datefilter:dateFilter,
        demo:demo,
        paymentType:paymentType,
        customerName:customerName,
    });

    const fetchCombinedCallsGraphData = async () => {
        try {
            const data = await Promise.all(
                callUsageList.map(async (chart, index) => {
                    let operation = chart.operation;
                    let params = getRequestJsonObj(operation);
                    let response = await requestApi.processServerRequest(params);
                    if (response.issuccess) {
                        let data = response.data;
                        let processedData = [...data];
                        return processedData;
                    }
                    return null;
                })
            );
            setCallsGraphData(data.filter(item => item !== null));
        } catch (error) {
            console.error("Error fetching combined call usage data:", error);
        }
    };
    return (
        <Fragment>
            <CallsUsage callList={callsGraphData}></CallsUsage>
        </Fragment>
    );
};
