import React, { createContext, useContext, useState, useEffect } from 'react';
import { getSessionValue, parseJSON, setSessionValue } from '../Common/Appcommon';

const ThemeContext = createContext();

let globalTheme = localStorage.getItem('theme') || 'light-theme';

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
        // Get the saved theme from localStorage
        return localStorage.getItem('theme') || 'light-theme';
    });
    const [subCurrency, setSubCurrency] = useState();
    const [subCurrencyISO, setSubCurrencyISO] = useState("");
    const [subCurrencyIcon, setSubCurrencyIcon] = useState("");
    const [agencyCurrency, setAgencyCurrency] = useState();
    const [agencyCurrencyISO, setAgencyCurrencyISO] = useState();

    useEffect(() => {
        let a = parseJSON(getSessionValue("aCurrency"));
        let s = parseJSON(getSessionValue("sCurrency"));
        let newData = {};
        newData["agency"] = a;
        newData["subaccount"] = s;
        processCurrency(newData);
    },[])

    const toggleTheme = (newTheme) => {
        //const newTheme = theme === 'light-theme' ? 'dark-theme' : 'light-theme';
        globalTheme = newTheme;
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme); // Save to localStorage
    };


    const processCurrency = (currencyList) => {
        if(Object.keys(currencyList).length > 0){
            let acurrency = currencyList["agency"];
            let subaccount = currencyList["subaccount"];
            if(acurrency){
                setAgencyCurrency(acurrency.name ?? "$");
                setAgencyCurrencyISO(acurrency.value ?? "usd");
                setSessionValue("aCurrency", JSON.stringify(acurrency))
            }
            if(subaccount){
                setSubCurrency(subaccount.name ?? "$");
                setSubCurrencyISO(subaccount.value ?? "usd");
                setSubCurrencyIcon(subaccount.icon ?? "");
                setSessionValue("sCurrency", JSON.stringify(subaccount))
            }
        }
    }

    useEffect(() => {
        document.body.className = theme; // Apply theme to body
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ 
            theme, 
            toggleTheme, 
            processCurrency,
            subCurrency,
            subCurrencyISO,
            agencyCurrency,
            agencyCurrencyISO,
            subCurrencyIcon,
        }}>
            <div className={""}>{children}</div>
        </ThemeContext.Provider>
    );
};

export function getGlobalTheme() {
    return globalTheme;
}
