import httpCommon from "../Helpers/httpCommon";
import {
    clearSessionValue, confirmBox,
    getSessionValue, setSessionValue, getLogoutURL, setLicenseErrorStatus
} from "../Common/Appcommon";

class RequestApiService {

    customSuccessResponses = [
        'Password has been sent to your emailid.',
        'Password changed successfully',
        'Record deleted successfully! Number of users in active status',
    ];

    constructor() {
        let loginemail = getSessionValue('loginemail');

        this.state = {
            loginemail: loginemail,
            csrfToken: '',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }

    async processServerRequest(params = [], requestType = "WebV2", method = 'post', isLoginValidation = false) {
        let appid = getSessionValue('appid');
        if(appid === undefined || appid === "undefined"){
            appid = "";
        }
        
        let isSubAccountView = getSessionValue("isSubAccountView");
        let sappid = getSessionValue("subAccountAppid");

        requestType = "WebV2";
        method = 'post'
        if (!isLoginValidation && (appid === null || appid === "") /*( params['ENTITY'] !== "login_check" || ( params['ENTITY'] === 'login_check' && (appid === null || appid === "" ) ) )*/) {
            //check user logged in or not.
            let validationParams = {
                "ENTITY": "ai_login"
            }
            let validationResponse = await this.processServerRequest(validationParams, "WebV2", 'post', true);
            //Redirect to login page if user not logged in.
            if (!validationResponse.issuccess) {
                clearSessionValue();
                window.location = getLogoutURL();//process.env.REACT_APP_CRATIO_LOGIN_URL;
            } else {
                let validationres = validationResponse.data;

                //update license status in session.
                if (validationres !== undefined && validationres.length > 0 && validationres[8] !== undefined) {
                    if (validationres[8][0] !== undefined && validationres[8][0][0] !== undefined && validationres[8][0][0] !== "" && validationres[8][0][0] !== null) {
                        setLicenseErrorStatus("header_exp_noti", 'true');
                        setSessionValue('licenseErrorMsg', validationres[8][0][0]);
                    } else {
                        setLicenseErrorStatus("header_exp_noti", 'false');
                        setSessionValue('licenseErrorMsg', '');
                    }
                }
                if (validationres !== undefined && validationres.length > 0 && (appid === "" || appid === null)) {
                    setSessionValue('loginuserid', validationres[0]);
                    setSessionValue('loginusername', validationres[1]);
                    setSessionValue('loginuseremail', validationres[2]);
                    setSessionValue('appid', validationres[3]);
                    setSessionValue('appTitle', validationres[4] !== undefined && validationres[4][20] !== undefined && validationres[4][20] !== "" ? validationres[4][20] : "Cratio CRM Software");
                    setSessionValue('menus', JSON.stringify(validationres[6]));
                    setSessionValue('leadformname', validationres[7]);
                    setSessionValue('licenseExpried', validationres[13]);
                    if (params['ENTITY'] === "login_check") {
                        return validationResponse;
                    } else {
                        window.location.reload();
                    }
                }
            }
        }

        let username = getSessionValue('loginusername');
        let loginuserid = getSessionValue('loginuserid');

        if (params['ENTITY'] !== "ai_check" && params['ENTITY'] !== "LoginRequest" && params['ENTITY'] !== "getRedirectPage" && params['OPERATION'] !== "ACTIVATE_AGENCY") {
            params['APPID'] = appid;
            params['productid'] = "";
            params['builderid'] = "";
            params['username'] = username;
            params['usertableid'] = loginuserid;
            params['requestfrom'] = requestType;
            params['minimalApp'] = true;
        }
        if (isSubAccountView && sappid !== "") {
            //if (params['ENTITY'] !== "ai_login") {
                appid = sappid;
                params['APPID'] = sappid;
            //} else if (params['ENTITY'] === "ai_login"){
            //    params['loginSubAccountAppid'] = sappid;
            //}
            params['isSubAccountView'] = isSubAccountView;
            params['username'] = "sadmin";
            params['usertableid'] = "0";
        }
        params['versioncode'] = process.env.REACT_APP_VERSION_CODE;
        let res = [];
        let reqParams = { 'in': JSON.stringify(params) };

        let files = params['file'];
        if(files !== undefined){
            if (params['fileparamname'] !== undefined) {
                reqParams[params['fileparamname']] = files;
            } else {
                reqParams['file'] = files;
            }
        }
        let reqtrack = "&appid=" + appid;
        if (params.hasOwnProperty("ENTITY") && params['ENTITY'] !== "" && params['ENTITY'] !== undefined) {
            reqtrack += "&entity=" + params['ENTITY'];
        }
        if (params.hasOwnProperty("OPERATION") && params['OPERATION'] !== "" && params['OPERATION'] !== undefined) {
            reqtrack += "&operation=" + params['OPERATION'];
        }
        if (method === 'get' || method === 'delete') {
            res = await httpCommon[method]("/appprocessor.php?device=" + requestType + reqtrack, { params: reqParams, withCredentials: true });
        } else {
            res = await httpCommon.post("/appprocessor.php?device=" + requestType + reqtrack, reqParams, {
                withCredentials: true,
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
        }

        let response = res.data;
        let isSuccess = true;
        let errorMsg = "";
        if (response[0] === "" || response[0] === "failed" || this.customSuccessResponses.some((item) => item.includes(response[0].trim()))) {
            isSuccess = true;
        } else {
            isSuccess = false;
            errorMsg = response[0];
            if (errorMsg.indexOf("Please note the following Email Id ") > 0) {
                //isSuccess = true;
                let successMsg = errorMsg.replace(/<br>/g, "");
                successMsg = successMsg.replace(/<br>/g, "\n");
                successMsg = successMsg.replace(/<br>/g, "");

                let isConfirm = await confirmBox("User Creation", successMsg, "success");
                if (isConfirm) {
                    window.location.href = "#/settings/account/users";
                    window.location.reload();
                }
                errorMsg = "";
            } else if (errorMsg.indexOf("Number of users in active status:") > 0) {
                isSuccess = true;
            }
        }
        let responseData = response[2];
        if (params['ENTITY'] !== "login_check") {
            let currentTime = response[3];
            setSessionValue("currentTime", currentTime);
        } else {
            if (response.length > 0 && response[0] !== undefined && isNaN(response[0])) {
                isSuccess = false;
                responseData = [];
            }
            if (response.length > 0 && (response[2] === undefined || (response[2] !== undefined && response[2].length === 0))) {
                isSuccess = false;
            }
        }
        let serverRes = {
            issuccess: isSuccess,
            errormsg: errorMsg,
            data: responseData
        };
        return serverRes;
    }

    async processFileUpload(params = []) {
        let appid = getSessionValue('appid');
        if (params['ENTITY'] !== "login_check") {
            params['APPID'] = appid;
            params['productid'] = "";
            params['builderid'] = "";
            params['username'] = getSessionValue('loginusername');
            params['usertableid'] = getSessionValue('loginuserid');
            params['requestfrom'] = "Web";
            params['minimalApp'] = true;
        }
        let files = params['file'];
        let reqParams = {
            'in': JSON.stringify(params),
        }

        if (params['fileparamname'] !== undefined) {
            reqParams[params['fileparamname']] = files;
        } else {
            reqParams['file'] = files;
        }

        let res = await httpCommon.post("/appprocessor.php", reqParams, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        let response = res.data;
        let isSuccess = true;
        let errorMsg = "";
        if (response[0] === "" || response[0] === "failed") {
            isSuccess = true;
        } else {
            isSuccess = false;
            errorMsg = response[0];
        }
        let serverRes = {
            issuccess: isSuccess,
            errormsg: errorMsg,
            data: response[2]
        };
        return serverRes;
    }
}
const requestApiService = new RequestApiService();
export default requestApiService;
