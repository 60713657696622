import React, { Fragment, useState,useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Row,Col, Button, Label,Input,Card, InputGroup,CardBody } from "reactstrap";
import requestApi from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import {  handleErrorResponse, showToast, getServerDomainURL,parseJSON, translateContent, getDarkThemeColor } from '../../Common/Appcommon';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FormText } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Switch from "react-switch";
import { tr } from 'date-fns/locale';
import cx from "classnames";
import { useTheme } from "../../Context/ThemeContext";

const AgencySettings = (props) => {
    const {accountInfo} = props;
    let {data_row} = accountInfo;
    let account_id = data_row.account_id !== undefined && data_row.account_id !== "" ? data_row.account_id : "";
    const { handleSubmit,formState: { errors },control } = useForm();
    const [inputData,setInputData] = useState({concurrency_limit:8,webhook_sync:false,analysis_prompt:false});
    const [isCopied, setTextCopied] = useState(false);
    const baseUrl = getServerDomainURL();
    let Url = "events/vmhooks.php?accountid=" + account_id;
    const webhookUrl = baseUrl + "/" + Url;
    const [provider , setProvider] = useState();
    const [showLoading,setShowLoading] = useState(false);
    const [showUrl , setShowUrl] = useState(false);
    const {theme} = useTheme();
    const isDark = theme === 'dark-theme'? true : false;

    useEffect(async () => {
        await getproviderData();
    }, []);


    const submitProviderCredentials = async () => {
        let providerData = inputData;
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'SAVE_API_DETAILS',
            'PROVIDER_DATA' : providerData,
            'PROVIDER' : provider,
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNTID" : appid,
            'isSync':false
        };
        setShowLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast(translateContent("vm.subaccount.provider.save.msg"));
            await getproviderData();
        }else{
            if(response.errormsg !== ""){
                handleErrorResponse(response.errormsg);
            }else{
                handleErrorResponse(translateContent("vm.subaccount.provider.error.msg"));
            }
        }
        setShowLoading(false);
    }


    const getproviderData = async () => {
        
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'GET_API_DETAILS',
            "SUBACCOUNTID" : appid
        };
        setShowLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data['providerDetails'];
            let customProvider = response.data['provider'];
            setProvider(customProvider); 
            if(data !== undefined && data.length > 0){
                data = parseJSON(data);
                setShowUrl(false);
                if(data.webhook_sync !== undefined && data.webhook_sync !== null && data.webhook_sync !== "" && data.webhook_sync !== undefined && data.webhook_sync !== null && data.webhook_sync === false){
                    setShowUrl(true);
                }
                setInputData({...data,
                    concurrency_limit: (data.concurrency_limit) || 8, 
                    webhook_sync: data.webhook_sync !== undefined && data.webhook_sync !== null && data.webhook_sync !== "" ? data.webhook_sync : true,
                    analysis_prompt: data.analysis_prompt !== undefined && data.analysis_prompt !== null && data.analysis_prompt !== "" ? data.analysis_prompt : true,
                });
            }
        }
        setShowLoading(false);
    }
    const copyUrl = () => {
        setTextCopied(true);
    }
    const onInputChange = async (e, name,type = "") => {
        let tmpInputData = { ...inputData };
    
        if (type === "Switch") {
            tmpInputData[name] = e;
            if(name === "webhook_sync"){
                setShowUrl(!e);
            }
        } else {
            let name = e.target.name;
            let value = e.target.value;
            tmpInputData[name] = value;
        }
        setInputData(tmpInputData);
    }
    

   
    return(
        <Loading showLoader={showLoading} mainContent={
            <Fragment>
                <form id='permissions-form' onSubmit={handleSubmit(submitProviderCredentials)}>
                    <div className='agency-details gap-4'>
                    <div className='field-details'>
                    <Card className='card-br'>
                        <CardBody className='pb6'>
                            <div>
                                <Row md={8} className='mb-2 d-flex'>
                                    <div className='d-flex gap-3'>
                                        <Switch 
                                            height={20} 
                                            width={40} 
                                            onColor={getDarkThemeColor()} 
                                            className=""  
                                            onChange={(e) => onInputChange(e,"webhook_sync", "Switch")}
                                            name={"analysis"}
                                            checked={(inputData["webhook_sync"] !== "" && inputData["webhook_sync"] !== undefined && inputData["webhook_sync"] !== null) ?  inputData["webhook_sync"] : true}
                                        />
                                        <Label className='agency-setting-title'>Automatically sync call logs after the call ends</Label>
                                    </div>
                                    {/* <Col md = {5}>
                                    </Col> */}
                                </Row>
                                <Row md={8}>
                                    <Col md={12} className={`${(showUrl) ?  'ps-5' : 'hide'}`}>
                                        <Label className='sub-setting-web-title'> Webhooks URL </Label>
                                        <div className='pe-5'>
                                            <div className="d-flex align-items-center w-80 pe-5 me-4 ">
                                                <InputGroup>
                                                    <Input value={webhookUrl} className={`google-ads-webhook-url`} disabled={true} />
                                                    <CopyToClipboard onCopy={copyUrl} text={webhookUrl}>
                                                        <Button className='theme-bg-g'>
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </Button>
                                                    </CopyToClipboard>
                                                </InputGroup>
                                            </div>
                                            {isCopied && (
                                                <FormText color="success" className={cx({ "dark-provider-url-btn" : isDark }, { "provider-url-btn" : !isDark },'mb-2')}>Text has been copied.</FormText>
                                            )}
                                            <p className={cx("gen-description pt-2")}/* 'gen-description pt-2' */>Please ensure the above URL is configured at the agent level to receive post-call events from voice platforms. <a href='https://help.voicemetrics.ai/en/category/ai-voice-platforms' target='_blank' className={cx({ "dark-provider-url-btn" : isDark }, { "provider-url-btn" : !isDark })}>(Learn more)</a></p>
                                        </div>
                                    </Col>
                                </Row>
                                </div>
                                </CardBody>
                            </Card>
                            </div>
                            <div className='field-details'>

                            <Card className='card-br'>
                                <CardBody className='pb-3'>
                                <Row md={8}>
                                    <div  className='d-flex gap-3'>
                                        <Switch 
                                            height={20} 
                                            width={40} 
                                            onColor={getDarkThemeColor()} 
                                            className=""  
                                            onChange={(e) => onInputChange(e,"analysis_prompt", "Switch")}
                                            name={"analysis"}
                                            checked={inputData["analysis_prompt"]}
                                        />
                                        <Label className='agency-setting-title'>Automatically update the Analysis prompt</Label>
                                    </div>
                                    {/* <Col md={5}>
                                    </Col> */}
                                </Row>
                                </CardBody>
                            </Card>
                            </div>
                            <div className='field-details'>

                            <Card className='card-br'>
                                <CardBody className=''>
                                    <Col md={12} className=''>
                                        <Label className='agency-setting-title'>Set the Maximum Concurrent Outbound Calls Allowed</Label> {/* Call Concurrency Limit (for Outbound Calls) */}
                                    </Col>
                                    <Col md={12} className="mt-2 ps-4 ms-3 pe-4">
                                        <div className='w-80 pe-5 me-3'>
                                            <div className="d-flex w-80 align-items-cente pe-5">
                                                <Controller
                                                    name='concurrency_limit'
                                                    control={control}
                                                    rules={{ required: (inputData.concurrency_limit === undefined || inputData.concurrency_limit === "") ? "Please provide concurrency limit" : false}}
                                                    render={({ field }) => {
                                                        return (
                                                            <input
                                                                {...field}
                                                                type="number"
                                                                name='concurrency_limit'
                                                                defaultValue={8}
                                                                value={inputData.concurrency_limit}
                                                                autoComplete={"off"}
                                                                onChange={(e) => {
                                                                    onInputChange(e,"concurrency_limit");
                                                                    field.onChange(e);
                                                                }}
                                                                className={`form-control ${errors.concurrency_limit ? 'is-invalid' : ''}`}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                        {errors.concurrency_limit && <em className={`error invalid-feedback`}>{errors.concurrency_limit.message}</em>}
                                        </div>
                                    </Col>
                                    <p className='gen-description ps-1 pt-2 ps-4 ms-3'>Please specify the Concurrent Calls Limit for outbound calls (e.g., if the limit is 10, allocate 8 for outbound calls).</p>
                                    <div className="d-flex justify-center mt-4">
                                        <Button size="lg" className='theme-bg-g'>
                                            {"Save Credentials"}
                                        </Button>
                                    </div>
                        </CardBody>
                    </Card>
                   </div>
                   </div>
                </form>
                
            </Fragment>
        }/>
    )
}
const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgencySettings);