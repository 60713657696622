import React, { Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from "reactstrap";
import Loading from "../../Common/Loading";
import Testsmtp from "./TestSMTPConnection";
import { modalOptions } from "./Constants";
import Select from "react-select";

const ModalContent = ({ isModalOpen,  closeModal, DetailsKey, IntegrationsKey, handleInputChange, errorMessage, showButtonLoading, connectAPI, buttonStatusValues, }) => {
    let modalFields = [];
    let modalLable = [];
    if (modalOptions[DetailsKey] !== undefined) {
        const { fields, label } = modalOptions[DetailsKey];
        modalFields = fields;  
        modalLable = label;
    }

    return (
      <>
        { DetailsKey !== 'test_connect' ?  
          ( <Modal isOpen={isModalOpen}>
                <ModalHeader toggle={closeModal}>
                Connect {modalLable}
                </ModalHeader>
                <ModalBody>
                    {modalFields && modalFields.map((field, fieldIndex) => (
                        <Fragment key={fieldIndex}>
                            <Label>{field.label}</Label>
                            {field.type === "dropdown" ? (
                                <Select
                                    placeholder={`Select ${field.label}`}
                                    name={field.name}
                                    className="mb-2"
                                    classNamePrefix="wa-select"
                                    value={
                                        IntegrationsKey[field.name]
                                        ? {
                                            value: IntegrationsKey[field.name],
                                            label: field.options.find(option => option.value === IntegrationsKey[field.name])?.label
                                        }
                                        : null
                                    }
                                    options={field.options}
                                    onChange={(e) => handleInputChange(e, field.name)}
                                />
                            ) : (
                                <div>
                                <Input
                                    className="mb-2"
                                    type={field.type}  // Directly setting the type
                                    name={field.name}
                                    value={IntegrationsKey[field.name] || ""}
                                    onChange={(e) => handleInputChange(e, field.name)}
                                    //maxLength={1000}
                                    required
                                />
                                {field.desc && <span>{field.desc}</span>}
                                </div>
                            )}
                        </Fragment>
                    ))}
                    {errorMessage && <div><em className="error">{errorMessage}</em></div>}
                </ModalBody>
                <ModalFooter>
                    <Loading
                        showLoader={showButtonLoading}
                        mainContent={
                            <Fragment>
                                {isModalOpen && (
                                    <Button className="container-footer-save theme-bg-g" onClick={() => connectAPI(IntegrationsKey, buttonStatusValues)}>
                                        Connect
                                    </Button>
                                )}
                            </Fragment>
                        }
                    />
                </ModalFooter>
            </Modal>) : (<Testsmtp closeModal={closeModal}></Testsmtp>) 
        }
      </>
    );
};

export default ModalContent;
