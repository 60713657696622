import React, { useEffect, useState } from "react";
import ListTable from "../../Common/ListTable";
import requestApiService from "../../services/requestApi.service";

const ProfitListView = (props) => {
    const { dateFilter, searchTxt } = props;
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        getListView();
    }, [dateFilter, searchTxt]);

    const getListView = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_PROFIT_DATA",
            dateFilter: dateFilter,
            searchTxt: searchTxt,
            isOtherApiReq: true,
        };
        let response = await requestApiService.processServerRequest(params);
        if(response.issuccess) {
            let data = response.data;
            setFormData(data);
        }
    }


    const columns = [
        {
            name: (
                <div>
                    S.No
                </div>
            ),
            selector: (row, index) => index + 1,
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "7% !important",
        },
        {
            name: (
                <div>
                    Sub Account
                </div>
            ),
            selector: row => row["account_name"],
            sortable: true,
        },
        {
            name: (
                <div>
                    Date Reported
                </div>
            ),
            selector: row => row["call_date"],
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "14% !important",
        },
        {
            name: (
                <div>
                    Total Minutes
                </div>
            ),
            selector: row => parseFloat(row["call_duration"]),
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "14% !important",
        },
        {
            name: (
                <div>
                    Total Cost
                </div>
            ),
            cell: (row) => {
                let call_cost = parseFloat(row["call_cost"]);
                call_cost = call_cost.toFixed(3);
                return(
                    <>
                        { call_cost }
                    </>
                )
            },
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.call_cost) - parseFloat(b.call_cost), // ✅ Numeric sorting
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "12% !important",
        },
        {
            name: (
                <div>
                    Total Calls
                </div>
            ),
            selector: row => parseInt(row["calls_count"]),
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "12% !important",
        },
        {
            name: (
                <div>
                    Provider Cost
                </div>
            ),
            cell: (row) => {
                let provider_cost = parseFloat(row["provider_cost"]);
                provider_cost = provider_cost.toFixed(3);
                return(
                    <>
                        { provider_cost }
                    </>
                )
            },
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.provider_cost) - parseFloat(b.provider_cost), // ✅ Ensures numeric sorting
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "14% !important",
        },
        {
            name: (
                <div>
                    Profit
                </div>
            ),
            cell: (row) => {
                let profit = parseFloat(row["profit"]);
                
                profit = profit.toFixed(3);
                return(
                    <>
                        { profit }
                    </>
                )
            },
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.profit) - parseFloat(b.profit), // ✅ Ensures numeric sorting
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "10% !important",
        },
    ];

    return(
        <div>
            <ListTable columns={columns} data={formData}/>
        </div>
    );

}

export default ProfitListView;