import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Loading from "../../Common/Loading";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import "./index.css";
import { useTheme } from "../../Context/ThemeContext";
import { getDarkThemeColor } from "../../Common/Appcommon";

const VerticalBarChart = ({ chartData, Loader, defaultChartConfig, type }) => {
    const chartConfig = defaultChartConfig?.[0] || {};
    const [chartValues, setChartValues] = useState(type || 'bar');
    const [seriesData, setSeriesData] = useState(chartData?.[1] || []);
    const [categories, setCategories] = useState(chartData?.[0] || []);
    const constantWidth = "100%"; // Set constant width
    const constantHeight = 450;
    const isHorizontal = chartConfig.isHorizontal || false;
    const download = chartConfig.download || false;
    const dataLabels = chartConfig.dataLabel || false;
    const height = /* chartConfig.height */ 600;
    const width = chartConfig.width;
    const { theme } = useTheme();

    useEffect(() => {
        // Update chart type and data on chart type change
        setChartValues(type || 'bar');
        
    }, [type]);

    useEffect(() => {
        // Update seriesData and categories when chartData changes
        if (chartData?.[0] && chartData?.[1]) {
            setCategories(chartData[0]);  // Set X-axis categories
            setSeriesData(chartData[1]);  // Set data for the chart
        }
    }, [chartData]);

    // Dynamically adjust options based on chart type
    const options = {
        chart: {
            type: chartValues || 'bar', // Dynamic chart type
            toolbar: {
                show: false, // Disable the toolbar
            },
        },
        plotOptions: {
            bar: {
                horizontal: isHorizontal,
                startingShape: "flat",
                endingShape: "rounded",
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: dataLabels,
            formatter: (value) => (value === 0 ? "" : value.toLocaleString()),
            style: {
                fontSize: "10px",
                colors: chartValues === 'bar' && theme === "dark-theme" ? ["#fff"] : ["#000"], 
            },
            offsetX: 10,
        },
        xaxis: {
            categories: categories.length > 0 ? categories : ['No Data'], // Ensure categories are available
            labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            offsetY: -3,
        },
        yaxis: {
            
        },
        tooltip: {
            shared: chartValues === 'area', // Shared tooltip for area charts
            intersect: chartValues !== 'area', // Non-intersecting tooltip for other charts
            y: {
                formatter: (value) =>
                    value.toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }),
            },
        },
        stroke: {
            show: chartValues === 'area',
            curve: 'smooth',
            width: 5, // Border width
        },
        colors: [getDarkThemeColor(), '#1e90ff', '#ff6347', '#ffa500'], // Default colors
        
        /* fill: {
            type: chartValues === 'area' ? 'gradient' : 'solid', // Apply gradient only to area chart
            width:10,
            gradient: {
                shade: "light",
                type: "vertical", // Gradient direction
                shadeIntensity: 0.5,
                gradientToColors: ['#A7D8FF', '#A7D8FF'], // Same light color at both ends
                inverseColors: false, // Keep colors as defined
                opacityFrom: 0.5, // Starting opacity
                opacityTo: 0.5,   // Ending opacity
                stops: [0, 100], // Positions of the gradient stops
            },
        }, */
    };

    return (
        <Loading
            showLoader={Loader}
            mainContent={
                !Loader && chartValues !== '' && (
                    <div className="summary-container-calls">
                        <div className="card mb-3 widget-chart text-start">
                            <div className="widget-chart-content">
                                <div className="bar mt-2">
                                    <ReactApexChart
                                        key={`${chartValues}_${type}`} // Use a dynamic key to force re-render
                                        options={options}
                                        series={seriesData}
                                        type={chartValues}
                                        height={constantHeight}
                                        width={constantWidth}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerticalBarChart);
