import React, { Fragment, useState } from 'react';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { Card, CardBody, Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import Common from "../Common";
import { isAgentAccount } from "../../Common/Appcommon";
import Payments from "../Usage/Payments";
import DateFilter from "../../Common/DateFilter";
import { subDays } from "date-fns";

const AllSubPaymentHistory = (props) => { 
    const startDate = subDays(new Date(), 6);
    const endDate = new Date();
    let isagent = isAgentAccount();
    const [dateFilter, setDateFilter] = useState({
        Start_DateTime: [startDate, endDate],
        noofdays: 6,
    });
    const [searchStr, setSearchStrpayid] = useState("");

    const handleDateChange = (startDate, endDate) => {
        if (startDate && endDate) {
          let dateobj = {};
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);
          const timeDifference = endDate.getTime() - startDate.getTime();
          const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
          dateobj["Start_DateTime"] = [startDate, endDate];
          dateobj["noofdays"] = numberOfDays;
          setDateFilter(dateobj);
        }
      };

    const handleSearchpayid = (e) => {
        setSearchStrpayid(e.target.value);  // Update the search payment ID state
    };

    // Title body content for layout
    const getTitleBody = () => {
        return (
            <Row md={12} className="justify-end">
                <Col md={7}>   
                    <div className="d-flex justify-end">
                        <div className="input-with-icon">
                            <span className="search-icon">
                                <i className="icon-color fi fi-rs-search"></i>
                            </span>
                            <Input
                                id="paymentId"
                                className="search-input"
                                autoComplete="off"
                                placeholder="Search by Name / Payment Id"
                                onChange={handleSearchpayid}  // Trigger re-render when value changes
                                value={searchStr}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="d-flex justify-end">
                        <DateFilter
                            handleDateChange={handleDateChange}
                            defaultSelectIndex={3}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    return (    
        <Fragment>   
            {isagent && 
                <Common
                    title="Sub Account Payment History"
                    titleContent={<span>Sub Account Payment History</span>}
                    titleBody={getTitleBody()}
                    mainContent={
                        <div className="agency-details mb-3 w-100">
                            <div className="field-details">
                                <Card className="card-br">
                                    <CardBody className="pb-2">
                                        <div className="card-fields profile-title-info d-grid">
                                            <span className="heading">Invoice History</span>
                                        </div>
                                        <Payments searchStr={searchStr} dateFilter={dateFilter} isAgent={isagent} allSubPayment={"allSubPayment"}/>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    }
            />}
        </Fragment> 
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSubPaymentHistory);
