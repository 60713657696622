import React, { Fragment, useState, useEffect } from 'react';
import "./assets/css/index.css";
import fileIcon from "./assets/images/file.png";
import txtfileIcon from "./assets/images/txt-file.png";
import docfileIcon from "./assets/images/doc-file.png";
import pdffileIcon from "./assets/images/pdf-file.png";
import docxfileIcon from "./assets/images/docx-file.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertUTCToLocalTime, formatDate, formatFileSize, showToast } from '../../Common/Appcommon';
import { Button, Input, InputGroup } from "reactstrap";
import Loading from '../../Common/Loading';
import { confirmBox, getDeleteIcon, getSessionValue, translateContent, uploadFileIntoS3 } from '../../Common/Appcommon';
import { getProviderKey } from '../Agents/ProviderDetails';
import requestApiService from '../../services/requestApi.service';

const FilesMainContent = (props) => {
    let { knbData, showLoading, onFileSuccess } = props;
    const [fileInfo, setFileInfo] = useState({});
    const [copiedId, setCopiedId] = useState(null);
    const [fileTypeImage, setFileTypeImage] = useState(null);
    const [showButtonLoading, setShowButtonLoading] = useState(false);
    const [showDeleteLoading, setShowDeleteLoading] = useState(false);
    useEffect(() => {
        if (knbData?.['details'] !== undefined && knbData?.['details'] !== null && knbData?.['details'] !== "") {
            let files = JSON.parse(knbData['details']);
            if(files['mimetype'] !== undefined && files['mimetype'] !== null){
                if(files['mimetype'].includes('text')){
                    setFileTypeImage(txtfileIcon);
                }else if(files['mimetype'].includes('pdf')){
                    setFileTypeImage(pdffileIcon);
                }else if(files['mimetype'].includes('doc')){
                    setFileTypeImage(docfileIcon);
                }else if(files['mimetype'].includes('docx')){
                    setFileTypeImage(docxfileIcon);
                }else{
                    setFileTypeImage(fileIcon);
                }
            }
            setFileInfo(files);
        }
    }, [knbData]);

    const onCopy = (id) => {
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 1500);
    }


    const handleDownload = async (fileUrl, fileName) => {
        setShowButtonLoading(true);
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName || 'download';
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href); // Clean up URL object
        } catch (error) {
            console.error('Download failed:', error);
        }
        setShowButtonLoading(false);
    };


    const deleteFile = async (id, knbId) => {
        let isFileDeleted = false;
        let isDelete = await confirmBox(translateContent("vm.delete.confirm"), translateContent("vm.delete.confirm.msg"), getDeleteIcon(), 'Delete', 'Cancel');
        if (isDelete) {
            setShowDeleteLoading(true);
            let proName = getSessionValue("agent_provider_name");
            let apikey = getProviderKey(proName);
            const apiUrl = `https://api.vapi.ai/file/${id}`;
            try {
                console.log("apiUrl: ", apiUrl)
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + apikey
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                } else {
                    const result = await response.json();
                    isFileDeleted = true;
                    let params = {
                        "ENTITY": "AIPROCESSOR",
                        "OPERATION": "DELETE_KNB",
                        "ID": knbId
                    }
                    let formresponse = await requestApiService.processServerRequest(params, "WebV2");
                    if (formresponse.issuccess) {
                        showToast(translateContent("vm.delete.success"));
                        onFileSuccess();
                        setShowDeleteLoading(false);
                    }
                }
            } catch (error) {
                setShowDeleteLoading(false);
                isFileDeleted = false;
                console.log("file delete error: ", error)
            }
        }
        return isFileDeleted;
    }



    return (
        <Fragment>
            {Object.keys(fileInfo).length > 0 &&
                < div className='file-viewer-container d-flex items-center flex-col flex-1 w-full'>
                    <div className='file-viewer-flex-container row d-flex w-100'>
                        <div className='col-md-7 file-viewer-left-panel p-6'>
                            <div className='file-viewer-card mb-4 d-flex w-full'>
                                <div className='file-viewer-card-body w-full'>
                                    <div className='file-viewer-stack gap-6 w-full'>
                                        <div className='file-viewer-display w-full p-6 justify-center'>
                                            <div className='w-full d-flex justify-content-center'>
                                                <img className='file-icon-display' src={fileTypeImage} />
                                            </div>
                                            <div class="word-wrap mt-4 text-wrap text-center text-base font-medium text-text">
                                                {fileInfo['name']}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-2 file-info'>
                                        <div>
                                            <label className={'form-label'}>Filename</label>
                                        </div>
                                        <div className={'form-label'}>{fileInfo['name']}</div>
                                        <div className={'form-label'}>
                                            <span>{fileInfo['mimetype']}</span>
                                            <span class="mx-2">•</span>
                                            <span>{formatFileSize(fileInfo['bytes'])}</span>
                                        </div>
                                    </div>
                                    <div className='mt-3 files-btn-container d-flex'>
                                        <div className='badge-theme btn btn-secondary'>
                                            <CopyToClipboard onCopy={() => onCopy("file_url")} text={fileInfo['url']}>
                                                <div><FontAwesomeIcon icon={copiedId === "file_url" ? faCheck : faCopy} />
                                                    <span className='pl-1'>Copy URL</span></div>
                                            </CopyToClipboard>
                                        </div>
                                        <div>
                                            <Loading showLoader={showButtonLoading} mainContent={
                                                <Fragment>
                                                    <button className='btn btn-secondary theme-bg-g' onClick={() => handleDownload(fileInfo['url'], fileInfo['name'])}>Download</button>
                                                </Fragment>
                                            } />
                                        </div>
                                        <div>
                                            <Loading showLoader={showDeleteLoading} mainContent={
                                                <Fragment>
                                                    <button className='btn btn-danger' onClick={() => { deleteFile(fileInfo['id'], knbData['id']) }}>Delete</button>
                                                </Fragment>
                                            } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 file-viewer-right-panel p-6'>
                            <div className='file-viewer-right-panel-body'>
                                <div className='file-viewer-right-panel-stack gap-6'>
                                    <div className='file-viewer-id'>
                                        <label className='field-container-title form-label'>ID</label>
                                        <div className=''>
                                            <div className="d-flex align-items-center">
                                                <InputGroup>
                                                    <Input value={fileInfo['id']} className={`google-ads-webhook-url`} disabled={true} />
                                                    <CopyToClipboard onCopy={() => onCopy("file_id")} text={fileInfo['id']}>
                                                        <Button className='theme-bg-g'>
                                                            <FontAwesomeIcon icon={copiedId === "file_id" ? faCheck : faCopy} />
                                                        </Button>
                                                    </CopyToClipboard>
                                                </InputGroup>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='file-viewer-started-at mt-2'>
                                        <label className='field-container-title form-label'>Created At</label>
                                        <div className=''>
                                            <InputGroup>
                                                <Input disabled={true} value={formatDate(convertUTCToLocalTime(knbData['createdon']), "mmm dd, yyyy, hh:MM:ss TT")} className={`google-ads-webhook-url`} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment >
    )
}

export default FilesMainContent;