import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { confirmBox, formatDate, getLicenseInfo, translateContent,setCookie,getDeleteIcon } from "../../Common/Appcommon";
import { useStepsContext } from "../Subscription/context";
import requestApi from "../../services/requestApi.service";
import { useTheme } from "../../Context/ThemeContext";


const CancelPage = () => {
    const { setCurrentStep, accountid, setShowLoading, appid, isAgency } = useStepsContext();
    const {subCurrency} = useTheme();
    const handleBtnClick = async (name) => {
        if(name === "confirm_cancel"){
            const confirm = await confirmBox(
                translateContent("vm.stripe.cancel.confirm.title"),
                translateContent("vm.stripe.cancel.confirm.msg"),getDeleteIcon()
            );
            if(confirm){
                setShowLoading(true);
                let response = false;
                const params = {
                    ENTITY: "STRIPE_PROCESSOR",
                    OPERATION: "CANCEL_CARD",
                    accountid: accountid,
                    agency_appid: appid,
                    isAgency: isAgency,
                    isOtherApiReq: true,
                    currencysymbol:subCurrency,
                };
                try {
                    let result = await requestApi.processServerRequest(params);
                    if(result.issuccess){
                        response = result.data;
                        if(response){
                            setCookie("header_exp_noti", true, 1, "");
                            setCurrentStep(6);
                            window.location.reload();
                        }
                    }
                } catch (error) {
                    console.error("Error fetching dashboard summary:", error);
                }
                setShowLoading(false);
                return response;
            }
        }else{
            setCurrentStep(6);
        }
    }
    
    let license_end_date = getLicenseInfo("license_end_date");
    if(license_end_date !== ""){
        license_end_date = formatDate(license_end_date, "mmm dd,yyyy");
    }

    return (
        <Fragment>
            <div className="m-3 cancel-page">
                <div className={`listview-container templates-listview kv-main-wrapper h100per content`}>
                    <ul>We’re sorry to see you go. Your subscription will be canceled at the end of your</ul>
                    <ul>current billing cycle on <strong className="error">{license_end_date}</strong>.but we’d love to understand what we can </ul>
                    <ul>do to enhance your experience. Let’s talk! Our support team is ready to assist in </ul>
                    <ul>any way we can.</ul>

                    {/* <ul>We're sorry to hear you’re considering canceling your subscription.</ul>
                    <ul>Your cancellation will take effect at the end of your current billing period on  <strong className="error">{license_end_date}</strong>.</ul>
                    <ul>We truly appreciate the opportunity to serve you. If there's anything more we can do to help, please contact our support team.</ul>
                    <ul>Thank you for being with us!</ul> */}
                    <ul className="cancel-subscription-btn-container">
                        <Button 
                            className="mt-3 m-1 me-2 btn-icon cancel-sub-btn" 
                            onClick={e => handleBtnClick("confirm_cancel")}>
                                Confirm Cancellation
                        </Button>
                        <Button 
                            className="mt-3 m-1 me-2 btn-icon le-button theme-bg-g" 
                            onClick={e => handleBtnClick("cancel_btn")}>
                                Keep Your Subscription
                        </Button>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default CancelPage;