export const callTypes = [
    {
        label: "Inbound",
        value: "inbound"
    },
    {
        label: "Outbound",
        value: "outbound"
    },
    {
        label: "Both",
        value: "both"
    }
];

export const sentTypes = [
    {
        label: "Custom phone number",
        value: "custom_ph_no"
    },
    {
        label: "Contact number",
        value: "contact_no"
    }
];