import React, { Fragment, useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { showToast } from '../../Common/Appcommon';

const CKEditorComponent = (props) => {

    const { mentionItems, onEditorChange, content, isMentionsNeeded, isNew, showToolbar } = props;
    let isToolBarNeeded = showToolbar === undefined ? true : showToolbar;
    let defaultFontFamily = "Verdana, Geneva, sans-serif";
    let defaultFontSize = "14px";

    const applyDefaultStyles = (editor) => {
        editor.execute('fontFamily', { value: defaultFontFamily });
        editor.execute('fontSize', { value: defaultFontSize });
    };
  
    return (
        <div>
            <CKEditor
                key={mentionItems.length}
                editor={CustomEditor}
                data={content}
                config={{
                    toolbar: !isToolBarNeeded ? undefined : {
                        removeItems: [
                                        'imageUpload','horizontalLine', 'image', 
                                        'imageInsert','pictureEditing',
                                        'blockQuote','table','mediaEmbed',
                                        'sourceEditing','specialCharacters'
                                    ],
                        shouldNotGroupWhenFull: false,
                    },
                    fontFamily: {
                        options: [
                            'Arial, Helvetica, sans-serif',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif'
                        ],
                        supportAllValues: true,
                        default: defaultFontFamily
                    },
                    fontSize: {
                        options: [10,12,14,16,18,20,22,24],
                        supportAllValues: true,
                    },
                    mention: {
                        feeds: [
                            {
                                marker: '$',
                                feed: mentionItems,
                                minimumCharacters: 0,
                                dropdownLimit: 100,
                                itemRenderer: (item) => {
                                    return `${item.name}`; // Customize item rendering
                                },
                            }
                        ]
                    }
                }}
                onClick={(evt, editor) => {
                    if (evt.domEvent.target.tagName === 'IMG') {
                    }
                }}
                onReady={editor => {
                    applyDefaultStyles(editor);
                    if (isNew) {
                        editor.editing.view.change(writer => {
                            writer.setStyle(
                                'font-family',
                                defaultFontFamily,
                                editor.editing.view.document.getRoot()
                            );
                            writer.setStyle(
                                'font-size',
                                defaultFontSize,
                                editor.editing.view.document.getRoot()
                            );
                        });

                        // Make Arial the default selection in the fontFamily dropdown
                        editor.commands.get('fontFamily').value = defaultFontFamily;
                        // Make 16px the default selection in the fontSize dropdown
                        editor.commands.get('fontSize').value = defaultFontSize;

                        editor.commands.get('imageUpload').on('execute', () => {
                            showToast("Not Supported","top-right","error");
                        });
                    }
                   
                }}
                onChange={(event, editor) => {
                    let data = editor.getData();
                    data = data.replace(
                        /<table>/g,
                        '<table style="border-collapse: collapse; font-size: 10px; table-layout: fixed; width: 100%; border: 0px solid #000;">'
                    );
                    // Apply inline styles to all <td> elements
                    const updatedData = data.replace(/<td>/g, '<td style="border: 1px solid #ccc; padding: 5px;">');
                    onEditorChange(updatedData, editor);
                }}
            />
        </div>
    );
};

export default CKEditorComponent;

