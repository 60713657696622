import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import AppSidebar from '../../Layout/AppSidebar';
import AppHeader from '../../Layout/AppHeader';
import Loading from '../../Common/Loading';
import { 
    confirmBoxWithImage, 
    getHeaderNotification, 
    getLicenseErrorStatus,
    getSessionValue,
    isAgentAccount,
    isMobile,
    setLicenseErrorStatus,checkIsPageNotFound,translateContent
} from '../../Common/Appcommon';
import offer from "../../assets/utils/images/donot_miss.png";
import noAccessImag from '../../assets/utils/images/403.jpg';

const Common = (props) => {
    const [notification, setNotification] = useState({ show: false });
    const [modal, setModal] = useState(false); // Open modal by default
    const toggle = () => setModal(!modal);  
    
    let { mainContent, titleContent, title, titleBody, mainLoader, demoPopup, demoHandler, mobileViewNeedLeftMenu = false,titleKey,subAccountView=false } = props;
    const [isRedirectNeeded, setIsRedirectNeeded] = useState(false);
    if (title !== undefined && title !== "") {
        document.title = title;
    }

    useEffect(async() => {
        let isPageNotFound = await checkIsPageNotFound(titleKey);
        if (isPageNotFound) {
            setIsRedirectNeeded(isPageNotFound);
            return;
        }
        if(subAccountView){
            setIsRedirectNeeded(subAccountView);
            return;
        }
        async function getNotification() {
            let headernotification = await getHeaderNotification();
            setNotification(headernotification);
        }
        getNotification();
    }, [])

    useEffect(() => {
        processValidLicense();
    }, [])

    const processValidLicense = async () => {
        let popup_exp_noti = getLicenseErrorStatus("popup_exp_noti");
        if(popup_exp_noti){
            let balance_days = getSessionValue("balance_days");
            if(balance_days !== "UL" && balance_days <= 4){
                let isAgency = isAgentAccount();
                let subscription_url = "#/subscription";
                let subAccountUrl = "";
                let current_url = window.location.hash;
                let customer_type = getSessionValue("customer_type");
                if(customer_type === "Trial"){
                    subAccountUrl = "#/subaccounts";
                }
                let title = "";
                let body = "";
                let confirmBtn = "";
                let cancelBtn = "";
                if(customer_type === "Trial"){
                    if(balance_days < 0){
                        title = translateContent("vm.payment.trial.end.title");
                        body = translateContent("vm.payment.trial.end.body");
                        confirmBtn = translateContent("vm.payment.trial.end.confirmbtn");
                    }else{
                        title = translateContent("vm.payment.trial.waring.title");
                        body = translateContent("vm.payment.trial.waring.body");
                        confirmBtn = translateContent("vm.payment.trial.waring.confirmbtn");
                        cancelBtn = translateContent("vm.payment.trial.waring.cancel");
                    }
                }
                if (title !== "" && current_url !== subscription_url && current_url !== subAccountUrl) {
                    if(isAgency && balance_days < 0){
                        let isMaxAccountsReached = getSessionValue('max_accounts');
                        if(current_url !== "#/subaccounts" || (current_url === "#/subaccounts" && !isMaxAccountsReached)){
                            toggle();
                        }
                        /* if(customer_type === "Trial" && balance_days <= 0){
                            toggle();
                        }else{
                            let confirm = customConfirmBox(title, body, "", confirmBtn, cancelBtn, "", "", false, false);
                            if(confirm){
                                window.location.href = subscription_url;
                            }else{
                                setLicenseErrorStatus("popup_exp_noti", "false");
                            }
                        } */
                    }else if(!isAgency){
                        let confirm = await confirmBoxWithImage(title, body, 'diamond-exclamation', confirmBtn, cancelBtn, "", "", false, false);
                        if(confirm){
                            window.location.href = subscription_url;
                        }else{
                            setLicenseErrorStatus("popup_exp_noti", "false");
                        }
                    }
                }else{
                }
            }
        }
    }
    
    const customConfirmBox = () => {
        const redirectPage = () => {
            window.location.hash = "#/subscription";
            toggle();
        }
        return (
            <div className=''>
              <Modal isOpen={modal} className='agency-trial-end-popup' centered>
                <ModalBody>
                    <img src={offer} width={200} height={150} className='mb-3'></img>
                    <h3>Your FREE Trial Has Ended!</h3>
                    <p>Unlock Voicemetrics for a one-time price – No recurring fees, ever!</p>
                    <Button className='buy-btn' onClick={e => redirectPage()}>
                        Buy Lifetime Deal
                    </Button>
                    <p className="mt-2">🔥 Claim your discount before it's gone! 🔥</p>
                </ModalBody>
              </Modal>
            </div>
          );
    }

    const oncloseAction = () => {
        setLicenseErrorStatus("false");
        setNotification(false);
    }

    // let loaderType = "ball-clip-rotate";
    return (
        <Fragment>
            <div className={cx('header-notification', {
                'hide': !notification.show
            }, { "mobile-header": isMobile() })}>
                <div className={'header-notification-body'}>
                    <>
                        {/* {notification.icon && <FontAwesomeIcon icon={faInfoCircle} />} */}
                        {notification.text}
                    </>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={(e) => { oncloseAction(e) }} ></button>
            </div>
            {!isRedirectNeeded && titleContent && <AppHeader titleContent={titleContent} titleBody={titleBody} headerNotifyShow={notification.show} demoPopup={demoPopup} demoHandler={demoHandler} mobileViewNeedLeftMenu={mobileViewNeedLeftMenu}/>}
            <div className="app-main">
            {isRedirectNeeded ? (
                <>
                 <div className='no-records-container'>
                 <div className='d-grid align-center'>
                     <img src={noAccessImag} alt={"Forbidden"}/>
                 </div>
             </div>
             </>) :(
                <>
                    <AppSidebar />
                    <div className="app-main__outer">
                        <Loading showLoader={mainLoader} mainContent={ 
                            <div className="app-main__inner app-normal__inner">
                                <div className={`app-main-content ${!notification.show ? "" : "header-msg"}`}>
                                    {mainContent}
                                </div>
                            </div>
                        } />

                    </div>
                </>)
            }
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Common);
