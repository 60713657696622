//Core modules
import React from 'react';

//Custom modules
import { StepsProvider } from "../Subscription/context.js";
import Usage from './Usage.js';
import "./assets/index.css";

const UsageMain = () => {

    const hash = window.location.hash;

    return (
        <StepsProvider>
            <Usage isUsage = {hash === "#/usage" || hash === "#/plan-billing"}/>
        </StepsProvider>
    )
}

export default UsageMain;