import React, { Fragment, useEffect, useState } from "react";
import twilioimg from "../../assets/utils/images/Intagration image/twilio.svg";
import { cardData } from "./Constants";
import requestApiService from "../../services/requestApi.service";
import ShowIntegrationInfo from "./ShowIntDetails.js";

const Twilio = (props) => {
    let { isInstalation, buttonValue, unInstall, name } = props;
    const [isConnected, setConnectionStatus] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);
    const [carddata, setData] = useState(cardData);
    let key = "";
    let foundData = carddata.find((data) => data.name === "twilio");
    if (foundData) {
        key = foundData.name;
    }

    useEffect(() => {
        (async () => {
            await fetchCalAccountDetails();
        })();
    }, [buttonValue]);

    const fetchCalAccountDetails = async () => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "LOAD_INTEGRATIONS",
            TYPE: name,
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let integrationInfo = response.data;
            if (integrationInfo.length > 0) {
                setConnectionStatus(true);
                const connectedInfo = JSON.parse(integrationInfo[0][2] || "{}");
                const { apiKey, authToken } = connectedInfo.data || {};
                let IntegrationAccountInfo = [
                        { type: "id", id: integrationInfo[0][0] },
                        { type: "text", label: "SID ", value: apiKey },
                        { type: "text", label: "Auth Token ", value: authToken },
                    ];
                setAccountInfo(IntegrationAccountInfo);
            } else {
                setAccountInfo([]);
                setConnectionStatus(false);
            }
        }
    };

    const onOpenModal = (type, openModule = false) => {
        isInstalation(type, openModule, "twilio", "SMS");
    };

    const integrationHeadData = {
        name: "Twilio",
        tag: "SMS",
        image: twilioimg,
        installButtonText: "Install App",
        uninstallButtonText: "Uninstall App",
    };
    // change the twilio Content 
    const integrationContent = [
        { heading: "Website", subheading: "https://cal.com", type: "url" },
        { heading: "Overview", subheading: "The integration with Cal.com transforms scheduling into an effortless experience for your team and customers. By combining ’s advanced AI calling capabilities with Cal.com’s flexible calendar management, businesses can fully automate appointment booking, rescheduling, and reminders. This integration lets you simplify workflows and increase efficiency by enabling AI agents to handle scheduling tasks seamlessly, even in high-volume scenarios.", type: "text" },
        { heading: "Key Benefits", 
            subheading: [
                { 
                    title: "Automated Scheduling and Rescheduling",
                    description: "Free up valuable time by allowing our AI-powered agents to take over appointment bookings and rescheduling tasks. integrates with Cal.com to check real-time availability and instantly confirm bookings on your behalf." 
                },
                { 
                    title: "Enhanced Customer Experience", 
                    description: "Provide a smoother, faster experience by offering 24/7 booking capabilities. AI agents can assist customers anytime, checking availability and scheduling appointments within seconds." 
                },
                { 
                    title: "Real-Time Syncing", 
                    description: "All appointments are automatically synced with your Cal.com calendar, ensuring you and your team have the latest information without needing manual updates." 
                },
        ], type: "list" }
    ];

    return (
        <Fragment>
            <div className="agent-details">
                <ShowIntegrationInfo
                    keyname={name}
                    headData={integrationHeadData}
                    integrationData={integrationContent}
                    isInstalled={buttonValue.includes(name)}
                    isConnected={isConnected}
                    accountInfo={accountInfo}
                    onInstall={onOpenModal}
                    onRemove={unInstall}
                />
            </div>
        </Fragment>
    );
};

export default Twilio;